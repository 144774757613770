import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, CardTitle, ButtonToolbar, ButtonGroup, Label, Input } from 'reactstrap';
import { StoreApi } from '../../storeApi';
import StoreCdnUsage from './StoreCdnUsage';
import StoreCdnSize from './StoreCdnSize';

const CdnStates = ({ store }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [storeCdnUsageLogs, setStoreCdnUsageLogs] = useState(null);
  const [storeCdnSizeLogs, setStoreCdnSizeLogs] = useState(null);
  const [unit, setUnit] = useState('M');

  const getStoreName = () => {
    return (store && store.name) || '';
  };

  useEffect(() => {
    if (store) {
      setStoreCdnUsageLogs(null);
      StoreApi.getStoreCdnUsageLogs(store.id)
        .then((logs) => {
          setStoreCdnUsageLogs(logs);
        })
        .catch((error) => {
          setStoreCdnUsageLogs([]);
          setErrorMessage('Failed to get CDN Usage logs, ' + error.message);
          console.error('cdnUsageLogs error:', error);
        });

      setStoreCdnSizeLogs(null);
      StoreApi.getStoreCdnSizeLogs(store.id)
        .then((logs) => {
          setStoreCdnSizeLogs(logs);
        })
        .catch((error) => {
          setStoreCdnSizeLogs([]);
          setErrorMessage('Failed to get CDN Size logs, ' + error.message);
          console.error('cdnSizeLogs error:', error);
        });
    }
  }, [store]);

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader className="p-3">
              <h5 style={{ marginBottom: 0 }}>Store: {getStoreName()}</h5>
            </CardHeader>
            <CardBody>
              <div>
                <StoreCdnUsage store={store} logs={storeCdnUsageLogs} unit={unit} setUnit={setUnit} />
              </div>
              <hr style={{ width: '100%', marginTop: 40, marginBottom: 40 }} />
              <div>
                <StoreCdnSize store={store} logs={storeCdnSizeLogs} unit={unit} setUnit={setUnit} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

CdnStates.propTypes = {
  store: PropTypes.object,
};

export default CdnStates;
