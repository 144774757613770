import React, { Component } from 'react';
import Select from 'react-select';
import * as storeActions from '../../actions/storeActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

@connect(state => ({
  store: state.store
}))
class XMStoresDropdown extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    selectedOption: null,
    options: []
  };

  static propTypes = {
    options: PropTypes.array.isRequired,
    storeId: PropTypes.number
    // redirectTo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // TODO: Need to have an endpoint for all stores - replace {store.all}
    let options = this.state.options;

    if (this.state.options.length == 0) {
      options = this.props.options.map((option, id) => {
        return { value: option.id, label: option.name };
      });

      this.setState({ options });
    }

    if (this.props.storeId) {
      let selectedOption = options.find(
        option => option.value == this.props.storeId
      );
      this.setState({ selectedOption });
    }
  }

  handleChange = selectedOption => {
    const { onChange, dispatch, storeId, redirectTo } = this.props;

    if (
      this.props.storeId != selectedOption.value ||
      this.state.selectedOption.value != selectedOption.value
    ) {
      this.setState({ selectedOption });

      let storeId = Number(selectedOption.value);

      dispatch(storeActions.getOne(storeId));

      if (redirectTo) {
        redirectTo(storeId);
      }
    }

    onChange(selectedOption);
  };

  render() {
    return (
      <Select
        value={this.state.selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
      />
    );
  }
}

export default XMStoresDropdown;
