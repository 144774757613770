import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

/**
 * @param pagination
 * @param paginate
 */
class XMPagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0
    };

    this.onPreviousPageClick = this.onPreviousPageClick.bind(this);
    this.onSelectedPageClick = this.onSelectedPageClick.bind(this);
    this.onNextPageClick = this.onNextPageClick.bind(this);
  }

  onPreviousPageClick() {
    const { pagination } = this.props;

    this.setState(
      {
        page: pagination.first ? this.state.page : this.state.page - 1
      },
      this.doPaginate
    );
  }

  onSelectedPageClick(page) {
    this.setState(
      {
        page: page
      },
      this.doPaginate
    );
  }

  onNextPageClick() {
    const { pagination } = this.props;

    this.setState(
      {
        page: pagination.last ? this.state.page : this.state.page + 1
      },
      this.doPaginate
    );
  }

  /**
   * Call paginated function, passed into props, with the clicked page
   */
  doPaginate() {
    const { paginate } = this.props;

    paginate(this.state.page);
  }

  render() {
    const { pagination } = this.props;

    return (
      <nav>
        <Pagination>
          {!pagination.first ? (
            <PaginationItem>
              <PaginationLink
                previous
                onClick={() => this.onPreviousPageClick()}
              >
                Prev
              </PaginationLink>
            </PaginationItem>
          ) : null}
          {Array(pagination.totalPages)
            .fill(1)
            .map((el, i) => (
              <PaginationItem key={i} active={pagination.number == i}>
                <PaginationLink onClick={() => this.onSelectedPageClick(i)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          {!pagination.last ? (
            <PaginationItem>
              <PaginationLink next onClick={() => this.onNextPageClick()}>
                Next
              </PaginationLink>
            </PaginationItem>
          ) : null}
        </Pagination>
      </nav>
    );
  }
}

export default XMPagination;
