import { apiConfigHelper } from './apiConfig';
import { createAxios } from './utils';

export const getStoreApi = () => {
  return createAxios(apiConfigHelper.getApiBaseUrl() + '/store-manager/stores/');
};
const _axios = getStoreApi();
export default _axios;

export class StoreApi {
  static error = null;
  static async getStoreCdnUsageLogs(storeId, from, to) {
    try {
      StoreApi.error = null;
      const response = await _axios.get(`${storeId}/stats/cdn-usage-logs`, { from, to });
      if (response.status !== 200) {
        throw new Error(`Failed to get the detailed info for request ${failureRequestCode}`);
      }
      return response.data;
    } catch (err) {
      StoreApi.error = err;
      return false;
    }
  }

  static async getStoreCdnSizeLogs(storeId, from, to) {
    try {
      StoreApi.error = null;
      const response = await _axios.get(`${storeId}/stats/cdn-size-logs`, { from, to });
      if (response.status !== 200) {
        throw new Error(`Failed to get the detailed info for request ${failureRequestCode}`);
      }
      return response.data;
    } catch (err) {
      StoreApi.error = err;
      return false;
    }
  }
}
