import syncApi from '../../syncApi';

export function fetchActivityRequests(storeId, type, perPage, showLoading = true) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_ACTIVITY_REQUESTS_START', payload: { showLoading } });
      let url = `/${storeId}/request?n=${perPage}`;
      if (type === 'current') {
        url += `&q=open`;
      } else if (type === 'recent') {
        url += `&q=all`;
      } else if (type === 'failed') {
        url += `&q=failed`;
      } else {
        throw new Error('Unknown type - ' + type);
      }
      const response = await syncApi.get(url);
      if (response.status === 200) {
        const requests = [];
        Object.keys(response.data).map((id) => {
          requests.push({
            id,
            ...response.data[id],
          });
        });
        dispatch({ type: 'FETCH_ACTIVITY_REQUESTS_SUCCESS', payload: requests });
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_FAILURE_REQUESTS_FAILED',
        payload: { error: error && error.message ? error.message : 'UNKNOWN ERROR' },
      });
    }
  };
}

export function setActivityRequestPerPage(value) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ACTIVITY_REQUEST_PER_PAGE', payload: value });
  };
}

export function setActivityRequestType(value) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ACTIVITY_REQUEST_TYPE', payload: value });
  };
}

export function actionActivityRequest(storeId, action, requestCode, requestReloadFlag) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACTION_ACTIVITY_REQUEST_START',
        payload: {
          code: requestCode,
          action,
        },
      });
      let response = null;
      if (action === 'cancel') {
        response = await syncApi.get(`/${storeId}/request/${requestCode}/stop`);
      } else if (action === 'restart') {
        response = await syncApi.get(`/${storeId}/request/${requestCode}/restart`);
      } else {
        throw new Error(`Unknown Action ${action}`);
      }
      if (response.status === 200 || response.status === 202) {
        dispatch({ type: 'ACTION_ACTIVITY_REQUEST_SUCCESS', payload: response.data });
        dispatch({ type: 'ACTION_ACTIVITY_REQUEST_RELOAD_FLAG', payload: requestReloadFlag + 1 });
      } else {
        throw new Error('incorrect response status - ' + response.status);
      }
    } catch (error) {
      let message = '';
      if (error.status) {
        message += error.status + ' - ';
      }
      message += error.message;
      dispatch({
        type: 'ACTION_ACTIVITY_REQUEST_FAILED',
        payload: { error: message },
      });
    }
  };
}

export function clearActivityRequestAction() {
  return async (dispatch) => {
    dispatch({ type: 'ACTION_ACTIVITY_REQUEST_CLEAR' });
  };
}

export function setRequestActionReloadFlag() {
  return async (dispatch) => {
    dispatch({ type: 'ACTION_ACTIVITY_REQUEST_RELOAD_FLAG' });
  };
}

export function setActivitySelectedTables(tables = []) {
  return { type: 'SET_ACTIVITY_SELECTED_TABLES', payload: tables };
}

export function setActivityIsDeleteBeforeLoad(isDeleteBeforeLoad = false) {
  return { type: 'SET_ACTIVITY_IS_DELETE_BEFORE_LOAD', payload: isDeleteBeforeLoad };
}

export function loadActivityStoreData(storeId, tables = null, isDeleteBeforeLoad = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_START', payload: { tables, isDeleteBeforeLoad } });
      let response;
      if (tables === null) {
        response = await syncApi.get(`/${storeId}/uploadData?truncate=${isDeleteBeforeLoad}`);
      } else {
        if (tables.length === 0) {
          throw new Error('Please select table');
        }
        response = await syncApi.post(`/${storeId}/uploadData?truncate=${isDeleteBeforeLoad}`, { tables });
      }
      dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_STARTED', payload: response.data });
      dispatch(fetchLoadActivityStoreDataRequestDetail(storeId, response.data.id));
    } catch (error) {
      console.error('LOAD_ACTIVITY_STORE_DATA_FAILED', error);
      dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_FAILED', payload: { error: error.message } });
    }
  };
}

export function fetchLoadActivityStoreDataRequestDetail(storeId, requestId) {
  return async (dispatch) => {
    try {
      const response = await syncApi.get(`/${storeId}/request/${requestId}`);
      dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_REQUEST_DETAIL', payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_FAILED', payload: { error: error.message } });
    }
  };
}

export function clearActivityLoadData() {
  return async (dispatch) => {
    dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_CLEAR' });
  };
}
