import React, { useEffect } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  actionActivityRequest,
  fetchActivityRequests,
  setActivityRequestPerPage,
  setActivityRequestType,
  fetchSourceTables,
  clearActivityRequestAction,
  clearActivityLoadData,
  setActivitySelectedTables,
  setActivityIsDeleteBeforeLoad,
  loadActivityStoreData,
  fetchLoadActivityStoreDataRequestDetail,
} from '../../../../actions/synchronisationActions';

const perPageOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const requestTypeOptions = [
  { label: 'Current', value: 'current' },
  { label: 'Recent', value: 'recent' },
  { label: 'Failed', value: 'failed' },
];

const isFailedRequest = (request) => {
  return request.stage === 'FAILED' || (request.stage === 'COMPLETED' && request.summary && request.summary.failed > 0);
};

const isCompletedOrFailedRequest = (request) => {
  return request.stage === 'FAILED' || request.stage === 'COMPLETED';
};

// eslint-disable-next-line react/prop-types
function ColumnStatus({ request }) {
  try {
    switch (request.stage) {
      case 'SENT':
      case 'EXTRACTING':
      case 'TRANSFERRING':
      case 'TRANSFER_PENDING':
      case 'LOADING': {
        const { stageProgress } = request;
        const currentIndex = stageProgress ? stageProgress.currentIndex : 0;
        const endIndex = stageProgress ? stageProgress.endIndex : 0;
        if (currentIndex && endIndex) {
          const percent = parseInt((100 * currentIndex) / endIndex);
          return (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${percent}%` }}
                aria-valuenow={percent}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {request.stageProgress.currentIndex} / {request.stageProgress.endIndex}
              </div>
            </div>
          );
        } else if (currentIndex) {
          return <div>{currentIndex}</div>;
        } else {
          return `- ${request.stage} -`;
        }
      }
      case 'FAILED': {
        return <div>{request.error}</div>;
      }
      case 'COMPLETED': {
        if (isFailedRequest(request) && request.error) {
          return request.error;
        } else {
          if (request.summary) {
            return Object.keys(request.summary).map((k) => (
              <span key={k}>
                {k}:<i>{request.summary[k]}</i>&nbsp;&nbsp;
              </span>
            ));
          }
          return '';
        }
      }
      default:
        return `- ${request.stage} -`;
    }
  } catch (error) {
    return <div>Status loading error: {error.message}</div>;
  }
}

const DataLoading = ({ message }) => (
  <div className="progress" style={{ clear: 'both' }}>
    <div
      className="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      style={{ width: '100%' }}
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {message ? message : 'Data Loading...'}
    </div>
  </div>
);

function SyncActivity({
  dispatch,
  currentStore,
  perPage,
  requestType,
  requests,
  requestsLoading,
  requestsError,
  sourceTables,
  requestActionMessageOpen,
  requestActionLoading,
  requestActionCode,
  requestAction,
  requestActionError,
  requestReloadFlag,
  selectedTables,
  isDeleteBeforeLoad,
  loadStoreDataMessageOpen,
  loadStoreDataLoading,
  loadStoreDataTables,
  loadStoreDataIsDeleteBeforeLoad,
  loadStoreDataRequestId,
  loadStoreDataRequestDetail,
  loadStoreDataError,
}) {
  const navigate = useNavigate();
  const sourceTableOptions = sourceTables
    ? sourceTables.map((option) => ({ key: option, label: option, value: option }))
    : [];

  useEffect(() => {
    if (loadStoreDataLoading && loadStoreDataRequestId) {
      if (
        loadStoreDataRequestDetail &&
        (loadStoreDataRequestDetail.stage === 'COMPLETED' || loadStoreDataRequestDetail.stage === 'FAILED')
      ) {
        if (loadStoreDataRequestDetail.stage === 'COMPLETED') {
          if (loadStoreDataRequestDetail.summary && loadStoreDataRequestDetail.summary.failed !== 0) {
            dispatch({
              type: 'LOAD_ACTIVITY_STORE_DATA_FAILED',
              payload: {
                error: 'Failed to process tables, failed count: ' + loadStoreDataRequestDetail.summary.failed,
              },
            });
          } else {
            dispatch({ type: 'LOAD_ACTIVITY_STORE_DATA_SUCCESS' });
          }
        } else {
          dispatch({
            type: 'LOAD_ACTIVITY_STORE_DATA_FAILED',
            payload: {
              error:
                'Failed to process tables.' +
                (loadStoreDataRequestDetail.error ? loadStoreDataRequestDetail.error : ''),
            },
          });
        }
        loadRequests(false);
        return;
      }
      const timer = setTimeout(() => {
        dispatch(fetchLoadActivityStoreDataRequestDetail(currentStore.storeId, loadStoreDataRequestId));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [loadStoreDataRequestDetail]);

  useEffect(() => {
    let shortRefresh = false;
    for (let i = 0; i < requests.length; i++) {
      if (requests[i].stage !== 'COMPLETED' && requests[i].stage !== 'FAILED') {
        shortRefresh = true;
        break;
      }
    }

    const timer = setTimeout(
      () => {
        loadRequests(false);
      },
      shortRefresh ? 3000 : 60000
    );
    return () => {
      clearTimeout(timer);
    };
  }, [requests]);

  useEffect(() => {
    if (currentStore && currentStore.storeId) {
      loadRequests();
      dispatch(fetchSourceTables(currentStore.storeId));
    }
  }, [currentStore]);

  useEffect(() => {
    loadRequests();
  }, [perPage, requestType, requestReloadFlag]);

  const loadRequests = (showLoading = true) => {
    dispatch(fetchActivityRequests(currentStore.storeId, requestType, perPage, showLoading));
  };

  const handleChangePerPage = (option) => {
    dispatch(setActivityRequestPerPage(option.value));
    dispatch(clearActivityRequestAction());
  };
  const handleChangeRequestType = (option) => () => {
    if (option.value !== requestType) {
      dispatch(setActivityRequestType(option.value));
    } else {
      loadRequests();
    }
    dispatch(clearActivityRequestAction());
  };
  const getPerPageOption = () => {
    for (let i = 0; i < perPageOptions.length; i++) {
      if (perPageOptions[i].value === perPage) {
        return perPageOptions[i];
      }
    }
  };
  const getRequestTypeOption = () => {
    for (let i = 0; i < requestTypeOptions.length; i++) {
      if (requestTypeOptions[i].value === requestType) {
        return requestTypeOptions[i];
      }
    }
  };
  const cancelRequest = (request) => () => {
    if (window.confirm('Are you sure to cancel the request?')) {
      if (isCompletedOrFailedRequest(request)) {
        alert('Only non completed or failed request can be canceled.');
        return;
      }
      dispatch(actionActivityRequest(currentStore.storeId, 'cancel', request.id, requestReloadFlag));
    }
  };

  const restartRequest = (request) => () => {
    if (window.confirm('Are you sure to restart the request?')) {
      if (!isFailedRequest(request)) {
        alert('Only failed request can be restarted.');
        return;
      }
      dispatch(actionActivityRequest(currentStore.storeId, 'restart', request.id, requestReloadFlag));
    }
  };

  const jumoToFailedRequest = (request) => () => {
    navigate(`/stores/${currentStore.storeId}/synchronization/failures`, {
      state: {
        jumpToOneFailedRequest: request.id,
      },
    });
  };

  const emptyActivityLoadData = () => {
    dispatch(clearActivityLoadData());
  };

  const getLoadStoreDataRequestDetailProgress = () => {
    const request = loadStoreDataRequestDetail;
    if (!request) return { percent: 0, message: '' };
    let percent = 5;
    let message = '';
    switch (request.stage) {
      case 'CREATED': {
        percent = 10;
        break;
      }
      case 'REQUESTED': {
        percent = 15;
        break;
      }
      case 'EXTRACT_PENDING': {
        percent = 20;
        break;
      }
      case 'EXTRACTING': {
        percent = 25;
        break;
      }
      case 'TRANSFER_PENDING': {
        percent = 30;
        break;
      }
      case 'TRANSFERRING': {
        percent = 40;
        break;
      }
      case 'SENT': {
        percent = 50;
        break;
      }
      case 'RECEIVED': {
        percent = 55;
        break;
      }
      case 'LOADING': {
        percent = 60;
        const { stageProgress } = request;
        if (stageProgress) {
          message = `${stageProgress.currentIndex} / ${stageProgress.endIndex}`;
          percent += parseInt(((100 - percent) * stageProgress.currentIndex) / stageProgress.endIndex);
        }
        break;
      }
      case 'COMPLETED': {
        percent = 100;
        break;
      }
      case 'FAILED': {
        percent = 100;
        break;
      }
    }

    if (message) {
      message = percent + '% -' + message;
    } else {
      message = percent + '%';
    }

    return { percent, message };
  };
  const loadStoreDataRequestDetailProgress = getLoadStoreDataRequestDetailProgress();

  return (
    <div className="p-4">
      <div className="row ">
        <div className="col col-sm-6">
          <h4>Activity</h4>
        </div>
        <div className="col col-sm-6">
          <div style={{ float: 'right' }}>
            <div className="btn-group btn-group-toggle " data-toggle="buttons">
              {requestTypeOptions.map((option, key) => (
                <label
                  key={key}
                  className={
                    'btn ' +
                    (option === getRequestTypeOption() ? ' btn-secondary' : 'btn-light') +
                    (requestsLoading ? ' disabled' : '')
                  }
                >
                  <input
                    type="radio"
                    name="type"
                    id={`type${key}`}
                    onClick={handleChangeRequestType(option)}
                    disabled={requestsLoading}
                    autoComplete="off"
                  />{' '}
                  {option.label}
                </label>
              ))}
            </div>
          </div>
          <div style={{ float: 'right', width: 100 }} className="pe-2">
            <Select
              defaultValue={getPerPageOption()}
              options={perPageOptions}
              onChange={handleChangePerPage}
              isDisabled={requestsLoading}
            />
          </div>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col col-sm-12">
          {requestActionMessageOpen && !requestActionLoading && !requestActionError && (
            <div className="mt-2 mb-2">
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => dispatch(clearActivityRequestAction())}
                >
                  <span aria-hidden="true">×</span>
                </button>
                Processed Action "{requestAction}" for {requestActionCode} successfully.
              </div>
            </div>
          )}
          {requestActionMessageOpen && !requestActionLoading && requestActionError && (
            <div className="mt-2 mb-2">
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => dispatch(clearActivityRequestAction())}
                >
                  <span aria-hidden="true">×</span>
                </button>
                Failed to process Action "{requestAction}" for {requestActionCode}. Error: {requestActionError}
              </div>
            </div>
          )}

          <table className="table table-sm table-bordered">
            <thead className="thead-light">
              <tr>
                <th
                  style={{ textAlign: 'center', width: 80 }}
                  onClick={() => {
                    console.log(requests);
                  }}
                >
                  Request id
                </th>
                <th style={{ textAlign: 'center', width: 160 }}>Created</th>
                <th style={{ textAlign: 'center', width: 120 }}>Type</th>
                <th style={{ textAlign: 'center', width: 100 }}>Stage</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center', width: 80 }}>Failures</th>
                <th style={{ textAlign: 'center', width: 80 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {!requestsLoading &&
                requests.map((request, key) => (
                  <tr
                    key={key}
                    style={{ cursor: isFailedRequest(request) ? 'pointer' : '' }}
                    title={isFailedRequest(request) ? 'Double click to check the details of the failed request' : ''}
                    onDoubleClick={isFailedRequest(request) ? jumoToFailedRequest(request) : () => {}}
                  >
                    <td
                      align="center"
                      onClick={() => {
                        console.log(request);
                      }}
                    >
                      {request.id.substr(0, 8)}
                    </td>
                    <td align="center">{new Date(request.created).toLocaleString()}</td>
                    <td align="center">{request.requestType}</td>
                    <td align="center">{request.stage}</td>
                    <td align="center">
                      <ColumnStatus request={request} />
                    </td>
                    <td align="center">
                      {request.summary && request.summary.failed !== 0 && <>{request.summary.failed}</>}
                    </td>
                    <td align="center">
                      <a
                        className={'text-muted btn btn-link' + (isCompletedOrFailedRequest(request) ? ' disabled' : '')}
                        title={isCompletedOrFailedRequest(request) ? '' : 'Cancel Request'}
                        onClick={cancelRequest(request)}
                      >
                        <i className="icon-close"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        className={'text-muted btn btn-link' + (isFailedRequest(request) ? '' : ' disabled')}
                        title={isFailedRequest(request) ? 'Restart Request' : ''}
                        onClick={restartRequest(request)}
                      >
                        <i className="icon-reload"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              {!requestsLoading && requests.length === 0 && (
                <tr>
                  <td colSpan={7} align="center">
                    No Record...
                  </td>
                </tr>
              )}
              {requestsLoading && (
                <tr>
                  <td colSpan={7} align="center">
                    <DataLoading />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col col-sm-12">
          <div style={{ float: 'left' }} className="pe-2">
            <button
              className="btn btn-light"
              onClick={() => {
                emptyActivityLoadData();
                dispatch(loadActivityStoreData(currentStore.storeId, null, isDeleteBeforeLoad));
              }}
              disabled={loadStoreDataLoading}
            >
              Load all
            </button>
          </div>
          <div style={{ float: 'left', width: 1.5, height: 35, backgroundColor: '#ddd' }} className="me-2"></div>
          <div style={{ float: 'left' }} className="pe-2">
            <button
              className="btn btn-light"
              onClick={() => {
                emptyActivityLoadData();
                dispatch(
                  loadActivityStoreData(currentStore.storeId, selectedTables ? selectedTables : [], isDeleteBeforeLoad)
                );
              }}
              disabled={loadStoreDataLoading || !selectedTables || selectedTables.length === 0}
            >
              Load tables
            </button>
          </div>
          <div style={{ float: 'left', width: 270 }} className="pe-2">
            <Select
              isMulti={true}
              defaultValue={
                selectedTables ? selectedTables.map((table) => ({ key: table, label: table, value: table })) : []
              }
              options={sourceTableOptions}
              onChange={(e) => {
                emptyActivityLoadData();
                dispatch(setActivitySelectedTables(e && e.map((table) => table.value)));
              }}
              isDisabled={loadStoreDataLoading}
            />
          </div>
          <div style={{ float: 'left', width: 200 }} className="pe-2 pt-2">
            <div className="form-group form-check pull-left pe-2">
              <input
                type="checkbox"
                checked={isDeleteBeforeLoad}
                onChange={(e) => {
                  emptyActivityLoadData();
                  dispatch(setActivityIsDeleteBeforeLoad(e.target.checked));
                }}
                className="form-check-input"
                id="isDeleteBeforeLoadCheck"
                disabled={loadStoreDataLoading}
              />
              <label
                className="form-check-label ps-1"
                htmlFor="isDeleteBeforeLoadCheck"
                style={{ MozUserSelect: 'none', WebkitUserSelect: 'none', msUserSElect: 'none' }}
              >
                Delete Before Load
              </label>
            </div>
          </div>
        </div>
        <div className="col col-12 mt-2">
          {loadStoreDataMessageOpen && !loadStoreDataLoading && !loadStoreDataError && (
            <div className="mt-2 mb-2">
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                <button type="button" className="close" aria-label="Close" onClick={emptyActivityLoadData}>
                  <span aria-hidden="true">×</span>
                </button>
                Load {loadStoreDataTables === null ? 'all tables' : 'tables ' + loadStoreDataTables.join(', ')}{' '}
                successfully.
              </div>
            </div>
          )}
          {loadStoreDataMessageOpen && !loadStoreDataLoading && loadStoreDataError && (
            <div className="mt-2 mb-2">
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <button type="button" className="close" aria-label="Close" onClick={emptyActivityLoadData}>
                  <span aria-hidden="true">×</span>
                </button>
                Failed to load tables - {loadStoreDataError}
              </div>
            </div>
          )}
          {loadStoreDataLoading && loadStoreDataRequestId && (
            <div className="mt-2 mb-2">
              <div className="alert alert-info fade show" role="alert">
                <div className="pull-left">
                  Processing load{' '}
                  {loadStoreDataTables === null ? 'all tables' : 'tables ' + loadStoreDataTables.join(', ')}
                </div>
                <div className="pull-right">
                  Request Id: {loadStoreDataRequestId.substr(0, 8)}, Stage:{' '}
                  <strong>{loadStoreDataRequestDetail ? loadStoreDataRequestDetail.stage : 'UNKNOWN'}</strong>
                </div>
                <div className="progress mt-1" style={{ clear: 'both' }}>
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: loadStoreDataRequestDetailProgress.percent + '%' }}
                    aria-valuenow={loadStoreDataRequestDetailProgress.percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {loadStoreDataRequestDetailProgress.message}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SyncActivity.propTypes = {
  currentStore: PropTypes.object,
  dispatch: PropTypes.func,
  perPage: PropTypes.number,
  requestType: PropTypes.string,
  requests: PropTypes.array,
  requestsLoading: PropTypes.bool,
  requestsError: PropTypes.string,
  sourceTables: PropTypes.array,
  requestActionMessageOpen: PropTypes.bool,
  requestActionLoading: PropTypes.bool,
  requestActionCode: PropTypes.string,
  requestAction: PropTypes.string,
  requestActionError: PropTypes.string,
  requestReloadFlag: PropTypes.number,
  selectedTables: PropTypes.array,
  isDeleteBeforeLoad: PropTypes.bool,
  loadStoreDataMessageOpen: PropTypes.bool,
  loadStoreDataLoading: PropTypes.bool,
  loadStoreDataTables: PropTypes.array,
  loadStoreDataIsDeleteBeforeLoad: PropTypes.bool,
  loadStoreDataRequestId: PropTypes.string,
  loadStoreDataRequestDetail: PropTypes.object,
  loadStoreDataError: PropTypes.string,
};
export default connect((state) => ({
  currentStore: state.store.one,
  perPage: state.sync.activity.perPage,
  requestType: state.sync.activity.requestType,
  requests: state.sync.activity.requests,
  requestsLoading: state.sync.activity.requestsLoading,
  requestsError: state.sync.activity.requestsError,
  sourceTables: state.sync.sourceTable.tables,
  requestActionMessageOpen: state.sync.activity.requestActionMessageOpen,
  requestActionLoading: state.sync.activity.requestActionLoading,
  requestActionCode: state.sync.activity.requestActionCode,
  requestAction: state.sync.activity.requestAction,
  requestActionError: state.sync.activity.requestActionError,
  requestReloadFlag: state.sync.activity.requestReloadFlag,
  selectedTables: state.sync.activity.selectedTables,
  isDeleteBeforeLoad: state.sync.activity.isDeleteBeforeLoad,
  loadStoreDataMessageOpen: state.sync.activity.loadStoreDataMessageOpen,
  loadStoreDataLoading: state.sync.activity.loadStoreDataLoading,
  loadStoreDataTables: state.sync.activity.loadStoreDataTables,
  loadStoreDataIsDeleteBeforeLoad: state.sync.activity.isDeleteBeforeLoad,
  loadStoreDataRequestId: state.sync.activity.loadStoreDataRequestId,
  loadStoreDataRequestDetail: state.sync.activity.loadStoreDataRequestDetail,
  loadStoreDataError: state.sync.activity.loadStoreDataError,
}))(SyncActivity);
