import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TimeAgo from 'react-timeago';
import { BarLoader } from 'react-spinners';

class XMModalLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
    this.toogle = this.toogle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;
    if (isVisible !== prevProps.isVisible) {
      this.toogle();
    }
  }

  toogle() {
    this.setState({
      isVisible: !this.state.isVisible
    });
  }

  getLogColor(logType) {
    switch (logType) {
      case 'SUCCESS':
        return 'list-group-item-primary';
      case 'FAILED':
        return 'list-group-item-danger';
    }
    return '';
  }

  render() {
    const { toogle, isOpen, isWaiting, title, logs } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={toogle} className="lg">
          <ModalHeader toggle={toogle}>{title}</ModalHeader>
          <ModalBody>
            <div className="list-group">
              {logs.map((log, index) => (
                <div
                  key={index}
                  className={
                    'list-group-item ist-group-item-action flex-column align-items-start ' +
                    this.getLogColor(log.logType)
                  }
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{log.text}</h5>
                    {logs.length == index + 1 ? (
                      <small className="text-muted">
                        <TimeAgo date={log.timestamp} />
                      </small>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            {isWaiting ? (
              <BarLoader width={570} height={5} color={'#c1e7f4'} />
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default XMModalLog;
