import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (localStorage.getItem('jwtToken') ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  ));
};
