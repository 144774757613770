import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem } from 'reactstrap';

import { fetchDatabases } from '../../../../../actions/synchronisationActions';

function DBAliasModal({
  isOpen,
  storeId,
  protocol,
  hostName,
  port,
  loading,
  error,
  databases,
  onSelect,
  onCancel,
  fetchDatabases
}) {
  const [dbAlias, setDbAlias] = React.useState('');

  async function doFetchData() {
    await fetchDatabases(storeId, protocol, hostName, port);
  }

  return (
    <Modal isOpen={isOpen} onOpened={doFetchData} size="sm">
      <ModalHeader>Select Database id</ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center">
        {error && error.message && <div className="text-danger">{error.message}</div>}

        {loading ? (
          <div>Loading...</div>
        ) : (
          <ListGroup style={{ width: '80%' }}>
            {databases.length > 0 &&
              databases.map(db => (
                <ListGroupItem active={db === dbAlias} key={db} tag="button" action onClick={() => setDbAlias(db)}>
                  {db}
                </ListGroupItem>
              ))}
          </ListGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSelect(dbAlias)}>
          Confirm
        </Button>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(
  state => {
    return {
      databases: state.sync.db.databases,
      loading: state.sync.db.loading,
      error: state.sync.db.error
    };
  },
  dispatch => ({
    fetchDatabases: (storeId, protocol, hostName, port) => dispatch(fetchDatabases(storeId, protocol, hostName, port))
  })
)(DBAliasModal);
