import _axios from '../api';
import * as storeActions from './storeActions';
import { queryParams } from '../utils';

const API = '/data_centers';

export function getAll(page = 0, size = 20, sort = 'id', sortDir = 'DESC', searchRequest = null) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      _axios
        .get(`${API}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(searchRequest)}`)
        .then((response) => {
          dispatch({
            type: 'DATACENTRE_GETALL_SUCCESS',
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: 'DATACENTRE_GETALL_ERROR',
            payload: err.message,
          });
          reject(err);
        });
    });
}

export function getOne(dataCentreId) {
  return function (dispatch) {
    _axios
      .get(API + '/' + dataCentreId)
      .then((response) => {
        dispatch({
          type: 'DATACENTRE_GETONE_SUCCESS',
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'DATACENTRE_GETONE_ERROR',
          payload: err.message,
        });
      });
  };
}

/**
 * create (POST) || update (PUT)
 * @param {*} dataCentre
 */
export function save(dataCentre) {
  return dataCentre.id
    ? function (dispatch) {
        _axios
          .put(API, dataCentre)
          .then((response) => {
            dispatch({
              type: 'DATACENTRE_UPDATE_SUCCESS',
            });
            // refresh
            dispatch(getAll());
          })
          .catch((err) => {
            dispatch({
              type: 'DATACENTRE_UPDATE_ERROR',
              payload: err.message,
            });
          });
      }
    : function (dispatch) {
        _axios
          .post(API, dataCentre)
          .then((response) => {
            dispatch({
              type: 'DATACENTRE_CREATE_SUCCESS',
            });
            dispatch(getAll());
          })
          .catch((err) => {
            dispatch({
              type: 'DATACENTRE_CREATE_ERROR',
              payload: err.message,
            });
          });
      };
}

export function remove(dataCentreId) {
  return function (dispatch) {
    _axios
      .delete(API + '/' + dataCentreId)
      .then((response) => {
        dispatch({
          type: 'DATACENTRE_DELETE_SUCCESS',
        });
        // refresh datacentres
        dispatch(getAll());
      })
      .catch((err) => {
        dispatch({
          type: 'DATACENTRE_DELETE_ERROR',
          payload: err.message,
        });
      });
  };
}

export function select(dataCentreSelected) {
  return function (dispatch) {
    // update selected region
    Promise.all([
      dispatch({
        type: 'DATACENTRE_SELECT',
        payload: dataCentreSelected,
      }),
      // refresh ALL data based on the region selected
      dispatch(storeActions.getAllByDataCentre(dataCentreSelected)),
    ]);
    localStorage.setItem('dataCentreSelected', JSON.stringify(dataCentreSelected));
  };
}

export function clear() {
  return function (dispatch) {
    dispatch({
      type: 'DATACENTRE_CLEAR',
    });
  };
}
