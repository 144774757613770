import React from 'react';
import { connect } from 'react-redux';
import { Modal,  ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import { getSyncVersionName } from './SyncForm';


function SyncVersionConfirmationModal({
  isOpen,
  handleModalConfirmation,
  handleModalClose,
  requestSyncVersion
}) {
  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader style={{ 'background': '#29363D' }}><span style={{ 'color': '#ffffff' }}><i className={'fa fa-question-circle'}></i> Confirmation Required</span></ModalHeader>
      <ModalBody>
        Are you sure to proceed with changing Sync Version to <b>{getSyncVersionName(requestSyncVersion)}</b> ?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleModalConfirmation}>
          Confirm
        </Button>
        <Button color="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(
  state => ({
    requestSyncVersion: state.sync.source.requestSyncVersion
  })
)(SyncVersionConfirmationModal);
