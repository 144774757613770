import React from 'react';
import { Row, Col } from 'reactstrap';
import StoreDetailsExtraInfo from './StoreDetailsExtraInfo';

function StoreDetailsRow({ id, storeId, primaryDomain, stagingDomain, contactEmail, contactPhone, expand = false }) {
  return expand ? (
    <tr>
      <td></td>
      <td colSpan={7}>
        <Row>
          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo value={id} name="ID" />
          </Col>

          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo value={storeId} name="Store ID" />
          </Col>

          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo isLink={true} value={primaryDomain} name="Primary Domain" />
          </Col>

          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo isLink={true} value={stagingDomain} name="Staging Domain" />
          </Col>

          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo value={contactEmail} name="Contact Email" />
          </Col>

          <Col xs={12} md={6}>
            <StoreDetailsExtraInfo value={contactPhone} name="Contact Phone" />
          </Col>
        </Row>
      </td>
    </tr>
  ) : null;
}

export default StoreDetailsRow;
