import React from 'react';

const SegmentItem = ({ name, children , style}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
      <span className="col-sm-12 col-xs-12 col-md-3 col-lg-3" style={style}>{name}</span>
      <div className="col-sm-12 col-xs-12 col-md-9 col-lg-9" style={{ display: 'flex', flexDirection: 'row' }}>
        {children}
      </div>
    </div>
  );
};

export default SegmentItem;
