import React from 'react';

function NestedListDetailsPretty({ mainTitle, data }) {
  const renderDataWithSubtitles = (data, subtitle) => {
    // Render each key-value pair in an object as a titled section
    return (
      <table style={{ width: '100%', marginBottom: '10px' }}>
        <tbody>
          {Object.entries(data).map(([key, value]) => {
            if (
              value === null ||
              value === undefined ||
              (typeof value === 'object' && Object.keys(value).length === 0)
            ) {
              return null;
            }
            return (
              <tr key={key} style={{ padding: '10px 0' }}>
                <td colSpan={1} style={{ padding: '10px', verticalAlign: 'top' }}>
                  <b className="text-secondary">{key}</b>
                </td>
                <td colSpan={5} style={{ padding: '10px', paddingTop: '12px' }}>
                  <pre style={{ whiteSpace: 'break-spaces', lineHeight: '1.5' }}>{JSON.stringify(value, null, 2)}</pre>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <tr>
        <td colSpan={7} style={{ borderBottomWidth: 0 }}>
          <b className="text-primary">{mainTitle}</b>
        </td>
      </tr>
      <tr>
        <td colSpan={7}>{renderDataWithSubtitles(data, '')}</td>
      </tr>
    </>
  );
}

export default NestedListDetailsPretty;
