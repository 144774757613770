import { apiConfigHelper } from './apiConfig';
import { createAxios } from './utils';

export const getGatewayApi = () => {
  const _axios = createAxios(apiConfigHelper.getBaseUrlForGateway());

  _axios.interceptors.request.use((request) => {
    // update the API endpoint using the site domain
    request.baseURL = apiConfigHelper.getStoreUrl(request.baseURL);

    return request;
  });

  return _axios;
};

const _axios = getGatewayApi();
export default _axios;

export class GatewayApi {
  // Interface APIs: provider -> product -> version -> inteface -> operation
  static getProviders = async () => {
    const res = await _axios.get('/gateway/interfaces');
    return res.data;
  };

  static getProvider = async (providerName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}`);
    return res.data;
  };

  static saveProvider = async (providerName, data) => {
    if (!data) data = {};
    const res = await _axios.post(`/gateway/interfaces/${providerName}`, data);
    return res.data;
  };

  static deleteProvider = async (providerName) => {
    return await this.deleteData(`/gateway/interfaces/${providerName}`);
  };

  static getProviderLogs = async (providerName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}.log`);
    return res.data;
  };

  static getProviderProduct = async (providerName, productName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}/${productName}`);
    return res.data;
  };

  static saveProviderProduct = async (providerName, productName, data) => {
    if (!data) data = {};
    const res = await _axios.post(`/gateway/interfaces/${providerName}/${productName}`, data);
    return res.data;
  };

  static deleteProviderProduct = async (providerName, productName) => {
    return await this.deleteData(`/gateway/interfaces/${providerName}/${productName}`);
  };

  static getProviderProductLogs = async (providerName, productName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}/${productName}.log`);
    return res.data;
  };

  static getProviderProductVersion = async (providerName, productName, versionName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}/${productName}/${versionName}`);
    return res.data;
  };

  static saveProviderProductVersion = async (providerName, productName, versionName, data) => {
    if (!data) data = {};
    const res = await _axios.post(`/gateway/interfaces/${providerName}/${productName}/${versionName}`, data);
    return res.data;
  };

  static deleteProviderProductVersion = async (providerName, productName, versionName) => {
    return await this.deleteData(`/gateway/interfaces/${providerName}/${productName}/${versionName}`);
  };

  static getProviderProductVersionLogs = async (providerName, productName, versionName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}/${productName}/${versionName}.log`);
    return res.data;
  };

  static getProviderProductVersionInterface = async (providerName, productName, versionName, interfaceName) => {
    const res = await _axios.get(`/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}`);
    return res.data;
  };

  static saveProviderProductVersionInterface = async (providerName, productName, versionName, interfaceName, data) => {
    if (!data) data = {};
    const res = await _axios.post(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}`,
      data
    );
    return res.data;
  };

  static deleteProviderProductVersionInterface = async (providerName, productName, versionName, interfaceName) => {
    return await this.deleteData(`/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}`);
  };

  static getProviderProductVersionInterfaceLogs = async (providerName, productName, versionName, interfaceName) => {
    const res = await _axios.get(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}/log`
    );
    return res.data;
  };

  static getProviderProductVersionInterfaceOperation = async (
    providerName,
    productName,
    versionName,
    interfaceName,
    operationName
  ) => {
    const res = await _axios.get(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}/${operationName}`
    );
    return res.data;
  };

  static saveProviderProductVersionInterfaceOperation = async (
    providerName,
    productName,
    versionName,
    interfaceName,
    operationName,
    data
  ) => {
    if (!data) data = {};
    const res = await _axios.post(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}/${operationName}`,
      data
    );
    return res.data;
  };

  static deleteProviderProductVersionInterfaceOperation = async (
    providerName,
    productName,
    versionName,
    interfaceName,
    operationName
  ) => {
    return await this.deleteData(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}/${operationName}`
    );
  };

  static getProviderProductVersionInterfaceOperationLogs = async (
    providerName,
    productName,
    versionName,
    interfaceName,
    operationName
  ) => {
    const res = await _axios.get(
      `/gateway/interfaces/${providerName}/${productName}/${versionName}/${interfaceName}/${operationName}.log`
    );
    return res.data;
  };

  static getDetailedProviders = async () => {
    const providers = [];
    const res = await this.getProviders();
    for (const providerName of res.providers) {
      const products = [];
      const provider = { provider: providerName, products };
      const res = await this.getProvider(providerName);
      for (const productName of res.products) {
        const versions = [];
        const res = await this.getProviderProduct(providerName, productName);
        const product = { provider: providerName, product: productName, settings: res.settings, versions };
        for (const versionName of res.versions) {
          const interfaces = [];
          const res = await this.getProviderProductVersion(providerName, productName, versionName);
          const version = {
            provider: providerName,
            product: productName,
            version: versionName,
            settings: res.settings,
            interfaces,
          };
          for (const interfaceName of res.interfaces) {
            const res = await this.getProviderProductVersionInterface(
              providerName,
              productName,
              versionName,
              interfaceName
            );
            interfaces.push(res);
          }
          versions.push(version);
        }
        products.push(product);
      }
      providers.push(provider);
    }
    return providers;
  };

  // Integrations: store -> integration -> operation
  static getStores = async () => {
    const res = await _axios.get('/gateway/integrations');
    return res.data;
  };

  static getStoresLogs = async () => {
    const res = await _axios.get(`/gateway/integrations.log`);
    return res.data;
  };

  static getStore = async (storeId) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}`);
    return res.data;
  };

  static saveStore = async (storeId, data) => {
    if (!data) data = {};
    const res = await _axios.post(`/gateway/integrations/${storeId}`, data);
    return res.data;
  };

  static deleteStore = async (storeId) => {
    return await this.deleteData(`/gateway/integrations/${storeId}`);
  };

  static getStoreLogs = async (storeId, params) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}.log`, { params: params });
    return res.data;
  };

  static getStoreIntegrationExchangeLogs = async (exchangeId) => {
    return await _axios.get(`/gateway/integrations/exchange/${exchangeId}`);
  };

  static findStoreIntegration = async (storeId, integrationName) => {
    try {
      return await this.getStoreIntegration(storeId, integrationName);
    } catch (error) {
      return null;
    }
  };

  static getStoreIntegration = async (storeId, integrationName) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}/${integrationName}`);
    return res.data;
  };

  static saveStoreIntegration = async (storeId, integrationName, data) => {
    const res = await _axios.post(`/gateway/integrations/${storeId}/${integrationName}`, data);
    return res.data;
  };

  static deleteStoreIntegration = async (storeId, integrationName) => {
    return await this.deleteData(`/gateway/integrations/${storeId}/${integrationName}`);
  };

  static getStoreIntegrationLogs = async (storeId, integrationName, data) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}/${integrationName}.log`);
    return res.data;
  };

  static getStoreIntegrationOperation = async (storeId, integrationName, operationName) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}/${integrationName}/${operationName}`);
    return res.data;
  };

  static saveStoreIntegrationOperation = async (storeId, integrationName, operationName, data) => {
    const res = await _axios.post(`/gateway/integrations/${storeId}/${integrationName}/${operationName}`, data);
    return res.data;
  };

  static deleteStoreIntegrationOperation = async (storeId, integrationName, operationName) => {
    return await this.deleteData(`/gateway/integrations/${storeId}/${integrationName}/${operationName}`);
  };

  static getStoreIntegrationOperationLogs = async (storeId, integrationName, operationName) => {
    const res = await _axios.get(`/gateway/integrations/${storeId}/${integrationName}/${operationName}.log`);
    return res.data;
  };

  static getDetailedStores = async () => {
    const stores = [];
    const res = await this.getStores();
    for (const storeId of res.stores) {
      const integrations = [];
      const res = await this.getStore(storeId);
      const store = { storeId, settings: res.settings, integrations };
      for (const integrationName of res.integrations) {
        const res = await this.getStoreIntegration(storeId, integrationName);
        integrations.push(res);
      }
      stores.push(store);
    }
    return stores;
  };

  // others
  static getLogs = async () => {
    const res = await _axios.get('/gateway.log');
    return res.data;
  };

  static getCertificate = async () => {
    const res = await _axios.get('/gateway/certificate.pem', {
      headers: {
        Accept: 'text/plain',
      },
    });
    return res.data;
  };

  static deleteData = async (uri) => {
    try {
      await _axios.delete(uri);
    } catch (error) {
      if (error.status !== 404) {
        return false;
      }
    }
    return true;
  };

  // static disable = async () => {
  //   return await _axios.get('/gateway/disable');
  // };

  // static enable = async () => {
  //   return await _axios.get('/gateway/enable');
  // };
}
