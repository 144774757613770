import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import ListDetailsPretty from './ListDetailsPretty';
import { GatewayApi } from '../../../../api';
import NestedListDetailsPretty from './NestedListDetailsPretty';

const unEscapeJson = (string) => {
  if (string == null) {
    return '';
  }

  let parsedJSon;

  // escape added from Java REST response to avoid invalid JSON response
  // first try to parse to un-escape
  try {
    parsedJSon = JSON.parse(string);
  } catch (error) {
    return string ?? '';
  }

  // parse twice to make sure the final JSON object valid
  try {
    return JSON.parse(parsedJSon);
  } catch (error) {
    return parsedJSon;
  }
};

const reFormatLog = (log) => {
  const path = log.messages.path;
  const parameters = unEscapeJson(log.messages.parameters);
  const body = unEscapeJson(log.messages.body);
  const requestContent = unEscapeJson(log.messages.request);
  const receivedContent = unEscapeJson(log.messages.received);
  const responseContent = unEscapeJson(log.messages.response);
  return {
    ...log,
    messages: {
      request: requestContent,
      path: path,
      parameters: parameters,
      body: body,
      received: receivedContent,
      response: responseContent,
    },
  };
};

function ListDetails({ expand = false, logId }) {
  if (!expand) return;
  const [thisLog, setLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLog = async () => {
      try {
        const res = await GatewayApi.getStoreIntegrationExchangeLogs(logId);
        if (res.status != 200) {
          throw new Error('Failed to load exchange details...');
        }
        setLog(reFormatLog(res.data));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLog();
  }, []);

  if (loading) {
    return (
      <>
        <tr>
          <td colSpan={7}>
            <div>Loading...</div>
          </td>
        </tr>
      </>
    );
  }

  if (error) {
    return (
      <>
        <tr>
          <td colSpan={7}>
            <div>Error: {error.message}</div>
          </td>
        </tr>
      </>
    );
  }

  return (
    <>
      <tr>
        <td colSpan={7}>
          <Button
            color="primary"
            className="btn-sm"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(thisLog, null, 2)).then(() => {
                alert('Copied JSON log to clipboard!');
              });
            }}
          >
            Copy Log to Clipboard
          </Button>
        </td>
      </tr>
      <ListDetailsPretty title={'Request'} data={thisLog.messages.request} />
      <NestedListDetailsPretty
        mainTitle={'Sent'}
        data={{
          Path: thisLog.messages.path,
          Parameter: thisLog.messages.parameters,
          Body: thisLog.messages.body,
        }}
      />
      <ListDetailsPretty title={'Received'} data={thisLog.messages.received} />
      <ListDetailsPretty title={'Response'} data={thisLog.messages.response} />
    </>
  );
}

export default ListDetails;
