import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { getStoreIntegration } from '../../actions/gatewayActions';
import IntegrationsEditForm from './IntegrationsEditForm';
import _ from 'lodash';
import { integrationInitialValues } from './utils';
import withRouter from '../../withRouter';

// function Settings({ currentStore, initialValues }) {
class IntegrationsEdit extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { router, fetchStoreIntegration, currentGatewayStore } = this.props;
    fetchStoreIntegration(currentGatewayStore.storeId, router.params.integrationName);
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { currentStore, currentGatewayStore, error, storeIntegration } = this.props;
    if (error) {
      return (
        <div>
          <Alert color={'danger'}>{error}</Alert>
        </div>
      );
    }

    if (!storeIntegration) {
      return <div>Loading...</div>;
    }

    const { settings, ...leftStoreIntegration } = storeIntegration;
    const storeIntegrationInterface = storeIntegration.interface;
    const initialValues = _.merge({}, integrationInitialValues, leftStoreIntegration);

    initialValues.interface = [
      storeIntegrationInterface.provider,
      storeIntegrationInterface.product,
      storeIntegrationInterface.version,
      storeIntegrationInterface.interface,
    ].join('-');

    if (storeIntegration.connection.accessOperation) {
      const { accessOperation } = storeIntegration.connection;
      initialValues.connection.accessOperation = [
        accessOperation.provider,
        accessOperation.product,
        accessOperation.version,
        accessOperation.interface,
        accessOperation.operation,
      ].join('-');
    }

    if (storeIntegration.connection.refreshOperation) {
      const { refreshOperation } = storeIntegration.connection;
      initialValues.connection.refreshOperation = [
        refreshOperation.provider,
        refreshOperation.product,
        refreshOperation.version,
        refreshOperation.interface,
        refreshOperation.operation,
      ].join('-');
    }

    if (settings) {
      const newSettings = [];
      Object.keys(settings).forEach((name) => {
        newSettings.push({ name, value: settings[name] });
      });
      initialValues.settings = newSettings;
    }

    return (
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 mt-4">
        <h5>Edit Integration</h5>
        <hr className="separator" />
        <IntegrationsEditForm
          currentStore={currentStore}
          currentGatewayStore={currentGatewayStore}
          storeIntegration={storeIntegration}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    error: state.gateway.error,
    storeIntegration: state.gateway.storeIntegration,
  }),
  (dispatch) => ({
    fetchStoreIntegration: (storeId, integrationName) => {
      dispatch({ type: 'GATEWAY_STORE_INTEGRATION_GETONE_INIT' });
      dispatch(getStoreIntegration(storeId, integrationName));
    },
  })
)(withRouter(IntegrationsEdit));
