import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Button
} from 'reactstrap';

import { Field, reduxForm } from 'redux-form';

@reduxForm({
  form: 'languageForm',
  enableReinitialize: true
})
class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = form => {
    console.log('### form ###', form);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Card>
        <CardBody>
          <form
            onSubmit={handleSubmit(this.submit)}
            className="form-horizontal"
          >
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle>Default Language</CardTitle>
                    <FormGroup row>
                      <Col md="12">
                        <Field
                          name="default"
                          component="select"
                          className="form-control"
                        >
                          <option value="english">English (ENG)</option>
                          <option value="portuguese">Portuguese (PT)</option>
                        </Field>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle>Other Translations</CardTitle>
                    <FormGroup row>
                      <Col md="12">
                        <Field
                          name="other"
                          component="select"
                          className="form-control"
                          type="select-multiple"
                          multiple
                        >
                          <option value="english">English (ENG)</option>
                          <option value="portuguese">Portuguese (PT)</option>
                        </Field>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="float-end">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default Language;
