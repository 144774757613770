import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Button
} from 'reactstrap';

import { Field, reduxForm } from 'redux-form';

@reduxForm({
  form: 'erpConnectionForm',
  enableReinitialize: true
})
class ERPConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = form => {
    console.log('### form ###', form);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          <Row>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle>ERP Authentication</CardTitle>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="username">Username</label>
                    </Col>
                    <Col md="4">
                      <Field
                        name="username"
                        component="input"
                        type="text"
                        className="form-control"
                        placeholder="username"
                      />
                    </Col>
                    <Col md="2">
                      <label htmlFor="password">Password</label>
                    </Col>
                    <Col md="4">
                      <Field
                        name="password"
                        component="input"
                        type="password"
                        className="form-control"
                        placeholder="password"
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle>ERP Database Connection</CardTitle>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.provider">Provider</label>
                    </Col>
                    <Col md="8">
                      <Field
                        name="db.provider"
                        component="select"
                        className="form-control"
                      >
                        <option value="mysql">Mysql</option>
                        <option value="postgres">Postgres</option>
                        <option value="oracle">Oracle</option>
                        <option value="db2">IBM DB2</option>
                      </Field>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.host">Host</label>
                    </Col>
                    <Col md="8">
                      <Field
                        name="db.host"
                        component="input"
                        type="password"
                        className="form-control"
                        placeholder="127.0.0.1"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.port">Port</label>
                    </Col>
                    <Col md="8">
                      <Field
                        name="db.port"
                        component="input"
                        type="text"
                        className="form-control"
                        placeholder="3306"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.username">Username</label>
                    </Col>
                    <Col md="8">
                      <Field
                        name="db.username"
                        component="input"
                        type="text"
                        className="form-control"
                        placeholder="username"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.password">Password</label>
                    </Col>
                    <Col md="8">
                      <Field
                        name="db.password"
                        component="input"
                        type="password"
                        className="form-control"
                        placeholder="password"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="12">
                      <label htmlFor="db.port">Database & Schema</label>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.database">Database</label>
                    </Col>
                    <Col md="10">
                      <Field
                        name="db.database"
                        component="select"
                        className="form-control"
                      >
                        <option value="samltd">SAMLTD</option>
                        <option value="saminc">SAMINC</option>
                      </Field>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <label htmlFor="db.schema">Schema</label>
                    </Col>
                    <Col md="10">
                      <Field
                        name="db.schema"
                        component="input"
                        type="text"
                        className="form-control"
                        placeholder="Schema"
                      />
                    </Col>
                  </FormGroup>
                  <div className="float-end">
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default ERPConnection;
