import React, { Component } from 'react';
import XMTooltipButton from '../XMTooltipButton';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { apiConfigHelper } from '../../apiConfig';

@connect((state) => {
  return {
    backup: state.backup,
    store: state.store,
    erpType: state.erpType,
    operator: state.operator,
    dataCentre: state.dataCentre,
    user: state.user,
  };
})
class BreadcrumbIcon extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { router, currentStore, fetchSources, fetchRequestStatus } = this.props;
    if (currentStore && currentStore.storeId) {
      const sources = await fetchSources(currentStore.storeId);
      if (isEmpty(sources)) {
        router.navigate(`${router.location.pathname}/connection`);
      } else {
        await fetchRequestStatus(currentStore.storeId);
      }
    }
  }

  getStoreId(pathname) {
    return pathname.split('/')[2];
  }

  render () {
    const { pathname, store, version, syncStoresStatus } = this.props;
    const storeId = this.getStoreId(pathname);
    const dataCentreUrl = store && store.dataCentre && store.dataCentre.endpoint;
    const gatewayBaseUrl = dataCentreUrl ||  apiConfigHelper.getStoreUrlForGateway();
    const btns = [
      {
        icon: 'icon-chart',
        url: `/stores/${storeId}/stats`,
        tooltip: 'Stats',
      },
      {
        icon: 'icon-cloud-download',
        url: `/stores/${storeId}/backup`,
        tooltip: 'Backup',
      },
      {
        icon: 'icon-loop',
        url: `/stores/${storeId}/synchronization`,
        tooltip: 'Sync',
        isSync: true,
      },
      {
        icon: 'icon-puzzle',
        url: `/stores/${storeId}/gateway`,
        tooltip: 'API Gateways',
      },
      {
        icon: 'icon-lock',
        url: `/stores/${storeId}/credentials`,
        tooltip: 'Client Credentials',
      },

    ];

    return (
      <div style={{position: 'absolute', right:'0px', paddingRight: '20px', verticalAlign: 'top' }}>
        {storeId && (
          <div>
            { btns.map((btn, bi) => (
              <Link key={bi} to={ btn.url }>
                <XMTooltipButton
                  key={bi}
                  icon={btn.icon}
                  tooltipTitle={btn.tooltip}
                  displayWarningIcon={btn.warning}
                />
              </Link>
            ))}
            <span>Store ID: {store.one.name}</span>
            <span className="ms-4"> URL:{' '}
              <a target="_blank" href={`https://${store.one.primaryDomain}`}
                 rel="noreferrer"> {store.one.primaryDomain}
              </a>
            </span>
            <span className="ms-4"> Gateway:{' '}
              <a target="_blank" href={gatewayBaseUrl + '/gateway.html?access_token='
                 + localStorage.getItem('jwtToken')} rel="noreferrer"> gateway
              </a>
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default BreadcrumbIcon;