import React from 'react';
import moment from 'moment';

const TYPE_ICONS = {
  traceStage: 'fa-info',
  traceData: 'fa-table',
  traceError: 'fa-bug',
  traceSQL: 'fa-terminal'
};

function LogItem({ log }) {
  const [expand, setExpand] = React.useState(false);

  function toggleExpand() {
    setExpand(!expand);
  }

  return (
    <div>
      <div className="pt-1 pb-1 d-flex flex-row align-items-center diagnostic-log" onClick={toggleExpand}>
        <i className={`fa ${expand ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
        <i
          className={`fa ${TYPE_ICONS[log.type]} ms-1 ${log.type === 'traceError' ? 'text-danger' : 'text-success'}`}
        ></i>
        <span className="ms-2 diagnostic-log-msg">{log.message}</span>
        <span className="ms-2">{moment(log.time).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      {expand && (
        <div className="diagnostic-log-details">
          {Object.entries(log).map(([key, value]) => {
            if (key !== 'message') {
              return (
                <p key={key}>
                  {key}: {JSON.stringify(value)}
                </p>
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default LogItem;
