import React, { Component } from 'react';
import { Button, Col, FormGroup, Card, CardBody } from 'reactstrap';

import { connect } from 'react-redux';
import XMDock from '../../components/XMDock';
import XMModal from '../../components/XMModal';
import XMTable from '../../components/XMTable';
import XMSearchBox from '../../components/XMSearchBox';
import XMModalConfirm from '../../components/XMModalConfirm';
import VersionForm from './VersionForm';
import * as versionActions from '../../actions/versionActions';
import Moment from 'react-moment';

@connect(state => {
  return {
    user: state.user,
    version: state.version
  };
})
class Versions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      modal: false,
      modalConfirm: false,
      dock: false,
      modalDelete: false,
      search: null
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
    this.toggleDock = this.toggleDock.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.loadVersions = this.loadVersions.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  loadVersions() {
    const { dispatch } = this.props;
    dispatch(versionActions.getAll(undefined, undefined, undefined, undefined, this.state.search));
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleModalConfirm() {
    this.setState({
      modalConfirm: !this.state.modalConfirm
    });
  }

  toggleDock() {
    this.setState({
      dock: !this.state.dock
    });
  }

  handleSearch(search) {
    this.setState(
      {
        search: search
      },
      this.loadVersions
    );
  }

  // CRUD Actions
  handleCreate() {
    this.toggleModal();
  }

  handleEdit(versionId) {
    const { dispatch } = this.props;
    dispatch(versionActions.getOne(versionId));
    this.toggleDock();
  }

  handleDelete() {
    const { dispatch } = this.props;
    dispatch(versionActions.remove(this.state.selected));
    this.toggleModalConfirm();
  }

  handleDeleteConfirm(versionId) {
    this.setState(
      {
        selected: versionId
      },
      this.toggleModalConfirm
    );
  }

  handleCancel() {
    // this.setState( initialState );
  }

  componentDidMount() {
    this.loadVersions();
  }

  render() {
    const {
      user: { isSuperAdmin },
      version: { one, current, previous }
    } = this.props;

    let btns = [];
    let headers = [
      { name: 'Name', code: 'name' },
      { name: 'Release', code: 'release' },
      { name: 'Notes', code: 'notes' },
      { name: 'Info', code: 'info' }
    ];

    // add superAdmin actions
    if (isSuperAdmin) {
      headers.push({ name: 'Actions' });
      btns = [
        {
          icon: 'icon-note',
          action: this.handleEdit
        },
        {
          icon: 'icon-trash',
          action: this.handleDeleteConfirm
        }
      ];
    }

    return (
      <div>
        <XMModalConfirm isVisible={this.state.modalConfirm} handleConfirm={this.handleDelete} />
        <XMModal title="Create Version" isVisible={this.state.modal} handleCancel={this.handleCancel}>
          <VersionForm form={'insert'} toggle={this.toggleModal} handleCancel={this.handleCancel} />
        </XMModal>
        <XMDock title={one.name} isVisible={this.state.dock} handleCancel={this.handleCancel}>
          <VersionForm
            form={'update'}
            toggle={this.toggleDock}
            handleCancel={this.handleCancel}
            isEdit={true}
            initialValues={one}
          />
        </XMDock>
        <Card>
          <CardBody>
            <FormGroup row>
              <Col md="4">
                <XMSearchBox onSearch={this.handleSearch} />
              </Col>
              <Col md="8">
                {isSuperAdmin ? (
                  <Button color="light" onClick={() => this.handleCreate()}>
                    <i className="icon-plus" />
                  </Button>
                ) : null}
              </Col>
            </FormGroup>
            {current.id ? (
              <Card style={{ border: '0' }}>
                <h6>Current Version</h6>
                <CardBody style={{ padding: '0' }}>
                  <XMTable headers={headers} btns={btns}>
                    <tr>
                      <td className="align-middle">
                        <span>{current.name}</span>
                      </td>
                      <td className="align-middle">
                        <span>
                          <Moment format="Do MMM YYYY, HH:mm" withTitle>
                            {current.release}
                          </Moment>
                        </span>
                      </td>
                      <td className="align-middle">
                        <Button color="link" href={'https://' + current.notes} target="_blank">
                          {current.notes}
                        </Button>
                      </td>
                      <td className="align-middle">
                        <span>{current.info}</span>
                      </td>
                    </tr>
                  </XMTable>
                </CardBody>
              </Card>
            ) : null}
            <Card style={{ border: '0' }}>
              <h6> {current.id && 'Previous Versions'}</h6>
              <CardBody style={{ padding: '0' }}>
                <XMTable headers={headers} handleSort={this.handleSort} btns={btns} autoRenderRow={true}>
                  {previous.map(v => (
                    <React.Fragment key={v.id}>
                      <td className="align-middle">
                        <span>{v.name}</span>
                      </td>
                      <td className="align-middle">
                        <span>
                          <Moment format="Do MMM YYYY, HH:mm" withTitle>
                            {v.release}
                          </Moment>
                        </span>
                      </td>
                      <td className="align-middle">
                        <Button color="link" href={'https://' + v.notes} target="_blank">
                          {v.notes}
                        </Button>
                      </td>
                      <td className="align-middle">
                        <span>{v.info}</span>
                      </td>
                    </React.Fragment>
                  ))}
                </XMTable>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Versions;
