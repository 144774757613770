import React from 'react';
import Moment from 'react-moment';
import { Button, ButtonGroup } from 'reactstrap';

const ListRow = ({ log, expand, onRowClick }) => {
  const getWrapperClassColor = () => {
    const responseStatusCode = log.responseStatusCode || null;

    if (responseStatusCode >= 200 && responseStatusCode < 300) {
      return 'text-success';
    }

    if (responseStatusCode >= 400) {
      return 'text-danger';
    }

    return 'text-info';
  };

  return (
    <tr className={`${getWrapperClassColor()}`}>
      <td>
        <Button color="link" onClick={onRowClick}>
          <i className={expand ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'} />
        </Button>
      </td>
      <td>
        <Moment format="Do MMM YYYY, HH:mm" withTitle>
          {log.createdOn}
        </Moment>
      </td>
      <td>
        <Moment format="Do MMM YYYY, HH:mm" withTitle>
          {log.requestedOn}
        </Moment>
      </td>
      <td>{log.correlationId}</td>
      <td>{log.integrationShortName}</td>
      <td>{log.operationShortName}</td>
      <td>{log.responseStatusCode ?? 'N/A'}</td>
    </tr>
  );
};

export default ListRow;
