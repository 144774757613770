// REGION REDUCER
const initialState = {
  loading: false,
  fetched: false,
  error: null,
  one: {
    id: null,
    dataCentre: {},
    operator: {},
    storeStatus: {},
    storeStatuses: [],
    logs: [],
    syncVersion: null,
    orderIntegrationVersion: null,
  },
  all: [],
  allCloneable: [],
  pagination: {},
  storeTypeCount: [],
};
export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case 'STORE_LOADING': {
      return {
        ...state,
        loading: true,
        allCloneable: initialState.allCloneable,
      };
    }
    case 'STORE_GETALL_SUCCESS': {
      return {
        ...state,
        loading: false,
        fetched: true,
        all: action.payload.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements,
        },
      };
    }
    case 'STORE_GETSTORETYPECOUNT_SUCCESS': {
      return {
        ...state,
        storeTypeCount: action.payload,
      };
    }
    case 'STORE_GETALL_ERROR': {
      return {
        ...state,
      };
    }
    case 'STORE_GETALL_BY_ENVIRONMENT_SUCCESS': {
      return {
        ...state,
        allCloneable: action.payload.content,
      };
    }
    case 'STORE_GETALL_BY_ENVIRONMENT_ERROR': {
      return {
        ...state,
        allCloneable: initialState.allCloneable,
      };
    }
    case 'STORE_GETONE_SUCCESS': {
      return {
        ...state,
        one: action.payload,
        error: initialState.error,
      };
    }
    case 'STORE_GETONE_ERROR': {
      return {
        ...state,
      };
    }
    case 'STORE_CREATE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'STORE_CREATE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'STORE_UPDATE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'STORE_UPDATE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'STORE_UPDATESTATUS_SUCCESS': {
      return {
        ...state,
      };
    }
    case 'STORE_UPDATESTATUS_ERROR': {
      return {
        ...state,
      };
    }
    case 'STORE_DELETE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'STORE_DELETE_ERROR': {
      return {
        ...state,
      };
    }
    case 'STORE_CLEAR': {
      return {
        ...state,
        one: initialState.one,
        allCloneable: initialState.allCloneable,
        error: initialState.error,
      };
    }

    case 'SYNC_UPDATE_VERSION_SUCCESS': {
      return {
        ...state,
        one: {
          ...state.one,
          syncVersion: action.payload.syncVersion,
        },
      };
    }

    case 'ORDER_UPDATE_VERSION_SUCCESS': {
      return {
        ...state,
        one: {
          ...state.one,
          orderIntegrationVersion: action.payload.version,
        },
      };
    }

    case 'SYNC_UPDATE_VERSION_SUCCESS_BUT_SCHEDULER_FAILED': {
      return {
        ...state,
        one: {
          ...state.one,
          syncVersion: action.payload.syncVersion,
        },
      };
    }
  }
  return state;
}
