import React, { Component } from 'react';
import { Button } from 'reactstrap';

/**
 * @param pagination
 * @param paginate
 */
class XMColumnSort extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: null,
      sortDir: null,
    };

    this.onSortUpClick = this.onSortUpClick.bind(this);
    this.onSortDownClick = this.onSortDownClick.bind(this);
  }

  onSortUpClick(columnCode) {
    this.setState(
      {
        sort: columnCode,
        sortDir: 'ASC',
      },
      this.doSort
    );
  }

  onSortDownClick(columnCode) {
    this.setState(
      {
        sort: columnCode,
        sortDir: 'DESC',
      },
      this.doSort
    );
  }

  doSort() {
    const { columnSort } = this.props;
    columnSort(this.state.sort, this.state.sortDir);
  }

  render() {
    const { columnName, columnCode, columnStyle } = this.props;
    return (
      <th className="align-middle" style={columnStyle ? columnStyle : {}}>
        <span>{columnName}</span>
        <span>
          {!this.state.sortDir ? (
            <Button onClick={() => this.onSortUpClick(columnCode)} className="btn-transparent">
              <i className="icon-arrow-right" />
            </Button>
          ) : this.state.sortDir == 'ASC' ? (
            <Button onClick={() => this.onSortDownClick(columnCode)} className="btn-transparent">
              <i className="icon-arrow-down" />
            </Button>
          ) : (
            <Button onClick={() => this.onSortUpClick(columnCode)} className="btn-transparent">
              <i className="icon-arrow-up" />
            </Button>
          )}
        </span>
      </th>
    );
  }
}

export default XMColumnSort;
