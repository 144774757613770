import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import withRouter from '../../withRouter';

class Integrations extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { router, currentGatewayStore, currentStore } = this.props;
    const { integrations } = currentGatewayStore;
    return (
      <>
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6 mt-4">
          <h5>Integrations</h5>
          <hr className="separator" />
          <ul className="list-unstyled ms-4">
            {integrations.map((integration, i) => (
              <li key={i}>
                <Link to={`/stores/${currentStore.id}/gateway/integrations/${integration}`}>{integration}</Link>
              </li>
            ))}
          </ul>
          <div className="float-end">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                router.navigate(`/stores/${currentStore.id}/gateway/integrations-add`);
              }}
            >
              Add Integration
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
  }),
  (dispatch) => ({})
)(withRouter(Integrations));
