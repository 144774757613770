import React from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withRouter from '../../../withRouter';
import { Link } from 'react-router-dom';

const Tabs = [
  {
    path: 'credentials',
    title: 'Credentials'
  },
];

function MainTabs({ router, path, storeId, primaryDomain }) {
  return (
    <Nav tabs>
      {Tabs.map((tab) => (
        <NavItem key={tab.path}>
          <NavLink
            className={classnames({ active: router.location.pathname.endsWith(tab.path) })}
            tag={Link}
            to={`${path}/${tab.path}`}
          >
            {tab.title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

MainTabs.propTypes = {
  match: PropTypes.object,
  router: PropTypes.object,
  storeId: PropTypes.string,
  path: PropTypes.string,
  primaryDomain: PropTypes.string,
};

export default connect(() => ({}))(withRouter(MainTabs));
