import React from 'react';
import { connect } from 'react-redux';
import SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { apiConfigHelper } from '../../../../../apiConfig';

import LogItem from '../components/LogItem';

function reducer(state, action) {
  switch (action.type) {
    case 'traceStage': {
      return [
        ...state,
        { type: action.type, time: action.ts, message: action.stage, stage: action.stage, requestId: action.requestId }
      ];
    }

    case 'traceData': {
      return [
        ...state,
        {
          type: action.type,
          time: action.ts,
          message: `${action.table} ${JSON.stringify(action.fields)}`,
          table: action.table,
          fields: action.fields,
          requestId: action.requestId
        }
      ];
    }

    case 'traceError': {
      return [
        ...state,
        {
          type: action.type,
          time: action.ts,
          message: `${action.table} ${action.error}`,
          table: action.table,
          error: action.error,
          requestId: action.requestId
        }
      ];
    }

    case 'traceSQL': {
      return [
        ...state,
        { type: action.type, time: action.ts, message: action.sql, sql: action.sql, requestId: action.requestId }
      ];
    }

    case 'clear': {
      if (state.length > 1000) {
        const list = state;
        list.splice(0, 100);
        return list;
      }
    }
  }
  return state;
}

function LogContainer({ storeId, isPause, autoScroll, filter }, ref) {
  const [error, setError] = React.useState(null);
  const [connecting, setConnecting] = React.useState(true);
  const [stompClient, setStompClient] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);
  const [logs, setLogs] = React.useState([]);

  const messages = React.useRef([]);

  function setUpWebsocket() {
    let stomp = null;
    let sub = null;
    if (storeId && !stompClient) {
      try {
        setConnecting(true);
        const accessToken = localStorage.getItem('jwtToken');
        const ws = new SockJS(`https://${apiConfigHelper.getBaseUrl()}/sync/trace?access_token=${accessToken}`);
        stomp = Stomp.over(ws);
        stomp.debug = null;
        setStompClient(stomp);

        stomp.connect({ filter }, function(frame) {
          sub = stomp.subscribe(`/queue/${storeId}`, parseMessage);
          if (sub) {
            setConnecting(false);
            setSubscription(sub);
          }
        });
      } catch (error) {
        console.error(`unable to connect to websocket server`, error);
        setError(error);
        setStompClient(null);
      }
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
        setSubscription(null);
      }
      if (stomp) {
        stomp.disconnect();
        setStompClient(null);
      }
    };
  }

  React.useEffect(() => {
    if (stompClient && storeId) {
      if (isPause && subscription && stompClient) {
        subscription.unsubscribe();
        stompClient.disconnect();
        setSubscription(null);
        setStompClient(null);
      }
    } else {
      return setUpWebsocket();
    }
  }, [isPause, storeId]);

  React.useEffect(() => {
    if (autoScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages.current, autoScroll]);

  React.useEffect(() => {
    if (filter && storeId && stompClient) {
      stompClient.send(`/sync/${storeId}`, {}, JSON.stringify({ filter: filter }));
    }
  }, [filter, storeId]);

  React.useEffect(() => {
    const id = setInterval(() => {
      if (messages.current.length > 0) {
        if (messages.current.length > 1000) {
          messages.current.splice(0, messages.current.length - 1000);
          setLogs([...messages.current]);
        } else {
          setLogs([...messages.current]);
        }
      }
    }, 1000);

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, []);

  function parseMessage(msg) {
    messages.current = reducer(messages.current, JSON.parse(msg.body));
  }

  return (
    <div className="mt-3 diagnostic-log-container">
      {error && <div className="diagnostic-log">Failed to connect!</div>}
      {connecting && <div className="diagnostic-log">Connecting...</div>}
      {!connecting && !error && logs.length === 0 && <div className="diagnostic-log">Waiting for messages...</div>}
      {logs && logs.map(log => <LogItem key={log.t} log={log} />)}
      <div ref={ref}></div>
    </div>
  );
}

const ForwardComponent = React.forwardRef(LogContainer);

export default connect(
  state => ({ filter: state.sync.ui.diagnostic.filter }),
  null,
  null,
  { forwardRef: true }
)(ForwardComponent);
