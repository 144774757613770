import { combineReducers } from 'redux';
import { reducer as fomrReducer } from 'redux-form';
import dataCentreReducer from './dataCentreReducer';
import erpTypeReducer from './erpTypeReducer';
import storeReducer from './storeReducer';
import operatorReducer from './operatorReducer';
import userReducer from './userReducer';
import sync from './sync';
import backupReducer from './backupReducer';
import versionReducer from './versionReducer';
import dataFileReducer from './dataFileReducer';
import configReducer from './configReducer';
import environmentReducer from './environmentReducer';
import gatewayReducer from './gatewayReducer';
import clientCredentialReducer from './clientCredentialReducer';

const rootReducer = combineReducers({
  environment: environmentReducer,
  config: configReducer,
  version: versionReducer,
  backup: backupReducer,
  sync,
  dataFile: dataFileReducer,
  user: userReducer,
  dataCentre: dataCentreReducer,
  erpType: erpTypeReducer,
  store: storeReducer,
  operator: operatorReducer,
  form: fomrReducer,
  gateway: gatewayReducer,
  clientCredential: clientCredentialReducer,
});

export default rootReducer;
