import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'reactstrap';

import {
  createMapping,
  fetchSourceTableColumns,
  fetchTargetColumns
} from '../../../../../actions/synchronisationActions';
import { SelectField } from '../../../../../components/Field';
import withRouter from '../../../../../withRouter';

export class MappingForm extends Component {
  state = {
    disableTargetColumn: true,
    disableSourceColumn: true
  };

  componentDidUpdate(prevProps) {
    const { resetForm } = this.props;

    if (prevProps.resetForm !== resetForm && resetForm) {
      this.resetForm();
    }
  }

  submit = values => {
    const { createMapping, storeId } = this.props;
    if (storeId) {
      createMapping(storeId, {
        ...values,
        sourceTable: values.sourceTable && values.sourceTable.value,
        sourceColumn: values.sourceColumn && values.sourceColumn.value,
        targetTable: values.targetTable && values.targetTable.value,
        targetColumn: values.targetColumn && values.targetColumn.value
      });
    }
  };

  handleSourceTableChange = option => {
    const { fetchSourceTableColumns, storeId } = this.props;
    const { value } = option;
    fetchSourceTableColumns(storeId, value);
    this.setState({ disableTargetColumn: false });
  };

  handleTargetTableChange = option => {
    const { fetchTargetColumns, storeId } = this.props;
    const { value } = option;
    fetchTargetColumns(storeId, value);
    this.setState({ disableSourceColumn: false });
  };

  getTableOptions(options) {
    return options ? options.map(option => ({ key: option, id: option, name: option })) : [];
  }

  getColumnOptions(options) {
    return options ? options.map(option => ({ key: option.name, id: option.name, name: option.name })) : [];
  }

  resetForm = () => {
    const { reset } = this.props;
    this.setState({
      disableTargetColumn: true,
      disableSourceColumn: true
    });
    reset();
  };

  render() {
    const { handleSubmit, sourceTables, sourceColumns, targetTables, targetColumns } = this.props;

    return (
      <tr>
        <td>
          <Field
            component={SelectField}
            options={this.getTableOptions(sourceTables)}
            name="sourceTable"
            onChange={this.handleSourceTableChange}
          />
        </td>

        <td>
          <Field
            component={SelectField}
            options={this.getColumnOptions(sourceColumns)}
            name="sourceColumn"
            disabled={this.state.disableTargetColumn}
          />
        </td>

        <td>
          <Field
            component={SelectField}
            options={this.getTableOptions(targetTables)}
            name="targetTable"
            onChange={this.handleTargetTableChange}
          />
        </td>

        <td>
          <Field
            component={SelectField}
            options={this.getColumnOptions(targetColumns)}
            name="targetColumn"
            disabled={this.state.disableSourceColumn}
          />
        </td>

        <td>
          <Field component="input" className="form-control" type="text" name="defaultValue" style={{ width: '100%' }} />
        </td>

        <td>
          <Field component="input" className="form-control" type="text" name="rowGroup" style={{ width: '100%' }} />
        </td>

        <td>
          <Field component="input" className="form-control" type="text" name="converter" style={{ width: '100%' }} />
        </td>

        <td colSpan={2}>
          <div className="d-flex flex-row justify-content-end">
            <Button color="primary" type="submit" onClick={handleSubmit(this.submit)}>
              Save
            </Button>
            <Button color="secondary" type="button" className="ms-2" onClick={this.resetForm}>
              Reset
            </Button>
          </div>
        </td>
      </tr>
    );
  }
}

export default connect(
  state => ({
    sourceTables: state.sync.sourceTable.tables,
    sourceColumns: state.sync.sourceColumn.columns,
    targetTables: state.sync.targetTable.tables,
    targetColumns: state.sync.targetColumn.columns,
    resetForm: state.sync.mapping.resetForm
  }),
  dispatch => ({
    createMapping: (storeId, mapping) => dispatch(createMapping(storeId, mapping)),
    fetchSourceTableColumns: (storeId, tableName) => dispatch(fetchSourceTableColumns(storeId, tableName)),
    fetchTargetColumns: (storeId, tableName) => dispatch(fetchTargetColumns(storeId, tableName))
  })
)(
  withRouter(
  reduxForm({
    form: 'mappingForm',
    enableReinitialize: false
  })(MappingForm)
  )
);
