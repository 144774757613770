import React, { Component } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { PacmanLoader } from 'react-spinners';

import XMModalConfirm from '../../components/XMModalConfirm';
import XMTable from '../../components/XMTable';
import XMPagination from '../../components/XMPagination';
import * as backupActions from '../../actions/backupActions';
import XMTooltipButton from '../../components/XMTooltipButton';
import { isDevelopment } from '../../utils/environment';

const STATUS_COLOR = {
  IN_PROGRESS: 'warning',
  TRANSFORMING: 'warning',
  ERROR: 'danger',
  DONE: 'success'
};

@connect(state => {
  return {
    store: state.store,
    backup: state.backup
  };
})
class BackupTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.storeId,
      modalDelete: false,
      modalRestore: false,
      pagination: {}
    };

    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleRestore = this.toggleRestore.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
  }

  toggleDelete(backupId) {
    this.setState({
      backupId: backupId,
      modalDelete: !this.state.modalDelete
    });
  }

  handleDelete() {
    const { dispatch } = this.props;
    dispatch(backupActions.remove(this.state.storeId, this.state.backupId));
    this.toggleDelete();
  }

  toggleRestore(backupId) {
    this.setState({
      backupId: backupId,
      modalRestore: !this.state.modalRestore
    });
  }

  handleRestore() {
    const { toggle, backup, dispatch, isCloneOnly } = this.props;

    let backupId = this.state.backupId;
    let backupSelected;
    if (isCloneOnly) {
      backupSelected = _.find(backup.allCloneable.backups, function(b) {
        return b.id === backupId;
      });
    } else {
      backupSelected = _.find(backup.all, function(b) {
        return b.id === backupId;
      });
    }

    backupSelected = {
      ...backupSelected,
      store: backupSelected.storeResponse
    };

    dispatch(backupActions.restore(this.state.storeId, backupSelected));

    this.toggleRestore();
    if (toggle) {
      toggle();
    }
  }

  isWaiting(statusType) {
    return statusType == 'IN_PROGRESS' || statusType == 'TRANSFORMING';
  }

  handleCancelRestore = () => {
    this.setState({ modalRestore: false });
  };

  handleCancelDelete = () => {
    this.setState({ modalDelete: false });
  };

  render() {
    const { all, pagination, paginate, isCloneOnly = false } = this.props;

    let headers = [];
    headers.push({ name: 'File Name' });
    headers.push({ name: 'Type' });
    if (!isCloneOnly) {
      headers.push({ name: 'DB' });
      headers.push({ name: 'CDN' });
    }
    headers.push({ name: '' });
    headers.push({ name: 'Actions' });

    return all.length == 0 ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>No backups</div>
    ) : (
      <div>
        <XMModalConfirm
          isVisible={this.state.modalRestore}
          handleConfirm={this.handleRestore}
          onCancel={this.handleCancelRestore}
          message="Are you sure you want to RESTORE this Backup?"
          isValidatingStoreUrl={!isDevelopment()}
        />

        <XMModalConfirm
          isVisible={this.state.modalDelete}
          handleConfirm={this.handleDelete}
          onCancel={this.handleCancelDelete}
          message="Are you sure you want to DELETE this Backup?"
        />

        <XMTable headers={headers} autoRenderRow={true}>
          {all.map(b => (
            <React.Fragment key={b.id}>
              <td className="align-middle">{b.fileName}</td>
              <td className="align-middle">{b.type}</td>
              {!isCloneOnly ? (
                <td className="align-middle">
                  <Button color={STATUS_COLOR[b.statusType]} size="sm">
                    {this.isWaiting(b.statusType) ? (
                      <div style={{ display: 'inline-flex', width: '10em' }}>
                        <span>{b.statusType}</span>
                        <div className="ms-2">
                          <PacmanLoader size={8.5} color={'#FFF'} loading={true} />
                        </div>
                      </div>
                    ) : (
                      <span>{b.statusType}</span>
                    )}
                  </Button>
                </td>
              ) : null}
              {!isCloneOnly ? (
                <td className="align-middle">
                  { b.cdnStatusType !== null &&
                  <Button color={STATUS_COLOR[b.cdnStatusType]} size="sm">
                      {this.isWaiting(b.cdnStatusType) ? (
                        <div style={{ display: 'inline-flex', width: '10em' }}>
                          <span>{b.cdnStatusType}</span>
                          <div className="ms-2">
                            <PacmanLoader size={8.5} color={'#FFF'} loading={true} />
                          </div>
                        </div>
                      ) : (
                        <span>{b.cdnStatusType}</span>
                      )}
                    </Button>
                    }
                  </td>
                ) : null}
              <td className="align-middle">
                <Container>
                  {b.cloneStoreName ? (
                    <Row>
                      <Col xs="5">
                        <span className="text-primary">Cloned From</span>
                      </Col>
                      <Col>
                        <span className="text-primary">{b.cloneStoreName}</span>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col xs="5">
                      <span className="text-secondary">Created By</span>
                    </Col>
                    <Col>
                      <span className="text-secondary">{b.createdBy}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="5">
                      <span className="text-secondary">Created Date</span>
                    </Col>
                    <Col>
                      <span className="text-secondary">
                        <Moment format="Do MMM YYYY, HH:mm" withTitle>
                          {b.createdDate}
                        </Moment>
                      </span>
                    </Col>
                  </Row>
                </Container>
              </td>
              <td className="align-middle">
                <ButtonGroup>
                  <XMTooltipButton
                    icon="icon-cloud-upload"
                    tooltipTitle="Restore"
                    onClick={() => this.toggleRestore(b.id)}
                    disabled={(!(b.statusType === 'DONE' && (b.cdnStatusType === null || b.cdnStatusType === 'DONE')))}
                  />
                </ButtonGroup>
              </td>
            </React.Fragment>
          ))}
        </XMTable>
        {paginate && pagination ? <XMPagination pagination={pagination} paginate={paginate} /> : null}
      </div>
    );
  }
}

export default BackupTable;
