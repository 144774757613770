import React from 'react';
import { Button, Row, Col } from 'reactstrap';

function StoreDetailsExtraInfo({ isLink = false, value, name }) {
  return (
    <Row>
      <Col sm={3} className="text-muted" style={{ fontSize: '0.7em' }}>
        ({name})
      </Col>
      <Col sm={9}>
        {isLink ? (
          <Button color="link" href={'https://' + value} target="_blank">
            {value}
          </Button>
        ) : (
          value
        )}
      </Col>
    </Row>
  );
}

export default StoreDetailsExtraInfo;
