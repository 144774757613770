import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Button, Col, Row, Alert } from 'reactstrap';

import {
  fetchDatabases,
  fetchSources,
  fetchMappings,
  fetchSourceTables,
  fetchTargetTables,
  filterByOverride,
  loadMappedData,
  clearSourceMessage
} from '../../../../actions/synchronisationActions';
import { getOne } from '../../../../actions/storeActions';
import MappingTable from './containers/MappingTable';

class SyncMapping extends React.Component {
  async componentDidMount() {
    const { currentStore, fetchStore } = this.props;
    if (currentStore.storeId) {
      fetchStore(currentStore.id);
      this.fetchAll();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentStore } = this.props;
    if (prevProps.currentStore.storeId !== currentStore.storeId) {
      this.fetchAll();
    }
  }

  fetchAll() {
    const { currentStore, fetchSources, fetchMappings, fetchSourceTables, fetchTargetTables } = this.props;
    fetchSources(currentStore.storeId);
    fetchMappings(currentStore.storeId);
    fetchSourceTables(currentStore.storeId);
    fetchTargetTables(currentStore.storeId);
  }

  handleOverrideOnly = event => {
    const { filterByOverride } = this.props;
    filterByOverride(event.target.checked);
  };

  handleSendMappings = () => {
    const { currentStore, sendMappings } = this.props;
    sendMappings(currentStore.storeId);
  };

  onDismiss = () => {
    this.props.clearSourceMessage();
  };

  render() {
    const { currentStore, message, isError } = this.props;

    return (
      <div className="mt-4">
        {message && (
          <Alert color={isError ? 'danger' : 'success'} isOpen={!!message} toggle={this.onDismiss}>
            {message}
          </Alert>
        )}

        <Row>
          <Col xs={6}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" onChange={this.handleOverrideOnly} />
                <span className="ms-2">Show overrides only</span>
              </Label>
            </FormGroup>
          </Col>
          <Col xs={6} className="d-flex flex-row justify-content-end">
            <Button color="light" onClick={this.handleSendMappings}>
              Send Mappings
            </Button>
          </Col>
        </Row>

        <MappingTable storeId={currentStore && currentStore.storeId} />
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      currentStore: state.store.one,
      dataCentre: state.dataCentre,
      sources: state.sync.source.sources,
      message: state.sync.mapping.message,
      isError: state.sync.mapping.isError
    };
  },
  dispatch => ({
    fetchStore: storeId => dispatch(getOne(storeId)),
    fetchDatabases: (storeId, protocol, hostName, port) => dispatch(fetchDatabases(storeId, protocol, hostName, port)),
    fetchSources: storeId => dispatch(fetchSources(storeId)),
    fetchMappings: storeId => dispatch(fetchMappings(storeId)),
    fetchSourceTables: storeId => dispatch(fetchSourceTables(storeId)),
    fetchTargetTables: storeId => dispatch(fetchTargetTables(storeId)),
    filterByOverride: override => dispatch(filterByOverride(override)),
    sendMappings: storeId => dispatch(loadMappedData(storeId)),
    clearSourceMessage: () => dispatch(clearSourceMessage())
  })
)(SyncMapping);
