export const API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL = 'BusinessCentral';

export const SYNC2_SCHEDULER_STATUS = {
  NOT_LOADED: 'The store has not yet completed an initial full load.',

  SCHEDULER_OFF: 'The scheduler has not been configured and enabled.',

  AGENT_OFF: 'The remote agent is currently unreachable.',

  AGENT_ISSUE: 'There is a configuration issue with the remote agent.',

  CDC_DISABLED: 'The source database does not have CDC configured and enabled.',

  CDC_WRAPPED: 'The source database CDC number has become inconsistent with our value.',

  CDC_STATIONARY: 'The source database CDC number has not incremented in the last 24 hours.',

  NO_DATA: 'Although the CDC number is incrementing, no data has been received in the last 24 hours.',

  SYNC_OVERDUE: 'A synchronisation has not completed in the last hour.',

  OK: 'The site is synchronising normally with the data source',
};

export const SYNC2_SCHEDULER_NORMAL_STATUS = ['SCHEDULER_OFF', 'OK'];
