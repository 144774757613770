import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, ButtonGroup, Button, Spinner } from 'reactstrap';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';

import DataItem from './components/DataItem';

import { fetchGraphData, fetchStoreStatus } from '../../../../actions/synchronisationActions';
import { SYNC2_SCHEDULER_NORMAL_STATUS, SYNC2_SCHEDULER_STATUS } from '../../../../utils/constants';

function SyncDashboard({
  currentStore,
  sources,
  loading,
  data,
  totals,
  fetchGraphData,
  fetchStoreStatus,
  syncStoreStatus,
}) {
  const [type, setType] = React.useState('hour');
  const syncStatus =
    currentStore && currentStore.storeId && syncStoreStatus[currentStore.storeId]
      ? syncStoreStatus[currentStore.storeId]
      : '';
  let syncStatusDesc = syncStatus && SYNC2_SCHEDULER_STATUS[syncStatus] ? SYNC2_SCHEDULER_STATUS[syncStatus] : '';
  const isWarningSyncStatus = syncStatus && !SYNC2_SCHEDULER_NORMAL_STATUS.includes(syncStatus);

  useEffect(() => {
    if (currentStore.storeId && !isEmpty(sources)) {
      fetchGraphData(currentStore.storeId, type);

      fetchStoreStatus(currentStore.storeId);
      const timer = setInterval(() => {
        fetchStoreStatus(currentStore.storeId);
      }, 15000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [currentStore, sources, type]);

  return (
    <div className={isWarningSyncStatus ? 'mt-2' : 'mt-4'}>
      {isWarningSyncStatus && (
        <Row className="pb-3">
          <Col sm={6}>
            <img src="/img/icon-yellow-triangle-256.png" style={{ width: 48 }} />
            {syncStatus} - {syncStatusDesc}
          </Col>
        </Row>
      )}
      <Card>
        <CardHeader>
          <Row className="ps-3 pe-3">
            <Col sm={6}>
              <h3>Sync Traffic</h3>
            </Col>
            <Col sm={6} className="d-flex flex-row align-items-center justify-content-end">
              <ButtonGroup>
                <Button className={type === 'hour' ? 'active' : ''} color="light" onClick={() => setType('hour')}>
                  Hour
                </Button>
                <Button className={type === 'today' ? 'active' : ''} color="light" onClick={() => setType('today')}>
                  Day
                </Button>
                <Button className={type === 'week' ? 'active' : ''} color="light" onClick={() => setType('week')}>
                  Week
                </Button>
                <Button className={type === 'month' ? 'active' : ''} color="light" onClick={() => setType('month')}>
                  Month
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {loading && (
            <div className="d-flex flex-row justify-content-center">
              <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
            </div>
          )}
          {!isEmpty(data) && (
            <ResponsiveContainer width="100%" aspect={4.0 / 1.5}>
              <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="upload" stroke="#2e79e2" />
                <Line type="monotone" dataKey="row" stroke="#4dbd74" />
                <Line type="monotone" dataKey="byte" stroke="#f79e5a" />
                <Line type="monotone" dataKey="error" stroke="#f86c6b" />
              </LineChart>
            </ResponsiveContainer>
          )}
        </CardBody>
        <CardFooter>
          <Row>
            <Col className="d-flex flex-row align-items-center">
              <DataItem title="Total Uploads" value={totals.UPLOADS} color="#2e79e2" />
              <DataItem title="Total Rows Updated" value={totals.ROWS} color="#4dbd74" />
              <DataItem title="Total Bytes" value={totals.BYTES} color="#f79e5a" />
              <DataItem title="Total Errors" value={totals.ERRORS} color="#f86c6b" />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    loading: state.sync.graph.loading,
    totals: state.sync.graph.totals,
    data: state.sync.graph.data,
    sources: state.sync.source.sources,
    syncStoreStatus: state.sync.status.syncStoreStatus,
  }),
  (dispatch) => ({
    fetchGraphData: (storeId, type) => dispatch(fetchGraphData(storeId, type)),
    fetchStoreStatus: (storeId) => dispatch(fetchStoreStatus(storeId)),
  })
)(SyncDashboard);
