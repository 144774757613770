import { GatewayApi } from '../api';
import { API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL } from '../utils/constants';

export function getOrCreateGatewayStore(store) {
  return async (dispatch) => {
    try {
      let { storeId } = store;
      let gatewayStore;
      try {
        gatewayStore = await GatewayApi.getStore(storeId);
      } catch (error) {
        if (error.status === 404) {
          await GatewayApi.saveStore(storeId, {});
          gatewayStore = await GatewayApi.getStore(storeId);
        } else {
          throw error;
        }
      }

      if (store.erpType.code === 'MS_Dynamics_BC') {
        let needToFetchAgain = false;
        if (!gatewayStore.integrations.includes('ActiveDirectory')) {
          await GatewayApi.saveStoreIntegration(storeId, 'ActiveDirectory', {
            storeId,
            integration: 'ActiveDirectory',
            interface: {
              provider: 'Microsoft',
              product: 'Azure',
              version: '365',
              interface: 'ActiveDirectory',
            },
            connection: {
              type: 'HTTP',
              uri: 'https://login.microsoftonline.com',
              authType: 'NONE',
            },
          });
          needToFetchAgain = true;
        }

        if (!gatewayStore.integrations.includes(API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL)) {
          await GatewayApi.saveStoreIntegration(storeId, API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL, {
            storeId,
            integration: API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL,
            interface: {
              provider: 'Microsoft',
              product: API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL,
              version: '18.5',
              interface: 'apiV2',
            },
            connection: {
              type: 'HTTP',
              uri: '',
              authType: 'BEARER',
              accessOperation: {
                provider: 'Microsoft',
                product: 'Azure',
                version: '365',
                interface: 'ActiveDirectory',
                operation: 'clientCertificate',
              },
            },
          });
          needToFetchAgain = true;
        }

        if (needToFetchAgain) {
          gatewayStore = await GatewayApi.getStore(storeId);
        }
      }

      dispatch({
        type: 'GATEWAY_STORE_GETONE_SUCCESS',
        payload: gatewayStore,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_GETONE_ERROR',
        payload: error.message,
      });
      console.log('gatewayActions.getOne Error:', error);
    }
  };
}

export function getStore(storeId) {
  return async (dispatch) => {
    try {
      let gatewayStore;
      try {
        gatewayStore = await GatewayApi.getStore(storeId);
      } catch (error) {
        if (error.status === 404) {
          // Not Found, we need to create one
          await GatewayApi.saveStore(storeId, {});
          gatewayStore = await GatewayApi.getStore(storeId);
        } else {
          throw error;
        }
      }

      dispatch({
        type: 'GATEWAY_STORE_GETONE_SUCCESS',
        payload: gatewayStore,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_GETONE_ERROR',
        payload: error.message,
      });
      console.log('gatewayActions.getOne Error:', error);
    }
  };
}

export function saveStore(storeId, data) {
  return async (dispatch) => {
    try {
      const gatewayStore = await GatewayApi.saveStore(storeId, data);
      dispatch({
        type: 'GATEWAY_STORE_UPDATE_SUCCESS',
        payload: gatewayStore,
      });
      await dispatch(getOne(storeId));
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_UPDATE_ERROR',
        payload: error.message,
      });
    }
  };
}

export function clearStoreIntegration() {
  return async (dispatch) => {
    console.log('clear clearStoreIntegration');
    dispatch({ type: 'GATEWAY_STORE_INTEGRATION_GETONE_INIT' });
  };
}

export function getStoreIntegration(storeId, integrationName) {
  return async (dispatch) => {
    try {
      const storeIntegration = await GatewayApi.getStoreIntegration(storeId, integrationName);
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_GETONE_SUCCESS',
        payload: storeIntegration,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_GETONE_ERROR',
        payload: error.message,
      });
      console.log('gatewayActions.getStoreIntegration Error:', error);
    }
  };
}

export function saveStoreIntegration(storeId, integration, data) {
  return async (dispatch) => {
    try {
      const gatewayStoreIntegration = await GatewayApi.saveStoreIntegration(storeId, integration, data);
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_UPDATE_SUCCESS',
        payload: gatewayStoreIntegration,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_UPDATE_ERROR',
        payload: error.message,
      });
    }
  };
}

export function getAvailableInterfaces() {
  return async (dispatch) => {
    try {
      const availableInterfaces = [];
      const providers = await GatewayApi.getDetailedProviders();

      providers.forEach((provider) => {
        provider.products.forEach((product) => {
          product.versions.forEach((version) => {
            version.interfaces.forEach((item) => {
              const names = [item.provider, item.product, item.version, item.interface];
              item.title = names.join(' ');
              item.value = names.join('-');
              availableInterfaces.push(item);
            });
          });
        });
      });

      dispatch({
        type: 'GATEWAY_GET_AVAILABLE_INTERFACES_SUCCESS',
        payload: availableInterfaces,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_GET_AVAILABLE_INTERFACES_ERROR',
        payload: error.message,
      });
      console.log('getAvailableInterfaces error:', error);
    }
  };
}

export function getAzureOperations() {
  return async (dispatch) => {
    try {
      const azureOperations = [];
      const provider = 'Microsoft',
        product = 'Azure',
        version = '365',
        interfaceName = 'ActiveDirectory';

      const azureInterface = await GatewayApi.getProviderProductVersionInterface(
        provider,
        product,
        version,
        interfaceName
      );
      const prevValue = [provider, product, version, interfaceName].join('-') + '-';
      const prevTitle = [provider, product, version, interfaceName].join(' ') + ' ';
      azureInterface.operations.forEach((operation) => {
        azureOperations.push({
          title: prevTitle + operation,
          value: prevValue + operation,
        });
      });

      dispatch({
        type: 'GATEWAY_GET_AZURE_OPERATIONS_SUCCESS',
        payload: azureOperations,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_GET_AZURE_OPERATIONS_ERROR',
        payload: error.message,
      });
      console.log('GetAzureOperations error:', error);
    }
  };
}

// export function clearError() {
//   return (dispatch) => {
//     dispatch({ type: 'GATEWAY_CLEAR_ERROR' });
//   };
// }

export function getStoreIntegrationOperation(storeId, integrationName, operationName) {
  return async (dispatch) => {
    try {
      const storeIntegration = await GatewayApi.getStoreIntegrationOperation(storeId, integrationName, operationName);
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_SUCCESS',
        payload: storeIntegration,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_ERROR',
        payload: error.message,
      });
      console.log('gatewayActions.getStoreIntegrationOperation Error:', error);
    }
  };
}

export function getLogs(storeId, filters) {
  return async (dispatch) => {
    try {
      const gatewayStoreLogs = await GatewayApi.getStoreLogs(
        storeId,
        Object.entries(filters).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
      );

      dispatch({
        type: 'GATEWAY_STORE_GETLOGS_SUCCESS',
        payload: gatewayStoreLogs,
      });
    } catch (error) {
      dispatch({
        type: 'GATEWAY_STORE_GETLOGS_ERROR',
        payload: error.message,
      });
    }
  };
}
