import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {
    cancelSyncVersionChange,
    requestSyncVersionUpdate,
    updateSyncVersion
} from '../../../../../actions/synchronisationActions';
import {NativeSelect} from '../../../../../components/Field';
import {required} from '../../../../../components/Field/validate';
import SyncVersionConfirmationModal from './SyncVersionConfirmationModal';

function SyncVersionField({ currentStore, initialValues, disabled, options, requestSyncVersion,setRequestSyncVersion, updateSyncVersion, cancelChanges, syncVersionModalOpen, schedulerData }) {
  
  function handleModalConfirmation() {
    updateSyncVersion(currentStore.id, requestSyncVersion, initialValues.id, schedulerData);
  }

  function handleModalClose() {
    cancelChanges(currentStore.id);
  }

  function handleSelectChange(value) {
    setRequestSyncVersion(value, initialValues.id);
  }

  return (
    <React.Fragment>
      <Field
        component={NativeSelect}
        className="px-1"
        id="syncVersion"
        name="syncVersion"
        validate={[required]}
        disabled={disabled}
        options={options}
        onChange={({ target: { value } }) => {
          handleSelectChange(value);
        }}
      />

      <SyncVersionConfirmationModal
        isOpen={syncVersionModalOpen}
        handleModalConfirmation={() => { handleModalConfirmation(requestSyncVersion);}}
        handleModalClose={handleModalClose}
      />
    </React.Fragment>
  );
}

export default connect(
  state => ({
    requestSyncVersion: state.sync.source.requestSyncVersion,
    syncVersionModalOpen: state.sync.source.syncVersionModalOpen,
    schedulerData: state.sync.source.schedulerData
  }),
  dispatch => ({
    cancelChanges: () => dispatch(cancelSyncVersionChange()),
    setRequestSyncVersion: (syncVersion, sourceId) => dispatch(requestSyncVersionUpdate(syncVersion, sourceId)),
    updateSyncVersion: (realStoreId, syncVersion, sourceId, schedulerData) => dispatch(updateSyncVersion(realStoreId, syncVersion, sourceId, schedulerData)),
  })
)(SyncVersionField);