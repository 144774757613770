const initialState = {
  diagnostic: {
    filter: ''
  },
  dataLoad: {
    selectedTables: [],
    isDeleteBeforeLoad: false,
    isDeleteBeforeStoreLoad: false,
  }
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case 'SYNC_DIAGNOSTIC_FILTER_CHANGE': {
      return { ...state, diagnostic: { filter: action.payload.filter } };
    }

    case 'SYNC_DATA_LOAD_SOURCE_TABLE_CHANGE': {
      return { ...state, dataLoad: { ...state.dataLoad, selectedTables: action.payload.tables || [] } };
    }

    case 'SYNC_IS_DELETE_BEFORE_LOAD_CHANGE': {
      return { ...state, dataLoad: { ...state.dataLoad, isDeleteBeforeLoad: action.payload.isDeleteBeforeLoad || false } };
    }

    case 'SYNC_IS_DELETE_BEFORE_STORE_LOAD_CHANGE': {
      return { ...state, dataLoad: { ...state.dataLoad, isDeleteBeforeStoreLoad: action.payload.isDeleteBeforeStoreLoad || false } };
    }

    case 'SYNC_CLEAR_UI_STATE': {
      return initialState;
    }
  }
  return state;
}
