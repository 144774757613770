import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  fetchSourceTables,
  fetchStoreTableFilters,
  clearSourceMessage
} from '../../../../actions/synchronisationActions';
import SourceTable from './containers/SourceTable';
import Expression from './components/Expression';
import Tree from './components/Tree';
import SaveForm from './containers/SaveForm';
import ValidateTree from './containers/ValidateTree';
import ValidateExpression from './containers/ValidateExpression';
import DeleteFilter from './containers/DeleteFilter';
import { Alert } from 'reactstrap';

function SyncFilter({
                      currentStore,
                      sourceTables,
                      tableName,
                      tableFilters,
                      storedExpression,
                      storedTree,
                      message,
                      isError,
                      dispatch
                    }) {
  const [expression, setExpression] = React.useState('');
  const [tree, setTree] = React.useState('');

  React.useEffect(() => {
    if (storedExpression !== undefined) {
      setExpression(storedExpression);
    }
  }, [storedExpression]);

  React.useEffect(() => {
    if (storedTree !== undefined) {
      setTree(storedTree);
    }
  }, [storedTree]);

  React.useEffect(() => {
    if (currentStore && currentStore.storeId) {
      if (isEmpty(sourceTables)) {
        dispatch(fetchSourceTables(currentStore.storeId));
      }
      dispatch(fetchStoreTableFilters(currentStore.storeId));
      setExpression('');
      setTree('');
      onDismiss();
    }
  }, [currentStore]);

  const onDismiss = () => {
    dispatch(clearSourceMessage());
  };

  return (
    <div className="mt-4">
      <div className="d-flex flex-row align-items-center">
        <div className="col-sm-6 col-md-4 col-lg-4 sync-filters-left-col">
          {sourceTables && tableFilters &&
          <SourceTable sourceTables={sourceTables}
                       tableFilters={tableFilters}
                       storeId={currentStore && currentStore.storeId}
          />
          }
        </div>
        <div className="col-sm-6 col-md-8 col-lg-8 sync-filters-right-col">
          <div className="row">
            <div>Expression</div>
            <Expression value={expression}
                        setExpression={setExpression}
            />
          </div>
          <div className="row">
            <div className="col-sm-6">
              <ValidateExpression tableName={tableName}
                                  expression={expression}
              />
            </div>
            <div className="col-sm-6">
              <ValidateTree tableName={tableName}
                            tree={tree}
                            storeId={currentStore && currentStore.storeId}
              />
            </div>
          </div>
          <div className="row">
            <div>Tree</div>
            <Tree value={tree}
                  setTree={setTree}
            />
          </div>

          <div className="row sync-filters-action">
            <div className="col-8">
              {message && (
                <Alert color={isError ? 'danger' : 'success'} isOpen={!!message} toggle={onDismiss}>
                  {message}
                </Alert>
              )}
            </div>

            <div className="col-4">
              <SaveForm storeId={currentStore && currentStore.storeId}
                        tableName={tableName}
                        expression={expression}
                        tree={tree}
              />

              {tableFilters && tableFilters.hasOwnProperty(tableName) &&
              <DeleteFilter storeId={currentStore && currentStore.storeId}
                            tableName={tableName}
              />
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({
  currentStore: state.store.one,
  sourceTables: state.sync.sourceTable.tables,
  tableName: state.sync.tableFilter.filter.tableName,
  tableFilters: state.sync.tableFilter.filters,
  storedExpression: state.sync.tableFilter.filter.expr,
  storedTree: state.sync.tableFilter.filter.tree,
  message: state.sync.tableFilter.message,
  isError: state.sync.tableFilter.isError
}))(SyncFilter);