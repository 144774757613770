import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field } from 'redux-form';

import DBAliasModal from './DBAliasModal';

import WarnButton from '../components/WarnButton';
import { InputField } from '../../../../../components/Field';
import { required } from '../../../../../components/Field/validate';

function DatabaseIDSelect({ storeId, protocol, hostName, port, onDatabaseIDSelect }) {
  const [open, setOpen] = React.useState(false);

  function toggle() {
    setOpen(!open);
  }

  function handleSelect(value) {
    toggle();
    onDatabaseIDSelect(value);
  }

  return (
    <React.Fragment>
      <FormGroup className="col-sm-12 col-xs-12 col-md-9 col-lg-9" style={{ marginBottom: 'none' }}>
        <Field component={InputField} type="text" name="dbAlias" id="dbAlias" readOnly validate={[required]} />
      </FormGroup>
      <FormGroup className="col-sm-12 col-xs-12 col-md-3 col-lg-3" style={{ marginBottom: 'none' }}>
        <WarnButton
          id="DbAliasBtn"
          hasWarn={!hostName || !protocol || !port}
          warningText="Please fill 'Connection' fields above"
          onClick={toggle}
        />
      </FormGroup>

      <DBAliasModal
        isOpen={open}
        storeId={storeId}
        protocol={protocol}
        hostName={hostName}
        port={port}
        onSelect={handleSelect}
        onCancel={toggle}
      />
    </React.Fragment>
  );
}

export default DatabaseIDSelect;
