// OPERATOR REDUCER
const initialState = {
  fetched: false,
  one: {
    name: '',
    username: '',
    password: '',
    erpType: {
      erpTypeVersions: []
    },
    erpTypeVersion: {},
    logs: []
  },
  all: [],
  pagination: {},
  error: null
};

const API = 'CALCULATIONS';

export default function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPERATOR_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        all: action.payload.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
    }
    case 'OPERATOR_GETALLBYROLE_SUCCESS': {
      return {
        ...state,
        all: action.payload,
        pagination: initialState.pagination
      };
    }
    case 'OPERATOR_SAVE_SUCCESS': {
      return {
        ...state,
        all: initialState.all,
        one: initialState.one
      };
    }
    case 'OPERATOR_SAVE_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    case 'OPERATOR_GETAONE_SUCCESS': {
      return {
        ...state,
        one: action.payload
      };
    }
    case 'OPERATOR_GETAONE_ERROR': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'OPERATOR_LOOKUP_FULFILLED': {
      return {
        ...state,
        one: action.payload
      };
    }
    case 'OPERATOR_ADD': {
      return {
        ...state,
        one: action.payload
      };
    }
    case 'STORE_CLEAR': {
      return {
        ...state,
        one: initialState.one,
        error: null
      };
    }
  }
  return state;
}
