const tableInitState = {
  loading: false,
  error: {},
  tables: [],
};

export default function sourceTableReducer(state = tableInitState, action) {
  switch (action.type) {
    case 'FETCH_SOURCE_TABLES_START':
      return { ...state, loading: true };

    case 'FETCH_SOURCE_TABLES_SUCCESS': {
      let { tables } = action.payload;
      if (tables) {
        return { ...state, loading: false, error: {}, tables };
      }
      return { ...state, loading: false };
    }

    case 'FETCH_SOURCE_TABLES_FAILED': {
      return { ...state, loading: false, error: action.payload.error };
    }

    default:
      return state;
  }
}
