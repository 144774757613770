import React, { Component } from 'react';
import { Button, Col, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as versionActions from '../../actions/versionActions';
import XMAuditTable from '../../components/XMAuditTable';

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = state => ({
  operator: state.operator,
  erpType: state.erpType,
  version: state.version
});

// SETUP REDUX FORM
@reduxForm({
  form: 'versionForm',
  enableReinitialize: true
})
// CONNECT TO REDUX
@connect(mapStateToProps)
class VersionForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  submit = version => {
    const { toggle, dispatch } = this.props;

    // new
    if (!version.id) {
      version = {
        ...version,
        release: new Date()
      };
    }

    dispatch(versionActions.save(version));
    toggle();
  };

  onSelectFlag(countryCode) {
    this.setState({
      countryCode: countryCode
    });
  }

  onCancel() {
    const { handleCancel, toggle } = this.props;
    handleCancel();
    toggle();
  }

  render() {
    const {
      handleSubmit,
      version: { one },
      isEdit
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          <FormGroup row>
            <Col md="3">
              <label htmlFor="name">Name</label>
            </Col>
            <Col md="8">
              <Field
                name="name"
                component="input"
                type="text"
                className="form-control"
              />
            </Col>
          </FormGroup>
          {isEdit ? (
            <FormGroup row>
              <Col md="3">
                <label htmlFor="release">Release</label>
              </Col>
              <Col md="8">
                <Field
                  name="release"
                  component="input"
                  type="text"
                  className="form-control"
                  disabled
                />
              </Col>
            </FormGroup>
          ) : null}
          <FormGroup row>
            <Col md="3">
              <label htmlFor="notes">Notes</label>
            </Col>
            <Col md="8">
              <Field
                name="notes"
                component="textarea"
                className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="info">Info</label>
            </Col>
            <Col md="8">
              <Field
                name="info"
                component="textarea"
                className="form-control"
              />
            </Col>
          </FormGroup>
          <div className="float-end">
            <Button
              type="button"
              color="secondary"
              className="me-2"
              onClick={() => this.onCancel()}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
        <XMAuditTable show={isEdit} audit={one} />
      </div>
    );
  }
}

export default VersionForm;
