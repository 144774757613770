import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  fetchDatabases,
  fetchSources,
  fetchRequestStatus,
  clearSyncInfo,
  clearUiState,
} from '../../actions/synchronisationActions';
import { getOne } from '../../actions/storeActions';

import StatusToast from './containers/StatusToast';
import SyncMainTabs from './containers/SyncMainTabs';
import SyncDashboard from './tabs/SyncDashboard';
import SyncConnection from './tabs/SyncConnection';
import SyncMapping from './tabs/SyncMapping';
import SyncFilter from './tabs/SyncFilter';
import SyncActivity from './tabs/SyncActivity';
import SyncScheduler from './tabs/SyncScheduler';
import SyncDiagnostics from './tabs/SyncDiagnostics';
import SyncFailures from './tabs/SyncFailures';
import Webhooks from './tabs/Webhooks';
import withRouter from '../../withRouter';

class Synchronization extends Component {
  async componentDidMount() {
    const { router, currentStore, fetchSources, fetchStore, fetchRequestStatus } = this.props;
    await fetchStore(router.params.storeId);
    if (currentStore.storeId) {
      const sources = await fetchSources(currentStore.storeId);
      if (isEmpty(sources)) {
        router.navigate(`${router.location.pathname}/connection`);
      } else {
        await fetchRequestStatus(currentStore.storeId);
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { currentStore, fetchSources, fetchRequestStatus } = this.props;
    if (prevProps.currentStore.storeId !== currentStore.storeId) {
      await fetchSources(currentStore.storeId);
      await fetchRequestStatus(currentStore.storeId);
    }
  }

  componentWillUnmount() {
    this.props.clearSyncInfo();
    this.props.clearUiState();
  }

  isSync2Mode() {
    return this.props.currentStore.syncVersion == 'SYNC_2';
  }

  isBusinessCentral() {
    const { currentStore } = this.props;
    return currentStore && currentStore.erpType && currentStore.erpType.code === 'MS_Dynamics_BC';
  }

  render() {
    const { router, currentStore } = this.props;
    return (
      <div className="card" style={{ padding: 24 }}>
        <SyncMainTabs
          path={`/stores/${currentStore.id}/synchronization`}
          storeId={currentStore.storeId}
          primaryDomain={currentStore.primaryDomain}
          sync2Mode={this.isSync2Mode()}
          isBusinessCentral={this.isBusinessCentral()}
        />

        <Routes>
          <Route path="/dashboard" element={<SyncDashboard />} />
          <Route path="/connection" element={<SyncConnection />} />
          <Route path="/mappings" element={<SyncMapping />} />
          <Route path="/filters" element={<SyncFilter />} />
          <Route path="/activities" element={<SyncActivity />} />
          <Route path="/scheduler" element={<SyncScheduler />} />
          <Route path="/diagnostics" element={<SyncDiagnostics />} />
          <Route path="/failures" element={<SyncFailures />} />
          <Route path="/webhooks" element={<Webhooks />} />
          <Route
            path="*"
            element={
              <Navigate
                to={
                  this.isSync2Mode()
                    ? `${router.location.pathname}/dashboard`
                    : `${router.location.pathname}/connection`
                }
              />
            }
          />
        </Routes>

        <StatusToast />
      </div>
    );
  }
}

Synchronization.propTypes = {
  router: PropTypes.object.isRequired,
  currentStore: PropTypes.object.isRequired,
  fetchSources: PropTypes.func.isRequired,
  fetchStore: PropTypes.func.isRequired,
  fetchRequestStatus: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      currentStore: state.store.one,
      dataCentre: state.dataCentre,
      sources: state.sync.source.sources,
      syncVersion: state.sync.source.syncVersion,
    };
  },
  (dispatch) => ({
    fetchStore: (storeId) => dispatch(getOne(storeId)),
    fetchDatabases: (storeId, protocol, hostName, port) => dispatch(fetchDatabases(storeId, protocol, hostName, port)),
    fetchSources: (storeId) => dispatch(fetchSources(storeId)),
    fetchRequestStatus: (storeId) => dispatch(fetchRequestStatus(storeId)),
    clearSyncInfo: () => dispatch(clearSyncInfo()),
    clearUiState: () => dispatch(clearUiState()),
    setSyncVersion: (syncVersion) => dispatch(setSyncVersion(syncVersion)),
  })
)(withRouter(Synchronization));
