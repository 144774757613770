import React, { Component } from 'react';
import { Container } from 'reactstrap';
import LoginForm from './containers/LoginForm';

const Login = ({firebaseInitialized}) =>  {
    if(!firebaseInitialized) return null;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <LoginForm />
        </Container>
      </div>
    );
}

export default Login;
