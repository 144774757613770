const schemaInitState = {
  loading: false,
  error: {},
  schemas: []
};

export default function schemaReducer(state = schemaInitState, action) {
  switch (action.type) {
    case 'SYNC_SCHEMA_FETCH_START': {
      return { ...state, loading: true, error: {} };
    }

    case 'SYNC_SCHEMA_FETCH_SUCCESS': {
      return { ...state, loading: false, error: {}, schemas: action.payload.schemas };
    }

    case 'SYNC_SCHEMA_FETCH_ERROR': {
      return { ...state, loading: false, error: action.payload.error };
    }

    default:
      return state;
  }
}
