import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StoreDeleteForm from '../containers/StoreDeleteForm';

const StoreDeletionModal = ({ isOpen, onClose, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} className="lg">
      <ModalHeader toggle={onClose}>Confirm delete</ModalHeader>
      <ModalBody>
        <StoreDeleteForm handleDelete={onConfirm} handleCancel={onCancel} toggle={onClose} />
      </ModalBody>
    </Modal>
  );
};

StoreDeletionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default StoreDeletionModal;
