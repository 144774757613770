const initialState = {
  open: false,
  title: null,
  body: null
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    // case 'SYNC_SOURCE_FETCH_ERROR': {
    //   return { open: true, title: 'Failed', body: action.payload.error.message };
    // }
    case 'FETCH_SYNC_REQUEST_FAILED':
    case 'LOAD_SYNC_STORE_DATA_FAILED':
    case 'LOAD_SYNC_MAPPED_DATA_FAILED':
    case 'LOAD_SYNC_GRAPH_DATA_FAILED': {
      return {
        open: true,
        title: 'Failed',
        type: 'danger',
        body:
          (action.payload.error && action.payload.error.data && action.payload.error.data.message) ||
          'Failed to load data!'
      };
    }
    case 'SYNC_CLEAR_ALL':
    case 'SYNC_CLEAR_TOAST': {
      return initialState;
    }
  }
  return state;
}
