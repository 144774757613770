import { reset } from 'redux-form';
import _axios from '../../api';
import syncApi, { SyncApi } from '../../syncApi';
import webhooksApi from '../../webhooksApi';

export * from './activities';
export * from './failures';
export * from './status';

export function testConnection(storeId, protocol, hostName, port) {
  return async (dispatch) => {
    try {
      const response = await syncApi.post(`/${storeId}/source`, { protocol, hostName, port });
      if (response.status === 200) {
        dispatch(connectSuccess());
      }
    } catch (error) {
      dispatch(connectFailed());
    }
  };
}

export function fetchDatabases(storeId, protocol, hostName, port) {
  return async function doFetch(dispatch) {
    try {
      dispatch({ type: 'SYNC_DATABASE_FETCH_START' });
      const response = await syncApi.post(`/${storeId}/source`, { protocol, hostName, port });
      dispatch({ type: 'SYNC_DATABASE_FETCH_SUCCESS', payload: { databases: response.data } });
    } catch (error) {
      dispatch({ type: 'SYNC_DATABASE_FETCH_ERROR', payload: { error } });
    }
  };
}

export function fetchSchemas(storeId, protocol, hostName, port, dbAlias) {
  return async function doFetch(dispatch) {
    try {
      dispatch({ type: 'SYNC_SCHEMA_FETCH_START' });
      const response = await syncApi.post(`/${storeId}/source`, {
        protocol,
        hostName,
        port,
        dbAlias,
      });
      dispatch({ type: 'SYNC_SCHEMA_FETCH_SUCCESS', payload: { schemas: response.data } });
    } catch (error) {
      console.error(error.message);
      dispatch({ type: 'SYNC_SCHEMA_FETCH_ERROR', payload: { error } });
    }
  };
}

export function fetchSources(storeId) {
  return async function (dispatch) {
    try {
      dispatch({ type: 'SYNC_SOURCE_FETCH_START' });
      const response = await syncApi.get(`/source?storeId=${storeId}`, {
        validateStatus: (status) => status !== 200 || status !== 404,
      });
      if (response.status === 404) {
        dispatch({ type: 'SYNC_SOURCE_FETCH_SUCCESS', payload: { sources: [], storeId } });
      } else {
        dispatch({ type: 'SYNC_SOURCE_FETCH_SUCCESS', payload: { sources: response.data, storeId } });
      }
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'SYNC_SOURCE_FETCH_ERROR', payload: { error: err } });
    }
  };
}

export function saveSource(storeId, source) {
  return async function doSaveSource(dispatch) {
    try {
      let response = await SyncApi.saveSource(storeId, source);
      if (!response) {
        throw new Error('Failed to save Sync Source.');
      }
      if (source.id) {
        dispatch({ type: 'SYNC_SOURCE_UPDATE_SUCCESS', payload: { source } });
      } else {
        dispatch({ type: 'SYNC_SOURCE_CREATE_SUCCESS', payload: { id: response.data.id, source } });
      }
      dispatch(fetchSources(storeId));
      return true;
    } catch (err) {
      console.error(err);
      dispatch({ type: 'SYNC_SOURCE_SAVE_ERROR', payload: { err } });
      return false;
    }
  };
}

export function applyMappings(storeId) {
  return async function doApplyMappings(dispatch) {
    try {
      const response = await syncApi.get(`/${storeId}/applyMappings`);
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function clear() {
  return function (dispatch) {
    dispatch({
      type: 'SYNCHRONISATION_CLEAR',
    });
  };
}

export function fetchMappings(storeId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_MAPPINGS_START' });
      const response = await syncApi.get(`/${storeId}/mapping`);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_MAPPINGS_SUCCESS', payload: { mappings: response.data } });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_MAPPINGS_FAILED', payload: { error } });
    }
  };
}

export function createMapping(storeId, mapping) {
  return async (dispatch) => {
    try {
      const response = await syncApi.post(`/${storeId}/mapping`, [mapping]);
      dispatch({ type: 'CREATE_MAPPINGS_SUCCESS' });
      if (response.status === 200) {
        dispatch(fetchMappings(storeId));
      }
    } catch (error) {
      dispatch({ type: 'CREATE_MAPPINGS_FAILED', payload: { error } });
      console.error(error);
    }
  };
}

export function deleteMapping(storeId, mappingId) {
  return async (dispatch) => {
    try {
      const response = await syncApi.delete(`/${storeId}/mapping/${mappingId}`);
      if (response.status === 200) {
        dispatch(fetchMappings(storeId));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchSourceTables(storeId, mappedOnly = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_SOURCE_TABLES_START' });
      const response = await syncApi.get(`/${storeId}/source?mappedOnly=${mappedOnly}`);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_SOURCE_TABLES_SUCCESS', payload: { tables: response.data.tables } });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_SOURCE_TABLES_FAILED', payload: { error } });
    }
  };
}

export function fetchWebhookSubscriptions(storeId, erpType) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_WEBHOOK_SUBSCRIPTIONS_START' });
      const response = await webhooksApi.get(`/subscriptions?erpType=${erpType}&storeId=${storeId}`);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_WEBHOOK_SUBSCRIPTIONS_SUCCESS', payload: { subscriptions: response.data } });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_WEBHOOK_SUBSCRIPTIONS_FAILED', payload: { error } });
    }
  };
}

export function fetchWebhookTypes(erpType) {
  return async (dispatch) => {
    try {
      const response = await webhooksApi.get(`/subscriptions/webhook-types?erpType=${erpType}`);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_WEBHOOK_TYPES_SUCCESS', payload: { webhookTypes: response.data } });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_WEBHOOK_TYPES_FAILED', payload: { error } });
    }
  };
}

export function deleteWebhookSubscription(storeId, erpType, subscriptionId) {
  return async (dispatch) => {
    try {
      const response = await webhooksApi.delete(
        `/subscriptions?erpType=${erpType}&storeId=${storeId}&subscriptionId=${subscriptionId}`
      );
      if (response.status === 204) {
        dispatch({ type: 'DELETE_WEBHOOK_SUBSCRIPTION_SUCCESS', payload: { subscriptionId } });
      }
    } catch (error) {
      dispatch({ type: 'DELETE_WEBHOOK_SUBSCRIPTION_FAILED', payload: { error } });
    }
  };
}

export function createWebhookSubscription(storeId, erpType, webhookType) {
  return async (dispatch) => {
    try {
      const response = await webhooksApi.post(
        `/subscriptions?erpType=${erpType}&storeId=${storeId}&webhookType=${webhookType}`
      );
      if (response.status === 200) {
        dispatch({ type: 'CREATE_WEBHOOK_SUBSCRIPTION_SUCCESS', payload: { subscription: response.data } });
      }
    } catch (error) {
      dispatch({ type: 'CREATE_WEBHOOK_SUBSCRIPTION_FAILED', payload: { error } });
    }
  };
}

export function fetchSourceTableColumns(storeId, tableName) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_SOURCE_COLUMNS_START' });
      const response = await syncApi.get(`/${storeId}/source/${tableName}`);
      if (response.status === 200) {
        const columns =
          response.data.tables && response.data.tables[tableName] && response.data.tables[tableName].columns;
        dispatch({ type: 'FETCH_SOURCE_COLUMNS_SUCCESS', payload: { columns } });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_SOURCE_COLUMNS_FAILED', payload: { error } });
    }
  };
}

export function fetchTargetTables(storeId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_TARGET_TABLES_START' });
      const response = await syncApi.get(`/${storeId}/target`);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_TARGET_TABLES_SUCCESS', payload: { tables: response.data.tables } });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_TARGET_TABLES_FAILED', payload: { error } });
    }
  };
}

export function fetchTargetColumns(storeId, tableName) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_TARGET_COLUMNS_START' });
      const response = await syncApi.get(`/${storeId}/target/${tableName}`);
      if (response.status === 200) {
        const columns =
          response.data.tables && response.data.tables[tableName] && response.data.tables[tableName].columns;
        dispatch({ type: 'FETCH_TARGET_COLUMNS_SUCCESS', payload: { columns } });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_SOURCE_COLUMNS_FAILED', payload: { error } });
    }
  };
}

export function fetchRequestStatus(storeId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_SYNC_REQUEST_STATUS_START' });
      const response = await syncApi.get(`/${storeId}/request`);
      dispatch({ type: 'FETCH_SYNC_REQUEST_STATUS_SUCCESS', payload: { status: response.data[0] } });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_SYNC_REQUEST_STATUS_FAILED', payload: { error } });
    }
  };
}

export function loadStoreData(storeId, tables = null, isDeleteBeforeLoad = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOAD_SYNC_STORE_DATA_START' });
      let response;
      if (tables) {
        response = await syncApi.post(`/${storeId}/uploadData?truncate=${isDeleteBeforeLoad}`, { tables });
      } else {
        response = await syncApi.get(`/${storeId}/uploadData?truncate=${isDeleteBeforeLoad}`);
      }
      dispatch({ type: 'LOAD_SYNC_STORE_DATA_SUCCESS', payload: { status: response.data } });
      dispatch(fetchSyncRequestDetails(storeId, response.data.id));
    } catch (error) {
      console.error(error);
      dispatch({ type: 'LOAD_SYNC_STORE_DATA_FAILED', payload: { error } });
    }
  };
}

export function loadMappedData(storeId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOAD_SYNC_MAPPED_DATA_START' });
      const response = await syncApi.get(`/${storeId}/sendMappings`);
      dispatch({ type: 'LOAD_SYNC_MAPPED_DATA_SUCCESS', payload: { status: response.data } });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'LOAD_SYNC_MAPPED_DATA_FAILED', payload: { error } });
    }
  };
}

export function fetchSyncRequestDetails(storeId, requestId) {
  return async (dispatch) => {
    try {
      const response = await syncApi.get(`/${storeId}/request/${requestId}`);
      dispatch({ type: 'FETCH_SYNC_REQUEST_SUCCESS', payload: { status: response.data } });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_SYNC_REQUEST_FAILED', payload: { error } });
    }
  };
}

export function fetchGraphData(storeId, type) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'LOAD_SYNC_GRAPH_DATA_START' });
      const response = await syncApi.get(`/${storeId}/graph/${type}`);
      dispatch({ type: 'LOAD_SYNC_GRAPH_DATA_SUCCESS', payload: { graph: response.data } });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'LOAD_SYNC_GRAPH_DATA_FAILED', payload: { error } });
    }
  };
}

export function fetchScheduler(sourceId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_SYNC_SCHEDULER_START' });
      const response = await syncApi.get(`/source/${sourceId}/schedule`);
      dispatch({ type: 'FETCH_SYNC_SCHEDULER_SUCCESS', payload: { scheduler: response.data } });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'FETCH_SYNC_SCHEDULER_FAILED', payload: { error } });
    }
  };
}

export function updateScheduler(sourceId, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'UPDATE_SYNC_SCHEDULER_START' });
      const response = await syncApi.put(`/source/${sourceId}/schedule`, values);
      dispatch({ type: 'UPDATE_SYNC_SCHEDULER_SUCCESS', payload: { scheduler: values } });
    } catch (error) {
      dispatch({ type: 'UPDATE_SYNC_SCHEDULER_FAILED', payload: { error } });
    }
  };
}

export function clearSyncInfo() {
  return { type: 'SYNC_CLEAR_ALL' };
}

export function filterByOverride(override) {
  return { type: 'FILTER_BY_OVERRIDE', payload: { override } };
}

export function clearToast() {
  return { type: 'SYNC_CLEAR_TOAST' };
}

export function connectSuccess() {
  return { type: 'SYNC_CONNECTION_SUCCESS' };
}

export function connectFailed() {
  return { type: 'SYNC_CONNECTION_FAILED' };
}

export function clearSourceMessage() {
  return { type: 'SYNC_CLEAR_SOURCE_MESSAGE' };
}

export function changeDiagnosticFilter(filter = '') {
  return { type: 'SYNC_DIAGNOSTIC_FILTER_CHANGE', payload: { filter } };
}

export function changeDataLoadSourceTable(tables = []) {
  return { type: 'SYNC_DATA_LOAD_SOURCE_TABLE_CHANGE', payload: { tables } };
}

export function changeIsDeleteBeforeLoad(isDeleteBeforeLoad = false) {
  return { type: 'SYNC_IS_DELETE_BEFORE_LOAD_CHANGE', payload: isDeleteBeforeLoad };
}

export function changeIsDeleteBeforeStoreLoad(isDeleteBeforeStoreLoad = false) {
  return { type: 'SYNC_IS_DELETE_BEFORE_STORE_LOAD_CHANGE', payload: { isDeleteBeforeStoreLoad } };
}

export function clearUiState() {
  return { type: 'SYNC_CLEAR_UI_STATE' };
}

export function fetchStoreTableFilters(storeId) {
  return async (dispatch) => {
    try {
      if (storeId) {
        const response = await syncApi.get(`/${storeId}/filter`);
        if (response.status === 200) {
          dispatch({ type: 'FETCH_STORE_TABLE_FILTERS_SUCCESS', payload: { filters: response.data } });
        }
      }
    } catch (error) {
      dispatch({ type: 'FETCH_STORE_TABLE_FILTERS_FAILED', payload: { error } });
    }
  };
}

export function fetchTableFilter(storeId, tableName) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_TABLE_FILTER_START', payload: { tableName } });
      const response = await syncApi.get(`/${storeId}/filter/${tableName}`);
      if (response.status === 200) {
        const filter = {
          tableName,
          expr: response.data.expr,
          tree: JSON.stringify(response.data.tree, null, 2),
        };
        dispatch({ type: 'FETCH_TABLE_FILTER_SUCCESS', payload: { filter } });
      }
    } catch (error) {
      // the API is returning 500 error if there's no filter for the requested table - no need to display this to the user

      // clear the filter from previous table
      const filter = {
        tableName,
        expr: '',
        tree: '',
      };
      dispatch({ type: 'FETCH_TABLE_FILTER_FAILED', payload: { error, filter } });
    }
  };
}

export function saveTableFilter(storeId, tableName, filter) {
  return async (dispatch) => {
    try {
      const response = await syncApi.post(`/${storeId}/filter/${tableName}`, filter);
      if (response.status === 200) {
        dispatch({ type: 'SAVE_TABLE_FILTER_SUCCESS', payload: { tableName } });
        dispatch(fetchTableFilter(storeId, tableName));
        dispatch(fetchStoreTableFilters(storeId));
      }
    } catch (error) {
      dispatch({ type: 'SAVE_TABLE_FILTER_FAILED', payload: { tableName, error } });
    }
  };
}

export function deleteTableFilter(storeId, tableName) {
  return async (dispatch) => {
    try {
      const response = await syncApi.delete(`/${storeId}/filter/${tableName}`);
      if (response.status === 202) {
        const filter = {
          tableName,
          expr: '',
          tree: '',
        };
        dispatch({ type: 'DELETE_TABLE_FILTER_SUCCESS', payload: { filter } });

        dispatch(fetchStoreTableFilters(storeId));
      }
    } catch (error) {
      dispatch({ type: 'DELETE_TABLE_FILTER_FAILED', payload: { error } });
    }
  };
}

export function validateTableFilterExpression(tableName, filter) {
  return async (dispatch) => {
    try {
      const response = await syncApi.post(`/filter/tree`, filter);
      if (response.status === 200) {
        // displaying tree as string for now
        dispatch({
          type: 'VALIDATE_TABLE_FILTER_EXPRESSION_SUCCESS',
          payload: { filter: { tableName, expr: filter.expr, tree: JSON.stringify(response.data.tree, null, 2) } },
        });
      }
    } catch (error) {
      dispatch({ type: 'VALIDATE_TABLE_FILTER_EXPRESSION_FAILED', payload: { error } });
    }
  };
}

export function validateTableFilterTree(storeId, tableName, filter) {
  return async (dispatch) => {
    try {
      // clear the filter state so we can update the UI with the result
      // just in case user want to restore the saved filter expression from the tree
      dispatch({
        type: 'FETCH_TABLE_FILTER_START',
        payload: {
          filter: {
            tableName,
            expr: '',
            tree: '',
          },
        },
      });

      const response = await syncApi.post(`/filter/expr`, filter);
      if (response.status === 200) {
        dispatch({
          type: 'VALIDATE_TABLE_FILTER_TREE_SUCCESS',
          payload: { filter: { tableName, expr: response.data.expr, tree: JSON.stringify(filter.tree, null, 2) } },
        });
      }
    } catch (error) {
      // if failed, grab and restore the saved filter to the UI
      dispatch(fetchTableFilter(storeId, tableName));

      dispatch({ type: 'VALIDATE_TABLE_FILTER_TREE_FAILED', payload: { error } });
    }
  };
}

export function updateSyncSchedulerBySyncVersion(syncVersion, sourceId, schedulerData) {
  return async (dispatch) => {
    try {
      if (schedulerData == null) {
        dispatch({ type: 'SYNC_UPDATE_VERSION_SUCCESS', payload: { syncVersion } });
        return;
      }
      const newSchedulerData = { ...schedulerData, isActive: syncVersion == 'SYNC_2' };
      await syncApi.put(`/source/${sourceId}/schedule`, newSchedulerData).then((response) => {
        dispatch({ type: 'SYNC_UPDATE_VERSION_SUCCESS', payload: { syncVersion } });
      });
    } catch (error) {
      dispatch({
        type: 'SYNC_UPDATE_VERSION_SUCCESS_BUT_SCHEDULER_FAILED',
        payload: {
          syncVersion,
          error: {
            message:
              'Sync Version successfully updated but an error has occured while trying to update the Sync Scheduler. Please manually update your scheduler.',
          },
        },
      });
    }
  };
}

export function requestSyncVersionUpdate(syncVersion, sourceId) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'SYNC_CHANGE_VERSION_STARTED' });
      if (sourceId != null) {
        await syncApi.get(`/source/${sourceId}/schedule`, `"${syncVersion}"`).then((response) => {
          dispatch({ type: 'SYNC_REQUEST_CHANGE_VERSION', payload: { syncVersion, schedulerData: response.data } });
        }); // EoF - await syncApi.get
      } else {
        dispatch({ type: 'SYNC_REQUEST_CHANGE_VERSION', payload: { syncVersion } });
      }
    } catch (error) {
      dispatch(reset('syncForm'));
      dispatch({
        type: 'SYNC_UPDATE_VERSION_FAILED',
        payload: { error: { message: 'Please try again. Failed to load scheduler data for Sync Version.' } },
      });
    }
  };
}

export function updateSyncVersion(realStoreId, syncVersion, sourceId, schedulerData) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'SYNC_CHANGE_VERSION_STARTED' });
      await _axios.patch(`/stores/${realStoreId}/sync-version`, `"${syncVersion}"`).then((response) => {
        dispatch(updateSyncSchedulerBySyncVersion(syncVersion, sourceId, schedulerData));
      });
    } catch (error) {
      dispatch(reset('syncForm'));
      dispatch({
        type: 'SYNC_UPDATE_VERSION_FAILED',
        payload: { error: { message: 'Please try again. Failed to update Sync Version.' } },
      });
    }
  };
}

export function cancelSyncVersionChange() {
  return async (dispatch) => {
    dispatch(reset('syncForm'));
    dispatch({ type: 'SYNC_CANCEL_VERSION_CHANGE' });
  };
}
