const failureInitState = {
  loading: false,
  error: '',
  failureRequests: [],
  failureRequestCode: '',
  failureRequestDetail: null,
  failureRequestFailuresLoading: false,
  failureRequestFailuresError: '',
  failureRequestFailures: null,
  failureRequestFailureCode: '',
  failureRequestFailureLoading: false,
  failureRequestFailureError: '',
  failureRequestFailure: null,
  failureRequestFailureDetailCode: '',
  failureRequestFailureDetailLoading: false,
  failureRequestFailureDetailError: '',
  failureRequestFailureDetail: [],
  failureRequestFailureDetailActionMessageOpen: false,
  failureRequestFailureDetailActionLoading: false,
  failureRequestFailureDetailActionCode: '',
  failureRequestFailureDetailAction: '',
  failureRequestFailureDetailActionError: '',
};

const emptyFailureRequestFailures = {
  failureRequestFailuresLoading: false,
  failureRequestFailures: [],
  failureRequestCode: '',
  failureRequestDetail: null,
  failureRequestFailuresError: '',
};

const emptyFailureRequestFailureDetail = {
  failureRequestFailureDetailLoading: false,
  failureRequestFailureDetail: [],
  failureRequestFailureDetailCode: '',
  failureRequestFailuresError: '',
};

const emptyFailureRequestFailureDetailAction = {
  failureRequestFailureDetailActionMessageOpen: false,
  failureRequestFailureDetailActionLoading: false,
  failureRequestFailureDetailActionCode: '',
  failureRequestFailureDetailAction: '',
  failureRequestFailureDetailActionError: '',
};

const emptyFailureRequestFailureDetailCode = {
  failureRequestFailureDetailCode: '',
};

export default function failureRequestsReducer(state = failureInitState, action) {
  switch (action.type) {
    case 'FETCH_FAILURE_REQUESTS_START':
      return {
        ...state,
        loading: true,
        failureRequests: [],
        error: '',
        ...emptyFailureRequestFailures,
        ...emptyFailureRequestFailureDetail,
        ...emptyFailureRequestFailureDetailCode,
        ...emptyFailureRequestFailureDetailAction,
      };
    case 'FETCH_FAILURE_REQUESTS_SUCCESS': {
      let failureRequests = action.payload;
      if (failureRequests) {
        return { ...state, loading: false, error: '', failureRequests };
      }
      return { ...state, loading: false, error: '', failureRequests: [] };
    }
    case 'FETCH_FAILURE_REQUESTS_FAILED': {
      return { ...state, loading: false, error: action.payload.error, failureRequests: [] };
    }
    // -------------------------------------------------
    case 'FETCH_FAILURE_REQUEST_FAILURES_START':
      return {
        ...state,
        failureRequestFailuresLoading: true,
        failureRequestFailures: [],
        failureRequestCode: action.payload,
        failureRequestDetail: null,
        failureRequestFailuresError: '',
        ...emptyFailureRequestFailureDetail,
        ...emptyFailureRequestFailureDetailCode,
        ...emptyFailureRequestFailureDetailAction,
      };
    case 'FETCH_FAILURE_REQUEST_FAILURES_SUCCESS': {
      const { failureRequestFailures, failureRequestDetail } = action.payload;
      if (failureRequestFailures) {
        return {
          ...state,
          failureRequestFailuresLoading: false,
          failureRequestFailuresError: '',
          failureRequestFailures,
          failureRequestDetail,
        };
      }
      return {
        ...state,
        failureRequestFailuresLoading: false,
        failureRequestFailuresError: '',
        failureRequestFailures: [],
        failureRequestDetail: null,
      };
    }
    case 'FETCH_FAILURE_REQUEST_FAILURES_FAILED': {
      return {
        ...state,
        failureRequestFailuresLoading: false,
        failureRequestFailuresError: action.payload.error,
        failureRequestFailures: [],
        failureRequestDetail: null,
      };
    }
    // -------------------------------------------------
    case 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_START':
      return {
        ...state,
        failureRequestFailureDetailLoading: true,
        failureRequestFailureDetail: [],
        failureRequestFailureCode: action.payload,
        failureRequestFailuresError: '',
        // ...emptyFailureRequestFailureDetailCode,
        // ...emptyFailureRequestFailureDetailAction,
      };
    case 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_SUCCESS': {
      let failureRequestFailureDetail = action.payload;
      if (failureRequestFailureDetail) {
        return {
          ...state,
          failureRequestFailureDetailLoading: false,
          failureRequestFailuresError: '',
          failureRequestFailureDetail,
        };
      }
      return {
        ...state,
        failureRequestFailureDetailLoading: false,
        failureRequestFailuresError: '',
        failureRequestFailureDetail: [],
      };
    }
    case 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_FAILED': {
      return {
        ...state,
        failureRequestFailureDetailLoading: false,
        failureRequestFailuresError: action.payload.error,
        failureRequestFailureDetail: [],
      };
    }
    // -------------------------------------------------
    case 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_START':
      return {
        ...state,
        failureRequestFailureDetailActionMessageOpen: true,
        failureRequestFailureDetailActionLoading: true,
        failureRequestFailureDetailActionCode: action.payload.code,
        failureRequestFailureDetailAction: action.payload.action,
        failureRequestFailureDetailActionError: '',
      };
    case 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_SUCCESS': {
      return {
        ...state,
        failureRequestFailureDetailActionLoading: false,
        failureRequestFailureDetailActionError: '',
      };
    }
    case 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_FAILED': {
      return {
        ...state,
        failureRequestFailureDetailActionLoading: false,
        failureRequestFailureDetailActionError: action.payload.error,
      };
    }
    case 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_CLEAR': {
      return {
        ...state,
        ...emptyFailureRequestFailureDetailAction,
      };
    }
    // -------------------------------------------------
    case 'SET_FAILURE_REQUEST_FAILURE_DETAIL_CODE': {
      return {
        ...state,
        failureRequestFailureDetailCode: action.payload,
      };
    }
    default:
      return state;
  }
}
