import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  validateTableFilterExpression
} from '../../../../../actions/synchronisationActions';

class ValidateExpression extends Component {
  submit = () => {
    const { validateTableFilterExpression, tableName, expression } = this.props;
    if (!isEmpty(expression)) {
      validateTableFilterExpression(tableName, {
        expr: expression
      });
    }
  };

  render() {
    return (
      <Button color="light" className="float-end" onClick={this.submit}>
        <i className="fa fa-angle-double-down"/>
      </Button>
    );
  }
}

ValidateExpression.propTypes = {
  tableName: PropTypes.string,
  expression: PropTypes.string
};

export default connect(
  state => ({}),
  dispatch => ({
    validateTableFilterExpression: (tableName, filter) => dispatch(validateTableFilterExpression(tableName, filter))
  }))(ValidateExpression);
