import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, FormGroup, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import * as gatewayActions from '../../actions/gatewayActions';
import { GatewayApi } from '../../gatewayApi';
import { sleep } from '../../utils';
import { arrToObjForFieldArr } from './utils';
import withRouter from '../../withRouter';

class IntegrationsOperationsSendForm extends Component {
  state = { message: '', error: false };
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    // this.props.initialize(this.props.initialValues);
  }

  submit = async (store) => {
    try {
      let { router, currentStore, currentGatewayStore, storeIntegration, storeIntegrationOperation } = this.props;
      const settings = {};
      if (store.settings) {
        store.settings.forEach((item) => {
          if (item.name && item.value);
          settings[item.name] = item.value;
        });
      }

      let dataRequest = {},
        dataResponse = {};
      {
        let { parameters, headers, ...request } = store.request;
        dataRequest = {
          parameters: arrToObjForFieldArr(parameters),
          headers: arrToObjForFieldArr(headers),
          ...request,
        };
      }
      {
        let { parameters, headers, ...response } = store.response;
        dataResponse = {
          parameters: arrToObjForFieldArr(parameters),
          headers: arrToObjForFieldArr(headers),
          ...response,
        };
      }

      const data = {
        enabled: store.enabled,
        storeId: currentGatewayStore.storeId,
        integration: storeIntegration.integration,
        interface: storeIntegration.interface.interface,
        interfaceType: store.interfaceType,
        operation: store.operation,
        override: true,
        product: storeIntegration.interface.product,
        provider: storeIntegration.interface.provider,
        version: storeIntegration.interface.version,
        request: dataRequest,
        response: dataResponse,
        settings,
      };

      await GatewayApi.saveStoreIntegrationOperation(
        currentGatewayStore.storeId,
        storeIntegration.integration,
        storeIntegrationOperation.operation,
        data
      );
      this.setState({
        message: 'Saved successfully',
        error: false,
      });
      await sleep(1000);
      router.navigate(`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}`);
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to save, reason: ' + error.message,
        error: true,
      });
    }

    return false;
  };

  onCancel = () => {
    this.props.router.navigate(-1);
  };

  onDelete = async () => {
    const { router, currentStore, currentGatewayStore, storeIntegration, storeIntegrationOperation } = this.props;
    if (!window.confirm('Are you sure to delete this item?')) {
      return;
    }

    try {
      await GatewayApi.deleteStoreIntegrationOperation(
        currentGatewayStore.storeId,
        storeIntegration.integration,
        storeIntegrationOperation.operation
      );
      this.setState({
        message: 'Deleted successfully',
        error: false,
      });
      await sleep(1000);
      router.navigate(`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}`);
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to delete, reason: ' + error.message,
        error: true,
      });
    }
  };

  clearMessage = () => {
    this.setState({
      message: '',
      error: false,
    });
  };

  render() {
    const { message, error } = this.state;
    const {
      handleSubmit,
      currentGatewayStore,
      currentStore,
      storeIntegration,
      storeIntegrationOperation,
      integrationsOperationsSendForm,
    } = this.props;

    if (!storeIntegration || !integrationsOperationsSendForm) {
      return <>Loading...</>;
    }

    return (
      <>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          {message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}
          <FormGroup row>
            <Col sm={12}>
              <Field
                component={'textarea'}
                className={'form-control'}
                style={{ height: '200px' }}
                name="operation"
                id="operation"
                required
              />
            </Col>
          </FormGroup>
          <div className="float-end">
            <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div style={{ marginTop: 5 }}>{message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}</div>
        </form>
      </>
    );
  }
}

// export default SettingsForm;
export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    storeIntegration: state.gateway.storeIntegration,
    integrationsOperationsSendForm: state.form.integrationsOperationsSendForm,
  }),
  (dispatch) => ({
    fetchAvailableInterfaces: () => dispatch(gatewayActions.getAvailableInterfaces()),
  })
)(
  withRouter(
  reduxForm({ form: 'integrationsOperationsSendForm', enableReinitialize: true })(IntegrationsOperationsSendForm)
  )
);
