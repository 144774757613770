import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { getStoreIntegration } from '../../actions/gatewayActions';
import IntegrationsOperationsEditForm from './IntegrationsOperationsEditForm';
import { integrationOperationInitialValues } from './utils';
import withRouter from '../../withRouter';

// function Settings({ currentStore, initialValues }) {
class IntegrationsOperationsAdd extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { router, fetchStoreIntegration, currentGatewayStore } = this.props;
    fetchStoreIntegration(currentGatewayStore.storeId, router.params.integrationName);
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { router, currentStore, currentGatewayStore, error, storeIntegration } = this.props;
    if (error) {
      return (
        <div>
          <Alert color={'danger'}>{error}</Alert>
        </div>
      );
    }

    if (!storeIntegration) {
      return <div>Loading...</div>;
    }

    const { integrationName } = router.params;
    const initialValues = integrationOperationInitialValues;
    return (
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 mt-4">
        <h5>
          Add Operation for "{currentGatewayStore.storeId}/{integrationName}"
        </h5>
        <hr className="separator" />
        <IntegrationsOperationsEditForm
          currentStore={currentStore}
          currentGatewayStore={currentGatewayStore}
          storeIntegration={storeIntegration}
          initialValues={initialValues}
          storeIntegrationOperation={''}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    error: state.gateway.error,
    storeIntegration: state.gateway.storeIntegration,
  }),
  (dispatch) => ({
    fetchStoreIntegration: (storeId, integrationName) => {
      dispatch({ type: 'GATEWAY_STORE_INTEGRATION_GETONE_INIT' });
      dispatch(getStoreIntegration(storeId, integrationName));
    },
  })
)(withRouter(IntegrationsOperationsAdd));
