const activityInitState = {
  perPage: 20,
  requestType: 'recent',
  requestsLoading: false,
  requestsError: '',
  requests: [],
  requestActionMessageOpen: false,
  requestActionLoading: false,
  requestActionCode: '',
  requestAction: '',
  requestActionError: '',
  requestReloadFlag: 0,
  selectedTables: [],
  isDeleteBeforeLoad: false,
  loadStoreDataMessageOpen: false,
  loadStoreDataLoading: false,
  loadStoreDataTables: null,
  loadStoreDataIsDeleteBeforeLoad: false,
  loadStoreDataRequestId: '',
  loadStoreDataRequestDetail: null,
  loadStoreDataError: '',
};

const emptyRequestAction = {
  requestActionMessageOpen: false,
  requestActionLoading: false,
  requestActionCode: '',
  requestAction: '',
  requestActionError: '',
};

const emptyLoadStoreData = {
  loadStoreDataMessageOpen: false,
  loadStoreDataLoading: false,
  loadStoreDataTables: null,
  loadStoreDataIsDeleteBeforeLoad: false,
  loadStoreDataRequestId: '',
  loadStoreDataRequestDetail: null,
  loadStoreDataError: '',
};

export default function activityRequestsReducer(state = activityInitState, action) {
  switch (action.type) {
    case 'FETCH_ACTIVITY_REQUESTS_START': {
      const newState = {
        ...state,
        requestsError: '',
        requestsLoading: action.payload.showLoading,
      };
      if (action.payload.showLoading) {
        newState.requests = [];
      }
      return newState;
    }
    case 'FETCH_ACTIVITY_REQUESTS_SUCCESS': {
      return {
        ...state,
        requestsLoading: false,
        requestsError: '',
        requests: action.payload,
      };
    }
    case 'FETCH_ACTIVITY_REQUESTS_FAILED': {
      return {
        ...state,
        requestsLoading: false,
        requestsError: action.payload.error,
        requests: [],
      };
    }
    case 'SET_ACTIVITY_REQUEST_PER_PAGE': {
      return {
        ...state,
        perPage: action.payload,
      };
    }
    case 'SET_ACTIVITY_REQUEST_TYPE': {
      return {
        ...state,
        requestType: action.payload,
      };
    }
    case 'ACTION_ACTIVITY_REQUEST_START': {
      return {
        ...state,
        requestActionMessageOpen: true,
        requestActionLoading: true,
        requestActionCode: action.payload.code,
        requestAction: action.payload.action,
        requestActionError: '',
      };
    }
    case 'ACTION_ACTIVITY_REQUEST_SUCCESS': {
      return {
        ...state,
        requestActionLoading: false,
        requestActionError: '',
      };
    }
    case 'ACTION_ACTIVITY_REQUEST_FAILED': {
      return {
        ...state,
        requestActionLoading: false,
        requestActionError: action.payload.error,
      };
    }
    case 'ACTION_ACTIVITY_REQUEST_CLEAR': {
      return {
        ...state,
        ...emptyRequestAction,
      };
    }
    case 'ACTION_ACTIVITY_REQUEST_RELOAD_FLAG': {
      return {
        ...state,
        requestReloadFlag: action.payload,
      };
    }
    case 'SET_ACTIVITY_SELECTED_TABLES': {
      return {
        ...state,
        selectedTables: action.payload,
      };
    }
    case 'SET_ACTIVITY_IS_DELETE_BEFORE_LOAD': {
      return {
        ...state,
        isDeleteBeforeLoad: action.payload,
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_START': {
      return {
        ...state,
        loadStoreDataMessageOpen: false,
        loadStoreDataLoading: true,
        loadStoreDataTables: action.payload.tables,
        loadStoreDataIsDeleteBeforeLoad: action.payload.isDeleteBeforeLoad,
        loadStoreDataError: '',
        loadStoreDataRequestId: '',
        loadStoreDataRequestDetail: null,
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_STARTED': {
      return {
        ...state,
        loadStoreDataRequestId: action.payload.id,
        loadStoreDataRequestDetail: null,
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_REQUEST_DETAIL': {
      return {
        ...state,
        loadStoreDataRequestDetail: action.payload,
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_SUCCESS': {
      return {
        ...state,
        loadStoreDataMessageOpen: true,
        loadStoreDataLoading: false,
        loadStoreDataError: '',
        // isDeleteBeforeLoad: false,
        // selectedTables: [],
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_FAILED': {
      return {
        ...state,
        loadStoreDataMessageOpen: true,
        loadStoreDataLoading: false,
        loadStoreDataError: action.payload.error,
        // isDeleteBeforeLoad: false,
        // selectedTables: [],
      };
    }
    case 'LOAD_ACTIVITY_STORE_DATA_CLEAR': {
      return {
        ...state,
        ...emptyLoadStoreData,
      };
    }
    default:
      return state;
  }
}
