import React, { Component } from 'react';
import { Col, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Field, reduxForm } from 'redux-form';
import BackupTable from './BackupTable';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as backupActions from '../../actions/backupActions';
import * as environmentActions from '../../actions/environmentActions';
import * as storeActions from '../../actions/storeActions';
import StoreOption from './components/StoreOption';

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = state => ({
  environment: state.environment,
  store: state.store,
  backup: state.backup,
  dataCentre: state.dataCentre.selected
});

// SETUP REDUX FORM
@reduxForm({
  form: 'backupForm',
  enableReinitialize: true
})
// CONNECT TO REDUX
@connect(mapStateToProps)
class BackupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeId: props.storeId,
      store: null,
      pagination: {}
    };
  }

  componentDidMount() {
    const { environment } = this.props;
    if (!environment.fetched) {
      this.loadEnvironments();
    }
  }

  loadEnvironments = () => {
    const { dispatch } = this.props;
    dispatch(environmentActions.getAll());
  };

  loadStores = () => {
    const { dispatch, dataCentre } = this.props;
    dispatch(storeActions.getAllByEnvironment(this.state.environment, '', dataCentre));
  };

  /**
   * XMPagination.doPaginate() will call this and arguments is not passed as object
   *
   * @param page
   * @param size
   * @param sort
   * @param sortDir
   * @param searchRequest
   */
  loadCloneableBackups = (page = undefined, size = undefined, sort = undefined, sortDir = undefined, searchRequest = undefined) => {
    const { dispatch, backup } = this.props;

    page = (page == undefined) ? this.state.pagination.page : page;
    size = size || this.state.pagination.size;

    this.setState(
      state => ({
        ...state,
        pagination: {
          ...backup.allCloneable.pagination,
          page
        }
      })
    );

    dispatch(backupActions.getAllCloneableByStore(this.state.environment, this.state.store.value, page, size, sort, sortDir));
  };

  handleEnvironmentChange = selectedOption => {
    const { dispatch, environment } = this.props;
    dispatch(storeActions.clear());
    dispatch(backupActions.clear());
    let env = _.find(environment.all, function(e) {
      return e.id == selectedOption.target.value;
    });

    this.setState(
      {
        environment: env
      },
      this.loadStores
    );
  };

  handleStoreChange = selectedOption => {
    this.setState(
      {
        store: selectedOption
      },
      this.loadCloneableBackups
    );
  };

  handleSearchInput = _.debounce(fuzzyString => {
    const { dispatch, dataCentre } = this.props;
    dispatch(storeActions.getAllByEnvironment(this.state.environment, fuzzyString, dataCentre));
  }, 500);

  render() {
    const { toggle, environment, store, backup } = this.props;

    let options = [];
    if (store.allCloneable && store.allCloneable.length > 0) {
      options = store.allCloneable
        .filter(option => option.storeStatus && option.storeStatus.statusType !== 'DELETED')
        .map(option => {
          return {
            value: option.id,
            label: option.name,
            link: option.primaryDomain
          };
        });
    }
    return (
      <div>
        <FormGroup row>
          <Col md="2">
            <label htmlFor="environment">Environment</label>
          </Col>
          <Col md="10">
            <Field
              name="environment"
              component="select"
              className="form-control"
              onChange={this.handleEnvironmentChange}
              required
            >
              <option />
              {environment.all.map(env => {
                return (
                  <option key={env.id} value={env.id}>
                    {env.name}
                  </option>
                );
              })}
            </Field>
          </Col>
        </FormGroup>
        {options.length > 0 && (
          <div>
            <FormGroup row>
              <Col md="2">
                <label htmlFor="name">Store</label>
              </Col>
              <Col md="10">
                <Select
                  options={options}
                  onChange={this.handleStoreChange}
                  onInputChange={this.handleSearchInput}
                  components={{ Option: StoreOption }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="12">
                <BackupTable
                  storeId={this.state.storeId}
                  all={backup.allCloneable.backups ? backup.allCloneable.backups : []}
                  isCloneOnly={true}
                  pagination={(backup.allCloneable.pagination && backup.allCloneable.pagination !== null) ? backup.allCloneable.pagination : null}
                  paginate={this.loadCloneableBackups}
                  toggle={toggle}
                />
              </Col>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

export default BackupForm;
