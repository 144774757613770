import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { connect } from 'react-redux';
import * as storeActions from '../../actions/storeActions';
import XMStoresDropdown from '../../components/XMStoresDropdown';
import classnames from 'classnames';

import ClientConnection from './ClientConnection';
import ERPConnection from './ERPConnection';
import WebService from './ERPSettings/WebService';
import Language from './ERPSettings/Language';
import Resources from './ERPSettings/Resources';
import withRouter from '../../withRouter';

@connect(state => {
  return {
    synchronisation: state.synchronisation,
    store: state.store,
    erpType: state.erpType,
    operator: state.operator,
    dataCentre: state.dataCentre
  };
})
class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      dropdownOpen: false,
      dropDownValue: null,
      selected: {
        type: null,
        status: null
      },
      dock: false,
      pagination: {},
      storeId: props.router.params.storeId
    };

    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.getStoreDropdown = this.getStoreDropdown.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    const { dispatch, store } = this.props;
    // dispatch(synchronisationActions.getAllByStore(1));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onChangeDropdown(e) {
    this.setState({ dropDownValue: e.currentTarget.textContent });
  }

  componentDidUpdate(prevProps, prevState) {
    const { store, router, dispatch } = this.props;

    let storeId = router.params.storeId || store.one.id || this.state.storeId;

    if (storeId != prevState.storeId) {
      dispatch(storeActions.getOne(storeId));

      // Update the state storeId
      this.setState({
        storeId: storeId
      });
    }

    if (!router.params.storeId && storeId) {
      // load the URL with the storeId
      this.redirectTo(storeId);
    }
  }

  redirectTo(storeId) {
    this.props.router.navigate(`/stores/${storeId}/synchronization/settings`);
  }

  getStoreDropdown() {
    const { router, store } = this.props;

    let storeId = router.params.storeId || store.one.id || this.state.storeId;

    // TODO: Need to have an endpoint for all stores - replace {store.all}

    return (
      <Row className="pb-3">
        <Col md="4">
          <XMStoresDropdown
            options={store.all}
            storeId={Number(storeId)}
            redirectTo={this.redirectTo}
          />
        </Col>
      </Row>
    );
  }

  getDropDownPage() {
    switch (this.state.dropDownValue) {
      case 'Language':
        return <Language />;
      case 'Resources':
        return <Resources />;
      default:
        return <WebService />;
    }
  }

  render() {
    const { synchronisation } = this.props;
    return (
      <div>
        <Card>
          <CardBody>
            {this.getStoreDropdown()}

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '1'
                  })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Client Connection
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '2'
                  })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  ERP Connection
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '3'
                  })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggleDropdown}
                  >
                    <DropdownToggle caret>ERP Settings</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={this.onChangeDropdown}>
                        Web Service
                      </DropdownItem>
                      <DropdownItem onClick={this.onChangeDropdown}>
                        Language
                      </DropdownItem>
                      <DropdownItem onClick={this.onChangeDropdown}>
                        Resources
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <ClientConnection />
              </TabPane>
              <TabPane tabId="2">
                <ERPConnection />
              </TabPane>
              <TabPane tabId="3">{this.getDropDownPage()}</TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(Settings);
