import React from 'react';

function DataItem({ title, value, color }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center ms-4">
      <h6>{title}</h6>
      <span style={{ color: color }}>{value}</span>
    </div>
  );
}

export default DataItem;
