// VERSION REDUCER
const initialState = {
  fetched: false,
  one: {},
  current: {},
  previous: [],
  pagination: {}
};
export default function versionReducer(state = initialState, action) {
  switch (action.type) {
    case 'VERSION_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        current: action.payload,
        previous: action.payload.previous.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
    }
    case 'VERSION_GETONE_SUCCESS': {
      return {
        ...state,
        one: action.payload
      };
    }
    case 'VERSION_CREATE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'VERSION_UPDATE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'VERSION_DELETE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
  }
  return state;
}
