import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';

import * as dataCentreActions from '../../actions/dataCentreActions';

import XMCrud from '../../components/XMCrud';
import XMModalConfirm from '../../components/XMModalConfirm';

import DataCentreForm from './DataCentreForm';

@connect((state) => {
  return {
    dataCentre: state.dataCentre,
  };
})
class DataCentres extends Component {
  state = {
    dropdownOpen: [],
    pagination: {
      page: undefined,
      size: undefined,
      sort: undefined,
      sortDir: undefined,
    },
    search: null,
    confirmModal: false,
  };

  componentDidMount() {
    const { dispatch, dataCentre } = this.props;
    if (!dataCentre.fetched) {
      dispatch(dataCentreActions.getAll());
    }
  }

  loadDataCentres = () => {
    const { dispatch } = this.props;

    dispatch(
      dataCentreActions.getAll(
        this.state.pagination.page,
        this.state.pagination.size,
        this.state.pagination.sort,
        this.state.pagination.sortDir,
        this.state.search
      )
    );
  };

  handlePagination = (page) => {
    this.setState(
      (state) => ({
        pagination: {
          ...state.pagination,
          page: page,
        },
      }),
      this.loadDataCentres
    );
  };

  handleSearch = (search) => {
    this.setState(
      {
        pagination: {},
        search: search,
      },
      this.loadDataCentres
    );
  };

  handleSort = (sort, sortDir) => {
    this.setState(
      (state) => ({
        pagination: {
          ...state.pagination,
          sort: sort,
          sortDir: sortDir,
        },
      }),
      this.loadDataCentres
    );
  };

  // CRUD Actions
  onCreate = () => {};

  onEdit = (dataCentreId) => {
    const { dispatch } = this.props;
    dispatch(dataCentreActions.getOne(dataCentreId));
  };

  onDelete = () => {
    const { dispatch } = this.props;
    dispatch(dataCentreActions.remove(this.state.selected));
    this.setState({ confirmModal: false });
  };

  onCancel = () => {
    const { dispatch } = this.props;
    dispatch(dataCentreActions.clear());
  };

  toggleConfirmModal = (dataCentreId) => {
    this.setState((state) => ({ ...state, selected: dataCentreId, confirmModal: !state.confirmModal }));
  };

  getDataCentreName = () => {
    const {
      dataCentre: { all },
    } = this.props;
    const dataCentre = all.find((dc) => dc.id == this.state.selected);
    return dataCentre && dataCentre.name;
  };

  render() {
    const { dataCentre } = this.props;

    return (
      <div>
        <XMCrud
          headers={[
            { name: 'Code', code: 'code', sortable: true },
            { name: 'Name', code: 'name', sortable: true },
            { name: 'Country', code: 'countryCode', sortable: true },
            { name: 'Region', code: 'region', sortable: true },
            { name: 'Actions' },
          ]}
          form={<DataCentreForm initialValues={dataCentre.one} />}
          title={!dataCentre.one.name ? 'Create Data Center' : dataCentre.one.name}
          hasRefresh={false}
          handleCreate={this.onCreate}
          handleEdit={this.onEdit}
          handleDelete={this.toggleConfirmModal}
          handleCancel={this.onCancel}
          handleSearch={this.handleSearch}
          handleSort={this.handleSort}
          handlePagination={this.handlePagination}
          pagination={dataCentre.pagination}
        >
          {dataCentre.all.map((d) => (
            <React.Fragment key={d.id}>
              <td className="align-middle">
                <span>{d.code}</span>
              </td>
              <td className="align-middle">
                <span>{d.name}</span>
              </td>
              <td className="align-middle">
                <span className="align-middle">
                  <ReactFlagsSelect
                    id={d.id}
                    rfsKey={`store-${d.id}`}
                    selected={d.countryCode}
                    showSelectedLabel={false}
                    disabled={true}
                    className={"menu-flags"}
                    selectButtonClassName={"menu-flags-btn"}
                    onSelect={() => void 0}
                  />{' '}
                  {d.countryCode}
                </span>
              </td>
              <td className="align-middle">
                <span>{d.region}</span>
              </td>
            </React.Fragment>
          ))}
        </XMCrud>

        <XMModalConfirm
          message={`Are you sure you want to delete data center ${this.getDataCentreName()}?`}
          isVisible={this.state.confirmModal}
          onCancel={this.toggleConfirmModal}
          handleConfirm={this.onDelete}
        />
      </div>
    );
  }
}

export default DataCentres;
