import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem } from 'reactstrap';

import { fetchSchemas } from '../../../../../actions/synchronisationActions';

function DBSchemaModal({
  isOpen,
  storeId,
  protocol,
  hostName,
  port,
  dbAlias,
  loading,
  schemas,
  error,
  onSelect,
  onCancel,
  fetchSchemas
}) {
  const [dbSchema, setDbSchema] = React.useState('');

  async function doFetchData() {
    await fetchSchemas(storeId, protocol, hostName, port, dbAlias);
  }

  return (
    <Modal isOpen={isOpen} onOpened={doFetchData} size="sm">
      <ModalHeader>Select database schema</ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center">
        {error && error.message && <div className="text-danger">{error.message}</div>}

        {loading ? (
          <div>Loading...</div>
        ) : (
          <ListGroup style={{ width: '80%' }}>
            {schemas.length > 0 &&
              schemas.map(schema => (
                <ListGroupItem
                  active={schema === dbSchema}
                  key={schema}
                  tag="button"
                  action
                  onClick={() => setDbSchema(schema)}
                >
                  {schema}
                </ListGroupItem>
              ))}
          </ListGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSelect(dbSchema)}>
          Confirm
        </Button>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default connect(
  state => {
    return {
      schemas: state.sync.schema.schemas,
      loading: state.sync.schema.loading,
      error: state.sync.schema.error
    };
  },
  dispatch => ({
    fetchSchemas: (storeId, protocol, hostName, port, dbAlias) =>
      dispatch(fetchSchemas(storeId, protocol, hostName, port, dbAlias))
  })
)(DBSchemaModal);
