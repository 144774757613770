const tableInitState = {
  isError: false,
  error: {},
  message: '',
  filter: {
    tableName: '',
    expr: '',
    tree: ''
  },
  filters: {}
};

export default function tableFilterReducer(state = tableInitState, action) {
  switch (action.type) {
    case 'FETCH_TABLE_FILTER_START':
      return { ...state, filter: action.payload };

    case 'FETCH_TABLE_FILTER_SUCCESS': {
      let { filter } = action.payload;
      if (filter) {
        return { ...state, error: {}, filter };
      }
      return { ...state };
    }

    case 'FETCH_TABLE_FILTER_FAILED': {
      return { ...state, filter: action.payload.filter, error: action.payload.error };
    }

    case 'SAVE_TABLE_FILTER_SUCCESS': {
      return { ...state, isError: false, message: 'Successfully saved ' + action.payload.tableName + '\'s filter!' };
    }

    case 'SAVE_TABLE_FILTER_FAILED': {
      let message = action.payload.error.data.error || 'Failed to save ' + action.payload.tableName + '\'s filter';
      return { ...state, isError: true, message, error: action.payload.error };
    }

    case 'DELETE_TABLE_FILTER_SUCCESS': {
      return { ...state, filter: action.payload.filter, isError: false, message: 'Successfully deleted ' + action.payload.filter.tableName + '\'s filter!' };
    }

    case 'DELETE_TABLE_FILTER_FAILED': {
      let message = action.payload.error.data.error || 'Failed to delete ' + action.payload.filter.tableName + '\'s filter';
      return { ...state, isError: true, message, error: action.payload.error };
    }

    case 'VALIDATE_TABLE_FILTER_EXPRESSION_SUCCESS': {
      return { ...state, filter: action.payload.filter };
    }

    case 'VALIDATE_TABLE_FILTER_EXPRESSION_FAILED': {
      let message = action.payload.error.data.error || 'Failed to validate filter expression';
      return { ...state, isError: true, message, error: action.payload.error };
    }

    case 'VALIDATE_TABLE_FILTER_TREE_SUCCESS': {
      return { ...state, filter: action.payload.filter };
    }

    case 'VALIDATE_TABLE_FILTER_TREE_FAILED': {
      let message = action.payload.error.data.error || 'Failed to validate filter tree';
      return { ...state, isError: true, message, error: action.payload.error };
    }

    case 'FETCH_STORE_TABLE_FILTERS_SUCCESS': {
      let { filters } = action.payload;
      if (filters) {
        return { ...state, error: {}, filters };
      }
      return { ...state };
    }

    case 'FETCH_STORE_TABLE_FILTERS_FAILED': {
      return { ...state, error: action.payload.error };
    }

    case 'SYNC_CLEAR_SOURCE_MESSAGE': {
      return { ...state, isError: false, message: null };
    }

    default:
      return state;
  }
}
