import React from 'react';
import { connect } from 'react-redux';

import SyncForm from './containers/SyncForm';

function SyncConnection({ currentStore, sources }) {
  function getSource() {
    const key = Object.keys(sources)[0];
    let source;
    if (key) {
      source = sources[key];
      return {
        ...source,
        storeId: currentStore.storeId,
        syncVersion: currentStore.syncVersion,
      };
    }

    return {
      isActive: true,
      storeId: currentStore.storeId,
      syncVersion: currentStore.syncVersion,
    };
  }

  return (
    <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8 mt-4">
      <SyncForm currentStore={currentStore} initialValues={getSource()} />
    </div>
  );
}

export default connect((state) => {
  return {
    currentStore: state.store.one,
    dataCentre: state.dataCentre,
    sources: state.sync.source.sources,
  };
})(SyncConnection);
