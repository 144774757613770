import _axios from '../api';
import * as storeActions from './storeActions';
import { queryParams } from '../utils';

const API = '/environments';

export function getAll(page = 0, size = 20, sort = 'id', sortDir = 'DESC') {
  return dispatch =>
    new Promise((resolve, reject) => {
      _axios
        .get(`${API}?page=${page}&size=${size}&sort=${sort},${sortDir}`)
        .then(response => {
          dispatch({
            type: 'ENVIRONMENT_GETALL_SUCCESS',
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(err => {
          dispatch({
            type: 'ENVIRONMENT_GETALL_ERROR',
            payload: err.message
          });
          reject(err);
        });
    });
}

export function select(dataCentreSelected) {
  return function(dispatch) {
    // update selected region
    Promise.all([
      dispatch({
        type: 'ENVIRONMENT_SELECT',
        payload: dataCentreSelected
      }),
      // refresh ALL data based on the region selected
      dispatch(storeActions.getAllByDataCentre(dataCentreSelected))
    ]);
    localStorage.setItem(
      'dataCentreSelected',
      JSON.stringify(dataCentreSelected)
    );
  };
}

export function clear() {
  return function(dispatch) {
    dispatch({
      type: 'ENVIRONMENT_CLEAR'
    });
  };
}
