const initialState = {
  loading: false,
  fetched: false,
  error: '',
  one: {
    storeId: null,
    enabled: true,
    settings: {},
    integrations: [],
  },
  availableInterfaces: [],
  storeIntegration: null,
  all: [],
  logs: [],
  pagination: {},
};

export default function gatewayReducer(state = initialState, action) {
  switch (action.type) {
    case 'GATEWAY_STORE_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GATEWAY_STORE_GETONE_SUCCESS': {
      return {
        ...state,
        one: action.payload,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_GETONE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_STORE_UPDATE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_UPDATE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_GET_AVAILABLE_INTERFACES_SUCCESS': {
      return {
        ...state,
        availableInterfaces: action.payload,
        error: initialState.error,
      };
    }
    case 'GATEWAY_GET_AVAILABLE_INTERFACES_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_GET_AZURE_OPERATIONS_SUCCESS': {
      return {
        ...state,
        azureOperations: action.payload,
        error: initialState.error,
      };
    }
    case 'GATEWAY_GET_AZURE_OPERATIONS_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_GETONE_INIT': {
      return {
        ...state,
        storeIntegration: null,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_GETONE_SUCCESS': {
      return {
        ...state,
        storeIntegration: action.payload,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_GETONE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_UPDATE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_UPDATE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    // case 'GATEWAY_CLEAR_ERROR': {
    //   return {
    //     ...state,
    //     error: initialState.error,
    //   };
    // }
    case 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_INIT': {
      return {
        ...state,
        storeIntegrationOperation: null,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_SUCCESS': {
      return {
        ...state,
        storeIntegrationOperation: action.payload,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_OPERATION_UPDATE_SUCCESS': {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case 'GATEWAY_STORE_INTEGRATION_OPERATION_UPDATE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GATEWAY_STORE_GETLOGS_SUCCESS': {
      if (typeof action.payload.page === 'undefined') {
        return {
          ...state,
          error: 'Invalid JSON response, cannot render this logs page content.',
        };
      }
      return {
        ...state,
        logs: action.payload.messages,
        pagination: {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          nextPage: action.payload.nextPage,
          hasNextPage: action.payload.nextPage > action.payload.page,
        },
        error: null,
      };
    }
    case 'GATEWAY_STORE_GETLOGS_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
  }
  return state;
}
