import { SettingsApi } from '../api';

export function getAllClientCredentials(storeId, page = 0, size = 20) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'CLIENT_CREDENTIALS_LOADING' });
      SettingsApi.getAllClientCredentials(storeId, page, size)
      .then(response => {
        dispatch({
          type: 'CLIENT_CREDENTIALS_GETALL_SUCCESS',
          payload: response.data
        });
        resolve(response.data);
      })
      .catch(err => {
        dispatch({
          type: 'CLIENT_CREDENTIALS_GETALL_ERROR',
          payload: err.message
        });
        reject(err);
      });
    });
}

export function register(clientCredential) {
  return dispatch =>
    new Promise((resolve, reject) => {
      SettingsApi.register(clientCredential)
        .then(response => {
          dispatch({
            type: 'CLIENT_CREDENTIALS_REGISTER_SUCCESS'
          });
          dispatch(getAllClientCredentials(clientCredential.storeId));
          resolve(response.data);
        })
        .catch(err => {
          dispatch({
            type: 'CLIENT_CREDENTIALS_REGISTER_ERROR',
            payload: err.data
          });
          reject(err);
        });
    });
}

export function remove(storeId, clientId) {
  return function(dispatch) {
    SettingsApi.delete(storeId, clientId)
    .then(() => {
      dispatch({
        type: 'CLIENT_CREDENTIALS_DELETE_SUCCESS'
      });
      dispatch(getAllClientCredentials(storeId));
    })
    .catch(err => {
      dispatch({
        type: 'CLIENT_CREDENTIALS_DELETE_ERROR',
        payload: err.message
      });
    });
  };
}

export function clearRegisterError() {
  return { type: 'CLIENT_CREDENTIALS_REGISTER_CLEAR_ERROR' };
}
