import axios from 'axios';
import _axios from '../oauth2Api';
import moment from 'moment';

import {
  getAuth,
} from 'firebase/auth';

// Create an AXIOS instance
export function createAxios(URL) {
  let _axios = axios.create({
    baseURL: URL,
    timeout: 20000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  // Add OAuth token
  _axios.interceptors.request.use((request) => {
    if (localStorage.getItem('jwtToken') && !request.headers.Authorization) {
      request.headers.Authorization = 'Bearer ' + localStorage.getItem('jwtToken');
    }
    return request;
  });

  _axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.debug(error);
      // ignore connect timeout
      if (error.code !== 'ECONNABORTED') {
        try {
          let httpError = error.response && error.response.status;
          if (httpError == 401 || error.message === 'Network Error') {
            return refresh(error.config);
          }
        } catch (e1) {
          console.error(e1);
          try {
            return refresh(error.config);
          } catch (e2) {
            console.error(e2);
            logOut();
          }
        }
      }
      return Promise.reject(error.response);
    }
  );

  function refresh(config) {
    return refreshToken().then((ret) => {
      if (ret) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('jwtToken')}`;
        return _axios(config);
      }
    });
  }

  return _axios;
}

// Refresh token
export async function refreshToken() {
  console.log('Refreshing token... ');
  let form = {
    refresh_token: localStorage.getItem('jwtRefreshToken'),
    grant_type: 'refresh_token'
  };

  try {
    const response = await _axios.post('/token', getFormURLencoded(form));
    localStorage.setItem('jwtToken', response.data.access_token);
    localStorage.setItem('jwtRefreshToken', response.data.refresh_token);
    return true;
  } catch (error) {
    console.error(`Failed to refresh token`, error);
    localStorage.removeItem('jwtToken');
    logOut();
    return false;
  }
}

export function loginTimeout() {
  const loginTime = sessionStorage.getItem('loginTime');
  if (loginTime) {
    const time = parseInt(loginTime);
    const isExpired = moment().subtract(12, 'hours').isAfter(moment(time));
    if (isExpired) {
      alert(`Time's up! Logging out...`);
      logOut();
      sessionStorage.removeItem('loginTime');
      return false;
    }
  }
  return true;
}

// Clean up & log out
export function logOut() {
  console.log('Logging out... ');
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('jwtRefreshToken');
  window.location = '/';
}

// RESET REDUX-FORM
export function afterSubmit(reduxFormName, reset, cleanState) {
  const afterSubmit = (result, dispatch) => {
    dispatch(reset(reduxFormName));
    dispatch(cleanState());
  };
  return afterSubmit;
}

// GET request params
export function queryParams(params = undefined) {
  return !params
    ? ''
    : '&' +
        Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&');
}

// POST form data
export function getFormURLencoded(params = undefined) {
  return Object.keys(params)
    .map((k) => k + '=' + params[k])
    .join('&');
}

export function capitalizeFirstLetter(string) {
  let strings = string.split('_');
  let finalString = '';
  for (let i = 0; i < strings.length; i++) {
    finalString += strings[i].charAt(0).toUpperCase() + strings[i].slice(1).toLowerCase() + ' ';
  }
  return finalString.trim();
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatBytes = (bytes, si = true, dp = 1) => {
  if (bytes !== undefined) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + units[u]; // + ' '
  }
  return 'Unknown';
};
