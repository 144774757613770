const initialState = {
  syncStoresStatusLoading: false,
  syncStoresStatusError: '',
  syncStoresStatus: {},
  syncStoreStatusLoading: false,
  syncStoreStatusError: '',
  syncStoreStatus: {},
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_SYNC_STORES_STATUS_START': {
      const newState = {
        ...state,
        syncStoresStatusError: '',
        syncStoresStatusLoading: true,
      };
      return newState;
    }
    case 'FETCH_SYNC_STORES_STATUS_SUCCESS': {
      return {
        ...state,
        syncStoresStatusLoading: false,
        syncStoresStatusError: '',
        syncStoresStatus: action.payload,
      };
    }
    case 'FETCH_SYNC_STORES_STATUS_FAILED': {
      return {
        ...state,
        syncStoresStatusLoading: false,
        syncStoresStatusError: action.payload.error,
        syncStoresStatus: {},
      };
    }
    case 'FETCH_SYNC_STORES_STATUS_INITIAL': {
      const newState = {
        ...state,
        syncStoresStatusError: '',
        syncStoresStatusLoading: false,
        syncStoresStatus: {},
      };
      return newState;
    }
    case 'FETCH_SYNC_STORE_STATUS_START': {
      const newState = {
        ...state,
        syncStoreStatusError: '',
        syncStoreStatusLoading: true,
      };
      return newState;
    }
    case 'FETCH_SYNC_STORE_STATUS_SUCCESS': {
      return {
        ...state,
        syncStoreStatusLoading: false,
        syncStoreStatusError: '',
        syncStoreStatus: action.payload,
      };
    }
    case 'FETCH_SYNC_STORE_STATUS_FAILED': {
      return {
        ...state,
        syncStoreStatusLoading: false,
        syncStoreStatusError: action.payload.error,
        syncStoreStatus: {},
      };
    }
    case 'FETCH_SYNC_STORE_STATUS_INITIAL': {
      const newState = {
        ...state,
        syncStoreStatusLoading: false,
        syncStoreStatusError: '',
        syncStoreStatus: {},
      };
      return newState;
    }
    default:
      return state;
  }
}
