import { combineReducers } from 'redux';
import sourceTable from './sourceTable';
import sourceColumn from './sourceColumn';
import targetTable from './targetTable';
import targetColumn from './targetColumn';
import tableFilter from './tableFilter';
import schema from './schema';
import mapping from './mapping';
import db from './db';
import source from './source';
import toast from './toast';
import request from './request';
import graph from './graph';
import scheduler from './scheduler';
import failure from './failure';
import ui from './ui';
import activity from './activity';
import webhookSubscriptions from './webhookSubscriptions';
import status from './status';

export default combineReducers({
  source,
  toast,
  db,
  schema,
  mapping,
  sourceTable,
  sourceColumn,
  targetTable,
  targetColumn,
  tableFilter,
  request,
  graph,
  scheduler,
  failure,
  ui,
  activity,
  webhookSubscriptions,
  status,
});
