import {apiConfigHelper} from './apiConfig';
import {createAxios} from './utils';

export const getWebhooksApi = () => {
  const _axios = createAxios(apiConfigHelper.getBaseUrlForWebhooks() + '/webhooks');

  _axios.interceptors.request.use((request) => {
    // update the API endpoint using the site domain
    request.baseURL = apiConfigHelper.getStoreUrl(request.baseURL);

    return request;
  });
  return _axios;
};
const _axios = getWebhooksApi();
export default _axios;

/**
 * Check of domain is valid
 * @param domain
 * @returns {*|RegExpMatchArray|Promise<Response | undefined>}
 */
export function isValidDomain(domain) {
  let re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
  return domain.match(re);
}

