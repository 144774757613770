import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, Dropdown, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import SidebarForm from './../SidebarForm';
import SidebarMinimizer from './../SidebarMinimizer';

class Sidebar extends Component {
  constructor(props) {
    super(props);
  }

  activeRoute(routeName, props) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }

  // todo Sidebar nav secondLevel
  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  render() {
    const props = this.props;
    const activeRoute = this.activeRoute;

    // badge addon to NavItem
    const badge = badge => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = item => {
      return item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames('nav-title', title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{' '}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames('divider', divider.class);
      return <li key={key} className={classes} />;
    };

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames(item.icon)
      };
      return navLink(item, key, classes);
    };

    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : '';
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink href={url} className={classes.link} active>
              <i className={classes.icon} />
              {item.name}
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink to={url} className={({ isActive }) => classes.link + (isActive ? " active" : "")}>
              <i className={classes.icon} />
              {item.name}
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      const url = item.url ? item.url : '';
      return (
        <Dropdown navs="true" key={key} toggle={() => {}} className={activeRoute(item.url, props)}>
          <NavLink className="nav-link nav-dropdown-toggle" to={url}>
            <i className={item.icon} />
            {item.name}
          </NavLink>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </Dropdown>
      );
    };

    // nav type
    const navType = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = () => {
      const { selected: user } = this.props.user;
      const nav = [];

      if (user && user.role !== 'PARTNER') {
        nav.push({ name: 'Dashboard', url: '/dashboard', icon: 'icon-chart' });
      }

      nav.push({ name: 'Stores', url: '/stores', icon: 'icon-globe' });

      if (user && user.role !== 'PARTNER') {
        nav.push({ name: 'Data Centers', url: '/datacentres', icon: 'icon-home' });
        nav.push({ name: 'Users', url: '/users', icon: 'icon-user' });
      }

      nav.push({ name: 'Version History', url: '/versions', icon: 'icon-user' });

      return nav.map((item, index) => navType(item, index));
    };

    const isExternal = url => {
      const link = url ? url.substring(0, 4) : '';
      return link === 'http';
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <SidebarForm />
        <nav className="sidebar-nav">
          <Nav>{navList()}</Nav>
        </nav>
        <SidebarMinimizer />
      </div>
    );
  }
}

export default Sidebar;
