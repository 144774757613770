import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, FormGroup, Button } from 'reactstrap';
import { Field, FieldArray, reduxForm, reset } from 'redux-form';
import { InputField } from '../../components/Field';
import * as gatewayActions from '../../actions/gatewayActions';
import { GatewayApi } from '../../gatewayApi';
import { sleep } from '../../utils';
import {
  arrToObjForFieldArr,
  ColLeft,
  ColRight,
  inputContentTypeList,
  renderParameters,
  renderSettings,
  renderTransforms,
} from './utils';
import withRouter from '../../withRouter';

class IntegrationsOperationsEditForm extends Component {
  state = { message: '', error: false };
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    // this.props.initialize(this.props.initialValues);
  }

  submit = async (store) => {
    try {
      let { router, currentStore, currentGatewayStore, storeIntegration, storeIntegrationOperation } = this.props;
      const settings = {};
      if (store.settings) {
        store.settings.forEach((item) => {
          if (item.name && item.value);
          settings[item.name] = item.value;
        });
      }

      let dataRequest = {},
        dataResponse = {};
      {
        let { parameters, headers, ...request } = store.request;
        dataRequest = {
          parameters: arrToObjForFieldArr(parameters),
          headers: arrToObjForFieldArr(headers),
          ...request,
        };
      }
      {
        let { parameters, headers, ...response } = store.response;
        dataResponse = {
          parameters: arrToObjForFieldArr(parameters),
          headers: arrToObjForFieldArr(headers),
          ...response,
        };
      }

      const data = {
        enabled: store.enabled,
        storeId: currentGatewayStore.storeId,
        integration: storeIntegration.integration,
        interface: storeIntegration.interface.interface,
        interfaceType: store.interfaceType,
        operation: store.operation,
        override: true,
        product: storeIntegration.interface.product,
        provider: storeIntegration.interface.provider,
        version: storeIntegration.interface.version,
        request: dataRequest,
        response: dataResponse,
        settings,
      };

      await GatewayApi.saveStoreIntegrationOperation(
        currentGatewayStore.storeId,
        storeIntegration.integration,
        storeIntegrationOperation.operation,
        data
      );
      this.setState({
        message: 'Saved successfully',
        error: false,
      });
      await sleep(1000);
      router.navigate(`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}`);
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to save, reason: ' + error.message,
        error: true,
      });
    }

    return false;
  };

  onCancel = () => {
    this.props.router.navigate(-1);
  };

  onDelete = async () => {
    const { router, currentStore, currentGatewayStore, storeIntegration, storeIntegrationOperation } = this.props;
    if (!window.confirm('Are you sure to delete this item?')) {
      return;
    }

    try {
      await GatewayApi.deleteStoreIntegrationOperation(
        currentGatewayStore.storeId,
        storeIntegration.integration,
        storeIntegrationOperation.operation
      );
      this.setState({
        message: 'Deleted successfully',
        error: false,
      });
      await sleep(1000);
      router.navigate(`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}`);
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to delete, reason: ' + error.message,
        error: true,
      });
    }
  };

  clearMessage = () => {
    this.setState({
      message: '',
      error: false,
    });
  };

  render() {
    const { message, error } = this.state;
    const {
      handleSubmit,
      currentGatewayStore,
      currentStore,
      storeIntegration,
      storeIntegrationOperation,
      integrationsOperationsEditForm,
    } = this.props;

    if (!storeIntegration || !integrationsOperationsEditForm) {
      return <>Loading...</>;
    }

    return (
      <>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          {message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}
          <FormGroup row>
            <ColLeft>Operation</ColLeft>
            <ColRight>
              <Field component={InputField} name="operation" id="operation" required />
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Enabled</ColLeft>
            <ColRight>
              <Field component={InputField} type="checkbox" name="enabled" id="enabled" />
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Interface Type</ColLeft>
            <ColRight>
              <Field component="select" required className="form-control" name="interfaceType" id="interfaceType">
                <option value="HTTP">HTTP</option>
                <option value="SOAP">SOAP</option>
              </Field>
            </ColRight>
          </FormGroup>
          <FormGroup row style={{ backgroundColor: '#e8e8e8', paddingTop: 5, paddingBottom: 5 }}>
            <Col sm={12}>
              <strong>Request</strong>
            </Col>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Request content type</ColLeft>
            <ColRight>
              <Field
                component="select"
                required
                className="form-control"
                name="request.inputContentType"
                id="request.inputContentType"
              >
                {inputContentTypeList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </ColRight>
          </FormGroup>
          <FieldArray
            name="request.transforms"
            component={renderTransforms}
            warn={(value, allValues, props) => {
              //   this.clearMessage();
            }}
          />
          <FormGroup row>
            <ColLeft>Send content type</ColLeft>
            <ColRight>
              <Field
                component="select"
                required
                className="form-control"
                name="request.outputContentType"
                id="request.outputContentType"
              >
                {inputContentTypeList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Send Method</ColLeft>
            <ColRight>
              <Field component="select" required className="form-control" name="request.method" id="request.method">
                {['GET', 'POST', 'PUT', 'DELETE'].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Send Path</ColLeft>
            <ColRight>
              <Field component={InputField} name="request.path" id="request.path" />
            </ColRight>
          </FormGroup>
          <FieldArray
            name="request.parameters"
            component={renderParameters}
            warn={(value, allValues, props) => {
              //   this.clearMessage();
            }}
            props={{ title: 'Parameters' }}
          />

          <FieldArray
            name="request.headers"
            component={renderParameters}
            warn={(value, allValues, props) => {
              //   this.clearMessage();
            }}
            props={{ title: 'Headers' }}
          />

          <FormGroup row style={{ backgroundColor: '#e8e8e8', paddingTop: 5, paddingBottom: 5 }}>
            <Col sm={12}>
              <strong>Response</strong>
            </Col>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Receive content type</ColLeft>
            <ColRight>
              <Field
                component="select"
                required
                className="form-control"
                name="response.inputContentType"
                id="response.inputContentType"
              >
                {inputContentTypeList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </ColRight>
          </FormGroup>
          <FieldArray
            name="response.transforms"
            component={renderTransforms}
            warn={(value, allValues, props) => {
              //   this.clearMessage();
            }}
          />
          <FormGroup row>
            <ColLeft>Response content type</ColLeft>
            <ColRight>
              <Field
                component="select"
                required
                className="form-control"
                name="response.outputContentType"
                id="response.outputContentType"
              >
                {inputContentTypeList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </ColRight>
          </FormGroup>
          <div>
            <hr size={1} />
          </div>
          {/* <FormGroup row style={{ marginBottom: 0 }}>
            <ColLeft>Settings</ColLeft>
            <ColRight>
              <FieldArray
                name="settings"
                component={renderSettings}
                warn={(value, allValues, props) => {
                  //   this.clearMessage();
                }}
              />
            </ColRight>
          </FormGroup> */}
          {storeIntegrationOperation && (
            <div className="float-start">
              <Button type="button" color="danger" className="me-2" onClick={() => this.onDelete()}>
                Delete
              </Button>
            </div>
          )}
          <div className="float-end">
            <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div style={{ marginTop: 5 }}>{message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}</div>
        </form>
      </>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    storeIntegration: state.gateway.storeIntegration,
    integrationsOperationsEditForm: state.form.integrationsOperationsEditForm,
  }),
  (dispatch) => ({
    fetchAvailableInterfaces: () => dispatch(gatewayActions.getAvailableInterfaces()),
  })
)(
  withRouter(
  reduxForm({ form: 'integrationsOperationsEditForm', enableReinitialize: true })(IntegrationsOperationsEditForm)
  )
);
