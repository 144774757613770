import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Card, CardBody, ButtonGroup } from 'reactstrap';

import { connect } from 'react-redux';
import BackupForm from './BackupForm';
import BackupTable from './BackupTable';
import XMRButton from '../../components/XMRButton';
import XMSearchBox from '../../components/XMSearchBox';
import * as backupActions from '../../actions/backupActions';
import XMTooltipButton from '../../components/XMTooltipButton';
import withRouter from '../../withRouter';

@connect((state) => {
  return {
    backup: state.backup,
    store: state.store,
    erpType: state.erpType,
    operator: state.operator,
    dataCentre: state.dataCentre,
  };
})
class Backups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backupId: null,
      modalClone: false,
      modalDelete: false,
      modalRestore: false,
      pagination: {},
      storeId: props.router.params.storeId,
    };
  }

  /**
   * XMPagination.doPaginate() will call this and arguments is not passed as object
   *
   * @param page
   * @param size
   * @param sort
   * @param sortDir
   * @param searchRequest
   */
  loadBackups = (
    page = undefined,
    size = undefined,
    sort = undefined,
    sortDir = undefined,
    searchRequest = undefined
  ) => {
    this.clearTimer();
    const { dispatch, router, store } = this.props;
    let storeId = router.params.storeId || store.one.id || this.state.storeId;
    if (storeId) {
      page = page == undefined ? this.state.pagination.page : page;
      size = size || this.state.pagination.size;

      this.setState((state) => ({
        ...state,
        storeId,
        pagination: {
          ...state.pagination,
          page,
          size,
        },
      }));

      dispatch(backupActions.getAllByStore(storeId, page, size, sort, sortDir, searchRequest));
    }
  };

  componentDidMount() {
    this.loadBackups();
  }

  isWaiting(backup) {
    return this.isWaitingStatus(backup.statusType) || this.isWaitingStatus(backup.cdnStatusType);
  }

  isWaitingStatus(statusType) {
    return (
      statusType == 'PENDING' || statusType == 'IN_PROGRESS' || statusType == 'TRANSFORMING' || statusType == 'QUEUED'
    );
  }

  getStoreId() {
    const { router, store } = this.props;
    return router.params.storeId || store.one.id || this.state.storeId;
  }

  handleCreate = () => {
    const { dispatch } = this.props;
    dispatch(backupActions.create(this.getStoreId()));
    this.loadBackups();
  };

  toggleClone = (backupId) => {
    const { dispatch } = this.props;
    this.setState({
      backupId: backupId,
      modalClone: !this.state.modalClone,
    });
    dispatch(backupActions.clear());
    if (this.state.modalClone) {
      this.loadBackups();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      backup: { all },
      router,
      dispatch,
    } = this.props;

    let storeId = this.getStoreId();

    if (storeId != prevState.storeId) {
      dispatch(storeActions.getOne(storeId));
      this.loadBackups();

      // Update the state storeId
      this.setState({
        storeId: storeId,
      });
      return;
    }

    if (!router.params.storeId && storeId) {
      // load the URL with the storeId
      this.redirectTo(storeId);
      return;
    }

    if (all !== prevProps.store.all) {
      // any backup with waiting status?
      let backupWaiting = _.find(all, (b) => {
        return this.isWaiting(b);
      });

      // TODO: move the "update time" (10s) to a Settings property?
      if (backupWaiting && !this.timerId) {
        this.timerId = _.delay(() => {
          if (!this.state.modalClone) {
            this.loadBackups();
          }
        }, 10000);
      }
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  redirectTo = (storeId) => {
    this.props.router.navigate(`/stores/${storeId}/backups`);
  };

  getStoreName = () => {
    const { store } = this.props;
    const storeId = this.getStoreId();
    const currentStore = store.all.find((s) => s.id == storeId);
    return (currentStore && currentStore.name) || '';
  };

  handleSearch = (fuzzyString) => {
    this.loadBackups(undefined, undefined, undefined, undefined, fuzzyString);
  };

  render() {
    const { backup } = this.props;
    return (
      <div>
        <Card>
          <CardBody>
            <Row className="pb-3">
              <Col md="5">
                <h3>Store: {this.getStoreName()}</h3>
              </Col>
              <Col md="4">
                <XMSearchBox onSearch={this.handleSearch} />
              </Col>
              <Col md="3">
                <ButtonGroup>
                  <XMRButton loading={backup.loading} handleRefresh={this.loadBackups} />
                  <XMTooltipButton
                    icon="icon-cloud-download"
                    color="light"
                    tooltipTitle="Create"
                    onClick={this.handleCreate}
                  />
                  <XMTooltipButton icon="fa fa-clone" color="light" tooltipTitle="Clone" onClick={this.toggleClone} />
                </ButtonGroup>
              </Col>
            </Row>

            <Modal isOpen={this.state.modalClone} toggle={this.toggleClone} className="lg" style={{ minWidth: '60%' }}>
              <ModalHeader toggle={this.toggleClone}>
                Clone Store{' '}
                <span style={{ color: 'grey', fontSize: '0.6em' }}>
                  Please select the store you which to clone into this store
                </span>
              </ModalHeader>
              <ModalBody>
                <BackupForm storeId={this.getStoreId()} handleCancel={this.toggleClone} toggle={this.toggleClone} />
              </ModalBody>
            </Modal>

            <BackupTable
              storeId={this.getStoreId()}
              all={backup.all}
              pagination={backup.pagination}
              paginate={this.loadBackups}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(Backups);
