import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { uniqueId, isEmpty } from 'lodash';
import { SYNC2_SCHEDULER_STATUS } from '../../utils/constants';

export class XMTooltipButton extends Component {
  state = {
    id: `TooltipButton-${uniqueId()}`,
  };

  render() {
    const { icon, tooltipPlacement, tooltipTitle, displayWarningIcon, ...rest } = this.props;
    let newTooltipTitle = tooltipTitle;
    if (displayWarningIcon) {
      newTooltipTitle +=
        ' | Warning: ' +
        displayWarningIcon +
        (SYNC2_SCHEDULER_STATUS[displayWarningIcon] ? ' - ' + SYNC2_SCHEDULER_STATUS[displayWarningIcon] : '');
    }

    return (
      <React.Fragment>
        <Button {...rest} id={this.state.id}>
          <i className={icon} />
          {displayWarningIcon && (
            <img
              src="/img/icon-yellow-triangle-256.png"
              style={{ position: 'absolute', top: 0, right: 5, width: 18 }}
            />
          )}
        </Button>
        {!isEmpty(newTooltipTitle) && (
          <UncontrolledTooltip placement={tooltipPlacement} target={this.state.id}>
            {newTooltipTitle}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }
}

XMTooltipButton.propTypes = {
  icon: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  tooltipTitle: PropTypes.string,
  displayWarningIcon: PropTypes.string,
};

XMTooltipButton.defaultProps = {
  className: 'btn-transparent',
  tooltipPlacement: 'top',
};

export default XMTooltipButton;
