import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import XMTable from "../../../../components/XMTable";
import {
    deleteWebhookSubscription,
    fetchWebhookSubscriptions,
    fetchWebhookTypes,
    createWebhookSubscription
} from '../../../../actions/synchronisationActions';
import { Button, Input } from 'reactstrap';

const headers = [
    {name: 'Webhook ID', code: 'id'},
    {name: 'Webhook Type', code: 'type'},
    {name: 'Expiry Date', code: 'expiryDate'},
    {name: 'Action', code: 'action'}
];

function Webhooks({currentStore, dispatch, subscriptions, webhookTypes, loading, error}) {
    useEffect(() => {
        if (currentStore.storeId && currentStore.erpType.code) {
            dispatch(fetchWebhookSubscriptions(currentStore.storeId, currentStore.erpType.code));
            dispatch(fetchWebhookTypes(currentStore.erpType.code));

        }
    }, [currentStore.storeId, (currentStore.erpType && currentStore.erpType.code)]);

    useEffect(()=>{
        if(webhookTypes.length && webhookType === null){
            setWebhookType(webhookTypes[0]);
        }
    }, [webhookTypes])

    const [webhookType, setWebhookType] = useState(null);

    const deleteSubscription = (e, subscriptionId) => {
        e.preventDefault();
        dispatch(deleteWebhookSubscription(currentStore.storeId, currentStore.erpType.code, subscriptionId));
    }

    const createSubscription = (e, subscriptionId) => {
        e.preventDefault();
        if(webhookType){
            dispatch(createWebhookSubscription(currentStore.storeId, currentStore.erpType.code, webhookType));
        }
    }

    const webhookOptions = webhookTypes && webhookTypes.map(i => {
        return {"name": i, 'value': i};
    });

    return (
        <div className="p-4">
            <div className="d-flex flex-column">
                { error && error.data && error.data.code && (<div className="alert alert-danger mt-1 mb-0 p-1">{error.data.code}</div>)}
                <XMTable headers={headers}>
                    <tr>
                        <td>Create New Webhook</td>
                        <td>
                            <div className='d-flex flex-column'>
                                <Input id="webhook-type" type="select" onChange={(e) => {setWebhookType(e.target.value);}}>
                                    {webhookOptions &&
                                        webhookOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.name}
                                            </option>
                                        ))}
                                </Input>
                            </div>
                        </td>
                        <td></td>
                        <td><Button color="primary" onClick={(e) => createSubscription(e)}>Create</Button></td>
                    </tr>
                    {loading ? (
                            <div>Loading...</div>
                        ) :
                        subscriptions && subscriptions.map(subscription => (
                            <tr>
                                <td>{subscription.id}</td>
                                <td>{subscription.webhookType}</td>
                                <td>{subscription.expirationDateTime}</td>
                                <td><Button color="secondary" onClick={(e) => deleteSubscription(e, subscription.id)}>Delete</Button></td>
                            </tr>
                        ))}
                </XMTable>
            </div>
        </div>
    );
}

export default connect(state => ({
    currentStore: state.store.one,
    subscriptions: state.sync.webhookSubscriptions.subscriptions,
    webhookTypes: state.sync.webhookSubscriptions.webhookTypes,
    loading: state.sync.webhookSubscriptions.loading,
    error: state.sync.webhookSubscriptions.error,
}))(Webhooks);
