import React from 'react';
import { connect } from 'react-redux';
import XMModalConfirm from '../../../../components/XMModalConfirm';
import XMModal from '../../../../components/XMModal';
import ClientCredentialForm from '../../../ClientCredentials/ClientCredentialForm';
import * as clientCredentialActions from '../../../../actions/clientCredentialActions';
import { Button, Card, CardBody, Col } from 'reactstrap';
import XMTable from '../../../../components/XMTable';
import withRouter from '../../../../withRouter';
import * as storeActions from '../../../../actions/storeActions';
import XMPagination from '../../../../components/XMPagination';

const mapStateToProps = (state) => ({
  currentStore: state.store.one,
  clientCredential: state.clientCredential,
  user: state.user,
});
class ClientCredentials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      modal: false,
      modalConfirm: false,
      dock: false,
      modalDelete: false,
      search: null
    };
  };

  loadClientCredentials = () => {
    const { dispatch, router, currentStore } = this.props;
    let storeId = currentStore.storeId || this.state.storeId;

    if (storeId !== undefined) {
      dispatch(clientCredentialActions.getAllClientCredentials(currentStore.storeId));
    }

    if (storeId === undefined && router.params.storeId !== undefined) {
      dispatch(storeActions.getOne(router.params.storeId))
      .then((response) => {
        dispatch(clientCredentialActions.getAllClientCredentials(response.storeId));
      })
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({ ...prevState, modal: !prevState.modal }));
  };

  toggleModalConfirm = () => {
    this.setState(prevState => ({ ...prevState, modalConfirm: !prevState.modalConfirm }));
  };

  toggleDock = () => {
    this.setState(prevState => ({ ...prevState, dock: !prevState.dock }));
  };

  handleSearch = (search) => {
    this.setState(
      { search: search },
      this.loadClientCredentials
    );
  };

  // CRUD Actions
  handleCreate = () => {
    this.toggleModal();
  };

  handleDelete = () => {
    const { dispatch, currentStore } = this.props;
    if (currentStore.storeId !== undefined) {
      dispatch(clientCredentialActions.remove(currentStore.storeId,
        this.state.selected));
    }
    this.toggleModalConfirm();
  };

  handleDeleteConfirm = (clientId) => {
    this.setState(
      { selected: clientId },
      this.toggleModalConfirm
    );
  };

  handleCancel = () => {};

  handleCancelDelete = () => {
    this.setState(
      this.toggleModalConfirm
    );
  };

  componentDidMount = () => {
    this.loadClientCredentials();
  }

  render() {
    const { state } = this;
    const {
      user: { isSuperAdmin },
      clientCredential: { all }
    } = this.props;

    let btns = [];
    let headers = [
      { name: 'Client Id', code: 'clientId' },
      { name: 'Client Name', code: 'clientName' }
    ];

    if (isSuperAdmin) {
      headers.push({ name: 'Actions' });
      btns = [
        {
          icon: 'icon-trash',
          action: this.handleDeleteConfirm
        }
      ];
    }

    return (
      <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6 mt-4">
        <XMModalConfirm isVisible={state.modalConfirm} handleConfirm={this.handleDelete} onCancel={this.handleCancelDelete} />
        <XMModal title="Add Client Credential" isVisible={state.modal} handleCancel={this.handleCancel}>
          <ClientCredentialForm form={'insert'} toggle={this.toggleModal} handleCancel={this.handleCancel} />
        </XMModal>

        <Card>
          <CardBody>
              <Col md="8">
                  <Button color="light" onClick={() => this.handleCreate()}>
                    Add Client Credential
                  </Button>
              </Col>
              <Card style={{ border: '0' }}>
                <CardBody style={{ padding: '0' }}>
                  <XMTable headers={headers} btns={btns} autoRenderRow={true}>
                    {all.map(c => (
                      <React.Fragment key={c.clientId}>
                        <td className='align-middle'>
                          <span>{c.clientId}</span>
                        </td>
                        <td className='align-middle'>
                          <span>{c.clientName}</span>
                        </td>
                      </React.Fragment>
                    ))}
                  </XMTable>
                </CardBody>
              </Card>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ClientCredentials));
