import { isEmpty } from 'lodash';

export function timeRange(value, allValues) {
  if (value && allValues.startHour) {
    // const [endHour, endMinute] = splitTime(value);
    // const [startHour, startMinute] = splitTime(allValues.startTime);
    // const isAfter = endHour > startHour || (endHour == startHour && endMinute >= startMinute);

    return parseInt(value) < parseInt(allValues.startHour) ? 'end hour must be larger start hour' : undefined;
  }
}

function splitTime(time) {
  const [hour, minute] = time.split(':');
  return [parseInt(hour), parseInt(minute)];
}

export function notEmpty(value) {
  return isEmpty(value) ? 'Select at least one' : undefined;
}

export function required(value) {
  return value === undefined || `${value}`.trim().length === 0 ? 'Field is required' : undefined;
}

export const min = minValue => value => {
  return value && parseInt(value) < minValue ? `Minimum value is ${minValue}` : undefined;
};

export const min0 = min(0);

export const max = maxValue => value => {
  return value && parseInt(value) > maxValue ? `Maximum value is ${maxValue}` : undefined;
};

export const max23 = max(23);
