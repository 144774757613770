import React, { Component } from 'react';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import { debounce } from 'lodash';

/**
 * @param pagination
 * @param paginate
 */
class XMSearchBox extends Component {
  constructor(props) {
    super(props);

    this.onSearch = debounce(this.onSearch, 350);
  }

  onSearch(fuzzyString) {
    this.props.onSearch(fuzzyString);
  }

  render() {
    return (
      <InputGroup>
          <InputGroupText>
            <i className="icon-magnifier" />
          </InputGroupText>
        <Input
          type="text"
          name="fuzzyString"
          onChange={(evt) => this.onSearch({ [evt.target.name]: evt.target.value })}
        />
      </InputGroup>
    );
  }
}

export default XMSearchBox;
