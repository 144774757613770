import React from 'react';
import {
  Link,
  matchPath,
  useLocation
} from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import BreadcrumbIcon from '../../components/BreadcrumbIcon';

const routes = {
  '/': {
    name: 'Home'
  },
  '/login': {
    name: 'Login'
  },
  '/dashboard': {
    name: 'Dashboard'
  },
  '/users': {
    name: 'Users'
  },
  '/versions': {
    name: 'Version History'
  },
  '/stores': {
    name: 'Stores'
  },
  '/datacentres': {
    name: 'Data Centers'
  },
  '/stores/backup': {
    name: 'Backup'
  },
  '/stores/credentials': {
    name: 'Client Credentials'
  },
  '/stores/synchronization': {
    name: 'Synchronization'
  },
  '/stores/synchronization/settings': {
    name: 'Settings'
  },
  '/stores/synchronization/sage': {
    name: 'Sage Data'
  }
};

const routeRules = {
  '/stores/:storeId/backup': {
    name: 'Backup'
  },
  '/stores/:storeId/credentials': {
    name: 'Client Credentials'
  },
  '/stores/:storeId/synchronization': {
    name: 'Synchronization'
  },
  '/stores/:storeId/synchronization/settings': {
    name: 'Synchronization'
  }
};

const getPaths = pathname => {
  const paths = ['/'];

  if (pathname === '/') {
    return paths;
  }

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const matchUrl = url => {
  let isMatch;
  for (var key in routeRules) {
    isMatch = matchPath({
      path: key,
      exact: true,
      strict: false
    }, url);

    if (isMatch !== null) {
      return routeRules[key];
    }
  }
};

const findRouteName = url => {
  if (routes[url]) {
    return routes[url];
  }

  return false;
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const paths = getPaths(pathname);
  const pathnames = pathname.split('/').filter(Boolean);

  return (
    <Breadcrumb aria-label='breadcrumb'>
      {paths.map((path, index) => {
        let routeObj = findRouteName(path);
        if (!routeObj) {
          routeObj = matchUrl(path);
        }
        if (routeObj) {
          const isLast = index === pathnames.length;
          return isLast ? (
            <BreadcrumbItem key={routeObj.name}>{routeObj.name}</BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={routeObj.name}>
              <Link key={routeObj.name} to={path}>
                {routeObj.name}
              </Link>
            </BreadcrumbItem>
          );
        }
        return null;
      })}
      <BreadcrumbIcon pathname={pathname}></BreadcrumbIcon>
    </Breadcrumb>
)};

export default Breadcrumbs;