import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getOne } from '../../actions/storeActions';
import MainTabs from './containers/MainTabs';
import ClientCredentials from './tabs/ClientCredentials';
import withRouter from '../../withRouter';
import PropTypes from 'prop-types';

class ClientCredential extends Component {
  async componentDidMount() {
    const { router, currentStore, fetchStore } = this.props;
    await fetchStore(router.params.storeId);
    if (currentStore.storeId) {
      router.navigate(`${router.location.pathname}/credentials`);
    }
  }

  render() {
    const { router, currentStore } = this.props;
    return (
      <div className="card" style={{ padding: 24 }}>
        <MainTabs path={`/stores/${currentStore.id}/credentials`} storeId={currentStore.storeId} primaryDomain={currentStore.primaryDomain} />

        <Routes>
          <Route path='/credentials' element={<ClientCredentials />} />
          <Route path='*' element={<Navigate to={`${router.location.pathname}/credentials`} /> } />
        </Routes>
      </div>
    );
  }
}

ClientCredential.propTypes = {
  match: PropTypes.object,
  router: PropTypes.object,
  currentStore: PropTypes.object,
  fetchStore: PropTypes.func,
};

export default connect(
  (state) => ({
    currentStore: state.store.one,
    dataCentre: state.dataCentre
  }),
  (dispatch) => ({
    fetchStore: (storeId) => dispatch(getOne(storeId)),
  })
)(withRouter(ClientCredential));
