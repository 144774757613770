import React from 'react';
import { connect } from 'react-redux';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';

import { clearToast } from '../../../actions/synchronisationActions';

function StatusToast({ isOpen = false, title, body, type = 'success', clearToast }) {
  return (
    <Toast isOpen={isOpen} style={{ position: 'fixed', left: 20, bottom: 20, zIndex: 1020 }}>
      <ToastHeader toggle={clearToast} icon={type}>
        {title}
      </ToastHeader>
      <ToastBody>{body}</ToastBody>
    </Toast>
  );
}

export default connect(
  state => {
    return {
      isOpen: state.sync.toast.open,
      title: state.sync.toast.title,
      body: state.sync.toast.body,
      type: state.sync.toast.type
    };
  },
  dispatch => ({
    clearToast: () => dispatch(clearToast())
  })
)(StatusToast);
