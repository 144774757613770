import _axios from '../oauth2Api';
//import React from 'react';
import { getFormURLencoded } from '../utils';
import {
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword, PhoneAuthProvider,
} from 'firebase/auth';

export function logAuth2In(user = { username: null, password: null, refresh_token: null, ipAccessToken: null }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      _axios
        .post('/token', getFormURLencoded(user))
        .then((response) => {
          localStorage.setItem('jwtToken', response.data.access_token);
          localStorage.setItem('jwtRefreshToken', response.data.refresh_token);
          dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((err) => {
          localStorage.removeItem('jwtToken');
          if(err?.data?.message ==='mfa-required'){
            dispatch({
              type: 'USER_MFA_REQUIRED',
            });
          }else{
            dispatch({
              type: 'USER_LOGIN_FAILURE',
              payload: err.data,
            });
          }
          reject(err);
        });
    });
}

export function logIn(user = { username: null, password: null, refresh_token: null }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), user.username, user.password)
        .then(function (userCredential) {
          dispatch({
            type: 'USER_PLATFORM_VERIFIED',
            payload: userCredential,
          });
          resolve(userCredential);
        })
        .catch(function (error) {
          if (error.code === 'auth/multi-factor-auth-required') {
            const resolver = getMultiFactorResolver(getAuth(), error);
            if (resolver.hints[0].factorId === TotpMultiFactorGenerator.FACTOR_ID || resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              dispatch({
                type: 'USER_OTP_REQUEST',
                payload: resolver,
              });
              resolve(resolver);
            } else {
              console.log('The second factor is unsupported.');
            }
          } else {
            localStorage.removeItem('jwtToken');
            dispatch({
              type: 'USER_LOGIN_FAILURE',
              payload: {
                code: '4011-unauthorized',
                message: 'Bad credentials',
                reason: 'USERNAME_OR_PASSWORD_INVALID',
              },
            });
            reject(error);
          }
        });
    });
}

const getMultiFactorAssertion = (resolver, mfaSelectedFactorId, otp, verificationId) => {
  if(mfaSelectedFactorId === TotpMultiFactorGenerator.FACTOR_ID){
    return TotpMultiFactorGenerator.assertionForSignIn(resolver.hints.find(hint=>hint.factorId===mfaSelectedFactorId).uid, otp)
  }
  const cred = PhoneAuthProvider.credential(
      verificationId, otp);
  return PhoneMultiFactorGenerator.assertion(cred);
}

export function verifyToken(otp, resolver, mfaSelectedFactorId, verificationId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const multiFactorAssertion = getMultiFactorAssertion(resolver, mfaSelectedFactorId, otp, verificationId);
      return resolver
        .resolveSignIn(multiFactorAssertion)
        .then(function (userCredential) {
          dispatch({
            type: 'USER_PLATFORM_VERIFIED',
            payload: userCredential,
          });
          resolve(userCredential);
        })
        .catch((e) => {
          dispatch({
            type: 'USER_LOGIN_FAILURE',
            payload: {
              code: e.code,
              message: 'Invalid or Expired Verification Code',
            },
          });
          reject(e);
        });
    });
}

export function select(user) {
  return function (dispatch) {
    dispatch({
      type: 'USER_SELECT',
      payload: user,
    });
    localStorage.setItem('userSelected', JSON.stringify(user));
  };
}

export function logOut(username) {
  return function (dispatch) {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('lastLoginTime-'+username);
    dispatch({
      type: 'USER_LOGOUT',
      payload: false,
    });
  };
}
