import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearStoreIntegration } from '../../actions/gatewayActions';
import IntegrationsEditForm from './IntegrationsEditForm';
import { integrationInitialValues } from './utils';
class IntegrationsAdd extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { clearStoreIntegration } = this.props;
    clearStoreIntegration();
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { currentStore, currentGatewayStore } = this.props;
    const initialValues = { ...integrationInitialValues };
    return (
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 mt-4">
        <h5>Add Integration</h5>
        <hr className="separator" />
        <IntegrationsEditForm
          currentStore={currentStore}
          currentGatewayStore={currentGatewayStore}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    dataCentre: state.dataCentre,
  }),
  (dispatch) => ({
    clearStoreIntegration: () => dispatch(clearStoreIntegration()),
  })
)(IntegrationsAdd);
