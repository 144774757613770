import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getOne } from '../../actions/storeActions';
import { getOrCreateGatewayStore } from '../../actions/gatewayActions';
import Logs from './Logs';
import MainTabs from './MainTabs';
import Settings from './Settings';
import Integrations from './Integrations';
import IntegrationsAdd from './IntegrationsAdd';
import IntegrationsEdit from './IntegrationsEdit';
import IntegrationsOperationsAdd from './IntegrationsOperationsAdd';
import IntegrationsOperationsEdit from './IntegrationsOperationsEdit';
import IntegrationsOperationsSend from './IntegrationsOperationsSend';
import PropTypes from 'prop-types';
import withRouter from '../../withRouter';

class StoreGateway extends Component {
  componentDidMount() {
    const { router, fetchStore, fetchOrCreateGatewayStore } = this.props;
    fetchStore(router.params.storeId).then(() => {
      fetchOrCreateGatewayStore(this.props.currentStore);
    });
  }

  render() {
    const { router, currentStore, currentGatewayStore } = this.props;
    if (!currentStore || !currentStore.storeId || !currentGatewayStore || !currentGatewayStore.storeId) {
      return <>Loading...</>;
    }
    return (
      <div className="card" style={{ padding: 24 }}>
        <MainTabs path={`/stores/${currentStore.id}/gateway`} currentStore={currentStore} />

        <Routes>
          <Route path='/settings' element={<Settings />} />
          <Route path='/integrations' element={<Integrations />} />
          <Route path='/integrations-add' element={<IntegrationsAdd />} />
          <Route path='/integrations/:integrationName' element={<IntegrationsEdit />} />
          <Route path='/integrations/:integrationName/operations-add' element={<IntegrationsOperationsAdd />} />
          <Route path='/integrations/:integrationName/:operationName' element={<IntegrationsOperationsEdit />} />
          <Route path='/integrations-send/:integrationName/:operationName' element={<IntegrationsOperationsSend />} />
          <Route path='/logs' element={<Logs />} />
          <Route path='*' element={<Navigate to={`${router.location.pathname}/settings`} /> } />
        </Routes>
      </div>
    );
  }
}

StoreGateway.propTypes = {
  match: PropTypes.object,
  router: PropTypes.object,
  currentStore: PropTypes.object,
  fetchStore: PropTypes.func,
  fetchOrCreateGatewayStore: PropTypes.func,
  currentGatewayStore: PropTypes.object,
};

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
  }),
  (dispatch) => ({
    fetchStore: (store_id) => dispatch(getOne(store_id)),
    fetchOrCreateGatewayStore: (currentStore) => dispatch(getOrCreateGatewayStore(currentStore)),
  })
)(withRouter(StoreGateway));
