import { apiConfigHelper } from './apiConfig';
import { createAxios } from './utils';

export const getSyncApi = () => {
  const _axios = createAxios(apiConfigHelper.getBaseUrlForSync() + '/sync');

  _axios.interceptors.request.use((request) => {
    // update the API endpoint using the site domain
    request.baseURL = apiConfigHelper.getStoreUrl(request.baseURL);

    return request;
  });
  return _axios;
};
const _axios = getSyncApi();
export default _axios;

/**
 * Check of domain is valid
 * @param domain
 * @returns {*|RegExpMatchArray|Promise<Response | undefined>}
 */
export function isValidDomain(domain) {
  let re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
  return domain.match(re);
}

export class SyncApi {
  static saveSource = async (storeId, source) => {
    try {
      let response;
      if (source.id) {
        response = await _axios.put(`source/${source.id}`, source, {
          headers: {
            'x-xm-store': storeId,
          },
        });
      } else {
        response = await _axios.post(`source`, source, {
          headers: {
            'x-xm-store': storeId,
          },
        });
      }
      return response;
    } catch (err) {
      console.log('sync error:', err);
      return false;
    }
  };
}
