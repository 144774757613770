import _axios from '../api';
import * as storeActions from './storeActions';
import { queryParams } from '../utils';

const API = '/backups';

export function getAllByStore(storeId, page = 0, size = 20, sort = 'id', sortDir = 'DESC', searchRequest = null) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'BACKUP_LOADING' });
      _axios
        .get(`${API}/store/${storeId}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(searchRequest)}`)
        .then(response => {
          dispatch({
            type: 'BACKUP_GETALL_SUCCESS',
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(err => {
          dispatch({
            type: 'BACKUP_GETALL_ERROR',
            payload: err.message
          });
          reject(err);
        });
    });
}

export function getAllCloneableByStore(environment, storeId, page = 0, size = 20, sort = 'id', sortDir = 'DESC') {
  return async dispatch => {
    dispatch({ type: 'BACKUP_LOADING' });
    try {
      let response;
      if (environment.name.toLowerCase() == process.env.NODE_ENV) {
        response = await _axios.get(
          `${API}/store/${storeId}?page=${page}&size=${size}&sort=${sort},${sortDir}&fuzzyString=DONE`
        );
      } else {
        response = await _axios.get(
          `${API}/store/${storeId}?environment=${environment.name}&page=${page}&size=${size}&sort=${sort},${sortDir}&fuzzyString=DONE`
        );
      }
      dispatch({
        type: 'BACKUP_GETALL_CLONEABLE_SUCCESS',
        payload: response.data
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'BACKUP_GETALL_CLONEABLE_ERROR',
        payload: err.message
      });
    }
  };
}

export function create(storeId) {
  return function(dispatch) {
    _axios
      .post(`${API}/store/${storeId}`)
      .then(() => {
        dispatch({
          type: 'BACKUP_CREATE_SUCCESS'
        });
        dispatch(getAllByStore(storeId));
      })
      .catch(err => {
        dispatch({
          type: 'BACKUP_CREATE_ERROR',
          payload: err.message
        });
      });
  };
}

export function restore(storeId, backup) {
  return function(dispatch) {
    _axios
      .post(`${API}/store/${storeId}/restore`, backup)
      .then(() => {
        dispatch({
          type: 'BACKUP_RESTORE_SUCCESS'
        });
        dispatch(getAllByStore(storeId));
      })
      .catch(err => {
        dispatch({
          type: 'BACKUP_RESTORE_ERROR',
          payload: err.message
        });
      });
  };
}

export function remove(storeId, backupId) {
  return function(dispatch) {
    _axios
      .delete(API + '/' + backupId)
      .then(() => {
        dispatch({
          type: 'BACKUP_DELETE_SUCCESS'
        });
        dispatch(getAllByStore(storeId));
      })
      .catch(err => {
        dispatch({
          type: 'BACKUP_DELETE_ERROR',
          payload: err.message
        });
      });
  };
}

export function select(dataCentreSelected) {
  return function(dispatch) {
    // update selected region
    Promise.all([
      dispatch({
        type: 'BACKUP_SELECT',
        payload: dataCentreSelected
      }),
      // refresh ALL data based on the region selected
      dispatch(storeActions.getAllByDataCentre(dataCentreSelected))
    ]);
    localStorage.setItem('dataCentreSelected', JSON.stringify(dataCentreSelected));
  };
}

export function clear() {
  return function(dispatch) {
    dispatch({
      type: 'BACKUP_CLEAR'
    });
  };
}
