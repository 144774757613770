import _axios from '../api';
import * as storeActions from './storeActions';
import { queryParams } from '../utils';

const API = '/configs';

export function getAll() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      _axios
        .get(`${API}`)
        .then((response) => {
          dispatch({
            type: 'CONFIG_GETALL_SUCCESS',
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: 'CONFIG_GETALL_ERROR',
            payload: err.message,
          });
          reject(err);
        });
    });
}
