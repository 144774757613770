import React, {Component, useEffect, useState} from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Root from './Root';
import Login from '../views/Login';
import { PrivateRoute } from '../routes/PrivateRoute';
import {StoreManagerApi} from "../api";
import {initializeApp} from "firebase/app";

const App = ()=>{
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(()=>{
    StoreManagerApi.getFBConfig().then(res=>{
      initializeApp(res);
      setFirebaseInitialized(true);
    });
  }, [])


    return (
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login firebaseInitialized={firebaseInitialized} />} />
          <Route element={<PrivateRoute />} >
            <Route path="*" element={<Root />} />
          </Route>
        </Routes>
      </HashRouter>
    );
}

export default App;
