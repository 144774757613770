import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as gatewayActions from '../../actions/gatewayActions';
import XMRButton from '../../components/XMRButton';
import { ClipLoader } from 'react-spinners';
import {
  Row,
  Col,
  Card,
  CardBody,
  ButtonGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  InputGroupText,
  InputGroup,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  CardFooter,
} from 'reactstrap';
import XMTable from '../../components/XMTable';
import ListRow from './components/Logs/ListRow';
import ListDetails from './components/Logs/ListDetails';

class Logs extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      expandId: null,
      page: 0,
      size: 10,
      integrationName: null,
      operationName: null,
      integrationsToggle: false,
      integrationOperationsToggle: false,
      showStoreIntegrationOperations: false,
      searchTerm: null,
      message: null,
      loading: true,
    };
    this.baseState = this.state;
  }

  async componentDidMount() {
    await this.fetchGatewayStoreLogs();
  }

  fetchGatewayStoreLogs = async (values) => {
    const currentFilters = {
      page: this.state.page,
      size: this.state.size,
      integrationName: this.state.integrationName,
      operationName: this.state.operationName,
      searchTerm: this.state.searchTerm,
    };

    const filters = Object.assign({}, currentFilters, values);
    const { dispatch, currentGatewayStore } = this.props;
    await dispatch(gatewayActions.getLogs(currentGatewayStore.storeId, filters));
    this.setState({ loading: false });
  };

  refreshInputs = () => {
    document.getElementById('pageInput').value = this.baseState.page + 1;
    document.getElementById('searchTerm').value = this.baseState.searchTerm;
  };

  refreshLogs = () => {
    this.setState({ ...this.baseState });
    this.fetchGatewayStoreLogs(this.baseState);
    this.refreshInputs();
  };

  handleIntegrationName = (value) => {
    let filterState = { integrationName: value, page: 0, searchTerm: null, operationName: null };
    this.setState(filterState);
    this.fetchGatewayStoreLogs(filterState);
    this.refreshInputs();

    if (value != null) {
      const { dispatch, currentGatewayStore } = this.props;
      dispatch(gatewayActions.getStoreIntegration(currentGatewayStore.storeId, value));
      this.setState({ showStoreIntegrationOperations: true });
    } else {
      this.setState({ showStoreIntegrationOperations: false });
    }
  };

  handleOperationName = (value) => {
    let filters = { operationName: value, page: 0, searchTerm: null };
    this.setState(filters);
    this.fetchGatewayStoreLogs(filters);
    this.refreshInputs();
  };

  handlePaginationPage = (value) => {
    if (value < 0) {
      value = 0;
    }
    this.setState({ page: value });
    this.fetchGatewayStoreLogs({ page: value });
  };

  handlePaginationSearchTerm = (value) => {
    if (value.length > 0 && value.length <= 2) {
      this.setState({ message: 'Please enter more than 2 characters.' });
    } else {
      this.setState({ message: null });
    }
    const filters = { searchTerm: value, page: 0, integrationName: null, operationName: null };
    this.setState(filters);
    this.fetchGatewayStoreLogs(filters);
  };

  toggleExpand = (id) => {
    return () => {
      if (this.state.expandId !== null && this.state.expandId === id) {
        this.setState({ expandId: null });
      } else {
        this.setState({ expandId: id });
      }
    };
  };

  getSelectedIntegrationName = () => {
    return this.state.integrationName ?? 'Integrations';
  };

  getSelectedOperationName = () => {
    return this.state.operationName ?? 'Operations';
  };

  showStoreIntegrationOperations = () => {
    return this.state.showStoreIntegrationOperations;
  };

  render() {
    const { gatewayLogs, pagination, currentGatewayStore, storeIntegration, error } = this.props;
    const { integrations } = currentGatewayStore;
    return (
      <Card>
        <CardBody>
          <Row className="pb-3">
            <Col md="6">
              <h6>Gateway Store ID: {currentGatewayStore.storeId}</h6>
            </Col>
            <Col md="3">
              {integrations && (
                <ButtonGroup className="float-start">
                  <ButtonDropdown
                    id="Integrations"
                    isOpen={this.state.integrationsToggle}
                    toggle={() => {
                      this.setState({ integrationsToggle: !this.state.integrationsToggle });
                    }}
                  >
                    <DropdownToggle caret className="p-0" color="transparent">
                      {this.getSelectedIntegrationName()}
                    </DropdownToggle>
                    <DropdownMenu left="true">
                      <DropdownItem
                        key="integration-all"
                        id="integration-all"
                        onClick={() => this.handleIntegrationName(null)}
                      >
                        All
                      </DropdownItem>
                      {integrations.map((integration, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            id={integration}
                            onClick={() => this.handleIntegrationName(integration)}
                          >
                            {integration}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonGroup>
              )}
            </Col>
            <Col md="3">
              {this.showStoreIntegrationOperations() && storeIntegration && storeIntegration.operations != null && (
                <ButtonGroup className="float-start">
                  <ButtonDropdown
                    id="IntegrationOperations"
                    isOpen={this.state.integrationOperationsToggle}
                    toggle={() => {
                      this.setState({ integrationOperationsToggle: !this.state.integrationOperationsToggle });
                    }}
                  >
                    <DropdownToggle caret className="p-0" color="transparent">
                      {this.getSelectedOperationName()}
                    </DropdownToggle>
                    <DropdownMenu left="true" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                      <DropdownItem
                        key="operation-all"
                        id="operation-all"
                        onClick={() => this.handleOperationName(null)}
                      >
                        All
                      </DropdownItem>
                      {Object.keys(storeIntegration.operations).map((operation) => {
                        return (
                          <DropdownItem
                            key={operation}
                            id={operation}
                            onClick={() => this.handleOperationName(operation)}
                          >
                            {operation}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </ButtonDropdown>
                </ButtonGroup>
              )}
            </Col>
          </Row>

          {pagination && (
            <nav>
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    previous
                    disabled={pagination.page == 1}
                    first={pagination.page == 1}
                    last={!pagination.hasNextPage}
                    onClick={() => {
                      this.handlePaginationPage(this.state.page - 1);
                    }}
                  >
                    Prev
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    next
                    disabled={!pagination.hasNextPage}
                    first={pagination.page == 1}
                    last={!pagination.hasNextPage}
                    onClick={() => {
                      this.handlePaginationPage(this.state.page + 1);
                    }}
                  >
                    Next
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem>
                  <InputGroup>
                    <InputGroupText>Page</InputGroupText>
                    <Input
                      id="pageInput"
                      type="number"
                      name="page"
                      style={{ width: '80px' }}
                      min="1"
                      defaultValue={this.state.page + 1}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.handlePaginationPage(e.target.value - 1);
                        }
                      }}
                    />
                  </InputGroup>
                </PaginationItem>
                <PaginationItem>
                  <InputGroup>
                    <InputGroupText>
                      <span>Search</span>
                    </InputGroupText>
                    <Input
                      id="searchTerm"
                      type="text"
                      name="searchTerm"
                      style={{ width: '280px' }}
                      placeholder="Correlation ID or Operation..."
                      minLength={3}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.handlePaginationSearchTerm(e.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </PaginationItem>
                <PaginationItem>
                  <ButtonGroup>
                    <XMRButton loading={false} handleRefresh={() => this.refreshLogs()} />
                  </ButtonGroup>
                </PaginationItem>
                {this.state.loading && (
                  <PaginationItem>
                    <ButtonGroup>
                      <Button className="btn btn-light">
                        <ClipLoader sizeUnit={'em'} size={1} color={'#3e515b'} loading={true} />
                      </Button>
                    </ButtonGroup>
                  </PaginationItem>
                )}
              </Pagination>
              <Row>
                <Col md="6">
                  <span className="float-start text-primary">
                    <b>Note:</b> Hit <b>"Enter"</b> after filling page or search keywords
                  </span>
                </Col>
                <Col md="6">
                  {gatewayLogs && !error && (
                    <>
                      <span className="float-end text-primary">
                        Per page: <b>{this.state.size}</b> logs
                      </span>
                      <span className="float-end text-primary me-2">-</span>
                      <span className="float-end text-primary me-2">
                        Current page: <b>{pagination.page}</b>
                      </span>
                    </>
                  )}
                </Col>
              </Row>
            </nav>
          )}
          {gatewayLogs && (
            <XMTable
              autoRenderRow={false}
              headers={[
                { name: 'Details' },
                { name: 'Created At' },
                { name: 'Requested At' },
                { name: 'Correlation ID' },
                {
                  name: 'Integration',
                },
                {
                  name: 'Operation',
                },
                {
                  name: 'Status Code',
                },
              ]}
            >
              {gatewayLogs.map((log, i) => [
                <ListRow
                  key={`row-${i}`}
                  log={log}
                  expand={this.state.expandId === i}
                  onRowClick={this.toggleExpand(i)}
                />,
                <ListDetails key={`detail-${i}`} expand={this.state.expandId === i} logId={log.id} />,
              ])}
            </XMTable>
          )}
          <CardFooter>
            {error && (
              <div>
                <label className="alert alert-danger">
                  <pre>{error}</pre>
                </label>
              </div>
            )}
            {this.state.message && (
              <div>
                <label className="alert alert-warning">{this.state.message}</label>
              </div>
            )}
            {!gatewayLogs && <label className="alert alert-info">No logs</label>}
          </CardFooter>
        </CardBody>
      </Card>
    );
  }
}

export default connect((state) => ({
  currentStore: state.store.one,
  currentGatewayStore: state.gateway.one,
  dataCentre: state.dataCentre,
  gatewayLogs: state.gateway.logs,
  pagination: state.gateway.pagination,
  storeIntegration: state.gateway.storeIntegration,
  error: state.gateway.error,
}))(Logs);
