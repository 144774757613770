// REGION REDUCER
const initialState = {
  fetched: false,
  all: [],
  pagination: {}
};
export default function erpTypeReducer(state = initialState, action) {
  switch (action.type) {
    case 'ERPTYPE_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        all: action.payload.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
      break;
    }
  }
  return state;
}
