import React, { Component } from 'react';
import Dock from 'react-dock';
import { Container, Row, Col, Button } from 'reactstrap';

class XMDock extends Component {
  render() {
    const { title, children } = this.props;
    return (
      <Dock position="right" fluid={true} size={0.5} isVisible={this.props.isVisible} className="dock">
        <div className="dock">
          <div className="dock-header">
            <Container>
              <Row>
                <Col xs="10">
                  <div className="title"> {title} </div>
                </Col>
                <Col xs="2">
                  <Button className="close-btn" color="link" onClick={this.props.handleCancel}>
                    X
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="dock-body">{children}</div>
        </div>
      </Dock>
    );
  }
}

export default XMDock;
