import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, FormGroup, Button } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { InputField } from '../../components/Field';
import * as gatewayActions from '../../actions/gatewayActions';
import { GatewayApi } from '../../gatewayApi';
import { sleep } from '../../utils';
import { ColLeft, ColRight, renderSettings } from './utils';
import { API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL } from '../../utils/constants';
import withRouter from '../../withRouter';

class IntegrationsEditForm extends Component {
  state = { message: '', error: false };
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.props.initialize(this.props.initialValues);
    const { fetchAvailableInterfaces, fetchAzureOperations } = this.props;
    fetchAvailableInterfaces();
    fetchAzureOperations();
  }

  submit = async (store) => {
    try {
      const { dispatch, router, currentStore, currentGatewayStore, storeIntegration } = this.props;
      const settings = {};
      if (store.settings) {
        store.settings.forEach((item) => {
          if (item.name && item.value);
          settings[item.name] = item.value;
        });
      }

      let integration = storeIntegration ? storeIntegration.integration : store.integration;
      const [provider, product, version, interfaceName] = store.interface.split('-');
      const { accessOperation, refreshOperation, ...otherConnection } = store.connection;
      const data = {
        storeId: currentGatewayStore.storeId,
        integration: store.integration,
        enabled: store.enabled,
        interface: { provider, product, version, interface: interfaceName },
        connection: otherConnection,
        settings,
      };

      if (storeIntegration) {
        if (accessOperation) {
          const [provider, product, version, objInterface, operation] = accessOperation.split('-');
          data.connection.accessOperation = {
            provider,
            product,
            version,
            interface: objInterface,
            operation,
          };
        }
        if (refreshOperation) {
          const [provider, product, version, objInterface, operation] = refreshOperation.split('-');
          data.connection.refreshOperation = {
            provider,
            product,
            version,
            interface: objInterface,
            operation,
          };
        }
      }

      if (!storeIntegration) {
        // check when creating a integration
        const res = await GatewayApi.findStoreIntegration(currentGatewayStore.storeId, integration);
        if (res) {
          throw new Error(`Integration "${integration}" has already existed in the store.`);
        }
      }
      await GatewayApi.saveStoreIntegration(currentGatewayStore.storeId, integration, data);
      this.setState({
        message: 'Saved successfully',
        error: false,
      });
      if (!storeIntegration) {
        await sleep(2000);
        router.navigate(`/stores/${currentStore.id}/gateway/integrations/${integration}`);
        dispatch({ type: 'GATEWAY_STORE_INTEGRATION_GETONE_INIT' });
        dispatch(gatewayActions.getStoreIntegration(currentGatewayStore.storeId, integration));
      }
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to save, reason: ' + error.message,
        error: true,
      });
    }
    return false;
  };

  onCancel = () => {
    this.props.router.navigate(`/stores/${this.props.currentStore.id}/gateway/settings`);
  };

  onDelete = async () => {
    const { dispatch, router, currentStore, currentGatewayStore, storeIntegration } = this.props;
    if (!window.confirm('Are you sure to delete this item?')) {
      return;
    }

    try {
      await GatewayApi.deleteStoreIntegration(currentGatewayStore.storeId, storeIntegration.integration);
      this.setState({
        message: 'Deleted successfully',
        error: false,
      });
      await sleep(1000);
      router.navigate(`/stores/${this.props.currentStore.id}/gateway/integrations`);
      dispatch(gatewayActions.getStore(currentStore.storeId));
    } catch (error) {
      console.log('error:', error);
      this.setState({
        message: 'Failed to delete, reason: ' + error.message,
        error: true,
      });
    }
  };

  clearMessage = () => {
    this.setState({
      message: '',
      error: false,
    });
  };

  render() {
    const { message, error } = this.state;
    const {
      router,
      handleSubmit,
      currentGatewayStore,
      currentStore,
      availableInterfaces,
      azureOperations,
      storeIntegration,
      integrationsEditForm,
    } = this.props;

    if (!availableInterfaces || !azureOperations || !integrationsEditForm || !integrationsEditForm.values) {
      return <>Loading...</>;
    }

    const integrationsEditFormValues = Object.assign({}, integrationsEditForm.values);
    const operations = [],
      operationOptions = [];
    let isAuthenticationReadOnly = false;

    if (storeIntegration) {
      if (storeIntegration.integration === API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL) {
        isAuthenticationReadOnly = true;

        azureOperations.forEach((item) => {
          operationOptions.push(item);
        });
      }

      if (storeIntegration.operations) {
        Object.keys(storeIntegration.operations).forEach((name) => {
          operations.push(name);
          operationOptions.push({
            title: integrationsEditFormValues.interface.replaceAll('-', ' ') + ' ' + name,
            value: integrationsEditFormValues.interface + '-' + name,
          });
        });
      }
    }

    if (
      integrationsEditFormValues &&
      integrationsEditFormValues.integration === API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL
    ) {
      isAuthenticationReadOnly = true;
    }

    return (
      <>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          {message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}
          <FormGroup row>
            <ColLeft>Interface</ColLeft>
            <ColRight>
              {storeIntegration && (
                <input
                  className="form-control"
                  value={integrationsEditFormValues.interface.replaceAll('-', ' ')}
                  disabled
                />
              )}
              {!storeIntegration && (
                <Field
                  component="select"
                  required
                  className="form-control"
                  name="interface"
                  id="interface"
                  onChange={(event, newValue) => {
                    const value = newValue.split('-');
                    const [provider, product, version, interfaceName] = value;
                    let integration = product;
                    if (product === 'Azure') {
                      integration = 'ActiveDirectory';
                    } else if (product === 'X3') {
                      integration = 'SageX3';
                    }
                    if (product === API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL) {
                      this.props.change('connection.authType', 'BEARER');
                    }
                    this.props.change('integration', integration);
                  }}
                >
                  <option key={-1} />
                  {availableInterfaces.map((availableInterface, i) => (
                    <option key={i} value={availableInterface.value}>
                      {availableInterface.title}
                    </option>
                  ))}
                </Field>
              )}
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Integration</ColLeft>
            <ColRight>
              <Field component={InputField} name="integration" id="integration" readOnly required />
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Enabled</ColLeft>
            <ColRight>
              <Field component={InputField} type="checkbox" name="enabled" id="enabled" />
            </ColRight>
          </FormGroup>
          <FormGroup row>
            <ColLeft>Connection</ColLeft>
            <ColRight>
              <Field component={InputField} name="connection.uri" id="connection.uri" warn={this.clearMessage} />
            </ColRight>
          </FormGroup>
          {integrationsEditFormValues.connection.uri.toLowerCase().startsWith('https') && (
            <>
              <FormGroup row>
                <ColLeft>&bull; SSL auth</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.clientCertificate"
                    id="connection.clientCertificate"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
              <FormGroup row>
                <ColLeft>&bull; SSL trust</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.trustCertificate"
                    id="connection.trustCertificate"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
            </>
          )}
          <FormGroup row>
            <ColLeft>Authentication</ColLeft>
            <ColRight>
              <Field
                component="select"
                required
                className="form-control"
                name="connection.authType"
                id="connection.authType"
                readOnly={isAuthenticationReadOnly}
              >
                {!isAuthenticationReadOnly && (
                  <>
                    <option value="NONE">NONE</option>
                    <option value="BASIC">BASIC</option>
                  </>
                )}
                <option value="BEARER">OAUTH2</option>
              </Field>
            </ColRight>
          </FormGroup>
          {integrationsEditFormValues.connection.authType === 'BASIC' && (
            <>
              <FormGroup row>
                <ColLeft>&bull; username</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.username"
                    id="connection.username"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
              <FormGroup row>
                <ColLeft>&bull; password</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.password"
                    id="connection.password"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
            </>
          )}
          {integrationsEditFormValues.connection.authType === 'BEARER' && (
            <>
              <FormGroup row>
                <ColLeft>&bull; access operation</ColLeft>
                <ColRight>
                  {!storeIntegration && (
                    <input
                      value="Please set this field after the ingration is created."
                      className="form-control"
                      readOnly
                    />
                  )}
                  {storeIntegration && (
                    <Field
                      component="select"
                      className="form-control"
                      name="connection.accessOperation"
                      id="connection.accessOperation"
                      onChange={this.clearMessage}
                    >
                      <option value=""></option>
                      {operationOptions.map((operation, i) => (
                        <option key={i} value={operation.value}>
                          {operation.title}
                        </option>
                      ))}
                    </Field>
                  )}
                </ColRight>
              </FormGroup>
              <FormGroup row>
                <ColLeft>&bull; access token</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.accessToken"
                    id="connection.accessToken"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
              <FormGroup row>
                <ColLeft>&bull; refresh operation</ColLeft>
                <ColRight>
                  {!storeIntegration && (
                    <input
                      value="Please set this field after the ingration is created."
                      className="form-control"
                      readOnly
                    />
                  )}
                  {storeIntegration && (
                    <Field
                      component="select"
                      className="form-control"
                      name="connection.refreshOperation"
                      id="connection.refreshOperation"
                      onChange={this.clearMessage}
                    >
                      <option value=""></option>
                      {operationOptions.map((operation, i) => (
                        <option key={i} value={operation.value}>
                          {operation.title}
                        </option>
                      ))}
                    </Field>
                  )}
                </ColRight>
              </FormGroup>
              <FormGroup row>
                <ColLeft>&bull; refresh token</ColLeft>
                <ColRight>
                  <Field
                    component={InputField}
                    name="connection.refreshToken"
                    id="connection.refreshToken"
                    warn={this.clearMessage}
                  />
                </ColRight>
              </FormGroup>
            </>
          )}
          <FormGroup row style={{ marginBottom: 0 }}>
            <ColLeft>Settings</ColLeft>
            <ColRight>
              <FieldArray
                name="settings"
                component={renderSettings}
                warn={(value, allValues, props) => {
                  //   this.clearMessage();
                }}
              />
            </ColRight>
          </FormGroup>
          {storeIntegration && (
            <div className="float-start">
              <Button type="button" color="danger" className="me-2" onClick={() => this.onDelete()}>
                Delete
              </Button>
            </div>
          )}
          <div className="float-end">
            <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div style={{ marginTop: 5 }}>{message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}</div>
        </form>
        {storeIntegration && (
          <>
            <div className="row">
              <div className="col-sm-12 mt-2">
                <hr size={1} style={{ width: '100%' }} />
              </div>
              <div className="col-sm-3 col-lg-2 col-xl-2">Operations</div>
              <div className="col-sm-6 col-lg-4 col-xl-2">
                <ul className="list-unstyled">
                  {operations.map((operation, i) => (
                    <li key={i}>
                      <Link
                        style={{ color: 'inherit', textDecoration: 'inherit' }}
                        to={`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}/${operation}`}
                      >
                        <i className="icon-pencil" style={{ cursor: 'pointer' }} title="Edit Item" />
                      </Link>
                      &nbsp;&nbsp;
                      <Link
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                        // to={`/stores/${currentStore.id}/gateway/integrations-send/${storeIntegration.integration}/${operation}`}
                        to={`/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}/${operation}`}
                      >
                        {operation}
                      </Link>
                    </li>
                  ))}
                </ul>

                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    router.navigate(
                      `/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}/operations-add`
                    );
                  }}
                >
                  Add Operation
                </Button>
              </div>
              <div className="col-sm-3 col-lg-4 col-xl-2">
                <div className="float-end">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      router.navigate(
                        `/stores/${currentStore.id}/gateway/integrations/${storeIntegration.integration}/operations-add`
                      );
                    }}
                  >
                    Add Operation
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    // currentGatewayStore: state.gateway.one,
    availableInterfaces: state.gateway.availableInterfaces,
    azureOperations: state.gateway.azureOperations,
    integrationsEditForm: state.form.integrationsEditForm,
  }),
  (dispatch) => ({
    fetchAvailableInterfaces: () => dispatch(gatewayActions.getAvailableInterfaces()),
    fetchAzureOperations: () => dispatch(gatewayActions.getAzureOperations()),
  })
)(reduxForm({ form: 'integrationsEditForm', enableReinitialize: true })(withRouter(IntegrationsEditForm)));
