import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  validateTableFilterTree
} from '../../../../../actions/synchronisationActions';

class ValidateTree extends Component {
  submit = () => {
    const { validateTableFilterTree, storeId, tableName, tree } = this.props;
    if (!isEmpty(tree)) {
      // using JSON.parse() to convert to a valid json
      validateTableFilterTree(storeId, tableName, {
        tree: JSON.parse(tree)
      });
    }
  };

  render() {
    return (
      <Button color="light" onClick={this.submit}>
        <i className="fa fa-angle-double-up"/>
      </Button>
    );
  }
}

ValidateTree.propTypes = {
  storeId: PropTypes.string.isRequired,
  tableName: PropTypes.string,
  tree: PropTypes.string
};

export default connect(
  state => ({}),
  dispatch => ({
    validateTableFilterTree: (storeId, tableName, filter) => dispatch(validateTableFilterTree(storeId, tableName, filter))
  }))(ValidateTree);
