import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { getStoreIntegration, getStoreIntegrationOperation } from '../../actions/gatewayActions';
import IntegrationsOperationsSendForm from './IntegrationsOperationsSendForm';
import { objToArrForFieldArr } from './utils';
import withRouter from '../../withRouter';

class IntegrationsOperationsAdd extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { router, fetchStoreIntegration, fetchStoreIntegrationOperation, currentGatewayStore } = this.props;
    fetchStoreIntegration(currentGatewayStore.storeId, router.params.integrationName);
    fetchStoreIntegrationOperation(
      currentGatewayStore.storeId,
      router.params.integrationName,
      router.params.operationName
    );
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { router, currentStore, currentGatewayStore, error, storeIntegration, storeIntegrationOperation } = this.props;
    if (error) {
      return (
        <div>
          <Alert color={'danger'}>{error}</Alert>
        </div>
      );
    }

    if (!storeIntegration || !storeIntegrationOperation) {
      return <div>Loading...</div>;
    }

    const { integrationName, operationName } = router.params;
    const { request, response, settings, ...initialValues } = storeIntegrationOperation;

    if (settings) {
      const newSettings = [];
      Object.keys(settings).forEach((name) => {
        newSettings.push({ name, value: settings[name] });
      });
      initialValues.settings = newSettings;
    }

    let dataRequest = {};
    {
      let { parameters, headers, ...newRequest } = request;
      dataRequest = {
        parameters: objToArrForFieldArr(parameters),
        headers: objToArrForFieldArr(headers),
        ...newRequest,
      };
    }
    let dataResponse = {};
    {
      let { parameters, headers, ...newRequest } = response;
      dataResponse = {
        parameters: objToArrForFieldArr(parameters),
        headers: objToArrForFieldArr(headers),
        ...newRequest,
      };
    }
    initialValues.request = dataRequest;
    initialValues.response = dataResponse;

    return (
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 mt-4">
        <h5>
          Send Operation "{currentGatewayStore.storeId}/{integrationName}/{operationName}"
        </h5>
        <hr className="separator" />
        <IntegrationsOperationsSendForm
          currentStore={currentStore}
          currentGatewayStore={currentGatewayStore}
          storeIntegration={storeIntegration}
          storeIntegrationOperation={storeIntegrationOperation}
          initialValues={initialValues}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    currentStore: state.store.one,
    currentGatewayStore: state.gateway.one,
    error: state.gateway.error,
    storeIntegration: state.gateway.storeIntegration,
    storeIntegrationOperation: state.gateway.storeIntegrationOperation,
  }),
  (dispatch) => ({
    fetchStoreIntegration: (storeId, integrationName) => {
      dispatch({ type: 'GATEWAY_STORE_INTEGRATION_GETONE_INIT' });
      dispatch(getStoreIntegration(storeId, integrationName));
    },
    fetchStoreIntegrationOperation: (storeId, integrationName, operationName) => {
      dispatch({ type: 'GATEWAY_STORE_INTEGRATION_OPERATION_GETONE_INIT' });
      dispatch(getStoreIntegrationOperation(storeId, integrationName, operationName));
    },
  })
)(withRouter(IntegrationsOperationsAdd));
