import _axios from '../api';
import { queryParams } from '../utils';

const API = '/erp_types';
export function getAll(
  page = 0,
  size = 10,
  sort = 'id',
  sortDir = 'DESC',
  searchRequest = null
) {
  return function(dispatch) {
    _axios
      .get(
        `${API}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(
          searchRequest
        )}`
      )
      .then(response => {
        dispatch({
          type: 'ERPTYPE_GETALL_SUCCESS',
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: 'ERPTYPE_GETALL_ERROR',
          payload: err.message
        });
      });
  };
}
