import syncApi from '../../syncApi';

export function fetchFailureRequests(storeId, searchKey, isFetchFirstRow) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_FAILURE_REQUESTS_START' });
      if (!searchKey) searchKey = '';
      const response = await syncApi.get(`/${storeId}/request?q=failed&v=list&s=${searchKey}`);
      if (response.status === 200) {
        const failureRequests = response.data;
        dispatch({ type: 'FETCH_FAILURE_REQUESTS_SUCCESS', payload: failureRequests });
        if (isFetchFirstRow && failureRequests.length) {
          dispatch(fetchFailureRequestFailures(storeId, failureRequests[0], true));
        }
      }
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE_REQUESTS_FAILED', payload: { error: error.message } });
    }
  };
}

export function fetchFailureRequestFailures(storeId, failureRequestCode, isFetchFirstRow) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_FAILURE_REQUEST_FAILURES_START', payload: failureRequestCode });
      const responseDetail = await syncApi.get(`/${storeId}/request/${failureRequestCode}`);
      if (responseDetail.status !== 200) {
        throw new Error(`failed to get the detailed info for request ${failureRequestCode}`);
      }
      const failureRequestDetail = responseDetail.data;
      const response = await syncApi.get(`/${storeId}/request/${failureRequestCode}/failure`);
      if (response.status === 200) {
        const failureRequestFailures = [];
        Object.keys(response.data).map((id) => {
          failureRequestFailures.push({
            id,
            ...response.data[id],
          });
        });
        dispatch({
          type: 'FETCH_FAILURE_REQUEST_FAILURES_SUCCESS',
          payload: { failureRequestFailures, failureRequestDetail },
        });
        if (isFetchFirstRow && failureRequestFailures.length) {
          dispatch(fetchFailureRequestFailureDetail(storeId, failureRequestCode, failureRequestFailures[0].id, true));
        }
      }
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE_REQUEST_FAILURES_FAILED', payload: { error: error.message } });
    }
  };
}

export function fetchFailureRequestFailureDetail(
  storeId,
  failureRequestCode,
  failureRequestFailureCode,
  isFetchFirstRow
) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_START', payload: failureRequestFailureCode });
      const response = await syncApi.get(
        `/${storeId}/request/${failureRequestCode}/failure/${failureRequestFailureCode}`
      );
      if (response.status === 200) {
        const failureRequestFailureDetail = response.data;
        dispatch({ type: 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_SUCCESS', payload: failureRequestFailureDetail });
        if (isFetchFirstRow && failureRequestFailureDetail.length) {
          dispatch(setFailureRequestFailureDetailCurrentCode(failureRequestFailureDetail[0].id.toString(), true));
        }
      }
    } catch (error) {
      dispatch({ type: 'FETCH_FAILURE_REQUEST_FAILURE_DETAIL_FAILED', payload: { error: error.message } });
    }
  };
}

export function actionFailureRequestFailureDetail(
  storeId,
  failureRequestCode,
  failureRequestFailureCode,
  action,
  failureRequestFailureDetailCode,
  isFetchFailureDetail
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_START',
        payload: {
          code: failureRequestFailureDetailCode,
          action,
        },
      });
      let response = null;
      if (action === 'delete') {
        response = await syncApi.delete(`/${storeId}/failure/${failureRequestFailureDetailCode}`);
      } else if (action === 'retry') {
        response = await syncApi.get(`/${storeId}/failure/${failureRequestFailureDetailCode}/retry`);
      } else {
        throw new Error(`Unknown Action ${action}`);
      }
      if (response.status === 200) {
        const failureRequestFailureDetail = response.data;
        dispatch({ type: 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_SUCCESS', payload: failureRequestFailureDetail });
        if (isFetchFailureDetail) {
          dispatch(fetchFailureRequestFailureDetail(storeId, failureRequestCode, failureRequestFailureCode));
        }
      } else {
        throw new Error('incorrect response status - ' + response.status);
      }
    } catch (error) {
      let message = error.status + ' - ';
      if (error.status === 501) {
        message += 'NOT IMPLEMENTED';
      } else {
        message += 'UNKNOWN ISSUE';
      }
      dispatch({
        type: 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_FAILED',
        payload: { error: message },
      });
    }
  };
}

export function clearFailureRequestFailureDetailAction() {
  return async (dispatch) => {
    dispatch({ type: 'ACTION_FAILURE_REQUEST_FAILURE_DETAIL_CLEAR' });
  };
}

export function setFailureRequestFailureDetailCurrentCode(failureRequestFailureDetailCurrentCode) {
  return async (dispatch) => {
    dispatch({ type: 'SET_FAILURE_REQUEST_FAILURE_DETAIL_CODE', payload: failureRequestFailureDetailCurrentCode });
  };
}
