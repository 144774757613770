import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

import { deleteMapping } from '../../../../../actions/synchronisationActions';
import MappingForm from './MappingForm';

class MappingTable extends Component {
  handleDeleteMapping = mappingId => {
    const { storeId, deleteMapping } = this.props;
    return () => {
      deleteMapping(storeId, mappingId);
    };
  };

  render() {
    const { mappings } = this.props;
    return (
      <Table bordered style={{ marginTop: 10, tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '13%' }}>Source Table</th>
            <th style={{ width: '14%' }}>Source Column</th>
            <th style={{ width: '13%' }}>Target Table</th>
            <th style={{ width: '14%' }}>Target Column</th>
            <th style={{ width: '13%' }}>Default Value</th>
            <th style={{ width: '8%' }}>Row Group</th>
            <th style={{ width: '13%' }}>Converter</th>
            <th style={{ width: '7%' }}>Override</th>
            <th style={{ width: '8%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          <MappingForm storeId={this.props.storeId} />
          {mappings &&
            mappings.map((mapping, idx) => (
              <tr key={idx}>
                <td>{mapping.sourceTable}</td>
                <td>{mapping.sourceColumn}</td>
                <td>{mapping.targetTable}</td>
                <td>{mapping.targetColumn}</td>
                <td>{mapping.defaultValue}</td>
                <td>{mapping.rowGroup}</td>
                <td>{mapping.converter}</td>
                <td>{mapping.isOverride ? 'Y' : null}</td>
                <td>
                  {mapping.isOverride && (
                    <button
                      className="btn btn-sm"
                      style={{ backgroundColor: 'transparent' }}
                      onClick={this.handleDeleteMapping(mapping.id)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

const filterMapping = state => {
  let { mappings: filteredMappings } = state.sync.mapping;
  if (state.form && state.form.mappingForm && state.form.mappingForm.values) {
    const { values } = state.form.mappingForm;
    if (values.sourceTable) {
      filteredMappings = filteredMappings.filter(mapping => values.sourceTable.value === mapping.sourceTable);
    }

    if (values.targetTable) {
      filteredMappings = filteredMappings.filter(mapping => values.targetTable.value === mapping.targetTable);
    }
    return filteredMappings;
  }

  if (state.sync.mapping.filter.override) {
    return filteredMappings.filter(mapping => mapping.isOverride);
  }

  return filteredMappings;
};

export default connect(
  state => ({
    mappings: filterMapping(state)
  }),
  dispatch => ({
    deleteMapping: (storeId, mappingId) => dispatch(deleteMapping(storeId, mappingId))
  })
)(MappingTable);
