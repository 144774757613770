import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { PacmanLoader } from 'react-spinners';

const Colors = {
  CREATING: 'primary',
  UPDATING: 'primary',
  CREATED: 'success',
  FAILED: 'danger',
  LIVE: 'success',
  DEACTIVATING: 'warning',
  REACTIVATING: 'warning',
  DELETING: 'warning',
  DEACTIVATED: 'danger',
  REACTIVATED: 'success'
};

class StoreStatusDropdown extends Component {
  state = {
    isOpen: false
  };

  toggleDropdown = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  isWaiting(storeStatus) {
    return storeStatus.statusType ? storeStatus.statusType.endsWith('ING') : false;
  }

  hasRetry = ({ nextStatusTypes }) => {
    return this.matchStatus(nextStatusTypes, 'RETRY');
  };

  hasDesactivate = ({ nextStatusTypes }) => {
    return this.matchStatus(nextStatusTypes, 'DEACTIVATE');
  };

  hasReactivate = ({ nextStatusTypes }) => {
    return this.matchStatus(nextStatusTypes, 'REACTIVATE');
  };

  matchStatus = (nextStatusTypes, statusTypesText) => {
    return _.indexOf(nextStatusTypes, statusTypesText) != -1;
  };

  handleStoreStatusChange = statusTypeText => {
    return () => this.props.onUpdateStatusClick(statusTypeText);
  };

  render() {
    const { storeStatus } = this.props.store;

    return (
      <Dropdown group size="sm" isOpen={this.state.isOpen} toggle={this.toggleDropdown}>
        <DropdownToggle color={Colors[storeStatus.statusType]} onClick={this.props.onToggleClick}>
          {this.isWaiting(storeStatus) ? (
            <div style={{ display: 'inline-flex', width: '10em' }}>
              <span>{storeStatus.statusType}</span>
              <div className="ms-2">
                <PacmanLoader size={8.5} color={'#FFF'} loading={true} />
              </div>
            </div>
          ) : (
            <span>{storeStatus.statusType}</span>
          )}
        </DropdownToggle>
        {this.hasRetry(storeStatus) || this.hasDesactivate(storeStatus) || this.hasReactivate(storeStatus) ? (
          <DropdownMenu>
            {storeStatus.statusType == 'FAILED' && (
              <DropdownItem onClick={this.props.onFailedClick}>
                <div>View Logs</div>
              </DropdownItem>
            )}
            {this.hasRetry(storeStatus) && (
              <DropdownItem onClick={this.handleStoreStatusChange('RETRY')}>
                <div>Retry</div>
              </DropdownItem>
            )}
            {this.hasDesactivate(storeStatus) && (
              <DropdownItem onClick={this.handleStoreStatusChange('DEACTIVATE')}>
                <div>Deactivate</div>
              </DropdownItem>
            )}
            {this.hasReactivate(storeStatus) && (
              <DropdownItem onClick={this.handleStoreStatusChange('REACTIVATE')}>
                <div>Reactivate</div>
              </DropdownItem>
            )}
          </DropdownMenu>
        ) : null}
      </Dropdown>
    );
  }
}

StoreStatusDropdown.propTypes = {
  store: PropTypes.object.isRequired,

  onToggleClick: PropTypes.func.isRequired,
  onFailedClick: PropTypes.func.isRequired,
  onUpdateStatusClick: PropTypes.func.isRequired
};

export default StoreStatusDropdown;
