import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

/**
 * Tree is readonly for now, it's just a visual representation
 *
 * @param value
 * @param setTree
 * @returns {*}
 * @constructor
 */
function Tree({ value, setTree }) {
  return (
    <Input type="textarea"
           name="tree"
           id="tree"
           className="form-control"
           readOnly
           onChange={e => {
             e.preventDefault();
             setTree(e.target.value);
           }}
           value={value}/>
  );
}

Tree.propTypes = {
  value: PropTypes.string,
  setTree: PropTypes.func.isRequired
};

export default Tree;