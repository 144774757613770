import _axios from '../api';
import _ from 'lodash';
import { queryParams } from '../utils';

const API = '/users';

export function getAll(
  page = 0,
  size = 100,
  sort = 'id',
  sortDir = 'DESC',
  searchRequest = null
) {
  return function(dispatch) {
    _axios
      .get(
        `${API}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(
          searchRequest
        )}`
      )
      .then(response => {
        dispatch({
          type: 'OPERATOR_GETALL_SUCCESS',
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: 'OPERATOR_GETALL_ERROR',
          payload: err.message
        });
      });
  };
}

export function getAllByRole(role = null) {
  return function(dispatch) {
    _axios
      .get(`${API}/role/${role}`)
      .then(response => {
        dispatch({
          type: 'OPERATOR_GETALLBYROLE_SUCCESS',
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: 'OPERATOR_GETALLBYROLE_ERROR',
          payload: err.message
        });
      });
  };
}

export function getOne(operatorId) {
  return dispatch =>
    new Promise((resolve, reject) => {
      _axios
        .get(API + '/' + operatorId)
        .then(response => {
          dispatch({
            type: 'OPERATOR_GETAONE_SUCCESS',
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(err => {
          dispatch({
            type: 'OPERATOR_GETAONE_ERROR',
            payload: err.message
          });
          reject();
        });
    });
}

/**
 * create (POST) || update (PUT)
 * @param {*} operator
 */
export function save(operator) {
  return dispatch =>
    new Promise((resolve, reject) => {
      let action = operator.id
        ? _axios.put(API, operator)
        : _axios.post(API, operator);

      action
        .then(response => {
          dispatch({
            type: 'OPERATOR_SAVE_SUCCESS'
          });
          // refresh operators
          dispatch(getAll());
          resolve();
        })
        .catch(err => {
          dispatch({
            type: 'OPERATOR_SAVE_ERROR',
            payload: err.data.code
          });
          // reject();
        });
    });
}

export function remove(operator) {
  return function(dispatch) {
    _axios
      .delete(API + '/' + operator.id)
      .then(response => {
        dispatch({
          type: 'OPERATOR_DELETE_SUCCESS'
        });
        // refresh operators
        dispatch(getAll());
      })
      .catch(err => {
        dispatch({
          type: 'OPERATOR_DELETE_ERROR',
          payload: err.message
        });
      });
  };
}

/**
 * <Typeahead /> onChance Event
 * @param {*} operator
 */
export function getById(id) {
  return function(dispatch) {
    _axios
      .get(API + '/' + id)
      .then(response => {
        dispatch({
          type: 'OPERATOR_LOOKUP_FULFILLED',
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: 'OPERATOR_LOOKUP_ERROR',
          payload: err.message
        });
      });
  };
}

export function clear() {
  return function(dispatch) {
    dispatch({
      type: 'STORE_CLEAR'
    });
  };
}

/**
 * <Typeahead /> onChance Event
 * @param {*} operator
 */
export function lookupOperator(operator) {
  return function(dispatch) {
    dispatch({
      type: 'OPERATOR_LOOKUP_FULFILLED',
      payload: _.size(operator) === 1 ? operator[0] : {}
    });
  };
}

/**
 * Clean the "selected" Operator
 * @param {*} operator
 */
export function cleanOperator() {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: 'OPERATOR_CLEAN',
        payload: {}
      });
      resolve(new Date());
    });
}
