import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, FormGroup, Button } from 'reactstrap';
import { Field, FieldArray, reduxForm, reset } from 'redux-form';
import { Link } from 'react-router-dom';

import { InputField } from '../../components/Field';
import { required } from '../../components/Field/validate';
import { apiConfigHelper } from '../../apiConfig';
import * as gatewayActions from '../../actions/gatewayActions';
import { ColLeft, ColRight, renderSettings } from './utils';
import withRouter from '../../withRouter';

class SettingsForm extends Component {
  state = { message: 'aaa', error: false };
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  submit = async (store) => {
    try {
      const { dispatch } = this.props;
      const settings = {};
      if (store.settings) {
        store.settings.forEach((item) => {
          if (item.name && item.value);
          settings[item.name] = item.value;
        });
      }
      const data = {
        storeId: this.props.currentGatewayStore.storeId,
        settings,
        enabled: store.enabled,
      };

      const storeId = data.storeId;
      await dispatch(gatewayActions.saveStore(storeId, data));
      await dispatch(gatewayActions.getStore(storeId));
      this.setState({
        message: 'Saved successfully',
        error: false,
      });
    } catch (error) {
      this.setState({
        message: 'Failed to save, reason: ' + error.message,
        error: true,
      });
    }

    return false;
  };

  onCancel = () => {
    const { dispatch, currentGatewayStore } = this.props;
    dispatch(reset('gatewaySettingsForm'));
  };

  clearMessage = () => {
    this.setState({
      message: '',
      error: false,
    });
  };

  render() {
    const { message, error } = this.state;
    const { router, handleSubmit, currentGatewayStore, currentStore } = this.props;
    const { integrations } = currentGatewayStore;
    return (
      <>
        <h5>Gateway Settings</h5>
        <hr className="separator" />
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          {message && <Alert color={error ? 'danger' : 'success'}>{message}</Alert>}
          <FormGroup row>
            <ColLeft>Enabled</ColLeft>
            <ColRight>
              <Field component={InputField} type="checkbox" name="enabled" id="enabled" />
            </ColRight>
          </FormGroup>
          <FormGroup row style={{ marginBottom1: 0 }}>
            <ColLeft>Settings</ColLeft>
            <ColRight>
              <FieldArray
                name="settings"
                component={renderSettings}
                warn={(value, allValues, props) => {
                  this.clearMessage();
                }}
              />
            </ColRight>
          </FormGroup>
          <div className="float-end">
            <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div className="mt-3">
            <hr className="separator" />
          </div>

          <FormGroup row>
            <ColLeft>Integrations</ColLeft>
            <ColRight>
              <ul className="list-unstyled" style={{ marginBottom: 2 }}>
                {integrations.map((integration, i) => (
                  <li key={i}>
                    <Link to={`/stores/${currentStore.id}/gateway/integrations/${integration}`}>{integration}</Link>
                  </li>
                ))}
              </ul>
              <div className="float-end">
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    router.navigate(`/stores/${currentStore.id}/gateway/integrations-add`);
                  }}
                >
                  Add Integration
                </Button>
              </div>
            </ColRight>
          </FormGroup>
        </form>
      </>
    );
  }
}

// export default SettingsForm;
export default connect(
  (state) => ({
    // currentGatewayStore: state.gateway.one,
  }),
  (dispatch) => {
    return {};
  }
)(withRouter(reduxForm({ form: 'gatewaySettingsForm', enableReinitialize: true })(SettingsForm)));
