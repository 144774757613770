import React, { Component } from 'react';
import { Button, Col, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ReactFlagsSelect from 'react-flags-select';
import { connect } from 'react-redux';
import * as dataCentreActions from '../../actions/dataCentreActions';
import XMAuditTable from '../../components/XMAuditTable';

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = state => ({
  operator: state.operator,
  erpType: state.erpType,
  dataCentre: state.dataCentre
});

// SETUP REDUX FORM
@reduxForm({
  form: 'dataCentreForm',
  enableReinitialize: true
})
// CONNECT TO REDUX
@connect(mapStateToProps)
class DataCentreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: null
    };
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  submit = dataCentre => {
    const { toggle, dispatch } = this.props;

    if (this.state.countryCode) {
      dataCentre.countryCode = this.state.countryCode;
    }

    dispatch(dataCentreActions.save(dataCentre));
    toggle();
  };

  onSelectFlag(countryCode) {
    this.setState({
      countryCode: countryCode
    });
  }

  onCancel() {
    const { handleCancel, toggle } = this.props;
    handleCancel();
    toggle();
  }

  render() {
    const {
      handleSubmit,
      dataCentre: { one },
      isEdit
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          <FormGroup row>
            <Col md="3">
              <label htmlFor="countryCode">Flag</label>
            </Col>
            <Col md="8" className="align-middle">
              <ReactFlagsSelect
                id={"countryCode"}
                name={"countryCode"}
                rfsKey={"countryCode"}
                selected={one.countryCode ?? this.state.countryCode}
                searchable={true}
                className={"menu-flags"}
                selectButtonClassName={"menu-flags-btn"}
                onSelect={this.onSelectFlag.bind(this)}
              />
            </Col>
          </FormGroup>
          {isEdit ? (
            <FormGroup row>
              <Col md="3">
                <label htmlFor="code">Code</label>
              </Col>
              <Col md="8">
                <Field
                  name="code"
                  component="input"
                  type="text"
                  className="form-control"
                  disabled={true}
                />
              </Col>
            </FormGroup>
          ) : null}
          <FormGroup row>
            <Col md="3">
              <label htmlFor="name">Name</label>
            </Col>
            <Col md="8">
              <Field
                name="name"
                component="input"
                type="text"
                className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="region">Region</label>
            </Col>
            <Col md="8">
              <Field
                name="region"
                component="input"
                type="text"
                className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="dbInstance">DB Instance</label>
            </Col>
            <Col md="8">
              <Field
                  name="dbInstance"
                  component="input"
                  type="text"
                  className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="dbHost">DB Host</label>
            </Col>
            <Col md="8">
              <Field
                  name="dbHost"
                  component="input"
                  type="text"
                  className="form-control"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="dnsRecordSet">DNS Record Set</label>
            </Col>
            <Col md="8">
              <Field
                  name="dnsRecordSet"
                  component="input"
                  type="text"
                  className="form-control"
              />
            </Col>
          </FormGroup>
          <div className="float-end">
            <Button
              type="button"
              color="secondary"
              className="me-2"
              onClick={() => this.onCancel()}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
        <XMAuditTable show={isEdit} audit={one} />
      </div>
    );
  }
}

export default DataCentreForm;
