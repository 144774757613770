import React from 'react';
import { connect } from 'react-redux';
import { InputGroup, Input, Button, Label, FormGroup } from 'reactstrap';

import { changeDiagnosticFilter } from '../../../../actions/synchronisationActions';

import LogContainer from './containers/LogContainer';

function SyncDiagnostics({ currentStore, storedFilter, dispatch }) {
  const [isPause, setPause] = React.useState(false);
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [filter, setFilter] = React.useState(storedFilter);

  let ref = React.useRef();

  function pauseOutput() {
    setPause(!isPause);
  }

  function handleApplyFilter(e) {
    e.preventDefault();
    dispatch(changeDiagnosticFilter(filter));
  }

  return (
    <div className="p-4">
      <div className="d-flex flex-row align-items-center">
        <div className="col-sm-6 col-md-4 col-lg-4">
          <InputGroup>
            <Input
              placeholder="Filter..."
              onChange={e => {
                e.preventDefault();
                setFilter(e.target.value);
              }}
              defaultValue={storedFilter}
            />
              <Button color="primary" onClick={handleApplyFilter}>
                <i className="fa fa-filter"></i>
              </Button>
          </InputGroup>
        </div>

        <div className="col-sm-3 col-md-2 col-lg-2 ps-5">
          <Button color="primary" onClick={pauseOutput}>
            {isPause ? 'Start Output' : 'Pause Output'}
          </Button>
        </div>

        <div className="flex-grow-1"></div>

        <FormGroup switch inline>
          <Input
            type="switch"
            id="autoScroll"
            name="autoScroll"
            checked={autoScroll}
            onChange={event => {
              setAutoScroll(event.target.checked);
            }}
          />
          <Label check>Auto Scroll</Label>
        </FormGroup>
      </div>

      <LogContainer
        ref={ref}
        storeId={currentStore && currentStore.storeId}
        isPause={isPause}
        autoScroll={autoScroll}
      />
    </div>
  );
}

export default connect(state => ({
  currentStore: state.store.one,
  storedFilter: state.sync.ui.diagnostic.filter
}))(SyncDiagnostics);
