const tableInitState = {
  loading: false,
  error: {},
  columns: []
};

export default function targetTableReducer(state = tableInitState, action) {
  switch (action.type) {
    case 'FETCH_TARGET_COLUMNS_START':
      return { ...state, loading: true };

    case 'FETCH_SOURCE_COLUMNS_FAILED': {
      return { ...state, loading: false, error: action.payload.error };
    }

    case 'FETCH_TARGET_COLUMNS_SUCCESS': {
      let { columns } = action.payload;
      columns = Object.keys(columns).map(key => ({ name: key, ...columns[key] }));
      return { ...state, columns };
    }

    default:
      return state;
  }
}
