import React, { Component } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import TimeAgo from 'react-timeago';
import Moment from 'react-moment';

class XMAuditTable extends Component {
  render() {
    const { show, audit } = this.props;

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let createdDate = new Date(audit.createdDate);
    let modifiedDate = new Date(audit.modifiedDate);

    return (
      <div style={{ paddingTop: '3rem' }}>
        {show ? (
          <Card>
            <CardBody>
              <Table hover responsive size="sm" className="animated fadeIn">
                <thead>
                  <tr>
                    <th className="align-middle">Created By</th>
                    <th className="align-middle">When</th>
                    <th className="align-middle">Modified By</th>
                    <th className="align-middle">When</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="align-middle">
                    <td className="align-middle">
                      <span>{audit.createdBy}</span>
                    </td>
                    <td className="align-middle">
                      <Moment format="Do MMM YYYY, HH:mm" withTitle>
                        {createdDate}
                      </Moment>
                    </td>
                    <td className="align-middle">
                      <span>{audit.modifiedBy}</span>
                    </td>
                    <td className="align-middle">
                      {audit.modifiedDate ? (
                        <Moment format="Do MMM YYYY, HH:mm" withTitle>
                          {modifiedDate}
                        </Moment>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        ) : null}
      </div>
    );
  }
}
export default XMAuditTable;
