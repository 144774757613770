import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { InputGroup, Input, Table } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import {
  fetchFailureRequests,
  fetchFailureRequestFailures,
  fetchFailureRequestFailureDetail,
  setFailureRequestFailureDetailCurrentCode,
  actionFailureRequestFailureDetail,
  clearFailureRequestFailureDetailAction,
} from '../../../../actions/synchronisationActions';
import { useState } from 'react';

const DataLoading = ({ message }) => (
  <div className="progress" style={{ clear: 'both' }}>
    <div
      className="progress-bar progress-bar-striped progress-bar-animated"
      role="progressbar"
      style={{ width: '100%' }}
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {message ? message : 'Data Loading...'}
    </div>
  </div>
);
function SyncFailures({
  dispatch,
  currentStore,
  failureRequestCode,
  failureRequests,
  failureRequestsLoading,
  // failureRequestsError,
  failureRequestDetail,
  failureRequestFailureCode,
  failureRequestFailures,
  failureRequestFailuresLoading,
  // failureRequestFailuresError,
  failureRequestFailureDetailCode,
  failureRequestFailureDetail,
  failureRequestFailureDetailLoading,
  // failureRequestFailureDetailError,
  failureRequestFailureDetailActionMessageOpen,
  failureRequestFailureDetailActionLoading,
  failureRequestFailureDetailActionCode,
  failureRequestFailureDetailAction,
  failureRequestFailureDetailActionError,
}) {
  const { state } = useLocation();
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    if (state && state.jumpToOneFailedRequest) {
      dispatch(fetchFailureRequests(currentStore.storeId, state.jumpToOneFailedRequest, true));
    } else if (currentStore && currentStore.storeId) {
      dispatch(fetchFailureRequests(currentStore.storeId, undefined, true));
    }
  }, [state, currentStore]);

  const handleFailureRequestClick = (code) => (e) => {
    dispatch(fetchFailureRequestFailures(currentStore.storeId, code, true));
  };

  const handleFailureRequestFailureClick = (code) => (e) => {
    dispatch(fetchFailureRequestFailureDetail(currentStore.storeId, failureRequestCode, code, true));
  };

  const handleFailureRequestFailureDetailClick = (code) => (e) => {
    dispatch(setFailureRequestFailureDetailCurrentCode(code.toString()));
  };

  const deleteFailureRequestFailureDetail = (failureRequestCode, failureRequestFailureCode, code) => (e) => {
    if (window.confirm('Are you sure to delete the request?')) {
      dispatch(
        actionFailureRequestFailureDetail(
          currentStore.storeId,
          failureRequestCode,
          failureRequestFailureCode,
          'delete',
          code,
          true
        )
      );
    }
  };

  const tryFailureRequestFailureDetail = (failureRequestCode, failureRequestFailureCode, code) => (e) => {
    if (window.confirm('Are you sure to retry the request?')) {
      dispatch(
        actionFailureRequestFailureDetail(
          currentStore.storeId,
          failureRequestCode,
          failureRequestFailureCode,
          'retry',
          code,
          true
        )
      );
    }
  };

  let failureRequestFailureDetailContent = null;
  if (failureRequestFailureDetailCode && failureRequestFailureDetail) {
    failureRequestFailureDetail.map((detail) => {
      if (detail.id === parseInt(failureRequestFailureDetailCode)) {
        failureRequestFailureDetailContent = detail;
      }
    });
  }

  const showFailureRequest = () => {
    if (searchKey.length > 0 && searchKey.length < 4) {
      return false;
    }
    if (failureRequestsLoading) return false;
    return true;
  };

  const onSearch = (searchKey) => {
    setSearchKey(searchKey);
    if (searchKey.length >= 4) {
      dispatch(fetchFailureRequests(currentStore.storeId, searchKey));
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (searchKey.length === 0) {
          dispatch(fetchFailureRequests(currentStore.storeId, undefined, true));
        } else if (failureRequests.length) {
          dispatch(fetchFailureRequestFailures(currentStore.storeId, failureRequests[0], true));
        }
        return false;
      }}
    >
      <div className="p-4">
        <div className="d-flex">
          <div style={{ width: 120 }}>
            <InputGroup>
              <Input
                placeholder="Search..."
                type="text"
                name="request"
                value={searchKey}
                onChange={(evt) => onSearch(evt.target.value)}
              />
            </InputGroup>
            <div className="sync-filters-source-table-div">
              {failureRequestsLoading && (
                <div className="p-3">
                  <DataLoading />
                </div>
              )}
              <Table borderless>
                <tbody>
                  {showFailureRequest() &&
                    failureRequests &&
                    failureRequests.map((requestCode, idx) => (
                      <tr key={idx} className={requestCode === failureRequestCode ? 'table-active' : ''}>
                        <td onClick={handleFailureRequestClick(requestCode)}>{requestCode.substr(0, 8)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div style={{ flex: 1, paddingLeft: '2em' }}>
            {failureRequestFailuresLoading && (
              <div className="p-3">
                <DataLoading message={'Loading Failure Tables....'} />
              </div>
            )}
            {!failureRequestFailuresLoading && failureRequestDetail && (
              <div className="row">
                <div className="col col-3">
                  <div>
                    <strong>Request ID:</strong>
                  </div>
                  <div>{failureRequestCode}</div>
                </div>
                <div className="col col-3">
                  <div>
                    <strong>Request Type:</strong>
                  </div>
                  <div>{failureRequestDetail.requestType}</div>
                </div>
                <div className="col col-3">
                  <div>
                    <strong>Request Stage:</strong>
                  </div>
                  <div>{failureRequestDetail.stage}</div>
                </div>
                <div className="col col-3">
                  <div>
                    <strong>Create Date:</strong>
                  </div>
                  <div>{new Date(failureRequestDetail.created).toLocaleString()}</div>
                </div>
                {failureRequestDetail.error && (
                  <div className="col col-12">
                    <div>
                      <strong>Request Error:</strong>
                    </div>
                    <div>{failureRequestDetail.error}</div>
                  </div>
                )}
              </div>
            )}
            {!failureRequestFailuresLoading && (
              <div className="failureRequestFailure mt-2">
                <table className="table table-sm table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Table</th>
                      <th>Failure category</th>
                      <th style={{ textAlign: 'center' }}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failureRequestFailures &&
                      failureRequestFailures.map((failureRequestFailure) => (
                        <tr
                          key={failureRequestFailure.id}
                          style={{ cursor: 'pointer' }}
                          onClick={handleFailureRequestFailureClick(failureRequestFailure.id)}
                          className={failureRequestFailure.id === failureRequestFailureCode ? 'table-active' : ''}
                        >
                          <td>{failureRequestFailure.table}</td>
                          <td>{failureRequestFailure.category}</td>
                          <td align="center">{failureRequestFailure.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {(failureRequestFailuresLoading || failureRequestFailureDetailLoading) && (
              <div className="p-3">
                <DataLoading message={'Loading Failure Detail....'} />
              </div>
            )}
            {!(failureRequestFailuresLoading || failureRequestFailureDetailLoading) && (
              <div className="failureRequestFailureDetail">
                <table className="table table-sm table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Failure Detail</th>
                      <th style={{ textAlign: 'center' }}>Phase</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failureRequestFailureDetail &&
                      failureRequestFailureDetail.map((detail) => (
                        <tr
                          key={detail.id}
                          style={{ cursor: 'pointer' }}
                          onClick={handleFailureRequestFailureDetailClick(detail.id)}
                          className={detail.id === parseInt(failureRequestFailureDetailCode) ? 'table-active' : ''}
                        >
                          <td>{detail.detail}</td>
                          <td align="center">{detail.stage}</td>
                          <td
                            align="center"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              return false;
                            }}
                          >
                            <a
                              className="text-muted btn btn-link"
                              title="Delete"
                              onClick={deleteFailureRequestFailureDetail(
                                failureRequestCode,
                                failureRequestFailureCode,
                                detail.id
                              )}
                            >
                              <i className="icon-trash"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              className="text-muted btn btn-link"
                              title="Retry"
                              onClick={tryFailureRequestFailureDetail(
                                failureRequestCode,
                                failureRequestFailureCode,
                                detail.id
                              )}
                            >
                              <i className="icon-reload"></i>
                            </a>{' '}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {failureRequestFailureDetailActionMessageOpen &&
              !failureRequestFailureDetailActionLoading &&
              !failureRequestFailureDetailActionError && (
                <div className="mt-2 mb-2">
                  <div className="alert alert-success alert-dismissible fade show" role="alert">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => dispatch(clearFailureRequestFailureDetailAction())}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    Processed Action "{failureRequestFailureDetailAction}" for {failureRequestFailureDetailActionCode}{' '}
                    successfully.
                  </div>
                </div>
              )}
            {failureRequestFailureDetailActionMessageOpen &&
              !failureRequestFailureDetailActionLoading &&
              failureRequestFailureDetailActionError && (
                <div className="mt-2 mb-2">
                  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => dispatch(clearFailureRequestFailureDetailAction())}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    Failed to process Action "{failureRequestFailureDetailAction}" for{' '}
                    {failureRequestFailureDetailActionCode}. Error: {failureRequestFailureDetailActionError}
                  </div>
                </div>
              )}
            {failureRequestFailureDetailContent && (
              <div>
                <table className="table table-sm table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Data Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <pre style={{ margin: 0 }}>
                          {JSON.stringify(failureRequestFailureDetailContent.dataItem, null, 2)}
                        </pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

SyncFailures.propTypes = {
  failureRequests: PropTypes.array,
  storeId: PropTypes.string,
  dispatch: PropTypes.func,
  currentStore: PropTypes.object,
  failureRequestCode: PropTypes.string,
  failureRequestDetail: PropTypes.object,
  failureRequestsLoading: PropTypes.bool,
  failureRequestsError: PropTypes.string,
  failureRequestFailureCode: PropTypes.string,
  failureRequestFailures: PropTypes.array,
  failureRequestFailuresLoading: PropTypes.bool,
  failureRequestFailuresError: PropTypes.string,
  failureRequestFailureDetailCode: PropTypes.string,
  failureRequestFailureDetail: PropTypes.array,
  failureRequestFailureDetailLoading: PropTypes.bool,
  failureRequestFailureDetailError: PropTypes.string,
  failureRequestFailureDetailActionMessageOpen: PropTypes.bool,
  failureRequestFailureDetailActionLoading: PropTypes.bool,
  failureRequestFailureDetailActionCode: PropTypes.string,
  failureRequestFailureDetailAction: PropTypes.string,
  failureRequestFailureDetailActionError: PropTypes.string,
};

export default connect((state) => ({
  currentStore: state.store.one,
  failureRequestCode: state.sync.failure.failureRequestCode,
  failureRequestsLoading: state.sync.failure.loading,
  failureRequestsError: state.sync.failure.error,
  failureRequests: state.sync.failure.failureRequests,
  failureRequestDetail: state.sync.failure.failureRequestDetail,
  failureRequestFailureCode: state.sync.failure.failureRequestFailureCode,
  failureRequestFailuresLoading: state.sync.failure.failureRequestFailuresLoading,
  failureRequestFailuresError: state.sync.failure.failureRequestFailuresError,
  failureRequestFailures: state.sync.failure.failureRequestFailures,
  failureRequestFailureDetailCode: state.sync.failure.failureRequestFailureDetailCode,
  failureRequestFailureDetailLoading: state.sync.failure.failureRequestFailureDetailLoading,
  failureRequestFailureDetailError: state.sync.failure.failureRequestFailureDetailError,
  failureRequestFailureDetail: state.sync.failure.failureRequestFailureDetail,
  failureRequestFailureDetailActionMessageOpen: state.sync.failure.failureRequestFailureDetailActionMessageOpen,
  failureRequestFailureDetailActionLoading: state.sync.failure.failureRequestFailureDetailActionLoading,
  failureRequestFailureDetailActionCode: state.sync.failure.failureRequestFailureDetailActionCode,
  failureRequestFailureDetailAction: state.sync.failure.failureRequestFailureDetailAction,
  failureRequestFailureDetailActionError: state.sync.failure.failureRequestFailureDetailActionError,
}))(SyncFailures);
