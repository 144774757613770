const mappingInitState = {
  loading: false,
  error: {},
  mappings: [],
  filter: {
    override: false
  },
  message: null,
  isError: false,
  resetForm: true
};

export default function mappingReducer(state = mappingInitState, action) {
  switch (action.type) {
    case 'FETCH_MAPPINGS_START':
      return { ...state, loading: true };

    case 'FETCH_MAPPINGS_SUCCESS':
      return { ...state, loading: false, error: {}, mappings: action.payload.mappings };

    case 'FETCH_MAPPINGS_FAILED':
      return { ...state, loading: false, error: action.payload.error };

    case 'FILTER_BY_OVERRIDE':
      return { ...state, filter: { override: action.payload.override } };

    case 'CREATE_MAPPINGS_SUCCESS': {
      return { ...state, message: 'Successfully created mapping', isError: false, resetForm: true };
    }

    case 'CREATE_MAPPINGS_FAILED': {
      let message = action.payload.error.data.message || 'Failed to create mapping';
      return { ...state, message: message, isError: true, resetForm: false };
    }

    case 'SYNC_CLEAR_SOURCE_MESSAGE': {
      return { ...state, message: null, isError: false };
    }

    case 'LOAD_SYNC_MAPPED_DATA_SUCCESS': {
      return {...state, message: 'Successfully sent mappings'};
    }

    case 'LOAD_SYNC_MAPPED_DATA_FAILED': {
      return { ...state, loading: false, error: action.payload.error };
    }

    default:
      return state;
  }
}
