import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';
import _ from 'lodash';

import { capitalizeFirstLetter, formatBytes } from '../../../utils';
import XMTooltipButton from '../../../components/XMTooltipButton';
import { SYNC2_SCHEDULER_NORMAL_STATUS } from '../../../utils/constants';

const StoreTableRow = ({ store, dropdownComponent, btns, expand, onStoreClick, handleEdit, syncStatus }) => {
  function formatDBSize(databaseSize) {
    if (databaseSize !== undefined) {
      if (databaseSize > 1000) {
        const size = databaseSize / 1000;
        return size.toFixed(2) + 'GB';
      } else {
        return databaseSize.toFixed(2) + 'MB';
      }
    }
    return 'Unknown';
  }

  const buttons = _.cloneDeep(btns);
  if (syncStatus && !SYNC2_SCHEDULER_NORMAL_STATUS.includes(syncStatus)) {
    // add warning prop to
    buttons.map((btn) => {
      if (btn.isSync) {
        btn.warning = syncStatus;
      }
    });
  }

  return (
    <React.Fragment>
      <td>
        <Button color="link" onClick={onStoreClick}>
          <i className={expand ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'} />
        </Button>
      </td>
      <td>
        <Button color="link" onClick={() => handleEdit(store.id)}>
          {store.name}
        </Button>
        {store.primaryDomain && (
          <div>
            <Button color="link" href={'https://' + store.primaryDomain} target="_blank">
              <span className="small text-muted">{store.primaryDomain}</span>
            </Button>
          </div>
        )}
      </td>
      <td className="align-middle">
        {formatDBSize(store.databaseSize)}
        <div>
          <span className="small text-muted">{store.databaseInstanceName}</span>
        </div>
      </td>
      <td className="align-middle">{formatBytes(store.cdnSize)}</td>
      <td className="align-middle">{store.contactName}</td>
      <td className="align-middle">
        {store.erpType ? store.erpType.name : null} ({store.erpTypeVersion ? store.erpTypeVersion.name : null})
      </td>
      <td className="align-middle">{capitalizeFirstLetter(store.storeType)}</td>
      <td className="align-middle">{dropdownComponent}</td>
      {btns && (
        <td className="align-middle">
          <ButtonGroup>
            {
              // ADD BTNS COL
              buttons.map((btn, bi) => (
                <XMTooltipButton
                  key={bi}
                  icon={btn.icon}
                  tooltipTitle={btn.tooltip}
                  onClick={() => btn.action(store.id)}
                  displayWarningIcon={btn.warning}
                />
              ))
            }
          </ButtonGroup>
        </td>
      )}
    </React.Fragment>
  );
};

StoreTableRow.propTypes = {
  store: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  dropdownComponent: PropTypes.element.isRequired,
  btns: PropTypes.array,
};

export default StoreTableRow;
