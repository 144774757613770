// ENVIRONMENT REDUCER
const initialState = {
  fetched: false,
  selected: {},
  one: {},
  all: [],
  allCloneable: [],
  pagination: {}
};
export default function environmentReducer(state = initialState, action) {
  switch (action.type) {
    case 'ENVIRONMENT_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        all: action.payload.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
    }
    case 'ENVIRONMENT_SELECT': {
      return {
        ...state,
        selected: action.payload
      };
    }
    case 'ENVIRONMENT_CLEAR': {
      return {
        ...state,
        one: initialState.one
      };
    }
  }
  return state;
}
