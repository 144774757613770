// USER REDUCER
const initialState = {
  selected: null,
  isSuperAdmin: false,
  isAuthenticated: false,
  error: null,
  showEnrollmentSuccessMessage: false,
  mfaSelectedFactorId: null,
  resolver: null
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGIN_SUCCESS': {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case 'USER_SELECT': {
      return {
        ...state,
        selected: action.payload,
        isSuperAdmin: action.payload.role == 'SUPER_ADMIN',
      };
    }
    case "USER_MFA_REQUIRED": {
      return {
        ...state,
        showMfaForm:true,
      };
    }
    case 'USER_LOGIN_FAILURE': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'USER_ENROLMENT_FAILURE': {
      return {
        ...state,
        error: { message: action.payload },
      };
    }
    case 'USER_ENROLMENT_SUCCESS': {
      return {
        ...state,
        showEnrollmentSuccessMessage: true,
        error: null,
      }
    }
    case 'USER_SUBMIT_LOGIN_FORM' : {
      return {
        ...state,
        showMfaForm: false,
        mfaSelectedFactorId: null,
      }
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case 'USER_OTP_REQUEST': {
      return {
        ...state,
        isAuthenticated: false,
        platformVerified: false,
        resolver: action.payload,
      };
    }
    case 'USER_AUTH_SELECTED': {
      return {
        ...state,
        mfaSelectedFactorId: action.payload,
      }
    }
    case 'USER_PLATFORM_VERIFIED': {
      return {
        ...state,
        isAuthenticated: false,
        platformVerified: true,
      };
    }
  }
  return state;
}
