import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { Row, Col, Card, CardBody, CardTitle, ButtonToolbar, ButtonGroup, Label, Input } from 'reactstrap';
import { formatBytes } from '../../utils';

const brandPrimary = '#20a8d8';
const brandSuccess = '#4dbd74';
const brandInfo = '#63c2de';
const brandWarning = '#f8cb00';
const brandDanger = '#f86c6b';

// convert Hex to RGBA
function convertHex(hex, opacity) {
  hex = hex.replace('#', '');
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  var result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  return result;
}

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

const StoreCdnSize = ({ logs, unit, setUnit }) => {
  const [counter, setCounter] = useState(0);
  const refresh = () => {
    setCounter(counter + 1);
  };

  // Get data
  let groupData = [];
  if (logs) {
    for (let i = 0; i < logs.length; i++) {
      let group;
      const cdnSize = logs[i].cdnSize;
      const cdnDate = new Date(logs[i].createdDate);
      const y = cdnDate.getUTCFullYear();
      const m = 1 + cdnDate.getUTCMonth();
      const d = cdnDate.getUTCDate();
      if (unit === 'M') {
        // Month
        group = '' + y + '-' + (m < 10 ? `0${m}` : m);
      } else if (unit === 'W') {
        // Week
        const weekRes = getWeekNumber(cdnDate);
        group = weekRes[0] + '-W' + weekRes[1];
      } else {
        // Day
        group = '' + y + '-' + (m < 10 ? `0${m}` : m) + '-' + (d < 10 ? `0${d}` : d);
      }

      const index = groupData.findIndex((element) => element.group == group);
      if (index === -1) {
        groupData.push({
          group,
          cdnSize,
          order: parseInt(group.replace(/\D/g, '')),
          data: [logs[i]],
        });
      } else {
        groupData[index].cdnSize += cdnSize;
        groupData[index].data.push(logs[i]);
      }
    }
  }

  groupData.sort((a, b) => a.order - b.order);
  const n = unit === 'D' ? 30 : unit === 'W' ? 20 : 12;
  groupData = groupData.slice(-n);

  if (unit !== 'D') {
    // calculate average data
    for (let i = 0; i < groupData.length; i++) {
      groupData[i].cdnSize = parseInt(groupData[i].cdnSize / groupData[i].data.length);
    }
  }

  let groupMax = 0;
  const labels = [],
    data = [];
  for (let i = 0; i < groupData.length; i++) {
    labels.push(groupData[i].group);
    data.push(groupData[i].cdnSize);
    groupMax = Math.max(groupMax, groupData[i].cdnSize);
  }

  const mainChart = {
    labels,
    datasets: [
      {
        label: 'Display ' + (unit === 'D' ? 'Daily' : unit === 'W' ? 'Weekly' : 'Monthly') + ' data',
        backgroundColor: convertHex(brandInfo, 10),
        borderColor: brandInfo,
        pointHoverBackgroundColor: '#fff',
        borderWidth: 2,
        data,
      },
    ],
  };

  const mainChartOpts = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: (label) => {
              return formatBytes(label);
            },
            beginAtZero: true,
            max: groupMax > 100 ? 100 * parseInt((groupMax * 1.2) / 100) : 100,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 2,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
    tooltips: {
      callbacks: {
        label: function (ele) {
          return formatBytes(ele.value);
        },
      },
    },
  };

  return (
    <>
      <Row>
        <Col sm="5">
          <CardTitle className="mb-0" onClick={() => refresh()}>
            CDN Data Size
          </CardTitle>
          <div className="small text-muted">The amount of CDN file data uploaded by users</div>
        </Col>
        <Col sm="7" className="d-none d-sm-inline-block">
          <ButtonToolbar className="float-end" aria-label="Toolbar with button groups">
            <ButtonGroup className="me-3" data-toggle="buttons" aria-label="First group">
              <Label htmlFor="cdnSizeOption1" className={'btn btn-outline-secondary ' + (unit === 'D' ? 'active' : '')}>
                <Input type="radio" id="cdnSizeOption1" checked={unit === 'D'} onClick={() => setUnit('D')} /> Day
              </Label>
              <Label htmlFor="cdnSizeOption2" className={'btn btn-outline-secondary ' + (unit === 'W' ? 'active' : '')}>
                <Input type="radio" id="cdnSizeOption2" checked={unit === 'W'} onClick={() => setUnit('W')} /> Week
              </Label>
              <Label htmlFor="cdnSizeOption3" className={'btn btn-outline-secondary ' + (unit === 'M' ? 'active' : '')}>
                <Input type="radio" id="cdnSizeOption3" checked={unit === 'M'} onClick={() => setUnit('M')} /> Month
              </Label>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 20 + 'px' }}>
        <Line data={mainChart} options={mainChartOpts} height={300} />
      </div>
    </>
  );
};

StoreCdnSize.propTypes = {
  logs: PropTypes.any,
  unit: PropTypes.string,
  setUnit: PropTypes.func,
};

export default StoreCdnSize;
