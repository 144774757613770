import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  saveTableFilter
} from '../../../../../actions/synchronisationActions';

class SaveForm extends Component {
  submit = () => {
    const { saveTableFilter, storeId, tableName, expression, tree } = this.props;
    if (storeId && tableName) {
      saveTableFilter(storeId, tableName, {
        expr: expression,
        tree: tree
      });
    }
  };

  render() {
    return (
      <Button className="float-end" color="primary" type="submit" onClick={this.submit}>
        Save
      </Button>
    );
  }
}

SaveForm.propTypes = {
  storeId: PropTypes.string,
  tableName: PropTypes.string,
  tree: PropTypes.string,
  expression: PropTypes.string
};

export default connect(
  state => ({}),
  dispatch => ({
    saveTableFilter: (storeId, tableName, filter) => dispatch(saveTableFilter(storeId, tableName, filter))
  }))(SaveForm);
