import React, { Component } from 'react';
import { Card, CardBody, Col, ButtonGroup, Button, FormGroup } from 'reactstrap';

import XMRButton from '../../components/XMRButton';
import XMDock from '../../components/XMDock';
import XMModal from '../../components/XMModal';
import XMTable from '../../components/XMTable';
import XMPagination from '../../components/XMPagination';
import XMSearchBox from '../../components/XMSearchBox';

class XMCrud extends Component {
  state = { selected: null, modal: false, modalConfirm: false, dock: false, pagination: {} };

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalConfirm = this.toggleModalConfirm.bind(this);
    this.toggleDock = this.toggleDock.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    // deafult actions
    this.btns = [
      {
        icon: 'icon-note',
        action: this.handleEdit,
        tooltip: 'Edit'
      }
    ];
    if (!this.props.disableDelete) {
      this.btns.push({
        icon: 'icon-trash',
        action: this.handleDelete,
        tooltip: 'Delete'
      });
    }

    // merge default actions with custom actions
    if (this.props.customActions) {
      // handle customAction
      this.btns = this.props.customActions.concat(this.btns);
    }
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleModalConfirm() {
    this.setState({
      modalConfirm: !this.state.modalConfirm
    });
  }

  toggleDock() {
    this.setState({
      dock: !this.state.dock
    });
  }

  handleCancel() {
    const { handleCancel } = this.props;
    this.setState({ selected: null, modal: false, modalConfirm: false, dock: false, pagination: {} }, handleCancel);
  }

  handleCreate() {
    const { handleCreate } = this.props;
    handleCreate();
    this.toggleModal();
  }

  handleEdit(rowKey) {
    const { handleEdit } = this.props;
    this.setState({
      selected: rowKey
    });
    handleEdit(rowKey);
    this.toggleDock();
  }

  handleDelete(rowKey = null) {
    this.props.handleDelete(rowKey);
  }

  handleDeleteConfirm() {
    const { handleDelete } = this.props;
    handleDelete(this.state.selected);
    this.toggleModalConfirm();
  }

  render() {
    const {
      title,
      details,
      loading,
      pagination,
      hasAdd = true,
      handleSearch,
      handleRefresh,
      handlePagination,
      handleCancel,
      handleSort
    } = this.props;
    return (
      <div>
        {this.props.form ? (
          <div>
            <XMModal title={title} isVisible={this.state.modal} handleCancel={handleCancel}>
              {React.cloneElement(this.props.form, {
                isEdit: false,
                handleCancel: handleCancel,
                toggle: this.toggleModal
              })}
            </XMModal>
            <XMDock title={title} isVisible={this.state.dock} handleCancel={this.handleCancel}>
              {React.cloneElement(this.props.form, {
                isEdit: true,
                handleCancel: handleCancel,
                toggle: this.toggleDock
              })}
            </XMDock>
          </div>
        ) : null}
        <Card>
          <CardBody>
            <FormGroup row>
              <Col md="4">
                <XMSearchBox onSearch={handleSearch} />
              </Col>
              <Col md="1">
                <ButtonGroup>
                  {hasAdd ? (
                    <Button color="light" onClick={() => this.handleCreate()}>
                      <i className="icon-plus" />
                    </Button>
                  ) : null}
                  {handleRefresh ? <XMRButton loading={loading} handleRefresh={handleRefresh} /> : null}
                </ButtonGroup>
              </Col>
              {details ? <Col md="7">{details}</Col> : null}
            </FormGroup>
            {this.props.render ? (
              this.props.render()
            ) : (
              <XMTable
                headers={this.props.headers}
                btns={this.btns}
                children={this.props.children}
                handleSort={handleSort}
                autoRenderRow={true}
              />
            )}

            <XMPagination pagination={pagination} paginate={handlePagination} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default XMCrud;
