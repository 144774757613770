const webhookSubscriptionsInitState = {
    loading: false,
    error: {},
    subscriptions: [],
    webhookTypes: [],
};

export default function webhookSubscriptionsReducer(state = webhookSubscriptionsInitState, action) {
    switch (action.type) {
        case 'FETCH_WEBHOOK_SUBSCRIPTIONS_START':
            return { ...state, loading: true };
        case 'FETCH_WEBHOOK_SUBSCRIPTIONS_SUCCESS': {
            let { subscriptions } = action.payload;
            if (subscriptions) {
                return { ...state, loading: false, error: {}, subscriptions };
            }
            return {...state, loading: false};
        }
        case 'FETCH_WEBHOOK_SUBSCRIPTIONS_FAILED': {
            return {...state, loading: false, error: action.payload.error};
        }
        case "DELETE_WEBHOOK_SUBSCRIPTION_SUCCESS": {
            const subscriptions = state.subscriptions.filter(i => i.id !== action.payload.subscriptionId);
            return {...state, loading: false, error: {}, subscriptions};
        }
        case "DELETE_WEBHOOK_SUBSCRIPTION_FAILED": {
            return {...state, loading: false, error: action.payload.error};
        }
        case "FETCH_WEBHOOK_TYPES_SUCCESS": {
            const {webhookTypes} = action.payload;
            return {...state, loading: false, webhookTypes};
        }
        case "CREATE_WEBHOOK_SUBSCRIPTION_SUCCESS": {
            const subscriptionToAdd = action.payload.subscription;
            const subscriptions = [subscriptionToAdd, ...state.subscriptions];
            return {...state, loading: false, error: {},  subscriptions}
        }
        case "CREATE_WEBHOOK_SUBSCRIPTION_FAILED" : {
            return {...state, loading: false, error: action.payload.error};
        }
        default:
            return state;
    }
}
