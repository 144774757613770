import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withRouter from '../../withRouter';
import { apiConfigHelper } from '../../apiConfig';

function MainTabs({ router, path, sources, currentStore }) {
  const { storeId, primaryDomain } = currentStore;
  if (!storeId) {
    return <></>;
  }

  const disableSettings = isEmpty(sources);

  const Tabs = [
    {
      path: 'settings',
      title: 'Gateway Settings',
      processingDisable: true,
    },
    {
      path: 'integrations',
      title: 'Integrations',
      processingDisable: true,
    },
    {
      path: 'logs',
      title: 'Logs',
      processingDisable: true,
    },
  ];

  const checkActive = (urlPath, tabPath) => {
    return urlPath.includes(tabPath);
  };

  const dataCentreUrl = currentStore && currentStore.dataCentre && currentStore.dataCentre.endpoint;
  const gatewayBaseUrl = dataCentreUrl ||  apiConfigHelper.getStoreUrlForGateway();

  return (
    <Nav tabs>
      {Tabs.map((tab) => (
        <NavItem key={tab.path}>
          <NavLink
            className={classnames({ active: checkActive(router.location.pathname, tab.path) })}
            disabled={
              (tab.emptySourceDisable && disableSettings) ||
              // (tab.processingDisable && !!syncStage && syncStage !== 'COMPLETED') ||
              (tab.path != 'settings' && tab.path != 'integrations' && tab.path != 'logs')
            }
            tag={Link}
            to={`${path}/${tab.path}`}
          >
            {tab.title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

MainTabs.propTypes = {
  router: PropTypes.object,
  path: PropTypes.string,
  sources: PropTypes.object,
  currentStore: PropTypes.object,
}

export default connect((state) => ({
  sources: state.sync.source.sources,
}))(withRouter(MainTabs));
