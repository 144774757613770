import syncApi from '../../syncApi';

export function fetchStoresStatus(storeIds) {
  return async (dispatch) => {
    try {
      if (!storeIds) {
        console.log('fetchStoresStatus: invalid storeIds');
        return;
      }
      dispatch({ type: 'FETCH_SYNC_STORES_STATUS_START' });
      let url = `/${storeIds.join(',')}/status`;
      const response = await syncApi.get(url);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_SYNC_STORES_STATUS_SUCCESS', payload: response.data });
      } else {
        throw new Error('incorrect response status - ' + response.status);
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_SYNC_STORES_STATUS_FAILED',
        payload: { error: error && error.message ? error.message : 'UNKNOWN ERROR' },
      });
    }
  };
}

export function fetchStoreStatus(storeId) {
  return async (dispatch) => {
    try {
      if (!storeId) {
        console.log('fetchStoreStatus: invalid storeId');
        return;
      }
      dispatch({ type: 'FETCH_SYNC_STORE_STATUS_START' });
      let url = `/${storeId}/status`;
      const response = await syncApi.get(url);
      if (response.status === 200) {
        dispatch({ type: 'FETCH_SYNC_STORE_STATUS_SUCCESS', payload: response.data });
      } else {
        throw new Error('incorrect response status - ' + response.status);
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_SYNC_STORES_STATU_FAILED',
        payload: { error: error && error.message ? error.message : 'UNKNOWN ERROR' },
      });
    }
  };
}
