import React, { Component } from 'react';
import { FormGroup, Col, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import * as operatorActions from '../../../actions/operatorActions';

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = state => ({
  store: state.store,
  operator: state.operator,
  erpType: state.erpType,
  dataCentre: state.dataCentre
});

// ACTIONS
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      lookupOperator: operatorActions.lookupOperator,
      cleanOperator: operatorActions.cleanOperator
    },
    dispatch
  );
};

// SETUP REDUX FORM
@reduxForm({
  form: 'storeDeleteForm',
  enableReinitialize: true
})
// CONNECT TO REDUX
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class StoreDeleteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  submit = form => {
    const { handleDelete, toggle } = this.props;
    const { password } = form;

    //XXX hardcode
    if (password == 'everythingisawesome') {
      handleDelete();
      toggle();
      this.setState({
        error: false
      });
    } else {
      this.setState({
        error: true
      });
    }
  };

  onCancel() {
    const { handleCancel, toggle } = this.props;
    handleCancel();
    toggle();
  }

  render() {
    const { handleSubmit } = this.props;
    const { error } = this.state;
    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
          <FormGroup row>
            <Col md="12">
              {error ? <div className="alert alert-danger mt-1 mb-0 p-1"> Wrong password! </div> : null}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <label htmlFor="password">Password</label>
            </Col>
            <Col md="9">
              <Field name="password" component="input" type="password" className="form-control" />
            </Col>
          </FormGroup>
          <div className="float-end">
            <Button type="button" color="secondary" className="me-2" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Confirm
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default StoreDeleteForm;
