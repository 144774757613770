import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, FormGroup} from "reactstrap";
import {
    getAuth,
    multiFactor, PhoneAuthProvider,
    PhoneMultiFactorGenerator, RecaptchaVerifier,
    sendEmailVerification,
    TotpMultiFactorGenerator
} from "firebase/auth";
import {envName, isProduction} from "../../../utils/environment";
import QRCode from "qrcode";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import 'react-phone-number-input/style.css'

function MFAEnrollmentForm({dispatch, user}) {

    const [showVerification, setShowVerification] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [enrolledTotp, setEnrolledTotp] = useState(false);
    const [totpSecret, setTotpSecret] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationId, setVerificationId] = useState(null);
    const [otp, setOtp] = useState("");
    const [smsCode, setSmsCode] = useState("");

    useEffect(() => {
        enrolTotp();
    }, [])

    const enrolTotp = () => {
        if (getAuth().currentUser == null) {
            return;
        }

        multiFactor(getAuth().currentUser)
            .getSession()
            .then((multiFactorSession) => {
                TotpMultiFactorGenerator.generateSecret(multiFactorSession)
                    .then((ts) => {
                        setTotpSecret(ts);
                        const totpIssuerName = isProduction() ? 'CB Store Manager' : 'CB Store Manager-' + envName().substring(0, 3);
                        const totpUri = ts.generateQrCodeUrl(getAuth().currentUser.email, totpIssuerName);
                        setShowVerification(true);
                        setInitialized(true);
                        setTimeout(() => {
                            QRCode.toCanvas(canvas, totpUri)
                                .then((res) => {

                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }, 0)

                    })
                    .catch((e) => {
                        if (e.code == 'auth/unverified-email') {
                            sendEmailVerification(getAuth().currentUser, {url: window.location.href});
                            setInitialized(true);
                        } else if (e.code == 'auth/maximum-second-factor-count-exceeded') {
                            setEnrolledTotp(true);
                            setInitialized(true);
                            setShowVerification(true);
                        } else if (e.code == 'auth/requires-recent-login') {
                            dispatch({
                                type: 'USER_ENROLMENT_FAILURE',
                                payload: 'Re-authentication required to be enrolled in 2FA.',
                            });
                        } else {
                            dispatch({
                                type: 'USER_ENROLMENT_FAILURE',
                                payload: e.message,
                            });
                        }
                    });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const enrolPhone = () => {
        const recaptchaVerifier = new RecaptchaVerifier(getAuth(), 'enrol-recaptcha-container');
        multiFactor(getAuth().currentUser).getSession()
            .then(function (multiFactorSession) {
                // Specify the phone number and pass the MFA session.
                const phoneInfoOptions = {
                    phoneNumber: phoneNumber,
                    session: multiFactorSession
                };

                const phoneAuthProvider = new PhoneAuthProvider(getAuth());

                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
            }).then(function (verificationId) {
            setVerificationId(verificationId);

        });
    }

    const verifyTotp2FA = () => {
        if (otp == '') {
            dispatch({
                type: 'USER_ENROLMENT_FAILURE',
                payload: 'Verification Code is required.',
            });
            return;
        }
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, otp);
        multiFactor(getAuth().currentUser)
            .enroll(multiFactorAssertion, getAuth().currentUser.displayName)
            .then((res) => {
                setEnrolledTotp(true);
            })
            .catch((e) => {
                var errMsg = e.message;
                if (e.code == 'auth/invalid-verification-code') {
                    errMsg = 'Your 2FA Verification Code is incorrect or has expired';
                }
                dispatch({
                    type: 'USER_ENROLMENT_FAILURE',
                    payload: errMsg,
                });
            });
    }

    const verifyPhone2FA = () => {
        if (smsCode == '') {
            dispatch({
                type: 'USER_ENROLMENT_FAILURE',
                payload: 'Verification Code is required.',
            });
            return;
        }
        const cred = PhoneAuthProvider.credential(
            verificationId, smsCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        multiFactor(getAuth().currentUser)
            .enroll(multiFactorAssertion, getAuth().currentUser.displayName)
            .then((res) => {
                dispatch({
                    type: 'USER_ENROLMENT_SUCCESS',
                });
            })
            .catch((e) => {
                var errMsg = e.message;
                if (e.code == 'auth/invalid-verification-code') {
                    errMsg = 'Your 2FA Verification Code is incorrect or has expired';
                }
                dispatch({
                    type: 'USER_ENROLMENT_FAILURE',
                    payload: errMsg,
                });
            });
    }


    return !initialized ? null : <div style={{paddingTop: '1.5rem'}}>
        {user.showEnrollmentSuccessMessage ?
            <Alert color="success">Successfully enrolled for 2FA, please log in.</Alert> : (
                <>
                    <h4 style={{paddingTop: '0.5rem'}}>Set Up Two-Factor Authentication</h4>
                    <FormGroup row>
                        {!showVerification ? <div>
                            <h5>Step 1: Verify your email</h5>
                            <p className="mb-2">A verification email has been sent to your inbox. Please click the link
                                in the email to
                                proceed.</p>
                            <p>Didn't receive the email?</p>
                            <Button type="button" color="primary" className="me-2" onClick={() => enrolTotp()}>
                                Resend email
                            </Button>
                        </div> : !enrolledTotp ? <div>
                            <h5 style={{paddingTop: '0.5rem'}}>Step 2: Set Up TOTP</h5>
                            <p className="mb-2">Scan this QR code below using your authenticator app (e.g. Google
                                Authenticator or
                                Authy).</p>
                            <p>Once scanned, enter the 6-digit code generated by the app to continue.</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <label className={"me-2"}>Enter code</label>
                                <input id="otp" className={"me-2"} onChange={(e) => setOtp(e.target.value)}/>
                                <Button type="button" color="primary" className="me-2"
                                        onClick={() => verifyTotp2FA(otp)}>
                                    Verify Code
                                </Button>
                            </div>
                        </div> : <div>
                            <h5 style={{paddingTop: '0.5rem'}}>Step 3: Phone Number Verification</h5>
                            <p className="mb-2">Please enter your phone number. A verification code will be sent via
                                SMS.</p>
                            <PhoneInputWithCountrySelect placeholder="Enter phone number" value={phoneNumber}
                                                         className="mb-4"
                                                         onChange={setPhoneNumber}/>
                            <Button type="button" color="primary" className="px-4 mb-4"
                                    disabled={phoneNumber === "" || verificationId !== null}
                                    onClick={() => enrolPhone()}>
                                Next
                            </Button>
                            {verificationId === null ? (<div id="enrol-recaptcha-container" key="1"
                                                             data-sitekey="6LcMZR0UAAAAALgPMcgHwga7gY5p8QMg1Hj-bmUv"
                            >
                            </div>) : (<div key="2">
                                <label className={"me-2"}>Enter code</label>
                                <input id="smsCode" className={"me-2"} onChange={(e) => setSmsCode(e.target.value)}/>
                                <Button type="button" color="primary" className="me-2" onClick={() => verifyPhone2FA()}>
                                    Verify Code
                                </Button></div>)}
                        </div>}
                    </FormGroup>
                    {showVerification && !enrolledTotp ? (
                        <FormGroup row>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <canvas id="canvas"></canvas>
                            </div>
                        </FormGroup>) : null}
                </>)}
    </div>;
}

export default reduxForm({
    form: 'MFAEnrollmentFormForm',
    enableReinitialize: true,
})(
    connect((state) => ({
        user: state.user,
    }))(MFAEnrollmentForm)
);
