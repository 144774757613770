import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody, Card, CardBody, ButtonGroup } from 'reactstrap';
import withRouter from '../../withRouter';
import * as storeActions from '../../actions/storeActions';
import CdnStats from './CdnStats';

function StoreStats({ dispatch, router, storeOne }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStore, setCurrentStore] = useState(null);

  const getStoreName = () => {
    return (currentStore && currentStore.name) || '';
  };

  useEffect(() => {
    const { storeId } = router.params;
    if (!storeId) {
      setErrorMessage('No Invalid StoreId');
      return;
    }

    if (storeOne && storeId == storeOne.id) {
      setCurrentStore(storeOne);
      return;
    }

    setErrorMessage('');
    setCurrentStore(null);
    dispatch(storeActions.getOne(storeId));
  }, [router.params.storeId, storeOne]);

  return (
    <div className="animated fadeIn">
      {errorMessage && (
        <div>
          Failed to get CDN logs for store {getStoreName()}, reason: {errorMessage}{' '}
        </div>
      )}
      {!errorMessage && (
        <>
          <CdnStats store={currentStore} />
        </>
      )}
    </div>
  );
}

StoreStats.propTypes = {
  storeOne: PropTypes.object,
  currentStore: PropTypes.object,
  dispatch: PropTypes.func,
  router: PropTypes.object,
};

export default withRouter(
  connect((state) => ({
    storeOne: state.store.one,
    // erpType: state.erpType,
    // operator: state.operator,
    // dataCentre: state.dataCentre,
  }))(StoreStats)
);
