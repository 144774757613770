import React from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';

import { capitalizeFirstLetter } from '../../../utils';

const StoreDetails = ({ store, version }) => {
  return (
    <Row>
      <Col md="6">
        <span className="text-secondary">Total: </span>
        <span className="text-primary">{store.pagination.totalElements}</span>
        {store.storeTypeCount.map((stc, key) => (
          <span key={key}>
            <span className="text-secondary ms-3">{capitalizeFirstLetter(stc.storeType)}: </span>
            <span className="text-primary">{stc.count}</span>
          </span>
        ))}
      </Col>
      <Col md="2">
        <span className="text-secondary">Version: </span>
        <span className="text-primary">{version.current.name}</span>
      </Col>
      <Col md="4">
        <span className="text-secondary">Release Date: </span>
        <span className="text-primary">
          <Moment format="Do MMM YYYY, HH:mm" withTitle>
            {version.current.release}
          </Moment>
        </span>
      </Col>
    </Row>
  );
};

export default StoreDetails;
