const initialState = {
  id: null,
  created: null,
  stage: null,
  type: null,
  percentage: 0,
  description: null
};

export default function requestReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_SYNC_REQUEST_STATUS_START':
      return initialState;

    case 'FETCH_SYNC_REQUEST_STATUS_SUCCESS':
    case 'LOAD_SYNC_STORE_DATA_SUCCESS':
    case 'LOAD_SYNC_MAPPED_DATA_SUCCESS':
      return { ...state, ...action.payload.status };

    case 'FETCH_SYNC_REQUEST_SUCCESS':
      const percentage = calcPercentage(action.payload.status);
      const description = formatDescription(action.payload.status, percentage);
      if (action.payload.status.stage === 'COMPLETED') {
        return { ...initialState, stage: 'COMPLETED', description };
      }
      return { ...state, ...action.payload.status, percentage, description };

    case 'SYNC_CLEAR_ALL':
      return initialState;
  }

  return state;
}

function calcPercentage({ stageProgress: { currentIndex, endIndex } = {} }) {
  let percentage = 0;
  if (endIndex !== 0) {
    percentage = Math.floor((currentIndex * 100) / endIndex);
  }
  return percentage;
}

function formatDescription({ stage, stageProgress: { checkpoint } = {} }, percentage) {
  switch (stage) {
    case 'EXTRACTING':
      return `${stage} ${checkpoint} ...`;

    case 'LOADING':
    case 'TRANSFERRING':
      return `${stage} COMPLETE ${percentage}% ...`;

    default:
      return stage;
  }
}
