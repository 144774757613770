import React from 'react';
import { components } from 'react-select';

const StoreOption = ({ data, ...rest }) => {
  return (
    <components.Option {...rest}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <span>{data.label}</span>
        <span style={{ fontSize: '0.8em', color: 'grey' }}>{data.link}</span>
      </div>
    </components.Option>
  );
};

export default StoreOption;
