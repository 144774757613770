import React from 'react';

function ListDetailsPretty({ title, data }) {
  return (
    <>
      <tr>
        <td>
          <b className="text-primary">{title}</b>
        </td>
        <td colSpan={6}>
          <pre style={{ whiteSpace: 'break-spaces' }}>{JSON.stringify(data, null, 2)}</pre>
        </td>
      </tr>
    </>
  );
}

export default ListDetailsPretty;
