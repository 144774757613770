const initialState = {
  fetched: false,
  loading: false,
  selected: {},
  all: [],
  pagination: {},
  error: null
};
export default function clientCredentialReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLIENT_CREDENTIALS_LOADING': {
      return {
        ...state,
        loading: true
      };
    }
    case 'CLIENT_CREDENTIALS_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        loading: false,
        all: action.payload.clientCredentials,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
    }
    case 'CLIENT_CREDENTIALS_REGISTER_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'CLIENT_CREDENTIALS_REGISTER_CLEAR_ERROR':
      return {
        ...state,
        error: null
      };
  }
  return state;
}
