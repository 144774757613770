import React, { Component } from 'react';
import { Alert, Col, FormGroup, Button } from 'reactstrap';
import { Field } from 'redux-form';
import { InputField } from '../../components/Field';

export const integrationInitialValues = {
  enabled: true,
  integration: '',
  interface: '',
  connection: {
    type: 'HTTP',
    uri: '',
    clientCertificate: '',
    trustCertificate: '',
    authType: 'NONE',
    username: '',
    password: '',
    accessOperation: '',
    accessToken: '',
    refreshOperation: '',
    refreshToken: '',
  },
};

export const integrationOperationInitialValues = {
  enabled: true,
  operation: '',
  interfaceType: 'HTTP',
  settings: [],
  request: {
    inputContentType: '*/*',
    outputContentType: '*/*',
    method: 'GET',
    path: '',
    transforms: [
      // // test
      // {
      //   description: '',
      //   type: 'RAW',
      //   inputContentType: '*/*',
      //   value: 'transform1 value',
      //   outputContentType: '*/*',
      // },
    ],
    parameters: [],
    headers: [],
  },
  response: {
    inputContentType: '*/*',
    outputContentType: '*/*',
    method: 'GET',
    path: '',
    transforms: [],
    parameters: [],
    headers: [],
  },
};

export const inputContentTypeList = [
  '*/*',
  'text/plain',
  'text/xml',
  'application/json',
  'application/x-www-form-urlencoded',
];

// ------------------------------------------------------------

export const arrToObjForFieldArr = (arr = []) => {
  if (!Array.isArray(arr)) return {};
  const obj = {};
  arr.forEach((item) => {
    obj[item.name] = item.value;
  });
  return obj;
};

export const objToArrForFieldArr = (obj = {}) => {
  const arr = [];
  Object.keys(obj).forEach((name) => {
    arr.push({ name, value: obj[name] });
  });
  return arr;
};

export const renderSetting = (setting, index, fields) => (
  <div style={{ width: '100%', position: 'relative' }} key={index}>
    <div style={{ display: 'table', width: '100%', height: 40 }}>
      <div style={{ display: 'table-cell', width: 150, paddingRight: 5 }}>
        <Field
          name={`${setting}.name`}
          component="input"
          placeholder="Name"
          type="text"
          className="form-control"
          required={true}
        />
      </div>
      <div style={{ display: 'table-cell' }}>
        <Field
          name={`${setting}.value`}
          placeholder="Value"
          type="text"
          component="input"
          label="Value"
          className="form-control"
          required={true}
        />
      </div>
    </div>
    <div style={{ position: 'absolute', top: 8, right: -20 }}>
      <i
        className="icon-minus"
        onClick={() => fields.remove(index)}
        style={{ cursor: 'pointer' }}
        title="Remove Setting"
      />
    </div>
  </div>
);

export const renderSettings = ({ fields, meta: { error, submitFailed } }) => {
  return (
    <>
      {fields.map(renderSetting)}
      <FormGroup row>
        <Col md="12">
          <div className="float-end" style={{ paddingLeft: 5 }}>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={() => {
                fields.push({});
              }}
            >
              Add Setting
            </button>
          </div>
        </Col>
      </FormGroup>
    </>
  );
};

export const renderTransforms = ({ fields, meta: { error, submitFailed } }) => {
  return (
    <>
      <FormGroup row>
        <ColLeft>Transform</ColLeft>
        <ColRight>
          {fields.map(renderTransform)}{' '}
          <div className="float-end">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                fields.push({
                  discription: '',
                  type: 'RAW',
                  inputContentType: '*/*',
                  value: '',
                  outputContentType: '*/*',
                });
              }}
            >
              Add Transform
            </button>
          </div>
        </ColRight>
      </FormGroup>
    </>
  );
};

export const renderTransform = (transform, index, fields) => (
  <div key={index} style={{ position: 'relative' }}>
    <div style={{ position: 'absolute', top: 8, right: -20 }}>
      <i
        className="icon-minus"
        onClick={() => fields.remove(index)}
        style={{ cursor: 'pointer' }}
        title="Remove Transform"
      />
    </div>
    <table style={{ width: '100%' }}>
      <tbody>
        <tr style={{ height: 40 }}>
          <td valign="top" width={100}>
            <div style={{ paddingTop: 8 }}>Description</div>
          </td>
          <td>
            <Field component={InputField} name={`${transform}.description`} id={`${transform}.description`} />
          </td>
        </tr>
        <tr style={{ height: 40 }}>
          <td valign="top">
            <div style={{ paddingTop: 8 }}>Type</div>
          </td>
          <td>
            <Field component="select" className="form-control" name={`${transform}.type`} id={`${transform}.type`}>
              {['RAW', 'AUTO', 'JAVASCRIPT', 'XSLT', 'JOLT', 'THYMELEAF', 'HANDLEBARS'].map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Field>
          </td>
        </tr>
        <tr style={{ height: 40 }}>
          <td valign="top">
            <div style={{ paddingTop: 8 }}>Input</div>
          </td>
          <td>
            <Field
              component="select"
              required
              className="form-control"
              name={`${transform}.inputContentType`}
              id={`${transform}.inputContentType`}
            >
              {inputContentTypeList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Field>
          </td>
        </tr>
        <tr style={{ height: 40 }}>
          <td valign="top">
            <div style={{ paddingTop: 8 }}>Transform</div>
          </td>
          <td>
            <Field
              component="textarea"
              className="form-control"
              name={`${transform}.value`}
              id={`${transform}.value`}
              style={{ minHeight: 150 }}
            />
          </td>
        </tr>
        <tr style={{ height: 40 }}>
          <td valign="top">
            <div style={{ paddingTop: 8 }}>Output</div>
          </td>
          <td>
            <Field
              component="select"
              required
              className="form-control"
              name={`${transform}.outputContentType`}
              id={`${transform}.outputContentType`}
            >
              {inputContentTypeList.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Field>
          </td>
        </tr>
      </tbody>
    </table>
    <div style={{ marginTop: 10 }}>
      <hr size={1} />
    </div>
  </div>
);

export const renderParameters = ({ fields, title, meta: { error, submitFailed } }) => {
  return (
    <>
      <FormGroup row>
        <ColLeft>Send {title}</ColLeft>
        <ColRight>
          {fields.map(renderParameter)}{' '}
          <div className="float-end">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                fields.push({});
              }}
            >
              Add {title}
            </button>
          </div>
        </ColRight>
      </FormGroup>
    </>
  );
};

export const renderParameter = (setting, index, fields) => (
  <div style={{ width: '100%', position: 'relative' }} key={index}>
    <div style={{ display: 'table', width: '100%', height: 40 }}>
      <div style={{ display: 'table-cell', width: 150 }}>
        <Field
          name={`${setting}.name`}
          component="input"
          placeholder="Name"
          type="text"
          className="form-control"
          required={true}
        />
      </div>
      <div style={{ display: 'table-cell', width: 20, textAlign: 'center' }}>=</div>
      <div style={{ display: 'table-cell' }}>
        <Field
          name={`${setting}.value`}
          placeholder="Value"
          type="text"
          component="input"
          label="Value"
          className="form-control"
          required={true}
        />
      </div>
    </div>
    <div style={{ position: 'absolute', top: 8, right: -20 }}>
      <i
        className="icon-minus"
        onClick={() => fields.remove(index)}
        style={{ cursor: 'pointer' }}
        title="Remove Item"
      />
    </div>
  </div>
);

export const ColLeft = ({ children, ...props }) => (
  <Col sm={3} lg={2} xl={2} {...props}>
    {children}
  </Col>
);

export const ColRight = ({ children, ...props }) => (
  <Col sm={9} lg={10} xl={10} {...props}>
    {children}
  </Col>
);
