import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { Input, Label, FormGroup } from 'reactstrap';

const ErrorMessage = ({ error }) => {
  return (
    <div style={{ fontSize: '0.6em' }} className="mt-1 text-danger">
      {error}
    </div>
  );
};

export const renderInputField = ({ input, disabled, type, meta: { touched, error }, ...rest }) => (
  <div>
    <input
      {...input}
      type={type}
      disabled={disabled}
      {...rest}
      className={'form-control ' + (touched && (error ? 'border border-danger' : ''))}
    />
    {touched && error && <ErrorMessage error={error} />}
  </div>
);

export const SelectField = ({ input, options, isMulti, style, disabled, ...rest }) => {
  if (options) {
    for (let i = 0; i < options.length; i++) {
      options[i] = {
        ...options[i],
        label: options[i].name,
        value: options[i].id
      };
    }
  }

  return (
    <Select
      isDisabled={disabled}
      {...input}
      {...rest}
      style={style}
      options={options}
      isMulti={isMulti}
      onBlur={() => {}}
    />
  );
};

export function CheckboxGroup({ input, options, meta: { touched, error }, disabled }) {
  return (
    <React.Fragment>
      <div className=" d-flex flex-row">
        {options.map((option, idx) => {
          return (
            <FormGroup check inline key={idx} className="test">
              <Input
                key={idx}
                name={`${input.name}[${idx}]`}
                className={idx === 0 ? '' : 'ms-3'}
                id={option.label}
                label={option.label}
                type="checkbox"
                checked={input.value && input.value.indexOf(option.name) !== -1}
                disabled={disabled}
                onChange={event => {
                  const newValue = [...input.value];
                  if (event.target.checked) {
                    newValue.push(option.name);
                  } else {
                    newValue.splice(newValue.indexOf(option.name), 1);
                  }
                  input.onBlur(newValue);
                  return input.onChange(newValue);
                }}
              />
              <Label check>
                {option.label}
              </Label>
              {' '}
            </FormGroup>
          );
        })}
      </div>
      {touched && error && <ErrorMessage error={error} />}
    </React.Fragment>
  );
}

export function Switch({ input, ...rest }) {
  return <FormGroup switch><Input {...input} {...rest} type="switch" role="switch" onChange={e => input.onChange(e.target.checked)} /></FormGroup>;
}

export function InputField({ input, className, meta: { touched, error }, ...rest }) {
  return (
    <div className={classnames(className, 'd-flex flex-column')}>
      <Input {...input} {...rest} className={classnames({ 'border border-danger': touched && error })} />
      {touched && error && <ErrorMessage error={error} />}
    </div>
  );
}

export function NativeSelect({ input, options, meta: { touched, error }, ...rest }) {
  return (
    <div className='d-flex flex-column'>
      <Input type="select" {...input} {...rest}>
        <option disabled />
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </Input>
      {touched && error && <ErrorMessage error={error} />}
    </div>
  );
}

export function RadioButtons({ input, options, meta: { touched, error }, ...rest }) {
  return (
    <FormGroup check>
      {options &&
        options.map(option => {
          return (
          <Label check className="btn btn-outline" key={option.value}>
            <Input type="radio" {...input} {...rest} value={option.value} 
            onChange={() => { rest.handler.handleSyncVersionClick(option.value);} }
            className={classnames({ 'border border-danger': touched && error })} 
            checked={ rest.selected == option.value}
            />{' '}
            {option.name}
          </Label>
      );})}
      {touched && error && <ErrorMessage error={error} />}
    </FormGroup>
  );
}