import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import StoreForm from './StoreForm';
import XMCrud from '../../components/XMCrud';
import XMModalLog from '../../components/XMModalLog';
import XMModalConfirm from '../../components/XMModalConfirm';
import XMTable from '../../components/XMTable';
import XMDock from '../../components/XMDock';
import * as storeActions from '../../actions/storeActions';
import * as erpTypeActions from '../../actions/erpTypeActions';
import * as versionActions from '../../actions/versionActions';
import * as synchronisationActions from '../../actions/synchronisationActions';
import StoreStatusDropdown from './components/StoreStatusDropdown';
import StoreDeletionModal from './components/StoreDeletionModal';
import StoreTableRow from './components/StoreTableRow';
import StoreDetails from './components/StoreDetails';
import StoreDetailsRow from './components/StoreDetailsRow';
import { isDevelopment } from '../../utils/environment';
import withRouter from '../../withRouter';

const Headers = [
  {},
  { name: 'Store', code: 'name', sortable: true },
  { name: 'DB', code: 'databaseSize', sortable: true, style: { minWidth: 80 } },
  { name: 'CDN', code: 'cdnSize', sortable: true, style: { minWidth: 80 } },
  { name: 'Contact', code: 'contactEmail', sortable: true, style: { minWidth: 100 } },
  { name: 'ERP', code: 'erpTypeVersion', sortable: true, style: { minWidth: 80 } },
  { name: 'Type', code: 'storeType', sortable: true, style: { minWidth: 80 } },
  { name: 'Status', code: 'storeStatuses.statusType', sortable: false }, //XXX: storeStatuses is the field name in Store.java
  { name: 'Actions', style: { width: 280 } },
];

@connect((state) => {
  return {
    user: state.user,
    version: state.version,
    dataCentre: state.dataCentre,
    store: state.store,
    operator: state.operator,
    syncStoresStatus: state.sync.status.syncStoresStatus,
  };
})
class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      logger: false,
      confirmModal: false,
      deleteModal: false,
      pagination: {
        page: undefined,
        size: undefined,
        sort: undefined,
        sortDir: undefined,
      },
      search: null,
      expandId: null,
      dock: false,
    };

    this.btns = [
      {
        icon: 'icon-chart',
        action: this.handleStats,
        tooltip: 'Stats',
      },
      {
        icon: 'icon-cloud-download',
        action: this.handleBackup,
        tooltip: 'Backup',
      },
      {
        icon: 'icon-note',
        action: this.handleEdit,
        tooltip: 'Edit',
      },
      {
        icon: 'icon-loop',
        action: this.handleSync,
        tooltip: 'Sync',
        isSync: true,
      },
      {
        icon: 'icon-lock',
        action: this.handleClientCredentials,
        tooltip: 'Client Credentials',
      },
    ];

    if (this.props.user.isSuperAdmin) {
      this.btns.push({
        icon: 'icon-trash',
        action: this.toggleConfirmModal,
        tooltip: 'Delete',
      });
    }
  }

  componentDidMount() {
    const { dispatch, dataCentre } = this.props;

    if (!_.isEmpty(dataCentre.selected)) {
      this.loadStores();
      this.loadStoreTypeCount();
    }

    dispatch(versionActions.getAll());
  }

  /**
   * Check if there is any "store creating" when the component is updated,
   * if so re-load till the status changes
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      store: { all },
    } = this.props;

    let preappendGatewayBtn = true;

    this.btns.forEach((btn) => {
      if (btn.icon == 'icon-puzzle') {
        preappendGatewayBtn = false;
        return;
      }
    });

    if (preappendGatewayBtn) {
      this.btns.splice(-1, 0, {
        icon: 'icon-puzzle',
        action: this.handleGateway,
        tooltip: 'API Gateways',
      });
    }

    if (all !== prevProps.store.all) {
      // any store with CREATING status?
      let storeCreating = _.find(all, (s) => {
        return this.isWaiting(s.storeStatus);
      });

      // TODO: move the "update time" (10s) to a Settings property?
      if (storeCreating && !this.timerId) {
        this.timerId = _.delay(this.loadStores, 10000);
      }

      // check sync2 store status
      this.loadStoresSync2Status();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  loadStores = () => {
    this.clearTimer();
    const { dispatch, dataCentre } = this.props;
    dispatch(
      storeActions.getAllByDataCentre(
        dataCentre.selected,
        this.state.pagination.page,
        this.state.pagination.size,
        this.state.pagination.sort,
        this.state.pagination.sortDir,
        this.state.search
      )
    );
  };

  loadStoresSync2Status = () => {
    const { dispatch, store } = this.props;
    const storeIds = [];
    if (store && store.all && store.all.length) {
      store.all.map((s) => (this.isSync2(s) ? storeIds.push(s.storeId) : null));
    }
    if (storeIds.length) {
      dispatch(synchronisationActions.fetchStoresStatus(storeIds));
    }
  };

  loadStoreTypeCount = () => {
    const { dispatch, dataCentre } = this.props;
    dispatch(storeActions.getStoreTypeCount(dataCentre.selected));
  };

  /**
   * Waiting actions, eg: creating, deleting, etc.
   * @param {*} store
   */
  isWaiting = (storeStatus) => {
    return storeStatus.statusType ? storeStatus.statusType.endsWith('ING') : false;
  };

  isSync2 = (store) => {
    return store.syncVersion === 'SYNC_2';
  };

  handlePagination = (page) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...state.pagination,
          page: page,
        },
      }),
      this.loadStores
    );
  };

  handleSearch = (search) => {
    this.setState({ pagination: {}, search: search }, this.loadStores);
  };

  handleSort = (sort, sortDir) => {
    this.setState(
      (state) => ({
        ...state,
        pagination: {
          ...state.pagination,
          sort: sort,
          sortDir: sortDir,
        },
      }),
      this.loadStores
    );
  };

  handleStats = (storeId) => {
    this.props.router.navigate(`/stores/${storeId}/stats`);
  };

  handleBackup = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId)).then(() => this.props.router.navigate(`/stores/${storeId}/backup`));
  };

  handleSync = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId)).then(() => this.props.router.navigate(`/stores/${storeId}/synchronization`));
  };

  handleClientCredentials = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId)).then(() => this.props.router.navigate(`/stores/${storeId}/credentials`));
  };

  handleGateway = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId)).then(() => this.props.router.navigate(`/stores/${storeId}/gateway`));
  };

  updateStoreStatus = (store, statusType, callback) => {
    if (typeof callback === 'undefined') {
      callback = () => {};
    }
    const { dispatch } = this.props;
    const storeStatus = { statusType: statusType };
    dispatch(storeActions.updateStoreStatus(store, storeStatus)).then(callback);
  };

  // CRUD Actions
  onCreate = () => {
    const { dispatch } = this.props;
    dispatch(erpTypeActions.getAll());
  };

  handleEdit = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId));
    this.setState({ dock: true });
  };

  onEdit = (storeId) => {
    const { dispatch } = this.props;
    dispatch(storeActions.getOne(storeId));
    dispatch(erpTypeActions.getAll());
    this.setState({ dock: false });
  };

  onDelete = () => {
    const { store } = this.props;
    const deletingStore = store.all.find((s) => s.id === this.state.selected);
    this.updateStoreStatus(deletingStore, 'DELETE', () => {
      this.setState({ confirmModal: false });
    });
  };

  toggleConfirmModal = (storeId) => {
    this.setState((state) => ({ ...state, selected: storeId, confirmModal: !state.confirmModal }));
  };

  onDeleteConfirm = () => {
    const {
      user: { isSuperAdmin },
    } = this.props;

    this.setState(isSuperAdmin ? this.onDelete : this.toggleDeleteModal);
  };

  onCancel = () => {
    const { dispatch } = this.props;
    dispatch(storeActions.clear());
    this.setState({ dock: false });
  };

  logger = (store) => {
    const { dispatch } = this.props;
    if (this.state.logger && this.isWaiting(store.storeStatus)) {
      _.delay(() => {
        dispatch(storeActions.getOne(store.id))
          .then(() => this.logger(store))
          .catch(this.hideLog);
      }, 1000);
    }
  };

  showLog = (store, force = false) => {
    const { dispatch } = this.props;
    if (force || this.isWaiting(store.storeStatus)) {
      dispatch(storeActions.getOne(store.id));
      this.setState({ logger: true }, () => this.logger(store));
    }
  };

  hideLog = () => {
    const { dispatch } = this.props;
    dispatch(storeActions.clear());
    this.setState({ logger: false });
  };

  toggleDeleteModal = () => {
    this.setState((state) => ({ ...state, deleteModal: !state.deleteModal }));
  };

  getStoreName = () => {
    const { selected } = this.state;
    const {
      store: { all },
    } = this.props;

    const selectedStore = all.find((s) => s.id == selected);
    if (selectedStore) {
      sessionStorage.setItem('storePrimaryDomain', selectedStore.primaryDomain);
    }
    return selectedStore && selectedStore.name;
  };

  toggleExpand = (id) => {
    return () => {
      if (this.state.expandId && this.state.expandId === id) {
        this.setState({ expandId: null });
      } else {
        this.setState({ expandId: id });
      }
    };
  };

  render() {
    const { store, version, syncStoresStatus } = this.props;

    return (
      <React.Fragment>
        <XMModalLog
          title={'Store: ' + store.one.name}
          isOpen={this.state.logger}
          isWaiting={this.isWaiting(store.one.storeStatus)}
          toogle={this.hideLog}
          logs={store.one.logs}
        />

        {store.one && (
          <XMDock title={store.one.name} isVisible={this.state.dock} handleCancel={this.onCancel}>
            <StoreForm
              initialValues={{ ...store.one, originName: store.one.name }}
              isEdit={true}
              handleCancel={this.onCancel}
              toggle={this.onCancel}
            />
          </XMDock>
        )}

        <XMCrud
          form={<StoreForm initialValues={{ ...store.one, originName: store.one.name }} />}
          details={<StoreDetails store={store} version={version} />}
          title={_.isEmpty(store.one) ? 'Create Store' : store.one.name}
          loading={store.loading}
          disableDelete={!this.props.user.isSuperAdmin}
          handleCreate={this.onCreate}
          handleEdit={this.onEdit}
          handleDelete={this.toggleConfirmModal}
          handleCancel={this.onCancel}
          handleSearch={this.handleSearch}
          handlePagination={this.handlePagination}
          handleRefresh={this.loadStores}
          pagination={store.pagination}
          render={() => (
            <XMTable headers={Headers} autoRenderRow={false} handleSort={this.handleSort}>
              {store.all.map((s) => [
                <tr key={s.id}>
                  <StoreTableRow
                    store={s}
                    handleEdit={this.handleEdit}
                    onStoreClick={this.toggleExpand(s.id)}
                    btns={this.btns}
                    expand={this.state.expandId === s.id}
                    syncStatus={syncStoresStatus[s.storeId] ? syncStoresStatus[s.storeId] : ''}
                    dropdownComponent={
                      <StoreStatusDropdown
                        store={s}
                        onToggleClick={() => this.showLog(s)}
                        onFailedClick={() => this.showLog(s, true)}
                        onUpdateStatusClick={(status) => this.updateStoreStatus(s, status)}
                      />
                    }
                  />
                </tr>,
                <StoreDetailsRow key={`${s.id}-expand`} {...s} expand={this.state.expandId === s.id} />,
              ])}
            </XMTable>
          )}
        ></XMCrud>

        <XMModalConfirm
          message={`Are you sure you want to delete store ${this.getStoreName()}?`}
          isVisible={this.state.confirmModal}
          onCancel={this.toggleConfirmModal}
          handleConfirm={this.onDeleteConfirm}
          isValidatingStoreUrl={!isDevelopment()}
        />

        <StoreDeletionModal
          isOpen={this.state.deleteModal}
          onClose={this.toggleDeleteModal}
          onCancel={this.onCancel}
          onConfirm={this.onDelete}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Stores);
