import _axios from '../api';
import { queryParams } from '../utils';

const API = '/versions';

export function getAll(
  page = 0,
  size = 20,
  sort = 'id',
  sortDir = 'DESC',
  searchRequest = null
) {
  return dispatch =>
    new Promise((resolve, reject) => {
      _axios
        .get(
          `${API}/history?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(
            searchRequest
          )}`
        )
        .then(response => {
          dispatch({
            type: 'VERSION_GETALL_SUCCESS',
            payload: response.data
          });
          resolve(response.data);
        })
        .catch(err => {
          dispatch({
            type: 'VERSION_GETALL_ERROR',
            payload: err.message
          });
          reject(err);
        });
    });
}

export function getOne(versionId) {
  return function(dispatch) {
    _axios
      .get(API + '/' + versionId)
      .then(response => {
        dispatch({
          type: 'VERSION_GETONE_SUCCESS',
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: 'VERSION_GETONE_ERROR',
          payload: err.message
        });
      });
  };
}

/**
 * create (POST) || update (PUT)
 * @param {*} store
 */
export function save(Version) {
  return Version.id
    ? function(dispatch) {
        _axios
          .put(API, Version)
          .then(response => {
            dispatch({
              type: 'VERSION_UPDATE_SUCCESS'
            });
            // refresh
            dispatch(getAll());
          })
          .catch(err => {
            dispatch({
              type: 'VERSION_UPDATE_ERROR',
              payload: err.message
            });
          });
      }
    : function(dispatch) {
        _axios
          .post(API, Version)
          .then(response => {
            dispatch({
              type: 'VERSION_CREATE_SUCCESS'
            });
            dispatch(getAll());
          })
          .catch(err => {
            dispatch({
              type: 'VERSION_CREATE_ERROR',
              payload: err.message
            });
          });
      };
}

export function remove(versionId) {
  return function(dispatch) {
    _axios
      .delete(API + '/' + versionId)
      .then(response => {
        dispatch({
          type: 'VERSION_DELETE_SUCCESS'
        });
        // refresh versions
        dispatch(getAll());
      })
      .catch(err => {
        dispatch({
          type: 'VERSION_DELETE_ERROR',
          payload: err.message
        });
      });
  };
}
