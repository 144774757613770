import React from 'react';
import PropTypes from 'prop-types';

import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

function WarnButton({ id, hasWarn, warningText, onClick }) {
  const [isOpen, setOpen] = React.useState(false);

  function toggle() {
    setOpen(!isOpen);
  }

  if (hasWarn) {
    return (
      <React.Fragment>
        <Button color="info" id={'Popover-' + id} type="button" onClick={toggle}>
          ...
        </Button>
        <Popover placement="right" isOpen={isOpen} target={'Popover-' + id} toggle={toggle}>
          <PopoverHeader>Warning</PopoverHeader>
          <PopoverBody>{warningText}</PopoverBody>
        </Popover>
      </React.Fragment>
    );
  }

  return (
    <Button color="info" onClick={onClick}>
      ...
    </Button>
  );
}

WarnButton.propTypes = {
  id: PropTypes.string.isRequired,
  hasWarn: PropTypes.bool,
  warningText: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default WarnButton;
