// CONFIG REDUCER
const initialState = {
  fetched: false,
  properties: []
};
export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIG_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        properties: action.payload.properties,
        enums: action.payload.enums
      };
    }
  }
  return state;
}
