import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Row, Col, Button, Alert } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';

import { Switch, CheckboxGroup, NativeSelect } from '../../../../../components/Field';
import { timeRange, notEmpty, required, min0, max23 } from '../../../../../components/Field/validate';
import {
  updateScheduler,
  clearSourceMessage,
  fetchSourceTables
} from '../../../../../actions/synchronisationActions';
import Select from 'react-select';

const DAY_OPTIONS = [
  { name: 'MON', label: 'Mo' },
  { name: 'TUE', label: 'Tu' },
  { name: 'WED', label: 'We' },
  { name: 'THU', label: 'Th' },
  { name: 'FRI', label: 'Fr' },
  { name: 'SAT', label: 'Sa' },
  { name: 'SUN', label: 'Su' }
];

const TIME_OPTIONS = [
  { name: 0, value: 0 },
  { name: 1, value: 1 },
  { name: 2, value: 2 },
  { name: 3, value: 3 },
  { name: 4, value: 4 },
  { name: 5, value: 5 },
  { name: 6, value: 6 },
  { name: 7, value: 7 },
  { name: 8, value: 8 },
  { name: 9, value: 9 },
  { name: 10, value: 10 },
  { name: 11, value: 11 },
  { name: 12, value: 12 },
  { name: 13, value: 13 },
  { name: 14, value: 14 },
  { name: 15, value: 15 },
  { name: 16, value: 16 },
  { name: 17, value: 17 },
  { name: 18, value: 18 },
  { name: 19, value: 19 },
  { name: 20, value: 20 },
  { name: 21, value: 21 },
  { name: 22, value: 22 },
  { name: 23, value: 23 }
];

const FREQUENCY_OPTIONS = [
  { name: 'Every Minute', value: 'ONE_MIN' },
  { name: 'Every 2 Minutes', value: 'TWO_MIN' },
  { name: 'Every 5 Minutes', value: 'FIVE_MIN' },
  { name: 'Every 10 Minutes', value: 'TEN_MIN' },
  { name: 'Every 15 Minutes', value: 'FIFTEEN_MIN' },
  { name: 'Every 30 Minutes', value: 'THIRTY_MIN' },
  { name: 'Every Hour', value: 'ONE_HOUR' }
];

function SchedulerForm({ scheduler, handleSubmit, sourceId, updating, message, error, currentStore, sourceTables, dispatch }) {
  const [enabled, setEnabled] = React.useState(false);
  const sourceTableOptions = sourceTables
    ? sourceTables.map((option) => ({ key: option, label: option, value: option }))
    : [];
  const [selectedTables, setSelectedTables] = React.useState(scheduler.tables || []);

  React.useEffect(() => {
    setEnabled(scheduler.isActive);
  }, [scheduler.isActive]);

  const handleTablesChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedTables(selectedValues);
  };

  useEffect(() => {
    if (currentStore && currentStore.storeId) {
      dispatch(fetchSourceTables(currentStore.storeId));
    }
  }, [currentStore]);

  function submit(values) {
    const updatedValues = {
      ...values,
      tables: selectedTables
    };
    if (sourceId) {
      dispatch(updateScheduler(sourceId, updatedValues));
    }
  }

  function onDismiss() {
    dispatch(clearSourceMessage());
  }

  const isBusinessCentral = () => {
    return currentStore && currentStore.erpType && currentStore.erpType.code === 'MS_Dynamics_BC';
  };

  return (
    <Form className="col-sm-12 col-md-6 col-lg-6"
          onSubmit={handleSubmit(submit)}>
      <FormGroup className="d-flex flex-row align-items-baseline">
        <Label for="exampleCheckbox"
               className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          Scheduler Status
        </Label>
        <Col xs={12} sm={8}>
          <Field
            id="schedulerStatus"
            name="isActive"
            defaultValue={scheduler.isActive}
            defaultChecked={scheduler.isActive}
            onChange={value => setEnabled(value)}
            component={Switch}
          />
        </Col>
      </FormGroup>

      <FormGroup className="d-flex flex-row align-items-baseline">
        <Label for="exampleCheckbox"
               className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          Week days
        </Label>
        <Col xs={12} sm={8}>
          <Field
            name="days"
            component={CheckboxGroup}
            disabled={!enabled}
            options={DAY_OPTIONS}
            validate={enabled ? [notEmpty] : []}
          />
        </Col>
      </FormGroup>

      <FormGroup className="d-flex flex-row align-items-baseline">
        <Label for="exampleCheckbox"
               className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          Operating Hours
        </Label>

        <Col xs={12} sm={8}>
          <Row>
            <Col>
              <Field
                id="startTime"
                name="startHour"
                component={NativeSelect}
                disabled={!enabled}
                validate={enabled ? [required, min0, max23] : []}
                options={TIME_OPTIONS}
              />
            </Col>
            <Col>
              <Field
                id="endTime"
                name="endHour"
                component={NativeSelect}
                disabled={!enabled}
                validate={enabled ? [timeRange, required, min0, max23] : []}
                options={TIME_OPTIONS}
              />
            </Col>
          </Row>
        </Col>
      </FormGroup>

      <FormGroup className="d-flex flex-row align-items-baseline">
        <Label for="exampleCheckbox"
               className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          Scheduler Frequency
        </Label>

        <Col className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
          <Field
            component={NativeSelect}
            id="frequency"
            name="frequency"
            disabled={!enabled}
            options={FREQUENCY_OPTIONS}
            validate={enabled ? [required] : []}
          />
        </Col>
      </FormGroup>

      {isBusinessCentral() && (
        <FormGroup className='d-flex flex-row align-items-baseline'>
          <Label for='exampleCheckbox'
                 className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
            Tables Scope
          </Label>

          <Col className='col-xs-12 col-sm-8 col-md-8 col-lg-8'>
            <Select
              id='tables'
              name='tables'
              isDisabled={!enabled}
              isMulti={true}
              defaultValue={
                selectedTables ? selectedTables.map(
                  (table) => ({ key: table, label: table, value: table })) : []
              }
              onChange={handleTablesChange}
              options={sourceTableOptions}
            />
          </Col>
        </FormGroup>
      )}

      {message && (
        <FormGroup>
          <Alert color={error ? 'danger' : 'success'} isOpen={!!message}
                 toggle={onDismiss}>
            {message}
          </Alert>
        </FormGroup>
      )}

      <FormGroup>
        <Button color="primary" disabled={updating}>
          Save
        </Button>
      </FormGroup>
    </Form>
  );
}

export default connect(state => ({
  sourceId: state.sync.source.currentSourceId,
  scheduler: state.sync.scheduler.scheduler,
  updating: state.sync.scheduler.updating,
  message: state.sync.scheduler.message,
  error: state.sync.scheduler.error,
  currentStore: state.store.one,
  sourceTables: state.sync.sourceTable.tables
}))(reduxForm({ form: 'syncSchedulerForm', enableReinitialize: true })(
  SchedulerForm));
