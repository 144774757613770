import { apiConfigHelper } from './apiConfig';
import { createAxios } from './utils';
export { getGatewayApi, GatewayApi } from './gatewayApi';
export { getSyncApi, SyncApi } from './syncApi';
export { getSettingsApi, SettingsApi } from './settingsApi';

export const getStoreManagerApi = () => {
  return createAxios(apiConfigHelper.getApiBaseUrl() + '/store-manager/');
};

const _axios = getStoreManagerApi();
export default _axios;

export class StoreManagerApi {
  static getStore = async (id) => {
    const res = await _axios.get(`/stores/${id}`);
    return res.data;
  };
  static getFBConfig = async () => {
    const res = await _axios.get(`/configs/firebase`);
    return res.data;
  };
}
