import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { PacmanLoader } from 'react-spinners';
import _ from 'lodash';

import { capitalizeFirstLetter } from '../../utils';
import XMModalConfirm from '../../components/XMModalConfirm';
import XMCrud from '../../components/XMCrud';
import XMModalLog from '../../components/XMModalLog';
import * as operatorActions from '../../actions/operatorActions';
import OperatorForm from './OperatorForm';

const COLOR = {
  CREATING: 'primary',
  CREATED: 'success',
  UPDATING: 'primary',
  UPDATED: 'success',
  DELETING: 'warning',
  FAILED: 'danger'
};

@connect(state => {
  return {
    erpType: state.erpType,
    operator: state.operator,
    dataCentre: state.dataCentre
  };
})
class Operators extends Component {
  state = {
    logger: false,
    pagination: {
      page: undefined,
      size: undefined,
      sort: undefined,
      sortDir: undefined
    },
    search: null,
    confirmModal: false
  };

  componentDidMount() {
    this.loadOperators();
  }

  componentDidUpdate(prevProps) {
    const {
      operator: { all }
    } = this.props;

    if (all !== prevProps.operator.all) {
      // any operator with CREATING status?
      let operatorCreating = _.find(all, o => {
        return this.isWaiting(o.statusType);
      });

      if (operatorCreating && !this.timerId) {
        this.timerId = _.delay(this.loadOperators, 5000);
      }
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  loadOperators = () => {
    this.clearTimer();
    const { dispatch } = this.props;

    dispatch(
      operatorActions.getAll(
        this.state.pagination.page,
        this.state.pagination.size,
        this.state.pagination.sort,
        this.state.pagination.sortDir,
        this.state.search
      )
    );
  };

  handlePagination = page => {
    this.setState(
      state => ({
        pagination: {
          ...state.pagination,
          page: page
        }
      }),
      this.loadOperators
    );
  };

  handleSearch = search => {
    this.setState(
      {
        pagination: {},
        search: search
      },
      this.loadOperators
    );
  };

  handleSort = (sort, sortDir) => {
    this.setState(
      state => ({
        pagination: {
          ...state.pagination,
          sort: sort,
          sortDir: sortDir
        }
      }),
      this.loadOperators
    );
  };

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  // CRUD Actions
  onCreate = () => {};

  onEdit = operatorId => {
    const { dispatch } = this.props;
    dispatch(operatorActions.getOne(operatorId));
  };

  onDelete = () => {
    const { dispatch, dataCentre } = this.props;

    dispatch(
      operatorActions.remove({
        id: this.state.selected,
        dataCentre: dataCentre.one
      })
    );

    this.setState({ confirmModal: false });
  };

  onCancel = () => {
    const { dispatch } = this.props;
    dispatch(operatorActions.clear());
  };

  /**
   * Waiting actions, eg: creating, deleting, etc.
   * @param {*} store
   */
  isWaiting = statusType => {
    return statusType ? statusType.endsWith('ING') : false;
  };

  logger = operator => {
    const { dispatch } = this.props;
    if (this.state.logger && this.isWaiting(operator.statusType)) {
      _.delay(() => {
        dispatch(operatorActions.getOne(operator.id))
          .then(() => {
            this.logger(operator);
          })
          .catch(() => {
            this.hideLog();
          });
      }, 1000);
    }
  };

  showLog = operator => {
    const { dispatch } = this.props;
    dispatch(operatorActions.getOne(operator.id));
    this.setState({ logger: true }, () => this.logger(operator));
  };

  hideLog = () => {
    const { dispatch } = this.props;
    dispatch(operatorActions.clear());
    this.setState({ logger: false });
  };

  getDropdown = operator => {
    const { statusType } = operator;

    return (
      <Button color={COLOR[statusType]} onClick={() => this.showLog(operator)} size="sm">
        {this.isWaiting(statusType) ? (
          <div style={{ display: 'inline-flex', width: '8em' }}>
            <span>{statusType}</span>
            <div className="ms-2">
              <PacmanLoader size={8.5} color={'#FFF'} loading={true} />
            </div>
          </div>
        ) : (
          <span>{statusType}</span>
        )}
      </Button>
    );
  };

  toggleConfirmModal = operatorId => {
    this.setState(state => ({ selected: operatorId, confirmModal: !state.confirmModal }));
  };

  getUserName = () => {
    const {
      operator: { all }
    } = this.props;
    const operator = all.find(o => o.id == this.state.selected);
    return operator && operator.username;
  };

  render() {
    const { operator } = this.props;
    return (
      <div>
        <XMModalLog
          title={'Operator: ' + operator.one.name}
          isOpen={this.state.logger}
          isWaiting={this.isWaiting(operator.one.statusType)}
          toogle={this.hideLog}
          logs={operator.one.logs}
        />

        <XMCrud
          headers={[
            { name: 'Name', code: 'name', sortable: true },
            { name: 'Username', code: 'username', sortable: true },
            { name: 'Role', code: 'role', sortable: true },
            { name: 'Phone', code: 'phone', sortable: true },
            { name: 'Status', code: 'statusType', sortable: true },
            { name: 'Actions' }
          ]}
          form={<OperatorForm initialValues={operator.one} />}
          title={!operator.one.name ? 'Create Operator' : operator.one.name}
          handleCreate={this.onCreate}
          handleEdit={this.onEdit}
          handleDelete={this.toggleConfirmModal}
          handleCancel={this.onCancel}
          handleSearch={this.handleSearch}
          handleRefresh={this.loadOperators}
          handleSort={this.handleSort}
          handlePagination={this.handlePagination}
          pagination={operator.pagination}
        >
          {operator.all.map(o => (
            <React.Fragment key={o.id}>
              <td className="align-middle">
                <span>{o.name}</span>
              </td>
              <td className="align-middle">
                <span>{o.username}</span>
              </td>
              <td className="align-middle">
                <span>{capitalizeFirstLetter(o.role)}</span>
              </td>
              <td className="align-middle">
                <span>{o.phone}</span>
              </td>
              <td className="align-middle">
                <span>{this.getDropdown(o)}</span>
              </td>
            </React.Fragment>
          ))}
        </XMCrud>

        <XMModalConfirm
          message={`Are you sure you want to delete user ${this.getUserName()}?`}
          isVisible={this.state.confirmModal}
          onCancel={this.toggleConfirmModal}
          handleConfirm={this.onDelete}
        />
      </div>
    );
  }
}

export default Operators;
