const dbInitState = {
  loading: false,
  error: {},
  databases: []
};

export default function dbReducer(state = dbInitState, action) {
  switch (action.type) {
    case 'SYNC_DATABASE_FETCH_START': {
      return { ...state, loading: true, error: {} };
    }

    case 'SYNC_DATABASE_FETCH_SUCCESS': {
      return { ...state, databases: action.payload.databases, loading: false, error: {} };
    }

    case 'SYNC_DATABASE_FETCH_ERROR': {
      return { ...state, loading: false, error: action.payload.error };
    }

    default:
      return state;
  }
}
