import { apiConfigHelper } from './apiConfig';
import { createAxios } from './utils';

export const getSettingsApi = () => {
  const _axios = createAxios(apiConfigHelper.getBaseUrlForSettings());

  _axios.interceptors.request.use((request) => {
    // update the API endpoint using the site domain
    request.baseURL = apiConfigHelper.getStoreUrl(request.baseURL);

    return request;
  });
  return _axios;
};
const _axios = getSettingsApi();
export default _axios;

export class SettingsApi {
  static getAllClientCredentials = async (storeId, page = 0, size = 20) => {
    const res = await getSettingsApi().get(`admin/oauth2/client-credentials?store-id=${storeId}&page=${page}&size=${size}`, {
      headers: {
        'x-xm-store': storeId,
      },
    });
    return res.data;
  };

  static register = async (data) => {
    return await getSettingsApi().post('/admin/oauth2/client-credentials', data, {
      headers: {
        'x-xm-store': data.storeId,
      },
    });
  };

  static delete = async (storeId, clientId) => {
    return await getSettingsApi().delete(`/admin/oauth2/client-credentials/${clientId}`, {
      headers: {
        'x-xm-store': storeId,
      },
    });
  };
}
