import React, { Component } from 'react';
import { Col, Card, CardTitle, CardBody, FormGroup, Button } from 'reactstrap';

import { Field, reduxForm } from 'redux-form';

@reduxForm({
  form: 'webserviceForm',
  enableReinitialize: true
})
class Webservice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = form => {
    console.log('### form ###', form);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Card>
        <CardBody>
          <CardTitle>Webservice Connection</CardTitle>
          <form
            onSubmit={handleSubmit(this.submit)}
            className="form-horizontal"
          >
            <FormGroup row>
              <Col md="1">
                <label htmlFor="protocol">Protocol</label>
              </Col>
              <Col md="3">
                <Field
                  name="protocol"
                  component="select"
                  className="form-control"
                >
                  <option value="https">https</option>
                  <option value="http">http</option>
                </Field>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="1">
                <label htmlFor="domain">Domain</label>
              </Col>
              <Col md="6">
                <Field
                  name="domain"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="domain.co.nz"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="1">
                <label htmlFor="port">Port</label>
              </Col>
              <Col md="3">
                <Field
                  name="port"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="port"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="1">
                <label htmlFor="path">Path</label>
              </Col>
              <Col md="6">
                <Field
                  name="path"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="sync-client"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="1">
                <label htmlFor="poolId">Pool Id</label>
              </Col>
              <Col md="3">
                <Field
                  name="poolId"
                  component="input"
                  type="text"
                  className="form-control"
                  placeholder="Web Service Pool id"
                />
              </Col>
            </FormGroup>
            <div className="float-end">
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default Webservice;
