import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Table, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import {
  fetchTableFilter
} from '../../../../../actions/synchronisationActions';

class SourceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTable: '',
      tableDisplayFilter: '',
      fuzzyString: null
    };

    this.onSearch = debounce(this.onSearch, 350);
  }

  onSearch = fuzzyString => {
    this.setState({ fuzzyString });
  };

  handleSourceTableClick = e => {
    const { fetchTableFilter, storeId } = this.props;
    const table = e.target.getAttribute('data-table');
    fetchTableFilter(storeId, table);
    this.setState({ selectedTable: table });
  };

  render() {
    let { sourceTables, tableFilters } = this.props;
    if (this.state.fuzzyString && sourceTables) {
      sourceTables = sourceTables.filter(
        sourceTable => sourceTable.toLowerCase().includes(this.state.fuzzyString.toLowerCase())
      );
    }

    return (
      <FormGroup row className="sync-filters-left-col-inner">
        <div>Source table</div>

        <InputGroup>
            <InputGroupText>
              <i className="icon-magnifier"/>
            </InputGroupText>
          <Input
            type="text"
            name="fuzzyString"
            onChange={evt => this.onSearch(evt.target.value)}
          />
        </InputGroup>

        <div className="sync-filters-source-table-div">
          <Table borderless>
            <tbody>
            {sourceTables &&
            sourceTables.map((sourceTable, idx) => (
              <tr key={idx} className={sourceTable === this.state.selectedTable ? 'table-active' : ''}>
                <td>
                  {tableFilters && tableFilters.hasOwnProperty(sourceTable) &&
                  <i className="fa fa-check"/>
                  }
                </td>
                <td data-table={sourceTable} onClick={this.handleSourceTableClick}>
                  {sourceTable}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </FormGroup>
    );
  }
}

SourceTable.propTypes = {
  sourceTables: PropTypes.array,
  tableFilters: PropTypes.object,
  storeId: PropTypes.string
};

export default connect(
  state => ({}),
  dispatch => ({
    fetchTableFilter: (storeId, tableName) => dispatch(fetchTableFilter(storeId, tableName))
  }))(SourceTable);
