import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class XMModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
    this.toogle = this.toogle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;
    if (isVisible !== prevProps.isVisible) {
      this.toogle();
    }
  }

  toogle() {
    const { handleCancel } = this.props;
    this.setState(
      {
        isVisible: !this.state.isVisible
      },
      handleCancel
    );
  }

  render() {
    const { title, children } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.state.isVisible}
          toggle={this.toggle}
          className="lg"
        >
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>{children}</ModalBody>
        </Modal>
      </div>
    );
  }
}

export default XMModal;
