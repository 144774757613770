import React, { version } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Col, Button, Alert, Label, Input } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Segment from '../components/Segment';
import SegmentItem from '../components/SegmentItem';

import { saveSource, testConnection, clearSourceMessage } from '../../../../../actions/synchronisationActions';
import { InputField, NativeSelect, RadioButtons } from '../../../../../components/Field';
import { required } from '../../../../../components/Field/validate';
import DbAliasSelect from './DbAliasSelect';
import DbSchemaSelect from './DbSchemaSelect';
import SyncVersionField from './SyncVersionField';
import { getStore as getGatewayStore } from '../../../../../actions/gatewayActions';
import { API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL } from '../../../../../utils/constants';
import withRouter from '../../../../../withRouter';

const SYNC_VERSION_OPTIONS = [
  { name: 'Off (None)', value: 'NONE' },
  { name: 'Sync 1', value: 'SYNC_1' },
  { name: 'Sync 2', value: 'SYNC_2' },
];

const GATEWAY_SYNC_SOURCES = ['MS_Dynamics_BC'];
const GATEWAY_SYNC_TYPE = 'GATEWAY';
const AGENT_SYNC_TYPE = 'AGENT';

export function getSyncVersionName(value) {
  let syncVersionName = '';
  SYNC_VERSION_OPTIONS.forEach((option) => {
    if (option.value != value) {
      return '';
    }
    syncVersionName = option.name;
  });

  return syncVersionName;
}

const DB_TYPE_OPTIONS = [
  { name: 'mssql', value: 'MSSQL' },
  { name: 'oracle', value: 'ORACLE' },
];

const PROTOCOL_TYPE_OPTIONS = [
  { name: 'https', value: 'https' },
  { name: 'http', value: 'http' },
];

const SOURCE_TYPE_OPTIONS = [
  {
    name: 'Sage X3 V6.5',
    value: 'SAGE_X3_V6_5',
    code: 'SAGE_X3_V6_5',
    isGatewaySyncAvailable: false,
  },

  {
    name: 'Sage X3 V8',
    value: 'SAGE_X3_V8',
    code: 'SAGE_X3_V8',
    isGatewaySyncAvailable: false,
  },
  {
    name: 'Sage X3 V9',
    value: 'SAGE_X3_V9',
    code: 'SAGE_X3_V9',
    isGatewaySyncAvailable: false,
  },
  {
    name: 'Sage X3 V11',
    value: 'SAGE_X3_V11',
    code: 'SAGE_X3_V11',
    isGatewaySyncAvailable: false,
  },
  {
    name: 'Sage 300',
    value: 'SAGE_300',
    code: 'SAGE_300',
    isGatewaySyncAvailable: false,
  },
  {
    name: 'Microsoft Dynamics 365 Business Central (Wave 1 2021)',
    value: 'BUSINESSCENTRAL_V18_5',
    code: 'BUSINESSCENTRAL_V18_5',
    isGatewaySyncAvailable: true,
  },
];

function SyncForm({
  handleSubmit,
  change,
  failed,
  message,
  failedSyncVersion,
  messageSyncVersion,
  currentStore,
  initialValues,
  saveSource,
  testConnection,
  clearSourceMessage,
  clearSyncVersionMessage,
  loading,
  erpType,
  fetchGatewayStore,
  currentGatewayStore,
}) {
  const [protocol, setProtocol] = React.useState(null);
  const [hostName, setHostName] = React.useState(null);
  const [port, setPort] = React.useState(null);
  const [dbAlias, setDbAlias] = React.useState(null);
  const [selectedErpSource, setSelectedErpSource] = React.useState(null);
  const [erpTypeSources, setErpTypeSources] = React.useState(SOURCE_TYPE_OPTIONS);

  React.useEffect(() => {
    setProtocol(initialValues.protocol);
    setHostName(initialValues.hostName);
    setPort(initialValues.port);
    setDbAlias(initialValues.dbAlias);
    setSelectedErpSourceFromValue(initialValues.sourceType);
    handleIntegrationName(initialValues.integrationName || '');
  }, [initialValues]);

  function submit(values) {
    saveSource(currentStore.storeId, values);
  }

  function handleDbAliasSelect(value) {
    change('dbAlias', value);
    setDbAlias(value);
  }

  function handleDbSchemaSelect(value) {
    change('dbSchema', value);
  }

  function handleTestConnection() {
    testConnection(currentStore.storeId, protocol, hostName, port);
  }

  function isSync2Mode() {
    return currentStore.syncVersion == 'SYNC_2';
  }

  function onDismiss() {
    clearSourceMessage();
  }

  const setSelectedErpSourceFromValue = (value) => {
    erpTypeSources.forEach((type) => {
      if (type.value === value) {
        setSelectedErpSource(type);
        change('syncType', type.isGatewaySyncAvailable ? GATEWAY_SYNC_TYPE : AGENT_SYNC_TYPE);
        if (GATEWAY_SYNC_SOURCES.includes(type.code)) {
          fetchGatewayStore(currentStore.storeId);
        }
        return;
      }
    });
  };

  const isSourceSelected = () => {
    return selectedErpSource !== null;
  };

  const isGatewaySync = () => {
    return (isSourceSelected() && selectedErpSource.isGatewaySyncAvailable) || false;
  };

  const hasGatewayStore = () => {
    return currentGatewayStore !== null && currentGatewayStore.storeId !== null;
  };

  const isGatewayStoreValid = () => {
    return hasGatewayStore() && currentGatewayStore.enabled == true && currentGatewayStore.integrations.length > 0;
  };

  const handleIntegrationName = (value) => {
    change('integrationName', value);
  };

  const isBusinessCentral = () => {
    return currentStore && currentStore.erpType && currentStore.erpType.code === 'MS_Dynamics_BC';
  };

  if (!currentStore.id) {
    return <div>Loading...</div>;
  }

  return (
    <form className="form-horizontal">
      <FormGroup row>
        <Col sm={3}>Store ID</Col>
        <Col sm={9}>
          <Field
            component={InputField}
            className="px-1"
            readOnly="readonly"
            type="text"
            name="storeId"
            id="storeId"
            validate={[required]}
          />
        </Col>
      </FormGroup>

      {messageSyncVersion && (
        <div className="mt-4">
          <Alert
            color={failedSyncVersion ? 'danger' : 'success'}
            isOpen={!!messageSyncVersion}
            toggle={clearSyncVersionMessage}
          >
            {messageSyncVersion}
          </Alert>
        </div>
      )}

      <Segment title="Sync Version">
        <SegmentItem name="Version" style={{ marginTop: '-1em' }}>
          <FormGroup className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{ marginBottom: 'none' }}>
            <SyncVersionField
              currentStore={currentStore}
              initialValues={initialValues}
              options={SYNC_VERSION_OPTIONS}
              disabled={loading || isBusinessCentral()}
            />
          </FormGroup>
        </SegmentItem>
      </Segment>

      {isSync2Mode() && (
        <Segment title="ERP">
          <SegmentItem name="Type">
            <FormGroup className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{ marginBottom: 'none' }}>
              <Field
                component={NativeSelect}
                type="select"
                name="sourceType"
                id="sourceType"
                validate={[required]}
                options={erpTypeSources}
                onChange={({ target: { value } }) => {
                  setSelectedErpSourceFromValue(value);
                }}
                disabled={isBusinessCentral()}
              />
            </FormGroup>
          </SegmentItem>
        </Segment>
      )}

      {isSync2Mode() && isSourceSelected() && isGatewaySync() && (
        <Segment title="Sync 2 - Gateway">
          {!isGatewayStoreValid() && (
            <SegmentItem name="Settings">
              <Link to={`/stores/${currentStore.id}/gateway`}>Gateway Settings</Link>
            </SegmentItem>
          )}
          {hasGatewayStore() && (
            <div
              className="row form-group"
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
            >
              <Button color="success" disabled={true}>
                Live
              </Button>
              <Button color={currentGatewayStore.enabled ? 'success' : 'secondary'} disabled={true}>
                {currentGatewayStore.enabled ? 'Enabled' : 'Disabled'}
              </Button>
            </div>
          )}

          {hasGatewayStore() && (
            <SegmentItem name="Sync Type">
              <FormGroup className="col-sm-12 col-xs-12 col-md-6 col-lg-6" style={{ marginBottom: 'none' }}>
                <Field
                  component={InputField}
                  className="px-1"
                  readOnly="readonly"
                  type="text"
                  name="syncType"
                  id="syncType"
                  validate={[required]}
                  placeholder={GATEWAY_SYNC_TYPE}
                />
              </FormGroup>
            </SegmentItem>
          )}
          {hasGatewayStore() && (
            <SegmentItem name="Integration">
              <FormGroup className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{ marginBottom: 'none' }}>
                <Field
                  component={NativeSelect}
                  type="select"
                  name="integrationName"
                  id="integrationName"
                  validate={[required]}
                  disabled={isBusinessCentral()}
                  options={
                    isBusinessCentral()
                      ? [
                          {
                            name: API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL,
                            value: API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL,
                          },
                        ]
                      : currentGatewayStore.integrations.map((integration) => {
                          return {
                            name: integration,
                            value: integration,
                          };
                        })
                  }
                  onChange={({ target: { value } }) => {
                    handleIntegrationName(value);
                  }}
                />
              </FormGroup>
            </SegmentItem>
          )}
        </Segment>
      )}

      {isSync2Mode() && isSourceSelected() && !isGatewaySync() && (
        <Segment title="Sync 2 - Agent">
          <SegmentItem name="Database">
            <FormGroup className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{ marginBottom: 'none' }}>
              <Field
                component={NativeSelect}
                type="select"
                name="dbType"
                id="dbType"
                options={DB_TYPE_OPTIONS}
                validate={[required]}
              />
            </FormGroup>
          </SegmentItem>
          <SegmentItem name="Connection">
            <FormGroup className="col-sm-12 col-xs-12 col-md-3 col-lg-3" style={{ marginBottom: 'none' }}>
              <Field
                component={NativeSelect}
                name="protocol"
                id="protocol"
                options={PROTOCOL_TYPE_OPTIONS}
                validate={[required]}
                onChange={({ target: { value } }) => {
                  setProtocol(value);
                }}
              />
            </FormGroup>
            <FormGroup className="col-sm-12 col-xs-12 col-md-6 col-lg-6" style={{ marginBottom: 'none' }}>
              <Field
                component={InputField}
                type="text"
                name="hostName"
                id="hostName"
                placeholder="host name"
                validate={[required]}
                onChange={({ target: { value } }) => {
                  setHostName(value);
                }}
              />
            </FormGroup>
            <FormGroup className="col-sm-12 col-xs-12 col-md-3 col-lg-3" style={{ marginBottom: 'none' }}>
              <Field
                component={InputField}
                type="text"
                name="port"
                id="port"
                validate={[required]}
                onChange={({ target: { value } }) => {
                  setPort(value);
                }}
              />
            </FormGroup>
          </SegmentItem>

          <SegmentItem name="Database id">
            <DbAliasSelect
              storeId={currentStore.storeId}
              protocol={protocol}
              hostName={hostName}
              port={port}
              onDatabaseIDSelect={handleDbAliasSelect}
            />
          </SegmentItem>

          <SegmentItem name="Database schema">
            <DbSchemaSelect
              storeId={currentStore.storeId}
              protocol={protocol}
              hostName={hostName}
              port={port}
              dbAlias={dbAlias}
              onDbSchemaSelect={handleDbSchemaSelect}
            />
          </SegmentItem>
        </Segment>
      )}

      {message && (
        <div className="mt-4">
          <Alert color={failed ? 'danger' : 'success'} isOpen={!!message} toggle={onDismiss}>
            {message}
          </Alert>
        </div>
      )}

      {isSync2Mode() && (
        <div className="mt-4">
          <Button color="primary" disabled={isBusinessCentral()} onClick={handleSubmit(submit)}>
            Save
          </Button>

          {isSourceSelected() && !isGatewaySync() && (
            <Button color="primary" className="ms-4" onClick={handleTestConnection}>
              Test Connection
            </Button>
          )}
        </div>
      )}
    </form>
  );
}

export default connect(
  (state) => ({
    failed: state.sync.source.failed,
    message: state.sync.source.message,
    failedSyncVersion: state.sync.source.failedSyncVersion,
    messageSyncVersion: state.sync.source.messageSyncVersion,
    loading: state.sync.source.loading,
    erpType: state.erpType,
    currentGatewayStore: state.gateway.one,
  }),
  (dispatch) => ({
    saveSource: (storeId, source) => dispatch(saveSource(storeId, source)),
    testConnection: (storeId, protocol, hostName, port) => dispatch(testConnection(storeId, protocol, hostName, port)),
    clearSourceMessage: () => dispatch(clearSourceMessage()),
    clearSyncVersionMessage: () => dispatch({ type: 'SYNC_CLEAR_VERSION_MESSAGE' }),
    fetchGatewayStore: (storeId) => dispatch(getGatewayStore(storeId)),
  })
)(
  withRouter(
  reduxForm({
    form: 'syncForm',
    enableReinitialize: true,
  })(SyncForm)
  )
);
