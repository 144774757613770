import axios from 'axios';
import { apiConfigHelper } from './apiConfig';

const _axios = axios.create({
  baseURL: apiConfigHelper.getBaseUrlForOauth() + '/oauth2',
  timeout: 20000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic c3RvcmVtYW5hZ2VyOnRlc3Q='
  }
});

// Add a response interceptor
_axios.interceptors.response.use(
  response => {
    sessionStorage.setItem('loginTime', Date.now().toString());
    return response;
  },
  error => {
    try {
      let httpError = error.response.status;
      if (httpError == 401 || httpError == 403) {
        console.log('### httpError ###', httpError);
      }
    } catch (error) {
      console.log('# ERROR #', error);
    }
    return Promise.reject(error.response);
  }
);

export default _axios;
