import { trim } from 'lodash';
import _axios from '../api';
import syncApi, { SyncApi } from '../syncApi';
import { queryParams } from '../utils';
import { API_GATEWAY_INTEGRATION_BUSINESS_CENTRAL } from '../utils/constants';
import { getOrCreateGatewayStore } from './gatewayActions';
import { isProduction } from '../utils/environment';

const API = '/stores';

export function getAllByDataCentre(
  dataCentre = {},
  page = 0,
  size = 20,
  sort = 'id',
  sortDir = 'DESC',
  searchRequest = null
) {
  return async (dispatch) => {
    dispatch({ type: 'STORE_LOADING' });
    try {
      const response = await _axios.get(
        `${API}/data_center/${dataCentre.id}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams(
          searchRequest
        )}`
      );
      dispatch({
        type: 'STORE_GETALL_SUCCESS',
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: 'STORE_GETALL_ERROR',
        payload: err.message,
      });
    }
  };
}

export function getStoreTypeCount(dataCentre = {}) {
  return function (dispatch) {
    dispatch({ type: 'STORE_LOADING' });
    _axios
      .get(`${API}/store_type_count/${dataCentre.id}`)
      .then((response) => {
        dispatch({
          type: 'STORE_GETSTORETYPECOUNT_SUCCESS',
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'STORE_GETSTORETYPECOUNT_ERROR',
          payload: err.message,
        });
      });
  };
}

export function getAllByEnvironment(
  environment = {},
  fuzzyString = '',
  dataCentre = {},
  page = 0,
  size = 20,
  sort = 'id',
  sortDir = 'DESC'
) {
  return async function (dispatch) {
    try {
      let response;
      if (environment.name.toLowerCase() == process.env.NODE_ENV) {
        response = await _axios.get(
          `${API}/data_center/${dataCentre.id}?page=${page}&size=${size}&sort=${sort},${sortDir}${queryParams({
            fuzzyString,
          })}`
        );
      } else {
        response = await _axios.get(
          `${API}/environments/${environment.name}?fuzzyString=${trim(
            fuzzyString
          )}&page=${page}&size=${size}&sort=${sort},${sortDir}`
        );
      }
      dispatch({
        type: 'STORE_GETALL_BY_ENVIRONMENT_SUCCESS',
        payload: response.data,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'STORE_GETALL_BY_ENVIRONMENT_ERROR',
        payload: err.message,
      });
    }
  };
}

export function getOne(storeId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      _axios
        .get(API + '/' + storeId)
        .then((response) => {
          dispatch({
            type: 'STORE_GETONE_SUCCESS',
            payload: response.data,
          });
          sessionStorage.setItem('storePrimaryDomain', response.data.primaryDomain);
          resolve(response.data);
        })
        .catch((err) => {
          dispatch({
            type: 'STORE_GETONE_ERROR',
            payload: err.message,
          });
          sessionStorage.removeItem('storePrimaryDomain');
          reject(err);
        });
    });
}

/**
 * create (POST) || update (PUT)
 * @param {*} store
 */
export function save(store) {
  return async (dispatch) => {
    if (store.id) {
      try {
        await _axios.put(API, store);
        await dispatch({ type: 'STORE_UPDATE_SUCCESS' });
        await dispatch(getAllByDataCentre(store.dataCentre));
        await dispatch(clear());
      } catch (err) {
        dispatch({
          type: 'STORE_UPDATE_ERROR',
          payload: err.status == 409 ? err.data.code : err.data.message,
        });
      }
    } else {
      try {
        const resErp = await _axios.get(`erp_types/${store.erpType.id}`);
        if (resErp && resErp.data && resErp.data.code === 'MS_Dynamics_BC') {
          store.orderIntegrationVersion = 'VERSION_2';
        }
        const resStore = await _axios.post(API, store);
        const store_id = resStore.data;
        await dispatch({ type: 'STORE_CREATE_SUCCESS' });
        await dispatch(getAllByDataCentre(store.dataCentre));
        const storeDataCentreRes = await _axios.get('data_centers/' + store.dataCentre.id);
        const storeDataCentre = storeDataCentreRes.data || null;

        const allowBcStoreCreation =
          !isProduction() ||
          (storeDataCentre && (storeDataCentre.code == 'prod_us2' || storeDataCentre.countryCode == 'AU'));

        if (allowBcStoreCreation) {
          await dispatch(async () => {
            const resStore = await _axios.get(`stores/${store_id}`);
            if (resStore && resStore.data && resStore.data.erpType.code === 'MS_Dynamics_BC') {
              const realStore = resStore.data;

              // platform-sync
              await SyncApi.saveSource(realStore.storeId, {
                storeId: realStore.storeId,
                sourceType: 'BUSINESSCENTRAL_V18_5',
                syncType: 'GATEWAY',
                integrationName: 'BusinessCentral',
                protocol: 'https',
                dbType: 'MSSQL',
                dateFormat: 'YMD',
                settings: {},
                syncVersion: 'SYNC_2',
              });

              // gateway
              await dispatch(getOrCreateGatewayStore(realStore));
            }
          });
        }

        await dispatch(clear());
      } catch (err) {
        dispatch({
          type: 'STORE_CREATE_ERROR',
          payload: err.status == 409 ? err.data.code : err.data.message,
        });
      }
    }
  };
}

export function updateStoreStatus(store, storeStatus) {
  return async (dispatch) => {
    try {
      await _axios.put(API + '/update_status/' + store.id, storeStatus);
      if (storeStatus && storeStatus.statusType === 'DELETE') {
        await syncApi.delete(`${store.storeId}/source`);
      }
      dispatch({ type: 'STORE_UPDATESTATUS_SUCCESS' });
      dispatch(getAllByDataCentre(store.dataCentre));
    } catch (error) {
      dispatch({
        type: 'STORE_UPDATESTATUS_ERROR',
        payload: error.message,
      });
    }
  };
}

export function clear() {
  return function (dispatch) {
    Promise.all([dispatch({ type: 'STORE_CLEAR' })]);
  };
}

export function remove(store) {
  return async (dispatch) => {
    try {
      await _axios.delete(API + '/' + store.id);
      dispatch({ type: 'STORE_DELETE_SUCCESS' });
      dispatch(getAllByDataCentre(store.dataCentre));
      await syncApi.delete(`${store.id}/source`);
      sessionStorage.removeItem('storePrimaryDomain');
    } catch (error) {
      dispatch({
        type: 'STORE_DELETE_ERROR',
        payload: error.message,
      });
    }
  };
}
