import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { isProduction } from '../../utils/environment';

const XMModalConfirm = ({ isVisible, message, onCancel, handleConfirm, isValidatingStoreUrl }) => {
  const [storeUrl, setFormStoreUrl] = React.useState('');
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    // reset if isVisible and message are updated
    setFormStoreUrl('');
    setError('');
  }, [isVisible, message]);

  const handleClick = () => {
    if (isValidatingStoreUrl
      && sessionStorage.getItem('storePrimaryDomain')
      && storeUrl !== sessionStorage.getItem('storePrimaryDomain')
    ) {
      setError('Please provide the correct Store URL to continue');
    } else {
      handleConfirm();
    }
  };

  return (
    <div>
      <Modal isOpen={isVisible} toggle={onCancel} className="lg">
        <ModalHeader toggle={onCancel}>Confirm</ModalHeader>
        <ModalBody style={{ color: (isProduction() ? 'red' : '') }}>
          {isValidatingStoreUrl
          && sessionStorage.getItem('storePrimaryDomain')
          && (<div className="form-group">
              <Label htmlFor="storeUrl">Please re-enter the Store
                URL: <b>{sessionStorage.getItem('storePrimaryDomain')}</b></Label>
              <Input type="text"
                     name="storeUrl"
                     id="storeUrl"
                     className="form-control"
                     onChange={e => {
                       e.preventDefault();
                       setFormStoreUrl(e.target.value);
                     }}
                     value={storeUrl}/>
              {error && <div className="text-danger">{error}</div>}
            </div>
          )}

          <div>{isProduction() && <b>PRODUCTION! </b> }{message}</div>
        </ModalBody>
        <ModalFooter>
          <div>
            <Button type="button" color="secondary" className="me-2" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="button" color="primary" onClick={handleClick}>
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

XMModalConfirm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  isValidatingStoreUrl: PropTypes.bool
};

XMModalConfirm.defaultProps = {
  isVisible: false,
  message: 'Are you sure?',
  isValidatingStoreUrl: false
};

export default XMModalConfirm;
