import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

// import mySaga from '../sagas/';
import rootReducer from '../reducers/';

const middleware = applyMiddleware(promise, thunk);

let store;
if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, composeWithDevTools(middleware));
} else {
  store = createStore(rootReducer, middleware);
}

export default store;
