import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

function Expression({ value, setExpression }) {
  return (
    <Input type="text"
           name="expression"
           id="expression"
           className="form-control"
           onChange={e => {
             e.preventDefault();
             setExpression(e.target.value);
           }}
           value={value}/>
  );
}

Expression.propTypes = {
  value: PropTypes.string,
  setExpression: PropTypes.func.isRequired
};

export default Expression;