import React, { Component } from 'react';
import {
  Nav,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

import HeaderDropdown from './HeaderDropdown';
import ReactFlagsSelect from 'react-flags-select';
import { connect } from 'react-redux';
import * as dataCentreActions from '../../actions/dataCentreActions';

@connect(state => {
  return {
    dataCentre: state.dataCentre
  };
})
class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onSelectDataCentre(dataCentre) {
    const { dispatch } = this.props;
    dispatch(dataCentreActions.select(dataCentre));
    this.toggle();
  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  render() {
    const { dataCentre } = this.props;

    if (!dataCentre.fetched) {
      return <div />;
    }

    const user = JSON.parse(localStorage.getItem('userSelected'));
    return (
      <header
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 10px',
          width: '100%',
          height: 55,
          position: 'fixed',
          zIndex: 1000,
          backgroundColor: '#573081'
        }}
      >
        <button type="icon-cloud-download" className="d-lg-none navbar-toggler" onClick={this.mobileSidebarToggle}>
          <i className="fa fa-bars" style={{ color: '#481148' }}></i>
        </button>
        <div style={{ flex: 1, alignItems: 'flex-start' }}>
          <img src="img/logo/logo.png" />
        </div>
        <Nav style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} navbar>
          <NavItem className="d-md-down-none">
            {dataCentre.selected ? (
              <Dropdown isOpen={this.state.dropdownOpen} size="md" toggle={this.toggle} direction="left">
                 <DropdownToggle caret disabled={user.role === 'PARTNER'} style={{ opacity: '1'}} className="align-middle">
                  <ReactFlagsSelect
                    id={"selected-dataCentre"}
                    rfsKey={"selected-dataCentre"}
                    selected={dataCentre.selected.countryCode}
                    disabled={true}
                    showSelectedLabel={false}
                    className={"menu-flags"}
                    selectButtonClassName={"menu-flags-btn"}
                    onSelect={() => void 0}
                  />
                  <span>{dataCentre.selected.name}</span>
                </DropdownToggle>
                <DropdownMenu>
                  {dataCentre.all.map((dc, index) => (
                    <DropdownItem key={index} size="sm" onClick={this.onSelectDataCentre.bind(this, dc)} className="align-middle">
                      <ReactFlagsSelect
                        id={dc.id}
                        rfsKey={dc.countryCode + dc.name}
                        selected={dc.countryCode}
                        showSelectedLabel={false}
                        className={"menu-flags-item"}
                        selectButtonClassName={"menu-flags-btn"}
                        onSelect={() => void 0}
                      />
                      <span>
                        {dc.name} ({dc.code})
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ) : null}
          </NavItem>
          <HeaderDropdown onSettingClick={this.props.onSettingClick} />
        </Nav>
      </header>
    );
  }
}

export default Header;
