import React from 'react';

const Segment = ({ title, children }) => {
  return (
    <div style={{ border: '2px solid #c2cfd6', padding: 10, marginTop: 40 }}>
      <span style={{ position: 'relative', top: -23, left: 13, backgroundColor: '#fff', padding: '0 10px' }}>
        {title}
      </span>
      {children}
    </div>
  );
};

export default Segment;
