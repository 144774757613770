import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsForm from './SettingsForm';

class Settings extends Component {
  state = { toggle: false };

  constructor(props) {
    super(props);
  }

  handleCancel = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { currentStore, currentGatewayStore } = this.props;
    const settings = currentGatewayStore.settings
      ? Object.keys(currentGatewayStore.settings).map((name) => ({
          name: name,
          value: currentGatewayStore.settings[name],
        }))
      : [];
    return (
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 mt-4">
        <SettingsForm
          currentGatewayStore={currentGatewayStore}
          currentStore={currentStore}
          handleCancel={this.handleCancel}
          initialValues={{
            settings,
            storeId: currentGatewayStore.storeId,
            enabled: currentGatewayStore.enabled,
          }}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  currentStore: state.store.one,
  currentGatewayStore: state.gateway.one,
  dataCentre: state.dataCentre,
}))(Settings);
