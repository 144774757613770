import { getSyncVersionName } from '../../views/Synchronization/tabs/SyncConnection/containers/SyncForm';

const initialState = {
  storeId: null,
  loading: false,
  sources: {},
  currentSourceId: null,
  failed: false,
  message: null,
  failedSyncVersion: false,
  messageSyncVersion: null,
  requestSyncVersion: null,
  syncVersionModalOpen: false,
  schedulerData: null,
};

export default function sourceReducer(state = initialState, action) {
  switch (action.type) {
    case 'SYNC_SOURCE_FETCH_START': {
      return {
        ...state,
        storeId: null,
        sources: {},
        loading: true,
      };
    }

    case 'SYNC_SOURCE_FETCH_SUCCESS': {
      const sources = {};
      action.payload.sources.forEach((source) => (sources[source.id] = source));
      let currentSourceId = null;
      if (action.payload.sources.length > 0) {
        currentSourceId = action.payload.sources[0].id;
      }
      return { ...state, loading: false, sources, storeId: action.payload.storeId, currentSourceId };
    }

    case 'SYNC_SOURCE_FETCH_ERROR': {
      return {
        ...state,
        loading: false,
      };
    }

    case 'SYNC_SOURCE_CREATE_SUCCESS': {
      return { ...state, failed: false, message: 'Source created successfully!' };
    }
    case 'SYNC_SOURCE_UPDATE_SUCCESS': {
      return { ...state, failed: false, message: 'Source updated successfully!' };
    }
    case 'SYNC_SOURCE_SAVE_ERROR': {
      return { ...state, failed: true, message: 'Source saved failed!' };
    }
    case 'SYNC_CONNECTION_SUCCESS': {
      return { ...state, failed: false, message: 'Successfully connected!' };
    }
    case 'SYNC_CONNECTION_FAILED': {
      return { ...state, failed: true, message: 'Can not establish connection!' };
    }
    case 'SYNC_CLEAR_SOURCE_MESSAGE': {
      return { ...state, failed: false, message: null };
    }

    // ================= BoF - SyncVersion Related Statuses
    case 'SYNC_CHANGE_VERSION_STARTED': {
      return {
        ...state,
        loading: true,
        failedSyncVersion: false,
        messageSyncVersion: null,
        syncVersionModalOpen: false,
      };
    }

    case 'SYNC_UPDATE_VERSION_SUCCESS': {
      const syncVersionName = getSyncVersionName(action.payload.syncVersion);
      return {
        ...state,
        loading: false,
        failedSyncVersion: false,
        syncVersionModalOpen: false,
        messageSyncVersion: `Successfully updated sync version to ${syncVersionName}.`,
        schedulerData: null,
      };
    }

    case 'SYNC_UPDATE_VERSION_FAILED':
    case 'SYNC_UPDATE_VERSION_SUCCESS_BUT_SCHEDULER_FAILED': {
      return {
        ...state,
        loading: false,
        syncVersionModalOpen: false,
        failedSyncVersion: true,
        messageSyncVersion: action.payload.error.message,
      };
    }

    case 'SYNC_CLEAR_VERSION_MESSAGE': {
      return { ...state, failedSyncVersion: false, messageSyncVersion: null };
    }

    case 'SYNC_REQUEST_CHANGE_VERSION': {
      return {
        ...state,
        loading: false,
        syncVersionModalOpen: true,
        requestSyncVersion: action.payload.syncVersion,
        schedulerData: action.payload.schedulerData,
      };
    }

    case 'SYNC_CANCEL_VERSION_CHANGE': {
      return { ...state, loading: false, syncVersionModalOpen: false, requestSyncVersion: null, schedulerData: null };
    }
    // ================= EoF - SyncVersion Related Statuses

    case 'SYNC_CLEAR_ALL':
      return initialState;
  }
  return state;
}
