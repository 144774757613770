import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { delay } from 'lodash';

/**
 * XM Refresh Button
 */
class XMRButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Delay the loading for X seconds when it is flagged back to false
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.loading !== undefined) {
      if (this.props.loading !== prevProps.loading) {
        if (this.props.loading) {
          this.setState({
            loading: true
          });
        } else {
          delay(() => {
            if (this.mounted) {
              this.setState({
                loading: false
              });
            }
          }, 2000);
        }
      }
    }
  }

  render() {
    const { handleRefresh } = this.props;
    return (
      <Button color="light" onClick={() => handleRefresh()}>
        {this.state.loading ? (
          <ClipLoader size={16} color={'#3e515b'} loading={true} />
        ) : (
          <i className="icon-refresh" />
        )}
      </Button>
    );
  }
}

export default XMRButton;
