import React, { Component } from 'react';
import version from './version';

class Footer extends Component {

  render() {
    return (
      <footer className="app-footer">
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <span>Version: {version}</span>
          <span style={{ marginLeft: 10 }}>
            Powered by <a href="https://commercebuild.com">commercebuild</a>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
