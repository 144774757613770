import React, { Component } from 'react';
import { Table, ButtonGroup, Button } from 'reactstrap';

import XMColumnSort from '../../components/XMColumnSort';
import XMTooltipButton from '../XMTooltipButton';

class XMTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      dock: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDock = this.toggleDock.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleDock(rowKey) {
    const { handleEdit } = this.props;
    this.setState(
      {
        dock: !this.state.dock,
      },
      handleEdit(rowKey)
    );
  }

  render() {
    const { headers, btns, handleSort } = this.props;
    return (
      <Table responsive size="sm" className="animated fadeIn">
        <thead>
          <tr>
            {headers.map((header, hi) =>
              header.sortable ? (
                <XMColumnSort
                  key={hi}
                  columnName={header.name}
                  columnCode={header.code}
                  columnSort={handleSort}
                  columnStyle={header.style ? header.style : {}}
                />
              ) : (
                <th className="align-middle" key={hi} style={header.style ? header.style : {}}>
                  {' '}
                  {header.name}{' '}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {
            // ROWS
            this.props.autoRenderRow
              ? React.Children.map(this.props.children, (row, ri) => (
                  <tr key={ri}>
                    {row}

                    {btns ? (
                      <td className="align-middle">
                        <ButtonGroup>
                          {
                            // ADD BTNS COL
                            btns.map((btn, bi) => (
                              <XMTooltipButton
                                key={bi}
                                icon={btn.icon}
                                tooltipTitle={btn.tooltip}
                                onClick={() => btn.action(row.key)}
                              />
                            ))
                          }
                        </ButtonGroup>
                      </td>
                    ) : null}
                  </tr>
                ))
              : this.props.children
          }
        </tbody>
      </Table>
    );
  }
}

export default XMTable;
