import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  deleteTableFilter
} from '../../../../../actions/synchronisationActions';
import XMModalConfirm from '../../../../../components/XMModalConfirm';

class DeleteFilter extends Component {
  state = {
    modalDelete: false
  };

  toggleDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete
    });
  };

  handleCancelDelete = () => {
    this.setState({ modalDelete: false });
  };

  handleDelete = () => {
    const { deleteTableFilter, storeId, tableName } = this.props;
    if (storeId && tableName) {
      deleteTableFilter(storeId, tableName);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Button className="float-end" color="danger" type="submit" id="deleteFilter" onClick={this.toggleDelete}>
          Delete
        </Button>

        <XMModalConfirm
          isVisible={this.state.modalDelete}
          handleConfirm={this.handleDelete}
          onCancel={this.handleCancelDelete}
          message={"Are you sure you want to DELETE " + this.props.tableName + "'s table filter?"}
        />
      </React.Fragment>
    );
  }
}

DeleteFilter.propTypes = {
  storeId: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired
};


export default connect(
  state => ({}),
  dispatch => ({
    deleteTableFilter: (storeId, tableName) => dispatch(deleteTableFilter(storeId, tableName))
  }))(DeleteFilter);
