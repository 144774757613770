import { isEmpty } from 'lodash';

const initialState = {
  loading: false,
  data: [],
  totals: {
    UPLOADS: 0,
    ROWS: 0,
    BYTES: 0,
    ERRORS: 0
  }
};

export default function graphReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_SYNC_GRAPH_DATA_START':
      return { ...initialState, loading: true };

    case 'LOAD_SYNC_GRAPH_DATA_SUCCESS': {
      const { data: originalData, totals, xaxis } = action.payload.graph;
      const data = xaxis.labels.map((label, index) => {
        const upload = getYAxisData(originalData.UPLOADS, index);
        const row = getYAxisData(originalData.ROWS, index);
        const byte = getYAxisData(originalData.BYTES, index);
        const error = getYAxisData(originalData.ERRORS, index);

        return { name: label, upload, row, byte, error };
      });
      return { loading: false, data, totals: isEmpty(totals) ? initialState.totals : totals };
    }

    case 'LOAD_SYNC_GRAPH_DATA_FAILED': {
      return { ...state, loading: false };
    }

    case 'SYNC_CLEAR_ALL':
      return initialState;
  }
  return state;
}

// list type [{'x': 0, 'y':0}]
function getYAxisData(list, index) {
  const record = (list && list.find(item => item.x === index)) || { y: 0 };
  return record.y;
}
