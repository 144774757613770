import React, { Component } from 'react';

import { Alert, FormGroup, Row, Col, Card, CardBody, Button, ButtonGroup } from 'reactstrap';

import { Field, FieldArray, reduxForm } from 'redux-form';
import ReactFlagsSelect from 'react-flags-select';
import { connect } from 'react-redux';
import _, { uniqueId } from 'lodash';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';

import { capitalizeFirstLetter } from '../../utils';
import _axios from '../../api';
import { SelectField, renderInputField } from '../../components/Field';
import * as storeActions from '../../actions/storeActions';
import * as operatorActions from '../../actions/operatorActions';
import XMTable from '../../components/XMTable';
import { isProduction } from '../../utils/environment';

// STATE (STORE) NEED ELEMENTS
const mapStateToProps = (state) => ({
  config: state.config,
  store: state.store,
  operator: state.operator,
  erpType: state.erpType,
  dataCentre: state.dataCentre,
});

// ACTIONS
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      lookupOperator: operatorActions.lookupOperator,
      cleanOperator: operatorActions.cleanOperator,
    },
    dispatch
  );
};

async function asyncValidate(values) {
  try {
    if (!values.id || values.originName !== values.name) {
      const response = await _axios.head(`/stores/${values.name}`);

      if (response.status === 200) {
        throw { name: `The store name is taken` };
      }
    }
  } catch (err) {
    if (!err.name) {
      console.error(err);
      return true;
    }

    throw err;
  }
}

// SETUP REDUX FORM
@reduxForm({
  form: 'storeForm',
  enableReinitialize: true,
  asyncValidate,
  asyncBlurFields: ['name'],
})
// CONNECT TO REDUX
@connect(mapStateToProps, mapDispatchToProps)
class StoreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryDomain: null,
      erpTypeSelected: null,
      domains: [],
    };

    this.makeDomainPrimary = this.makeDomainPrimary.bind(this);
    this.removeDomain = this.removeDomain.bind(this);

    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(operatorActions.getAllByRole('PARTNER'));
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   if (nextProps.erpType.all.length > 0 && nextProps.erpType.all.length !== this.props.erpType.all.length) {
  //     // re-render after we got all the ERP types
  //     return true
  //   }
  //   // re-render once ERP type has been changed
  //   return (nextState.erpTypeSelected !== this.state.erpTypeSelected);
  // }

  onChangeErpType(event, value) {
    const { erpType } = this.props;

    // set selected based on value
    let erpTypeSelected = _.find(erpType.all, function (o) {
      return o.id == value;
    });

    this.setState({
      erpTypeSelected: erpTypeSelected,
    });
  }

  submit = (store) => {
    const { config, toggle, dispatch, dataCentre, isEdit } = this.props;

    // add datacentre-region & do not send the storeStatuses for update

    store = {
      ...store,
      storeStatuses: null,
      dataCentre: {
        id: dataCentre.selected.id,
      },
    };

    // add 1st domain on insert
    if (!isEdit) {
      let isStgPrimary = true;

      if (!store.domains) {
        store.domains = [];
      }

      for (let i = 0; i < store.domains.length; i++) {
        if (store.domains[i].isPrimary) {
          isStgPrimary = false;
          break;
        }
      }

      // add stg domain
      store.domains.unshift({
        name: store.stgDomain,
        isStaging: true,
        isPrimary: isStgPrimary,
      });
    }

    dispatch(storeActions.save(store))
      .then(() => {
        toggle();
        this.setState({
          erpTypeSelected: null,
        });
      })
      .catch(() => {
        // remove stg domain (ui purpose)
        if (!isEdit) {
          store.domains.shift();
        }
      });
  };

  onCancel() {
    const { handleCancel, toggle } = this.props;
    this.setState({
      erpTypeSelected: null,
    });
    handleCancel();
    toggle();
  }

  removeDomain(fields, index) {
    const { isEdit } = this.props;
    fields.remove(index);

    if (isEdit) {
      for (let i = 0; i < fields.length; i++) {
        fields.get(i).isPrimary = i == 0;
      }

      this.setState({
        primaryDomain: {
          ...fields.get(0),
          index: 0,
        },
      });
    } else if (this.state.primaryDomain && this.state.primaryDomain.index == index) {
      this.setState({
        primaryDomain: null,
      });
    }
  }

  makeDomainPrimary(fields, index) {
    // "Sub domains" marked as Primary
    for (let i = 0; i < fields.length; i++) {
      fields.get(i).isPrimary = i == index;
    }

    // Primary isnt the "sub domains"
    if (index == undefined) {
      this.setState({
        primaryDomain: null,
      });
    } else {
      this.setState({
        primaryDomain: {
          ...fields.get(index),
          index: index,
        },
      });
    }
  }

  render() {
    const {
      config,
      operator,
      store: { one, error },
      dataCentre,
      erpType,
      handleSubmit,
      isEdit,
    } = this.props;
    const user = JSON.parse(localStorage.getItem('userSelected'));

    let erpTypeVersions = [];

    // if ERP is selected use the erpVersions from selected ERP
    if (this.state.erpTypeSelected) {
      erpTypeVersions = this.state.erpTypeSelected.erpTypeVersions;
    } else if (one.erpType) {
      erpTypeVersions = one.erpType.erpTypeVersions;
    }

    const currentDataCentre = dataCentre.selected;
    const allowBcStoreCreation =
      !isProduction() ||
      (currentDataCentre && (currentDataCentre.code == 'prod_us2' || currentDataCentre.countryCode == 'AU'));

    // render multiple domains with redux array field
    const renderDomains = ({ fields, meta: { error, submitFailed } }) => (
      <div>
        {!isEdit && (
          <FormGroup row>
            <Col md="3">
              <label htmlFor="primaryDn">
                Domain <span className="text-primary">{!this.state.primaryDomain && '(Primary)'}</span>
              </label>
            </Col>
            <Col md="9">
              <Row>
                <Col>
                  <span style={{ display: 'flex' }}>
                    <Field
                      name="protocol"
                      component="input"
                      disabled
                      type="text"
                      className="form-control px-1"
                      placeholder="https://"
                      style={{ maxWidth: '3.5rem' }}
                    />
                    <Field name="stgDomain" component="input" type="text" className="form-control px-1" required />
                  </span>
                </Col>
                <Col md="7" className="ps-0">
                  <p style={{ display: 'inline-block', marginTop: '14px' }}>.{config.properties['domain']}</p>
                  <div className="float-end">
                    {this.state.primaryDomain ? (
                      <Button color="primary" onClick={() => this.makeDomainPrimary(fields)} className="me-2">
                        Make Primary
                      </Button>
                    ) : null}
                    <Button onClick={() => fields.push({})} color="light">
                      <i className="icon-plus" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        )}
        {fields.map((domain, index) => (
          <FormGroup key={index} row>
            <Col md="3">
              <label htmlFor={`${domain}.name`}>
                Domain{' '}
                <span className="text-primary">
                  {fields.get(index).isStaging && '(Staging) '} {fields.get(index).isPrimary && '(Primary) '}{' '}
                </span>{' '}
              </label>
            </Col>
            <Col md="9">
              <Row>
                <Col>
                  <span style={{ display: 'flex' }}>
                    <Field
                      name={`${domain}.protocol`}
                      component="input"
                      disabled
                      type="text"
                      className="form-control px-1"
                      placeholder="https://"
                      style={{ maxWidth: '3.5rem' }}
                    />
                    <Field
                      name={`${domain}.name`}
                      component="input"
                      type="text"
                      className="form-control px-1"
                      disabled={fields.get(index).isStaging}
                      required
                    />
                  </span>
                </Col>
                <Col md="5" className="ps-0">
                  <div className="float-end">
                    {!fields.get(index).isPrimary && (
                      <Button color="primary" onClick={() => this.makeDomainPrimary(fields, index)} className="me-2">
                        Make Primary
                      </Button>
                    )}
                    <ButtonGroup>
                      {!isEdit || !fields.get(index).isStaging ? (
                        <Button onClick={() => this.removeDomain(fields, index)} color="light">
                          <i className="icon-trash" />
                        </Button>
                      ) : null}
                      {isEdit && index == 0 ? (
                        <Button onClick={() => fields.push({})} color="light">
                          <i className="icon-plus" />
                        </Button>
                      ) : null}
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        ))}
      </div>
    );

    return (
      <div>
        <div>
          <form onSubmit={handleSubmit(this.submit)} className="form-horizontal">
            {error && <Alert color="danger">{error}</Alert>}
            <hr className="separator" />
            <Field name="id" component="input" type="text" className="form-control" hidden />
            <Field name="originName" component="input" type="text" className="form-control" hidden />
            <FormGroup row>
              <Col md="3">
                <label htmlFor="dataCentre">Data Center</label>
              </Col>
              <Col md="9" className="align-middle">
                <ReactFlagsSelect
                  rfsKey={uniqueId()}
                  selected={dataCentre.selected.countryCode}
                  disabled={true}
                  showSelectedLabel={false}
                  className={"menu-flags"}
                  selectButtonClassName={"menu-flags-btn"}
                  onSelect={() => void 0}
                />
                <span>{dataCentre.selected.name}</span>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <label htmlFor="name">Name</label>
              </Col>
              <Col md="9">
                <Field
                  name="name"
                  type="text"
                  className="form-control"
                  required
                  maxLength={30}
                  component={renderInputField}
                />
              </Col>
            </FormGroup>

            <FieldArray name="domains" component={renderDomains} />

            <FormGroup row>
              <Col md="3">
                <label htmlFor="erpType">ERP</label>
              </Col>
              <Col md="5">
                <Field
                  name="erpType.id"
                  component="select"
                  className="form-control"
                  onChange={this.onChangeErpType.bind(this)}
                  disabled={isEdit}
                  required
                >
                  <option />
                  {erpType.all.map((type) => {
                    if (!allowBcStoreCreation && type.code === 'MS_Dynamics_BC') {
                      return;
                    }

                    return (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    );
                  })}
                </Field>
              </Col>
              <Col md="4">
                <Field name="erpTypeVersion.id" component="select" className="form-control" required>
                  <option />
                  {erpTypeVersions.map((version) => {
                    return (
                      <option key={version.id} value={version.id}>
                        {version.name}
                      </option>
                    );
                  })}
                </Field>
              </Col>
            </FormGroup>
            <h5>Contact Person</h5>
            <hr className="separator" />
            <FormGroup row>
              <Col md="3">
                <label htmlFor="contactName">Name</label>
              </Col>
              <Col md="9">
                <Field name="contactName" component="input" type="text" className="form-control" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="contactEmail">Email</label>
              </Col>
              <Col md="9">
                <Field name="contactEmail" component="input" type="text" className="form-control" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="contactPhone">Phone</label>
              </Col>
              <Col md="9">
                <Field name="contactPhone" component="input" type="text" className="form-control" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="partnerUsers">Partners</label>
              </Col>
              <Col md="9">
                <Field
                  name="partnerUsers"
                  component={SelectField}
                  options={operator.all}
                  isMulti={true}
                  defaultValue={user.role == 'PARTNER' ? user : null}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <label htmlFor="storeType">Store Type</label>
              </Col>
              <Col md="9">
                <Field name="storeType" component="select" className="form-control" required>
                  <option />
                  {config.enums &&
                    config.enums['storeType'].map((type, key) => {
                      return (
                        <option key={key} value={type}>
                          {capitalizeFirstLetter(type)}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </FormGroup>
            <div className="float-end">
              <Button type="button" color="secondary" className="me-2" onClick={() => this.onCancel()}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
        {isEdit ? (
          <div style={{ paddingTop: '3rem' }}>
            <Card>
              <CardBody>
                <XMTable headers={[{ name: 'Status' }, { name: 'User' }, { name: 'When' }]} autoRenderRow={true}>
                  {one.storeStatuses.map((ss) => (
                    <React.Fragment key={ss.id}>
                      <td className="align-middle">
                        <span>{ss.statusType}</span>
                      </td>
                      <td className="align-middle">
                        <span>{ss.createdBy}</span>
                      </td>
                      <td className="align-middle">
                        <Moment format="Do MMM YYYY, HH:mm" withTitle>
                          {ss.createdDate}
                        </Moment>
                      </td>
                    </React.Fragment>
                  ))}
                </XMTable>
              </CardBody>
            </Card>
          </div>
        ) : null}
      </div>
    );
  }
}

export default StoreForm;
