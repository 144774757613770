import React, { Component } from 'react';
import { Col, Card, CardTitle, CardBody, FormGroup, Button } from 'reactstrap';

import { Field, reduxForm } from 'redux-form';
import XMTable from '../../../components/XMTable';

@reduxForm({
  form: 'webserviceForm',
  enableReinitialize: true
})
class Webservice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submit = form => {
    console.log('### form ###', form);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Card>
        <CardBody>
          <CardTitle>Resources</CardTitle>
          <form
            onSubmit={handleSubmit(this.submit)}
            className="form-horizontal"
          >
            <XMTable
              headers={[
                { name: 'Resource' },
                { name: 'Resource Type' },
                { name: 'Resource Id' },
                { name: 'Description' }
              ]}
            >
              <tr>
                <td className="align-middle">Local Menu</td>
                <td className="align-middle">Product Status</td>
                <td className="align-middle">
                  <Field
                    name="productStatus"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Product states used to filter product data.
                </td>
              </tr>
              <tr>
                <td className="align-middle">Local Menu</td>
                <td className="align-middle">Due Date Item Type</td>
                <td className="align-middle">
                  <Field
                    name="dueDateItemType"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Transaction types used to fiulter transaction data.
                </td>
              </tr>
              <tr>
                <td className="align-middle">Sage Exchange</td>
                <td className="align-middle">Transaction types</td>
                <td className="align-middle">
                  <Field
                    name="dueDateItemType"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Sage Exchange Transaction types used to classify Sage Exchange
                  Transactions.
                </td>
              </tr>
              <tr>
                <td className="align-middle">Sage Exchange</td>
                <td className="align-middle">Payment Processor Status</td>
                <td className="align-middle">
                  <Field
                    name="dueDateItemType"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Sage Gateway Type Codes to classify Sage Exchange Gateways
                  used
                </td>
              </tr>
              <tr>
                <td className="align-middle">Sage Exchange</td>
                <td className="align-middle">Payment Card Status</td>
                <td className="align-middle">
                  <Field
                    name="dueDateItemType"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Sage Excahnge Payment transaction status code used to classify
                  payment transactions
                </td>
              </tr>
              <tr>
                <td className="align-middle">Miscellaneous</td>
                <td className="align-middle">Customer Statistical Group 1</td>
                <td className="align-middle">
                  <Field
                    name="dueDateItemType"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </td>
                <td className="align-middle">
                  Sage Exchange Payment transaction stauts codes used to
                  classufy payment transactions
                </td>
              </tr>
            </XMTable>
            <div className="float-end">
              <Button type="submit" color="primary" outline className="me-3">
                Populate Resources
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default Webservice;
