// BACKUP REDUCER
const initialState = {
  fetched: false,
  loading: false,
  selected: {},
  one: {},
  all: [],
  allCloneable: {
    backups: [],
    pagination: {}
  },
  pagination: {}
};
export default function backupReducer(state = initialState, action) {
  switch (action.type) {
    case 'BACKUP_LOADING': {
      return {
        ...state,
        loading: true,
        allCloneable: initialState.allCloneable
      };
    }
    case 'BACKUP_GETALL_SUCCESS': {
      return {
        ...state,
        fetched: true,
        loading: false,
        all: action.payload.content,
        pagination: {
          last: action.payload.isLast,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
          size: action.payload.size,
          number: action.payload.number,
          first: action.payload.isFirst,
          sort: action.payload.sort,
          numberOfElements: action.payload.numberOfElements
        }
      };
    }
    case 'BACKUP_GETALL_CLONEABLE_SUCCESS': {
      return {
        ...state,
        loading: false,
        allCloneable: {
          ...state.allCloneable,
          backups: action.payload.content,
          pagination: {
            last: action.payload.last,
            totalElements: action.payload.totalElements,
            totalPages: action.payload.totalPages,
            size: action.payload.size,
            page: action.payload.number,
            first: action.payload.first,
            numberOfElements: action.payload.numberOfElements
          }
        }
      };
    }
    case 'BACKUP_DELETE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'BACKUP_CREATE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'BACKUP_RESTORE_SUCCESS': {
      return {
        ...state,
        one: initialState.one
      };
    }
    case 'BACKUP_SELECT': {
      return {
        ...state,
        selected: action.payload
      };
    }
    case 'BACKUP_CLEAR': {
      return {
        ...state,
        one: initialState.one,
        allCloneable: initialState.allCloneable
      };
    }
  }
  return state;
}
